import { clsx } from 'clsx';
import type { InputHTMLAttributes } from 'react';
import { forwardRef } from 'react';
import { Input as AriaInput } from 'react-aria-components';
import { Flex } from '../../base/Flex/Flex';
import { Layout } from '../../base/Layout/Layout';
import { Text } from '../../base/Text/Text';
import {
  baseInputError,
  baseInputErrorContainer,
  inputIcon as iconStyles,
  input as inputStyles,
} from '../../theme/input.css';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/types';
import { inputContainer } from './Input.css';

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size' | 'style'> {
  readonly error?: string;
  readonly onClear?: () => void;
  readonly size?: 'md' | 'sm';
  readonly icon?: IconName;
}

// TODO: add support for onClear when icon is available

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ disabled, error, value, type, size, className: externalClassName, icon, ...restProps }: InputProps, ref) => {
    return (
      <Layout className={clsx(externalClassName, inputContainer)}>
        <AriaInput
          className={inputStyles({ size, hasIcon: Boolean(icon) })}
          data-has-error={Boolean(error) || undefined}
          disabled={disabled}
          ref={ref}
          type={type}
          value={value}
          {...restProps}
        />
        {icon != null && <Icon className={iconStyles} color="neutral7" data-testid="input-icon" name={icon} />}
        {Boolean(error) && (
          <Flex className={baseInputErrorContainer}>
            <Text className={baseInputError}>{error}</Text>
          </Flex>
        )}
      </Layout>
    );
  },
);
