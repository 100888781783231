import { Banner, Button, Card, Flex, spacing, Text } from '@lemonade-hq/blender-ui';
import { EmptySection, LoadingSection } from '@lemonade-hq/bluis';
import { DatetimeFormat, formatDatetime } from '@lemonade-hq/lemonation';
import { Suspense, useCallback } from 'react';
import { emptySectionWrapper } from './ImpactAnalysis.css';
import { Insights } from './Insights';
import { getTopicBannerText, getTopics2ndTitle, topicsSubtitlesMap, topicsTitlesMap } from './utils';
import { StyledSectionHeader } from 'components/LoCo/LoCoPagesSharedStyles';
import type { Release } from 'models/LoCo/Insurance/Release';
import type { Topic } from 'models/LoCo/Insurance/ReleaseRolloutInsightsReport';
import {
    useRefreshRolloutInsights,
    useSuspenseGetReleaseRolloutInsightsReport,
} from 'queries/LoCo/Insurance/ReleasesQueries';

const ReportTitle: React.FC<{
    readonly triggeredAt: string;
    readonly reportInProgress: boolean;
    readonly refreshInsights: () => void;
}> = ({ triggeredAt, reportInProgress, refreshInsights }) => {
    return (
        <Text>
            The data below is accurate as of &nbsp;
            <b>
                {formatDatetime(new Date(triggeredAt), {
                    datetimeFormat: DatetimeFormat.FullDateWithTime,
                })}
                &nbsp;(UTC)
            </b>
            {!reportInProgress && (
                <>
                    . Need fresher data?
                    <Button label="Click here" onClick={refreshInsights} variant="text" />
                    to refresh Insights.
                </>
            )}
        </Text>
    );
};

const TopicTitle: React.FC<{ readonly topic: Topic }> = ({ topic }) => (
    <Flex alignItems="flex-start" flexDirection="column" pb={spacing.s16}>
        <Text type="h4">{`${topic.analyzedCount} ${topicsTitlesMap[topic.topic]}`}</Text>
        <Text type="text-md">{topicsSubtitlesMap[topic.topic][topic.metCriteriaCount === 0 ? 0 : 1]}</Text>
        {topic.metCriteriaCount > 0 && (
            <Text type="text-md">
                {getTopics2ndTitle(topic.topic, topic.metCriteriaCount, topic.metCriteriaPercent)}
            </Text>
        )}
    </Flex>
);

export const ImpactAnalysis: React.FC<{ readonly release: Release }> = ({ release }) => {
    const {
        data: { report, reportInProgress },
    } = useSuspenseGetReleaseRolloutInsightsReport(release.publicId);
    const { topics } = report ?? {};

    const { mutateAsync } = useRefreshRolloutInsights(release.publicId);

    const refreshInsights = useCallback(async () => {
        await mutateAsync(release.publicId);
    }, [mutateAsync, release.publicId]);

    return (
        <>
            {reportInProgress && (
                <Banner
                    mb={spacing.s32}
                    title={
                        <Text>
                            <b>Report in progress</b> - a fresh Rollout Insights report for this Release is currently
                            being generated. Check back soon!
                        </Text>
                    }
                    variant="update"
                    withBorder={false}
                />
            )}
            <StyledSectionHeader>Let’s plan your release!</StyledSectionHeader>
            <Suspense fallback={<LoadingSection noBorders noShadow />}>
                {report == null ? (
                    <Flex className={emptySectionWrapper}>
                        <EmptySection subtitle="Check back soon!">No report to display</EmptySection>
                    </Flex>
                ) : (
                    <Flex flexDirection="column" gap={spacing.s20} width="100%">
                        <ReportTitle
                            refreshInsights={refreshInsights}
                            reportInProgress={reportInProgress}
                            triggeredAt={report.triggeredAt}
                        />
                        {topics?.map(topic => {
                            const bannerTitle = getTopicBannerText(
                                topic.topic,
                                topic.insights,
                                release.rolloutStrategy
                            );

                            return (
                                <Card key={topic.topic} p={spacing.s16}>
                                    <Flex flexDirection="row" justifyContent="space-between">
                                        <TopicTitle topic={topic} />
                                        {topic.csvLink != null && (
                                            <a href={topic.csvLink} rel="noopener noreferrer" target="_blank">
                                                <Button label="Download CSV" variant="secondary" />
                                            </a>
                                        )}
                                    </Flex>
                                    <Insights insights={topic.insights} topic={topic.topic} />
                                    {bannerTitle != null && (
                                        <Banner mt={spacing.s16} title={bannerTitle} variant="info" />
                                    )}
                                </Card>
                            );
                        })}
                    </Flex>
                )}
            </Suspense>
        </>
    );
};
