import { useEffect, useRef } from 'react';
import type { FC } from 'react';
import { useUnArchiveAttachment } from '../AttachmentsQueries';
import { useAttachmentsData } from '../context';
import type { AttachmentDTO } from '../types';
import { ArchiveDialog } from './Dialogs/ArchiveDialog';
import { UpdateAttachmentDialog } from './Dialogs/UpdateAttachmentDialog';

interface AttachmentActionsProps {
    readonly action?: string;
    readonly attachments: AttachmentDTO[];
    readonly onClose: () => void;
}

export const AttachmentActions: FC<AttachmentActionsProps> = ({ action, attachments, onClose }) => {
    const { entityPublicId, entityType } = useAttachmentsData();
    const { mutateAsync: unArchive } = useUnArchiveAttachment({
        entityPublicId: entityPublicId ?? '',
        entityType,
    });

    const prevAction = useRef<string | undefined>();

    useEffect(() => {
        const handleUnarchive = async (): Promise<void> => {
            await unArchive(
                attachments.map(it => ({
                    attachmentPublicId: it.publicId,
                }))
            );

            onClose();
        };

        if (action === 'unarchive' && prevAction.current !== action) {
            void handleUnarchive();
        }

        prevAction.current = action;
    }, [action, attachments, onClose, unArchive]);

    switch (action) {
        case 'archive':
            return <ArchiveDialog attachments={attachments} onClose={onClose} />;
        case 'update':
            return <UpdateAttachmentDialog attachments={attachments} onClose={onClose} />;
        default:
            return null;
    }
};
