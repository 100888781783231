import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { insuranceBlender } from '../../../apiClients';
import type { UWRuleType } from 'components/LoCo/common/display-texts/underwriting-rules';
import type { CreateEditionParams } from 'models/LoCo/Insurance/BaseEdition';
import type {
    UnderwritingDeclineRule,
    UnderwritingFiltersEdition,
    UnderwritingFlagRule,
} from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import { usePessimisticMutation } from 'queries/MutationHooks';

const BASE_PATH = '/api/v1/underwriting-filters-editions';

export enum UnderwritingFiltersEditionQueryKey {
    GetUnderwritingFiltersEdition = 'GET_UNDERWRITING_FILTERS_EDITION',
    GetProductUnderwritingFiltersEditions = 'GET_PRODUCT_UNDERWRITING_FILTERS_EDITIONS',
    CreateUnderwritingFiltersEdition = 'CREATE_UNDERWRITING_FILTERS_EDITION',
    ApproveUnderwritingFiltersEdition = 'APPROVE_UNDERWRITING_FILTERS_EDITION',
    ArchiveUnderwritingFiltersEdition = 'ARCHIVE_UNDERWRITING_FILTERS_EDITION',
    CreateUnderwritingFiltersEditionDeclineRule = 'CREATE_UNDERWRITING_FILTERS_EDITION_DECLINE_RULE',
    UpdateUnderwritingFiltersEditionDeclineRule = 'UPDATE_UNDERWRITING_FILTERS_EDITION_DECLINE_RULE',
    CreateUnderwritingFiltersEditionFlagsRule = 'CREATE_UNDERWRITING_FILTERS_EDITION_FLAG_RULE',
    EditUnderwritingFiltersEditionRule = 'EDIT_UNDERWRITING_FILTERS_EDITION_RULE',
    DeleteUnderwritingFiltersEditionRule = 'DELETE_UNDERWRITING_FILTERS_EDITION_RULE',
}

export async function getUnderwritingFiltersEdition(editionCode: string): Promise<UnderwritingFiltersEdition> {
    const response = await insuranceBlender.get<{ data: UnderwritingFiltersEdition }>(`${BASE_PATH}/${editionCode}`);

    return response.data.data;
}

export function useGetUnderwritingFiltersEdition(editionCode: string): UseQueryResult<UnderwritingFiltersEdition> {
    return useQuery({
        queryKey: [UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode],
        queryFn: async () => await getUnderwritingFiltersEdition(editionCode),
        enabled: Boolean(editionCode),
    });
}

async function createUnderwritingFiltersEdition({
    productCode,
    description,
    baseEditionCode,
}: CreateEditionParams): Promise<UnderwritingFiltersEdition> {
    const response = await insuranceBlender.post<{ data: UnderwritingFiltersEdition }>(BASE_PATH, {
        productCode,
        description,
        baseEditionCode,
    });

    return response.data.data;
}

export type DeclineRuleParamsRequest = Pick<
    UnderwritingDeclineRule,
    'expression' | 'lifecycleContext' | 'reasonCode'
> & { readonly editionCode: string };

async function createUnderwritingDeclineFiltersRules(params: DeclineRuleParamsRequest): Promise<void> {
    const { editionCode, ...rest } = params;
    await insuranceBlender.post<{ data: UnderwritingFiltersEdition }>(
        `${BASE_PATH}/${editionCode}/decline-rules`,
        rest
    );
}

export function useCreateUnderwritingDeclineFiltersRules(
    editionCode: string
): UseMutationResult<void, unknown, DeclineRuleParamsRequest, null> {
    return usePessimisticMutation({
        mutationFn: createUnderwritingDeclineFiltersRules,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.CreateUnderwritingFiltersEditionDeclineRule],
    });
}

async function updateUnderwritingDeclineFiltersRules(ruleId: string, params: DeclineRuleParamsRequest): Promise<void> {
    const { editionCode, ...rest } = params;
    await insuranceBlender.put<{ data: UnderwritingFiltersEdition }>(
        `${BASE_PATH}/${editionCode}/decline-rules/${ruleId}`,
        rest
    );
}

export function useUpdateUnderwritingDeclineFiltersRules(
    editionCode: string,
    ruleId: string
): UseMutationResult<void, unknown, DeclineRuleParamsRequest, null> {
    return usePessimisticMutation({
        mutationFn: async (params: DeclineRuleParamsRequest) =>
            await updateUnderwritingDeclineFiltersRules(ruleId, params),
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.UpdateUnderwritingFiltersEditionDeclineRule],
    });
}

export type CreateFlagRuleParams = Pick<UnderwritingFlagRule, 'expression' | 'flagCode'> & {
    readonly editionCode: string;
};

async function createUnderwritingFlagsFiltersRules(params: CreateFlagRuleParams): Promise<void> {
    const { editionCode, ...rest } = params;
    await insuranceBlender.post<{ data: UnderwritingFiltersEdition }>(`${BASE_PATH}/${editionCode}/flag-rules`, rest);
}

export function useCreateUnderwritingFlagsFiltersRules(
    editionCode: string
): UseMutationResult<void, unknown, CreateFlagRuleParams, null> {
    return usePessimisticMutation({
        mutationFn: createUnderwritingFlagsFiltersRules,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.CreateUnderwritingFiltersEditionFlagsRule],
    });
}

export function useCreateUnderwritingFiltersEdition(
    productCode: string
): UseMutationResult<UnderwritingFiltersEdition, unknown, CreateEditionParams, null> {
    return usePessimisticMutation({
        mutationFn: createUnderwritingFiltersEdition,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetProductUnderwritingFiltersEditions, productCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.CreateUnderwritingFiltersEdition],
    });
}

interface EditUnderwritingFiltersEditionRuleParams {
    readonly editionCode: string;
    readonly ruleId: string;
    readonly ruleType: UWRuleType;
}

export async function removeUnderwritingFiltersEditionRule({
    editionCode,
    ruleId,
    ruleType,
}: EditUnderwritingFiltersEditionRuleParams): Promise<void> {
    await insuranceBlender.delete(
        `${BASE_PATH}/${editionCode}/${ruleType === 'flag' ? 'flag-rules' : 'decline-rules'}/${ruleId}`
    );
}

export function useRemoveUnderwritingFiltersEditionRule(
    editionCode: string
): UseMutationResult<void, unknown, EditUnderwritingFiltersEditionRuleParams, null> {
    return usePessimisticMutation({
        mutationFn: removeUnderwritingFiltersEditionRule,
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.DeleteUnderwritingFiltersEditionRule],
    });
}

interface ReorderUnderwritingFiltersEditionRule {
    readonly editionCode: string;
    readonly rulePublicId: string;
    readonly destination: number;
    readonly ruleType: UWRuleType;
}

async function reorderUnderwritingFiltersEditionRule({
    editionCode,
    rulePublicId,
    destination,
    ruleType,
}: ReorderUnderwritingFiltersEditionRule): Promise<void> {
    const destinationPath = ruleType === 'flag' ? 'flag-rules' : 'decline-rules';
    await insuranceBlender.put(`${BASE_PATH}/${editionCode}/${destinationPath}/${rulePublicId}/order`, { destination });
}

type ReorderMutationArgs = Pick<ReorderUnderwritingFiltersEditionRule, 'destination' | 'rulePublicId'>;

export function useReorderUnderwritingFiltersEditionRule(
    editionCode: string,
    ruleType: UWRuleType
): UseMutationResult<void, unknown, ReorderMutationArgs, null> {
    return usePessimisticMutation({
        mutationFn: async args => await reorderUnderwritingFiltersEditionRule({ editionCode, ruleType, ...args }),
        invalidateKeys: [[UnderwritingFiltersEditionQueryKey.GetUnderwritingFiltersEdition, editionCode]],
        mutationKey: [UnderwritingFiltersEditionQueryKey.EditUnderwritingFiltersEditionRule],
    });
}
