import { Tag, TagMode, TagSize, themedColor } from '@lemonade-hq/bluis';
import { Flex, font } from '@lemonade-hq/cdk';
import type React from 'react';
import { isValidElement } from 'react';
import styled from 'styled-components';
import type { HeaderSectionProps } from './common';

const StyledTag = styled(Tag)`
  margin-left: 10px;
`;

const Container = styled(Flex)`
  text-transform: capitalize;
  flex-direction: column;
`;

const Title = styled.div.attrs({
  role: 'heading',
})`
  color: ${themedColor('primaryText')};
  ${font('main', { fontSize: '20px', lineHeight: '27px' })};
`;

const SubTitle = styled.div.attrs({
  role: 'description',
})`
  text-transform: uppercase;
  color: ${themedColor('primaryText')};
  ${font('main', { fontSize: '14px', lineHeight: '27px' })};
`;

export interface HeaderGeneralDetailsProps extends HeaderSectionProps {
  readonly title: JSX.Element | string;
  readonly subtitle?: JSX.Element | string;
  readonly partnerName?: string;
  readonly status?: {
    readonly mode: TagMode;
    readonly label: string;
    readonly size?: TagSize;
  };
}

export const HeaderGeneralDetails: React.FC<HeaderGeneralDetailsProps> = ({ title, subtitle, status, partnerName }) => {
  return (
    <Container>
      <Flex alignItems="center" gap="8px">
        <Title>{title}</Title>
        <Flex alignItems="center" gap="4px">
          {status && (
            <StyledTag light mode={status.mode} tagSize={status.size != null ? status.size : TagSize.Small} withStroke>
              {status.label}
            </StyledTag>
          )}
          {partnerName != null && (
            <Tag light mode={TagMode.Info} tagSize={TagSize.Small}>
              {partnerName}
            </Tag>
          )}
        </Flex>
      </Flex>
      {isValidElement(subtitle) ? subtitle : subtitle != null && <SubTitle>{subtitle}</SubTitle>}
    </Container>
  );
};
