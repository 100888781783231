import type { BadgeVariant } from '@lemonade-hq/blender-ui';
import {
    Accordion,
    Badge,
    Button,
    Flex,
    IconButton,
    spacing,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Tooltip,
} from '@lemonade-hq/blender-ui';
import { EmptySection, SummarySection, SummarySectionContent, SummarySectionTitle } from '@lemonade-hq/bluis';
import { capitalize } from '@lemonade-hq/ts-helpers';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { CoveragesGeneralDetailsSection, SettingsGeneralDetailsSection } from './GeneralDetailsSection';
import * as styles from './rulesTable.css';
import {
    ruleExpressionDisplay,
    RulesActionMenu,
} from 'components/LoCo/common/components/CoverageRules/CoverageRulesShared';
import { useOrderableTable } from 'components/LoCo/common/components/Table/useOrderableTable';
import { formatLifeCycleContext } from 'components/LoCo/common/display-texts/common';
import type { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageRuleDisplay, CoverageRuleType, RuleEntityType } from 'models/LoCo/Insurance/CoverageRule';
import type { CoverageInstance, SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import { isSettingsInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import {
    AlertLevel,
    CoverageRuleGroupType,
    CoverageRuleGroupTypeMap,
} from 'models/LoCo/Insurance/DigitalAgentEditionV2';
import type {
    Alert,
    AlertType,
    DigitalAgentPreviewEntity,
    EditionRulesGroup,
} from 'models/LoCo/Insurance/DigitalAgentEditionV2';
import { useOptimisticReorderDigitalAgentEditionRulesV2 } from 'queries/LoCo/Insurance/DigitalAgentEditionQueries';

type HandleReorder = ReturnType<typeof useOrderableTable>['handleReorder'];

interface OrderCellProps {
    readonly index: number;
    readonly isReorderMode: boolean;
    readonly handleReorder: HandleReorder;
    readonly rulePublicId: string;
    readonly lastIndex: number;
    readonly isLoading?: boolean;
}

const OrderCell: FC<OrderCellProps> = ({ index, isReorderMode, handleReorder, rulePublicId, lastIndex, isLoading }) => {
    return (
        <Flex alignItems="center" gap={spacing.s08} justifyContent="space-between" paddingInlineEnd={'16px'}>
            <Text>{index + 1}</Text>
            {isReorderMode && (
                <Flex flexDirection="column" gap={spacing.s04}>
                    <IconButton
                        disabled={index === 0 || isLoading}
                        icon="chevron-up"
                        onClick={async () => await handleReorder(rulePublicId, 'Up', index)}
                        size="sm"
                        variant="secondary"
                    />
                    <IconButton
                        disabled={index === lastIndex || isLoading}
                        icon="chevron-down"
                        onClick={async () => await handleReorder(rulePublicId, 'Down', index)}
                        size="sm"
                        variant="secondary"
                    />
                </Flex>
            )}
        </Flex>
    );
};

interface RulesTableProps {
    readonly filedRules: CoverageRuleDisplay[];
    readonly nonFiledRules: CoverageRuleDisplay[];

    readonly handleReorder: HandleReorder;
    readonly isReorderMode: boolean;
    readonly readonly: boolean;
    readonly editionCode: string;
    readonly groupedRulesType: CoverageRuleGroupType;
    readonly isLoadingRulesReorder?: boolean;
}

const RulesTable: FC<RulesTableProps> = ({
    filedRules,
    nonFiledRules,
    handleReorder,
    isReorderMode,
    readonly,
    editionCode,
    groupedRulesType,
    isLoadingRulesReorder,
}) => {
    const showContext = groupedRulesType === CoverageRuleGroupType.Restriction;

    return (
        <div
            className={styles.wrapper({
                variant: showContext ? 'withContext' : undefined,
            })}
        >
            <div className={styles.headerRow}>
                <Text color="tertiary">#</Text>
                {showContext && <Text color="tertiary">CONTEXT</Text>}
                <Text color="tertiary">SEGMENT</Text>
                <Text color="tertiary">OUTCOME</Text>
            </div>
            <div className={styles.rules}>
                {filedRules.map(rule => (
                    <div className={styles.row} key={rule.publicId}>
                        <Text color="tertiary">Filed</Text>
                        <Text color="tertiary">{ruleExpressionDisplay(rule.expression, true)}</Text>
                        <Text color="tertiary">{capitalize(rule.outcomeDisplay)}</Text>
                    </div>
                ))}
                {nonFiledRules.map((rule, index) => (
                    <div className={styles.row} key={rule.publicId}>
                        <OrderCell
                            handleReorder={handleReorder}
                            index={index}
                            isLoading={isLoadingRulesReorder}
                            isReorderMode={isReorderMode}
                            lastIndex={nonFiledRules.length - 1}
                            rulePublicId={rule.publicId}
                        />
                        {showContext && <Text>{rule.lifecycleContexts.map(formatLifeCycleContext).join(', ')}</Text>}
                        <Text>{ruleExpressionDisplay(rule.expression, true)}</Text>
                        <Text>{capitalize(rule.outcomeDisplay)}</Text>
                        {!readonly && (
                            <RulesActionMenu
                                editionCode={editionCode}
                                editionType={EditionType.DigitalAgent}
                                rule={rule}
                            />
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

const RulesTabs: FC<RulesTableProps> = props => {
    return (
        <Tabs variant="inline">
            <TabList>
                <Tab key="control">Control</Tab>
            </TabList>
            <TabPanels>
                <TabPanel>
                    <RulesTable {...props} />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

function alertLevelToVariant(level: AlertLevel): BadgeVariant {
    // eslint-disable-next-line default-case
    switch (level) {
        case AlertLevel.Warning:
            return 'negative';
        case AlertLevel.Info:
            return 'info';
    }
}

interface RulesCardProps {
    readonly title: string;
    readonly rules: CoverageRuleDisplay[];
    readonly editionCode: string;
    readonly readonly: boolean;
    readonly alerts: Alert<AlertType>[];
    readonly addRule: () => void;
    readonly groupedRulesType: CoverageRuleGroupType;
}

const RulesCard: FC<RulesCardProps> = ({ title, rules, editionCode, readonly, alerts, addRule, groupedRulesType }) => {
    const { mutateAsync: reorderRule } = useOptimisticReorderDigitalAgentEditionRulesV2(editionCode);

    const items = useMemo(() => rules.map(rule => rule.publicId), [rules]);

    const onReorder = useCallback(
        async (item: string, order: number) => {
            await reorderRule({ publicId: item, destination: order });
        },
        [reorderRule]
    );
    const { reorderButton, handleReorder, isReorderMode } = useOrderableTable(items, onReorder);

    const filedRules = rules.filter(rule => rule.isFiled);
    const nonFiledRules = rules.filter(rule => !rule.isFiled);

    return (
        <SummarySection title={title}>
            <SummarySectionTitle>
                <Flex flexDirection="column" gap={spacing.s08}>
                    <Text type="h5">{title}</Text>
                    {alerts.map(alert => (
                        <Tooltip content={alert.message} key={alert.type} side="top">
                            <Flex>
                                <Badge
                                    key={alert.type}
                                    label={alert.title}
                                    stroke
                                    variant={alertLevelToVariant(alert.level)}
                                />
                            </Flex>
                        </Tooltip>
                    ))}
                </Flex>
            </SummarySectionTitle>
            <SummarySectionContent gridColsWidth="1fr" style={{ width: '100%' }}>
                <Flex flexDirection="column" gap={spacing.s08} style={{ position: 'relative' }}>
                    {rules.length === 0 ? (
                        <>
                            {!readonly && (
                                <Flex justifyContent="flex-end">
                                    <Button label="Add rule" onClick={addRule} variant="secondary" />
                                </Flex>
                            )}
                            <EmptySection>No rules configured.</EmptySection>
                        </>
                    ) : (
                        <>
                            <RulesTabs
                                editionCode={editionCode}
                                filedRules={filedRules}
                                groupedRulesType={groupedRulesType}
                                handleReorder={handleReorder}
                                isReorderMode={isReorderMode}
                                nonFiledRules={nonFiledRules}
                                readonly={readonly}
                            />
                            {!readonly && (
                                <Flex gap={spacing.s12} style={{ position: 'absolute', right: 0 }}>
                                    {nonFiledRules.length > 1 && reorderButton}
                                    <Button label="Add rule" onClick={addRule} variant="secondary" />
                                </Flex>
                            )}
                        </>
                    )}
                </Flex>
            </SummarySectionContent>
        </SummarySection>
    );
};

interface PreviewEntityAccordionProps {
    readonly previewEntity: DigitalAgentPreviewEntity<CoverageInstance | SettingInstance>;
    readonly editionCode: string;
    readonly readonly: boolean;
    readonly type: RuleEntityType;
    readonly addRule: (
        entity: { readonly type: RuleEntityType; readonly code: string },
        lifecycle?: RuleLifecycleContext,
        ruleType?: CoverageRuleType
    ) => void;
}

export const PreviewEntityAccordion: React.FC<PreviewEntityAccordionProps> = ({
    previewEntity,
    editionCode,
    readonly,
    type,
    addRule,
}) => {
    const { instance, name, alerts } = previewEntity;
    const alert = alerts.at(0);

    const required = instance != null && !isSettingsInstance(instance) && instance.required;

    return (
        <Accordion
            badges={
                alert != null
                    ? [
                          {
                              label: alert.title,
                              variant: alertLevelToVariant(alert.level),
                              stroke: true,
                              tooltip: alert.message,
                          },
                      ]
                    : undefined
            }
            hasAsteriskMark={required}
            title={name}
        >
            <Flex flexDirection="column" gap={spacing.s08} padding="1.2rem" width="100%">
                {instance != null &&
                    (isSettingsInstance(instance) ? (
                        <SettingsGeneralDetailsSection instance={instance} />
                    ) : (
                        <CoveragesGeneralDetailsSection instance={instance} />
                    ))}

                {(Object.entries(previewEntity.rulesByType) as [CoverageRuleGroupType, EditionRulesGroup][]).map(
                    ([ruleKey, { rules, groupDisplayName, alerts: entityRules }]) => (
                        <RulesCard
                            addRule={() => {
                                const { lifecycleContext, ruleType } = CoverageRuleGroupTypeMap[ruleKey];
                                const entity = {
                                    type,
                                    code: previewEntity.code,
                                };
                                addRule(entity, lifecycleContext, ruleType);
                            }}
                            alerts={entityRules}
                            editionCode={editionCode}
                            groupedRulesType={ruleKey}
                            key={ruleKey}
                            readonly={readonly}
                            rules={rules}
                            title={groupDisplayName}
                        />
                    )
                )}
            </Flex>
        </Accordion>
    );
};
