import { useOptimisticMutation } from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import type { QueryKey, UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import { produce } from 'immer';
import { blenderGeneral } from '../../apiClients';
import type { AttachmentDTO } from './types';
import { usePessimisticMutation } from 'queries/MutationHooks';

export enum GalleryAttachmentQueryKey {
    GetAttachments = 'GET_ATTACHMENTS',
    AttachmentTypes = 'ATTACHMENT_TYPES',
    AttachmentArchiveReasons = 'ATTACHMENT_ARCHIVE_REASONS',
    UpdateAttachmentDetails = 'UPDATE_ATTACHMENT_DETAILS',
}

const getAttachmentTypes = async (entityType: EntityTypes): Promise<string[]> => {
    return await blenderGeneral
        .get<{ data: string[] }>(`/api/v1/attachments/types?entityType=${entityType}`)
        .then(async res => res.data.data);
};

export const useGetAttachmentTypes = ({
    entityType,
}: {
    readonly entityType: EntityTypes;
}): UseQueryResult<string[]> => {
    return useQuery({
        queryKey: [GalleryAttachmentQueryKey.AttachmentTypes, entityType],
        queryFn: async () => await getAttachmentTypes(entityType),
    });
};

const getAttachments = async (entityType: EntityTypes, entityPublicId: string): Promise<AttachmentDTO[]> => {
    return await blenderGeneral
        .get<{
            data: AttachmentDTO[];
        }>(`/api/v1/attachments?entityType=${entityType}&entityPublicId=${entityPublicId}&include=sources,detections`)
        .then(res => res.data.data);
};

export const useGetAttachments = ({
    entityType,
    entityPublicId,
}: {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
}): UseQueryResult<AttachmentDTO[]> => {
    return useQuery({
        queryKey: [GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType],
        queryFn: async () => await getAttachments(entityType, entityPublicId),
    });
};

const getArchiveReasons = async (): Promise<string[]> => {
    return await blenderGeneral
        .get<{ data: string[] }>('/api/v1/attachments/archive_reasons')
        .then(res => res.data.data);
};

export const useAttachmentArchiveReasons = (): UseQueryResult<string[]> => {
    return useQuery({
        queryKey: [GalleryAttachmentQueryKey.AttachmentArchiveReasons],
        queryFn: async () => await getArchiveReasons(),
        staleTime: Infinity,
    });
};

function mutateAttachmentDetails({
    data,
    variables,
}: {
    readonly data: AttachmentDTO[];
    readonly variables: {
        readonly type: string;
        readonly description: string;
        readonly attachmentPublicId: string;
    };
}): AttachmentDTO[] {
    const attachmentIndex = data.findIndex(it => it.publicId === variables.attachmentPublicId);

    return produce(data, draftData => {
        draftData[attachmentIndex].type = variables.type;
        draftData[attachmentIndex].description = variables.description;
    });
}

export const useSubmitAttachmentDetails = ({
    entityPublicId,
    entityType,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
}): UseMutationResult<
    null,
    unknown,
    {
        readonly type: string;
        readonly description: string;
        readonly attachmentPublicId: string;
    },
    AttachmentDTO[]
> => {
    return useOptimisticMutation({
        mutationFn: async ({
            type,
            description,
            attachmentPublicId,
        }: {
            type: string;
            description: string;
            attachmentPublicId: string;
        }) => {
            return await blenderGeneral.patch(`/api/v1/attachments/${attachmentPublicId}`, {
                type,
                description,
                entityPublicId,
                entityType,
            });
        },
        mutate: mutateAttachmentDetails,
        invalidateKeys: [[GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType]],
        mutateKey: [GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType],
    });
};

type ArchiveAttachment = {
    readonly reason: string;
    readonly attachmentPublicId: string;
};

const mutateArchive = ({
    data,
    variables,
}: {
    readonly data: AttachmentDTO[];
    readonly variables: ArchiveAttachment[];
}): AttachmentDTO[] => {
    let updatedData = data;

    variables.forEach(item => {
        const attachmentIndex = data.findIndex(it => it.publicId === item.attachmentPublicId);

        updatedData = {
            ...data,
            [attachmentIndex]: {
                ...data[attachmentIndex],
                status: 'archived',
                archivingReason: item.reason,
            },
        };
    });

    return updatedData;
};

export const useArchiveAttachment = ({
    entityPublicId,
    entityType,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
}): UseMutationResult<null, unknown, ArchiveAttachment[], AttachmentDTO[]> => {
    return useOptimisticMutation({
        mutationFn: async (attachmentList: ArchiveAttachment[]) => {
            return await blenderGeneral.post('/api/v1/attachments/archive', {
                entityPublicId,
                entityType,
                attachments: attachmentList,
            });
        },
        mutate: mutateArchive,
        invalidateKeys: [[GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType]],
        mutateKey: [GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType] as unknown as QueryKey[],
    });
};

const mutateUnArchive = ({
    data,
    variables,
}: {
    readonly data: AttachmentDTO[];
    readonly variables: {
        readonly attachmentPublicId: string;
    }[];
}): AttachmentDTO[] => {
    let updatedData = data;
    variables.forEach(attachment => {
        const attachmentIndex = data.findIndex(it => it.publicId === attachment.attachmentPublicId);

        updatedData = {
            ...data,
            [attachmentIndex]: {
                ...data[attachmentIndex],
                status: 'active',
            },
        };
    });

    return updatedData;
};

export const useUnArchiveAttachment = ({
    entityPublicId,
    entityType,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
}): UseMutationResult<null, unknown, { readonly attachmentPublicId: string }[], AttachmentDTO[]> => {
    return useOptimisticMutation({
        mutationFn: async (attachmentIds: { attachmentPublicId: string }[]) => {
            return await blenderGeneral.post('/api/v1/attachments/unarchive', {
                entityPublicId,
                entityType,
                attachments: attachmentIds,
            });
        },
        mutate: mutateUnArchive,
        invalidateKeys: [[GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType]],
        mutateKey: [GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType] as unknown as QueryKey[],
    });
};

function mutateSuggestedArchive({
    data,
    variables,
}: {
    readonly data: AttachmentDTO[];
    readonly variables: {
        readonly attachmentPublicId: string;
    };
}): AttachmentDTO[] {
    const attachmentIndex = data.findIndex(it => it.publicId === variables.attachmentPublicId);

    return produce(data, draftData => {
        draftData[attachmentIndex].suggestedArchive = false;
    });
}

export const useDismissArchiveSuggestion = ({
    entityPublicId,
    entityType,
}: {
    readonly entityPublicId: string;
    readonly entityType: EntityTypes;
}) => {
    return useOptimisticMutation({
        mutationFn: async ({ attachmentPublicId }: { attachmentPublicId: string }) => {
            return await blenderGeneral.post(`/api/v1/attachments/${attachmentPublicId}/dismiss_archive_suggestion`, {
                entityPublicId,
                entityType,
            });
        },
        mutate: mutateSuggestedArchive,
        invalidateKeys: [[GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType]],
        mutateKey: [GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType] as unknown as QueryKey[],
    });
};

export const useScanFraud = (entityPublicId: string, entityType: EntityTypes) => {
    return usePessimisticMutation({
        mutationFn: async (body: { attachmentPublicId: string; productLine?: string }) => {
            return await blenderGeneral.post(`/api/v2/fraud_detection`, { ...body, model: 'resistant_ai' });
        },
        invalidateKeys: [[GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType]],
    });
};
