import type { LabelValueItem } from '@lemonade-hq/typo';
import { displayBoolean } from 'components/LoCo/common/display-texts/common';
import type { CoverageInstance } from 'models/LoCo/Insurance/CoveragesEdition';

export function getIsBenefit(coverage: CoverageInstance): LabelValueItem {
    return {
        label: 'Non insurance benefit',
        value: displayBoolean(coverage.isBenefit),
    };
}
