import type { FC, KeyboardEventHandler, PropsWithChildren } from 'react';
import { FormLayout } from '../Form/FormLayout';
import * as styles from './DynamicList.css';

export const DynamicListContainer: FC<
  PropsWithChildren<{
    readonly as: 'ol' | 'ul';
    readonly onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  }>
> = ({ children, onKeyDown, as }) => {
  return (
    <FormLayout as={as} className={styles.list} onKeyDown={onKeyDown}>
      {children}
    </FormLayout>
  );
};
