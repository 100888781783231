import { useRef, useState } from 'react';

// defer the readiness every time the event is fired
export function useDebounce(delayInMs: number) {
    const [isReady, setIsReady] = useState(true);
    const timerRef = useRef<NodeJS.Timeout>();

    const onEvent = () => {
        if (isReady) {
            setIsReady(false);
        }

        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => setIsReady(true), delayInMs);
    };

    return {
        onEvent,
        isReady,
    };
}
