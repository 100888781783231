import { spacing } from '@lemonade-hq/blender-ui';
import { AccordionProvider, Flex, Text, themedColor, useAccordion } from '@lemonade-hq/cdk';
import styled from 'styled-components';

type CellValue = { readonly value: React.JSX.Element | string };

type Width = `${number}fr` | `${number}px`;

export type Column<TKey extends string> = { readonly key: TKey; readonly title: string; readonly width?: Width };

export type Columns = readonly Column<string>[];

export type ColumnsToRow<T extends Columns> = {
    readonly [K in T[number]['key']]: CellValue;
};

export type GridTableProps<TColumns extends Columns> = {
    readonly columns: TColumns;
    readonly rows: {
        readonly values: ColumnsToRow<TColumns>;
        readonly expended?: React.JSX.Element;
        readonly className?: string;
    }[];
    readonly options?: {
        readonly cellPadding?: Width;
    };
};

export type Rows = GridTableProps<Columns>['rows'];

// when creating a grid table where its cols and rows are dynamic (e.g. determined by conditions),
// we want to enforce that the column and row types match, and utilize TS checks of column keys vs. rows cells
export function typedRowsAndColumns<TColumns extends Columns>(data: {
    readonly columns: TColumns;
    readonly rows: GridTableProps<TColumns>['rows'];
}): { readonly columns: Columns; readonly rows: Rows } {
    return data;
}

const Grid = styled.div<{ readonly columnsWidths: Width[]; readonly nested?: boolean }>`
    display: grid;
    position: relative;
    width: 100%;
    grid-template-columns: ${props => props.columnsWidths.join(' ')};
    ${props => props.nested && `grid-column: 1 / -1;`}
`;

const TableWrapper = styled(Grid)`
    border-radius: 5px;
    border: 1px solid ${themedColor('separator')};
`;

const AccordionHeader = styled.div<{ readonly isOpen: boolean; readonly disabled: boolean }>`
    display: flex;
    flex-direction: column;
    grid-column: 1 / -1;
    background: ${({ isOpen }) => (isOpen ? themedColor('lightBackground') : 'none')};
    border: ${({ isOpen }) => (isOpen ? `1px solid` : 'none')};
    border-color: ${themedColor('secondaryButtonDisabledText')};
    border-radius: ${({ isOpen }) => (isOpen ? `3px` : '')};
    width: 100%;
    padding: 0;
    outline: 0;
    position: relative;
`;

const Accordion: React.FC<{
    readonly header: React.JSX.Element;
    readonly expended: React.JSX.Element;
}> = ({ header, expended }) => {
    const { isOpen, disabled } = useAccordion();

    return (
        <AccordionHeader disabled={disabled} isOpen={isOpen}>
            {header}
            {isOpen && expended}
        </AccordionHeader>
    );
};

const AccordionMaybe: React.FC<{
    readonly header: React.JSX.Element;
    readonly expended?: React.JSX.Element;
}> = ({ header, expended }) => {
    return expended ? <Accordion expended={expended} header={header} /> : header;
};

export const Cell: React.FC<CellValue> = ({ value }) => <Flex>{value}</Flex>;

const TableHeaderWrapper = styled(Grid)<{ readonly colGap?: Width }>`
    height: 45px;
    background: ${themedColor('lightBackground')};
    color: ${themedColor('secondaryText')};
    text-transform: uppercase;
    align-items: center;
    justify-items: start;
    padding: 10px 20px;
    font-size: 12px;
    column-gap: ${({ colGap }) => colGap ?? spacing.s10};
`;

export const TableRowWrapper = styled(Grid)<{ readonly selected?: boolean; readonly colGap?: Width }>`
    align-items: center;
    justify-items: start;
    padding: 10px 20px;
    column-gap: ${({ colGap }) => colGap ?? spacing.s10};
`;

const TableHeader: React.FC<{
    readonly columns: Columns;
    readonly columnsWidths: Width[];
    readonly colGap?: Width;
}> = ({ columns, columnsWidths, colGap }) => {
    return (
        <TableHeaderWrapper colGap={colGap} columnsWidths={columnsWidths} nested>
            {columns.map(({ title, key }) => (
                <Text key={key}>{title}</Text>
            ))}
        </TableHeaderWrapper>
    );
};

export const GridTable = <TColumns extends Columns>({
    columns,
    rows,
    options,
}: GridTableProps<TColumns>): JSX.Element => {
    const columnsWidths = Object.values<Column<string>>(columns).map(column => column.width ?? '1fr');

    return (
        <TableWrapper columnsWidths={columnsWidths}>
            <TableHeader colGap={options?.cellPadding} columns={columns} columnsWidths={columnsWidths} />
            {rows.map((row, i) => (
                <AccordionProvider key={i}>
                    <AccordionMaybe
                        expended={row.expended}
                        header={
                            <TableRowWrapper
                                className={row.className}
                                colGap={options?.cellPadding}
                                columnsWidths={columnsWidths}
                                key={i}
                                nested
                            >
                                {columns.map(col => (
                                    <Cell key={col.key} value={(row.values as Rows[number]['values'])[col.key].value} />
                                ))}
                            </TableRowWrapper>
                        }
                    />
                </AccordionProvider>
            ))}
        </TableWrapper>
    );
};
