import { Select } from '@lemonade-hq/blender-ui';

import type { Key } from 'react-aria-components';
import { Step } from 'components/LoCo/common/components/CoverageRules/ManageRuleCommon';
import { OutcomeWrapper } from 'components/LoCo/common/components/Dialog/OutcomeWrapper';
import { InputWrapper } from 'components/LoCo/common/components/InputWrapper';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import { useSuspenseGetRegistryFlagsByProductLine } from 'queries/LoCo/Insurance/UnderwritingRegistryQueries';

interface FlagSelectionProps {
    readonly onChange: (value: Key | null) => void;
    readonly flagCode: string | null;
}

export const FlagSelection: React.FC<FlagSelectionProps> = ({ onChange, flagCode }) => {
    const product = useGetProductData();

    const { data: flags } = useSuspenseGetRegistryFlagsByProductLine(product.productLineCode);

    return (
        <Step>
            <OutcomeWrapper title="Select Flag">
                <InputWrapper label="Flag">
                    <Select
                        onChange={onChange}
                        options={flags.map(flag => ({ label: flag.name, value: flag.code }))}
                        placeholder="Select Flag"
                        selectedKey={flagCode ?? undefined}
                        style={{ width: '25rem' }}
                    />
                </InputWrapper>
            </OutcomeWrapper>
        </Step>
    );
};
