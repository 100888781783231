import type { Infer, RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import get from 'lodash/get';
import type { InputHTMLAttributes, ReactNode } from 'react';
import { useCallback } from 'react';
import type { InputProps as BUIInputProps } from '../../../Input/Input';
import { Input as BUIInput } from '../../../Input/Input';
import { useForm } from '../../FormContext';
import type { CommonAdapterProps } from '../common';
import { useConnectToForms } from '../common';
import type { AssertSchemaKeyIsOfTypeNumber, AssertSchemaKeyIsOfTypeString } from '../shape-assertion.types';

type InputType = InputHTMLAttributes<HTMLInputElement>['type'];

export type AssertedInputProps<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>> = Omit<
  BUIInputProps,
  'onChange' | 'value'
> &
  (InputProps<TSchema, TSchemaKey> extends infer TExtractedProps extends {
    readonly schemaKey: infer TSchemaKeyToCheck extends TSchemaKey;
    readonly type?: infer TType extends InputType;
  }
    ? TType extends 'number'
      ? AssertSchemaKeyIsOfTypeNumber<TSchema, TSchemaKeyToCheck, TExtractedProps> & { readonly type: 'number' }
      : AssertSchemaKeyIsOfTypeString<TSchema, TSchemaKeyToCheck, TExtractedProps>
    : never);

type InputProps<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>> = BUIInputProps &
  CommonAdapterProps<TSchema, TSchemaKey>;

export const Input = <TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>(props): ReactNode => {
  const { schemaKey, rules, onBlur, type, ...restProps } = props as InputProps<TSchema, TSchemaKey>;
  const { values: data, dispatch } = useForm<TSchema>();
  const { disabled, visible } = useConnectToForms({ schemaKey, rules });

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const rawValue = e.target.value;
      const value = type === 'number' ? (rawValue === '' ? undefined : parseInt(rawValue)) : rawValue;
      dispatch({
        type: 'setValue',
        key: schemaKey as RecordPath<TSchema>,
        value: value as Infer<TSchema>[RecordPath<TSchema>],
      });
    },
    [dispatch, schemaKey, type],
  );

  return visible ? (
    <BUIInput
      disabled={disabled}
      id={schemaKey}
      onBlur={e => {
        dispatch({ type: 'blur', key: schemaKey });
        onBlur?.(e);
      }}
      onChange={onChange}
      type={type}
      value={get(data, schemaKey) ?? ''}
      {...restProps}
    />
  ) : null;
};
