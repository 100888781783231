import type { Infer, RecordLikeShape, RecordPath, RecordPathValidations } from '@lemonade-hq/maschema-schema';
import { addValidationsToRecordByPaths, validateRecord } from '@lemonade-hq/maschema-schema';
import type { Validation } from '@lemonade-hq/maschema-validations';
import { validate } from '@lemonade-hq/maschema-validations';
import { produce } from 'immer';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import set from 'lodash/set';
import type { Context, PropsWithChildren, Reducer } from 'react';
import { useMemo, useReducer } from 'react';
import type { PartialDeep } from 'type-fest';
import type { Action, Dispatch, FormContextData } from './FormContext';
import { FormContext } from './FormContext';
import type {
  Actions,
  Config,
  FormProps,
  FormStatus,
  ReducerState,
  Rules,
  SubscriptionFunction,
  SubscriptionType,
} from './types';

const initialStatus = {
  changed: false,
  submitted: false,
  shouldShowErrors: false,
  afterBlurValidationResults: {},
  afterSubmitValidationResults: {},
};

function executeAction<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>(
  key: TSchemaKey,
  actions: Actions<TSchema, TSchemaKey>[],
  values: PartialDeep<Infer<TSchema>>,
  config: Config<TSchema>,
): void {
  for (const action of actions) {
    if (action.type === 'setValue') {
      set(values, key as string, action.value);
    }

    if (action.type === 'setVisible') {
      // @ts-expect-error visible is not readonly since we cloned it
      // eslint-disable-next-line
      config.schemaKeysRules![key]!.visible = action.value;
    }

    if (action.type === 'setDisabled') {
      // @ts-expect-error disabled is not readonly since we cloned it
      // eslint-disable-next-line
      config.schemaKeysRules![key]!.disabled = action.value;
    }
  }
}

export function validateConditions<TSchema extends RecordLikeShape>(
  conditions: RecordPathValidations<TSchema>,
  values: PartialDeep<Infer<TSchema>>,
): boolean {
  return Object.entries(conditions).reduce(
    (accumulatedConditionsAcrossKeys, [schemaPath, validations]: [string, Validation[]]) => {
      const value = get(values, schemaPath);
      return (
        accumulatedConditionsAcrossKeys &&
        validations.reduce(
          (accumulatedConditions, condition) => accumulatedConditions && validate(value, condition),
          true,
        )
      );
    },
    true,
  );
}

export function runRulesForKey<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>(
  key: TSchemaKey,
  rules: Rules<TSchema, TSchemaKey>,
  values: PartialDeep<Infer<TSchema>>,
  config: Config<TSchema>,
): void {
  rules.forEach(rule => {
    const allConditionsMet = validateConditions(rule.conditions, values);

    if (allConditionsMet) {
      executeAction(key, rule.actions, values, config);
    }
  });
}

const reducerFromSchema =
  <TSchema extends RecordLikeShape, TChangePayload = never>(
    schema: TSchema,
  ): Reducer<ReducerState<TSchema, TChangePayload>, Action<TSchema, RecordPath<TSchema>, TChangePayload>> =>
  (prev, action) => {
    if (action.type === 'setShowErrors') {
      return produce(prev, draft => {
        draft.status.shouldShowErrors = action.shouldShowErrors;
        return draft;
      });
    }

    let newValues = prev.values;
    let newValidationResults = prev.validationResults;
    let newConfig = prev.config;
    let newStatus = prev.status;
    let newSubscriptions = prev.subscriptions;

    if (action.type === 'reset') {
      newValues = prev.initialValues ?? ({} as PartialDeep<Infer<TSchema>>);
      newValidationResults = validateRecord(schema, prev.initialValues);
      newStatus = initialStatus as FormStatus<TSchema>;
      Object.values(prev.subscriptions).forEach(sub =>
        sub.onReset?.({ values: newValues, validationResults: newValidationResults }),
      );
    }

    if (action.type === 'setValue') {
      newValues = set(structuredClone(prev.values), action.key, action.value);
      newValidationResults = validateRecord(schema, newValues);
      Object.values(prev.subscriptions).forEach(sub =>
        sub.onChange?.({
          key: action.key,
          value: action.value,
          validationResults: newValidationResults,
          changePayload: action.changePayload,
        }),
      );
    }

    if (action.type === 'setRules') {
      newConfig = produce(prev.config, draft => {
        // eslint-disable-next-line
        (draft.schemaKeysRules![action.key as RecordPath<RecordLikeShape>] ??= {}).rules = action.rules;
        return draft;
      });
    }

    if (action.type === 'blur') {
      newStatus = produce(prev.status, draft => {
        draft.afterBlurValidationResults[action.key as RecordPath<RecordLikeShape>] =
          prev.validationResults.failingValidations[action.key];
        return draft;
      });
    }

    if (action.type === 'subscribe') {
      newSubscriptions = produce(newSubscriptions, draft => {
        Object.entries(action.subscriptions).forEach(
          ([type, subscription]: [SubscriptionType<TSchema>, SubscriptionFunction<TSchema>]) => {
            draft[action.key] ??= {};
            (draft[action.key][type] as SubscriptionFunction<TSchema>) = subscription;
          },
        );

        return draft;
      });
    }

    if (action.type === 'unsubscribe') {
      newSubscriptions = produce(newSubscriptions, draft => {
        Object.keys(action.subscriptions).forEach((type: SubscriptionType<TSchema>) => {
          if (draft[action.key][type] != null) {
            // eslint-disable-next-line @typescript-eslint/no-dynamic-delete,
            delete draft[action.key][type];
          }
        });

        return draft;
      });
    }

    // We clone here to remove the readonly modifiers introduced by immer

    const configClone = structuredClone(newConfig);
    const valuesClone = structuredClone(newValues);

    for (const key in newConfig.schemaKeysRules) {
      runRulesForKey(
        key as RecordPath<TSchema>,
        configClone.schemaKeysRules?.[key as RecordPath<TSchema>]?.rules ?? [],
        valuesClone,
        configClone,
      );
    }

    newValues = valuesClone;
    newConfig = configClone;

    newStatus = produce(newStatus, draft => {
      draft.changed = !isEqual(newValues, prev.initialValues);
      return draft;
    });

    return {
      initialValues: prev.initialValues,
      values: newValues,
      validationResults: newValidationResults,
      config: newConfig,
      status: newStatus,
      subscriptions: newSubscriptions,
    };
  };

export const FormProvider = <TSchema extends RecordLikeShape>({
  schema,
  initialConfig,
  initialValues,
  globallyDisabled,
  children,
}: PropsWithChildren<FormProps<TSchema>>): JSX.Element => {
  const mergedSchema = useMemo(() => {
    return initialConfig?.additionalValidations
      ? addValidationsToRecordByPaths(schema, initialConfig.additionalValidations)
      : schema;
  }, [schema, initialConfig?.additionalValidations]);

  const [{ values, validationResults, config: currentConfig, status, subscriptions }, dispatch] = useReducer(
    reducerFromSchema(mergedSchema),
    {
      initialValues: initialValues ?? ({} as PartialDeep<Infer<TSchema>>),
      values: initialValues ?? ({} as PartialDeep<Infer<TSchema>>),
      validationResults: validateRecord(mergedSchema, initialValues),
      config: initialConfig ?? { schemaKeysRules: {}, showErrorsOnBlur: false, additionalValidations: {} },
      status: initialStatus as FormStatus<TSchema>,
      subscriptions: {},
    },
    args => {
      if (args.config.schemaKeysRules === undefined) {
        return produce(args, draft => {
          draft.config.schemaKeysRules = {};
        });
      }

      return args;
    },
  );

  const value = useMemo(
    (): FormContextData<TSchema> => ({
      schema: mergedSchema,
      values: values as Infer<TSchema, { readonly mergeUnions: true }>,
      dispatch: dispatch as Dispatch<TSchema>,
      validationResults,
      config: { ...currentConfig, globallyDisabled },
      status,
      subscriptions,
    }),
    [mergedSchema, values, dispatch, validationResults, currentConfig, status, subscriptions, globallyDisabled],
  );

  const TypeSafeFormContext = FormContext as unknown as Context<FormContextData<TSchema>>;

  return <TypeSafeFormContext.Provider value={value}>{children}</TypeSafeFormContext.Provider>;
};
