import pluralize from 'pluralize';
import { toReadable } from 'commons/StringUtils';
import type { Duration, SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import {
    DurationType,
    EntityScopeValueSelectionMethod,
    InsuranceScopeType,
    SettingUnit,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

export function getDurationTypeDisplayName(duration: DurationType): string {
    switch (duration) {
        case DurationType.Event:
            return 'Event (Claim)';
        case DurationType.Policy:
            return 'Policy Term';
        case DurationType.Timespan:
            return 'Time Span';
        default:
            return '';
    }
}

export function getValueSelectionMethodDisplayName(valueSelectionMethod: EntityScopeValueSelectionMethod): string {
    switch (valueSelectionMethod) {
        case EntityScopeValueSelectionMethod.Global:
            return 'Global';
        case EntityScopeValueSelectionMethod.PerEntity:
            return 'Set Per Entity';
        case EntityScopeValueSelectionMethod.Stacked:
            return 'Stacked';
        default:
            return 'Global';
    }
}

export function getInsuranceScopeTypeDisplayName(scopeType: InsuranceScopeType): string {
    switch (scopeType) {
        case InsuranceScopeType.InsuredEntity:
            return 'Per Insured Entity';
        case InsuranceScopeType.Policy:
            return 'Total Per Policy';
        case InsuranceScopeType.ExternalEntity:
            return 'External Entity';
        default:
            return '';
    }
}

export function coInsuranceVariantDisplayName(coInsuranceVariant: string): string {
    switch (coInsuranceVariant) {
        case 'coinsurance':
            return 'Co-Insurance';
        case 'copay':
            return 'Co-Pay';
        default:
            return '';
    }
}

export function getValueNumber(value: string): number {
    return Number(value.replace(',', ''));
}

const SETTING_TO_DISPLAY_NAME: Record<SettingType, string> = {
    [SettingType.Deductible]: 'Deductible',
    [SettingType.Limit]: 'Limit',
    [SettingType.WaitingPeriod]: 'Waiting Period',
    [SettingType.Coinsurance]: 'Co-Insurance',
};

export function getSettingTypeDisplayName(settingType: SettingType): string {
    return SETTING_TO_DISPLAY_NAME[settingType];
}

export function getDurationTimespan(duration: Duration): string {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return `${duration.amount} ${pluralize(duration.unit!.toString(), duration.amount)}`;
}

export function unitDisplay(settingInstance: SettingInstance): string {
    switch (settingInstance.type) {
        case SettingType.Deductible:
            return settingInstance.currencyUnit.toUpperCase();
        case SettingType.Limit:
            if (settingInstance.unit === SettingUnit.Currency && settingInstance.currencyUnit) {
                return settingInstance.currencyUnit.toUpperCase();
            }

            if (settingInstance.unit === SettingUnit.ClaimLossPercentage) {
                return '% of Claim Loss Amount';
            }

            return toReadable(settingInstance.unit);
        case SettingType.WaitingPeriod:
            return toReadable(settingInstance.durationUnit);
        case SettingType.Coinsurance:
            return coInsuranceVariantDisplayName(settingInstance.variant);
        default:
            return '';
    }
}
