import type { CoverageRuleDisplay, RuleEntityType } from './CoverageRule';
import { CoverageRuleType } from './CoverageRule';
import type { CoverageInstance, SettingInstance } from './CoveragesEdition';
import { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';

export enum CoverageRuleGroupType {
    NewBusinessInitialValue = 'new-business-initial-value',
    RenewalInitialValue = 'renewal-initial-value',
    MovingInitialValue = 'moving-initial-value',
    Restriction = 'restriction',
}

type VariantType = 'control' | 'test';

interface Variant {
    readonly rules: CoverageRuleDisplay[];
    readonly variantName: string;
    readonly type: VariantType;
}

type Mode = 'ab-test' | 'plain';

export interface CoverageRuleGroupBase {
    readonly mode: Mode;
    readonly groupDisplayName: string;
    readonly alerts: Alert<AlertType>[];
}

export interface CoverageRuleGroupABTest extends CoverageRuleGroupBase {
    readonly mode: 'ab-test';
    readonly variants: Variant[];
}

export interface CoverageRuleGroupPlain extends CoverageRuleGroupBase {
    readonly mode: 'plain';
    readonly rules: CoverageRuleDisplay[];
}

export const isCoverageRuleGroupPlain = (group: CoverageRuleGroupBase): group is CoverageRuleGroupPlain =>
    group.mode === 'plain';

export const isCoverageRuleGroupABTest = (group: CoverageRuleGroupBase): group is CoverageRuleGroupABTest =>
    group.mode === 'ab-test';

export type CoverageRulesGroup = CoverageRuleGroupABTest | CoverageRuleGroupPlain;

export interface EntityRules {
    // All rules for specific entity (e.g base_deductible)
    readonly entityType: RuleEntityType;
    readonly entityCode: string;
    readonly entityName: string;
    readonly rulesByType: Record<CoverageRuleGroupType, CoverageRulesGroup>;
}

export interface DigitalAgentEdition extends Edition {
    readonly entityRules: EntityRules[];
}

export interface UpdateDigitalAgentEditionViewPreferenceParams {
    readonly digitalAgentEditionCode: string;
    readonly coveragesEditionCode: string;
}

export interface GetDigitalAgentEditionViewPreferenceResponse {
    readonly coveragesEditionCode: string;
}

export enum AlertLevel {
    Warning = 'warning',
    Info = 'info',
}

export interface Alert<T> {
    readonly type: T;
    readonly level: AlertLevel;
    readonly title: string;
    readonly message: string;
}

export enum AlertType {
    FallbackValueMissing = 'fallback_value_missing',
    RequiredCoverage = 'required_coverage',
    MissingInstanceInCoverageEdition = 'missing_instance_in_coverage_edition',
    NoNonFiledRules = 'no_non_filed_rules',
    IdenticalVariants = 'identical_variants',
}

export interface DigitalAgentPreviewEntity<T> {
    readonly code: string;
    readonly name: string;
    readonly description: string;
    readonly rulesByType: Record<CoverageRuleGroupType, CoverageRulesGroup>;
    readonly alerts: Alert<AlertType>[];
    readonly instance: T | null;
}

export interface DigitalAgentEditionExtendedPreview {
    readonly coverages: DigitalAgentPreviewEntity<CoverageInstance>[];
    readonly settings: DigitalAgentPreviewEntity<SettingInstance>[];
}

export const CoverageRuleGroupTypeMap: Record<
    CoverageRuleGroupType,
    { readonly lifecycleContext?: RuleLifecycleContext; readonly ruleType: CoverageRuleType }
> = {
    [CoverageRuleGroupType.NewBusinessInitialValue]: {
        lifecycleContext: RuleLifecycleContext.NewBusiness,
        ruleType: CoverageRuleType.InitialValue,
    },
    [CoverageRuleGroupType.RenewalInitialValue]: {
        lifecycleContext: RuleLifecycleContext.Renewal,
        ruleType: CoverageRuleType.InitialValue,
    },
    [CoverageRuleGroupType.MovingInitialValue]: {
        lifecycleContext: RuleLifecycleContext.Moving,
        ruleType: CoverageRuleType.InitialValue,
    },
    [CoverageRuleGroupType.Restriction]: {
        ruleType: CoverageRuleType.Restriction,
    },
};
