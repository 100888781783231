// Coverages Edition
import type { Currency } from '@lemonade-hq/lemonation';
import { SettingType } from './SettingType';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageRuleDisplay } from 'models/LoCo/Insurance/CoverageRule';

export interface CoveragesEdition extends Edition {
    readonly coverages: CoverageInstance[];
    readonly settings: SettingInstance[];
}

export interface InstanceBase {
    readonly name: string;
    readonly description: string;
    readonly templateCode: string;
    readonly relatedRules: CoverageRuleDisplay[];
}

export type CoverageScopeType = InsuranceScopeType.InsuredEntity | InsuranceScopeType.Policy;
export type CoverageInsuranceScope = InsuredEntityScope | PolicyScope;

export interface CoverageInstance extends InstanceBase {
    readonly required: boolean;
    readonly relatedSettings: { readonly templateCode: string; readonly name: string }[];
    readonly isBenefit: boolean;

    readonly scope: CoverageInsuranceScope;
}

export interface SettingInstanceBase extends InstanceBase {
    readonly type: SettingType;
    readonly values: Values;
    readonly relatedCoverages: { readonly templateCode: string; readonly name: string }[];
}

export interface DeductibleSettingInstance extends SettingInstanceBase {
    readonly scope: InsuranceScope;
    readonly duration: Duration;
    readonly type: SettingType.Deductible;
    readonly currencyUnit: Currency;
    readonly valueSelectionMethod?: EntityScopeValueSelectionMethod;
}

export interface LimitSettingInstance extends SettingInstanceBase {
    readonly scope: InsuranceScope;
    readonly duration: Duration;
    readonly type: SettingType.Limit;
    readonly unit: SettingUnit;
    readonly valueSelectionMethod?: EntityScopeValueSelectionMethod;
    readonly currencyUnit?: Currency;
    readonly parentLimitTemplateCode?: string;
}

export interface WaitingPeriodSettingInstance extends SettingInstanceBase {
    readonly type: SettingType.WaitingPeriod;
    readonly durationUnit: DurationUnit;
}

export interface CoinsuranceSettingInstance extends SettingInstanceBase {
    readonly type: SettingType.Coinsurance;
    readonly variant: CoinsuranceVariant;
}

export type SettingInstance =
    | CoinsuranceSettingInstance
    | DeductibleSettingInstance
    | LimitSettingInstance
    | WaitingPeriodSettingInstance;

// Values (settings instance attribute)
export enum ValueType {
    List = 'list',
    Range = 'range',
}

export interface ListOfValues {
    readonly type: ValueType.List;
    readonly values: number[];
    readonly includeUnlimited: boolean;
}

export interface RangeValue {
    readonly type: ValueType.Range;
    readonly min: number;
    readonly max: number;
    readonly step: number;
}

export type Values = ListOfValues | RangeValue;

// Insurance scope (settings instance attribute)
export enum InsuranceScopeType {
    InsuredEntity = 'insured_entity',
    ExternalEntity = 'external_entity',
    Policy = 'policy',
}

export enum EntityScopeValueSelectionMethod {
    Global = 'global',
    PerEntity = 'per_entity',
    Stacked = 'stacked',
}

export enum CoinsuranceVariant {
    Coinsurance = 'coinsurance',
    Copay = 'copay',
}

export enum DurationType {
    Event = 'event' /* claim */,
    Policy = 'policy',
    Timespan = 'timespan',
}

export enum DurationUnit {
    Year = 'year',
    Month = 'month',
    Day = 'day',
    Week = 'week',
}

export interface Duration {
    readonly type: DurationType;
    readonly amount?: number;
    readonly unit?: DurationUnit;
}

export interface ExternalEntityScope {
    readonly type: InsuranceScopeType.ExternalEntity;
    readonly name: string;
}

export interface InsuredEntityScope {
    readonly type: InsuranceScopeType.InsuredEntity;
    readonly insuredEntityCode: string;
}

export interface PolicyScope {
    readonly type: InsuranceScopeType.Policy;
}

export type InsuranceScope = ExternalEntityScope | InsuredEntityScope | PolicyScope;

// Units
export enum SettingUnit {
    Currency = 'currency',
    Event = 'event',
    Item = 'item',
    ClaimLossPercentage = 'claim_loss_percentage',
}

// Violations
export enum ChangeContentType {
    Coverage = 'coverage',
    Setting = 'setting',
    Binding = 'binding',
}

export interface CoveragesEditionViolationPayload {
    readonly message: string;
    readonly templateCode: string;
    readonly type: ChangeContentType;
}

export interface EditionValidationsResponse {
    readonly isBreakingChanges: boolean;
    readonly violations: CoveragesEditionViolationPayload[];
}

type SubLimitSettingInstance = LimitSettingInstance & { readonly parentLimitTemplateCode: string };

export function isSubLimitSettingInstance(
    settingInstance: SettingInstance
): settingInstance is SubLimitSettingInstance {
    return settingInstance.type === SettingType.Limit && settingInstance.parentLimitTemplateCode !== undefined;
}

export const isSettingsInstance = (instance: CoverageInstance | SettingInstance): instance is SettingInstance => {
    return 'type' in instance;
};
