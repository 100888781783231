import { clsx } from 'clsx';
import { forwardRef } from 'react';
import { Icon } from '../Icon/Icon';
import type { IconName } from '../Icon/types';
import { badgeStyles } from './Badge.css';

export type BadgeVariant = 'attention' | 'info' | 'negative' | 'positive' | 'progress';

export interface BadgeProps {
  readonly label: JSX.Element | number | string;
  readonly size?: 'md' | 'sm';
  readonly stroke?: boolean;
  readonly transparent?: boolean;
  readonly className?: string;
  readonly icon?: IconName;
  readonly variant?: BadgeVariant;
  readonly onClick?: (e: React.MouseEvent<HTMLSpanElement>) => void;
}

export const Badge: React.FC<BadgeProps> = forwardRef<HTMLSpanElement, BadgeProps>(
  (
    {
      variant = 'info',
      size = 'sm',
      label,
      stroke = false,
      transparent = false,
      icon,
      className: externalClassName,
      onClick,
      ...restProps
    },
    ref,
  ) => {
    return (
      <span
        className={clsx(
          badgeStyles({ variant, size, stroke, transparent, clickable: onClick != null }),
          externalClassName,
        )}
        data-testid="badge"
        onMouseDown={e => onClick?.(e)} // using onmousedown rather than onclick in order to be able to prevent default focusing
        ref={ref}
        {...restProps}
      >
        {icon && <Icon data-testid="badge-icon" name={icon} size="sm" />}
        {label}
      </span>
    );
  },
);
