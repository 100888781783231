import { Flex } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import type { RuleActionData } from '../dialogs/UnderwritingRulesDialogs';
import { StyledRulesTitle, StyledRulesTitleContainer } from '../UnderwritingFiltersShared';
import { RulesTable } from './RulesTable';
import { useOrderableTable } from 'components/LoCo/common/components/Table/useOrderableTable';
import { StyledSectionHeader } from 'components/LoCo/LoCoPagesSharedStyles';
import type { UnderwritingFlagRulesGroup } from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import { useReorderUnderwritingFiltersEditionRule } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

const StyledContainer = styled(Flex)`
    flex-direction: column;
`;

interface FlagGroupedRulesProps {
    readonly rulesGroup: UnderwritingFlagRulesGroup;
    readonly hideActions: boolean;
    readonly editionCode: string;
    readonly onAction: (actionData: RuleActionData) => void;
}

export const FlagGroupedRules: React.FC<FlagGroupedRulesProps> = ({
    editionCode,
    hideActions,
    onAction,
    rulesGroup,
}) => {
    const { mutateAsync, isPending } = useReorderUnderwritingFiltersEditionRule(editionCode, 'flag');
    const { getClassNameForItem, handleReorder, isReorderMode } = useOrderableTable(
        rulesGroup.rules.map(r => r.publicId),
        async (rulePublicId, order) => await mutateAsync({ rulePublicId, destination: order })
    );

    return (
        <StyledContainer>
            <StyledSectionHeader>
                <StyledRulesTitleContainer>
                    <StyledRulesTitle>{rulesGroup.flagName}</StyledRulesTitle>
                </StyledRulesTitleContainer>
            </StyledSectionHeader>
            <RulesTable
                editionCode={editionCode}
                getReorderedRowClassName={getClassNameForItem}
                handleReorder={handleReorder}
                hideActions={hideActions}
                isLoading={isPending}
                isReorderMode={isReorderMode}
                onAction={onAction}
                rules={rulesGroup.rules}
                type="flag"
            />
        </StyledContainer>
    );
};
