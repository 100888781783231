import { Select } from '@lemonade-hq/bluis';
import type { FormProps } from '@lemonade-hq/cdk';
import { basicRequiredValidation } from '@lemonade-hq/cdk';
import { useEffect, useMemo } from 'react';
import { coInsuranceVariantDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import type { SettingTypeAttributesProps } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/CommonAttributes';
import {
    FieldType,
    useCommonAttributes,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/CommonAttributes';
import type {
    CoinsuranceAttributesState,
    SettingStateBase,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/SettingContext';
import { SettingActionType } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/SettingContext';
import { StyledFormInputWrapper, StyledFormWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import { CoinsuranceVariant } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

export const CoinsuranceAttributes: React.FC<SettingTypeAttributesProps> = ({ state, dispatch }) => {
    const coinsuranceState = state as SettingStateBase<CoinsuranceAttributesState> | undefined;

    const form = {
        fields: {
            [FieldType.Variant]: {
                startValue: coinsuranceState?.attributes?.variant ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
        },
    } satisfies FormProps;

    const { values, setValue, valid } = useCommonAttributes(form, state);

    useEffect(() => {
        dispatch({
            type: SettingActionType.SetCoinsuranceAttributes,
            payload: {
                newValues: {
                    type: SettingType.Coinsurance,
                    variant: values[FieldType.Variant] as CoinsuranceVariant,
                    isValid: valid,
                },
            },
        });
    }, [values, valid, dispatch, state.settingType]);

    const coinsuranceVariantOptions = useMemo(() => {
        return Object.values(CoinsuranceVariant).map(variant => ({
            id: variant,
            value: variant,
            label: coInsuranceVariantDisplayName(variant),
        }));
    }, []);

    const onVariantChange = ({ value }: { value: CoinsuranceVariant }) => {
        setValue(FieldType.Variant, value);
    };

    return (
        <StyledFormWrapper>
            <StyledFormInputWrapper label="Variant">
                <Select
                    onOptionSelected={option => onVariantChange({ value: option.value })}
                    options={coinsuranceVariantOptions}
                    placeholder="Select Variant"
                    value={values[FieldType.Variant] as CoinsuranceVariant}
                />
            </StyledFormInputWrapper>
        </StyledFormWrapper>
    );
};
