import {
    SummaryInnerSectionItemKey as Key,
    SummarySection,
    SummarySectionContent,
    SummarySectionTitle,
    SummaryInnerSectionItemValue as Value,
} from '@lemonade-hq/bluis';
import {
    scopeDisplay,
    SettingInstanceDuration,
} from '../coverage-editions/Settings/SettingInstancesTable/SettingsTableShared';
import { SettingInstanceValues } from 'components/LoCo/common/components/CoverageSettings/SettingValues';
import { unitDisplay } from 'components/LoCo/common/display-texts/setting-instance';
import { isSubLimitSettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import type { CoverageInstance, SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

const getCustomDetails = (instance: SettingInstance): JSX.Element | null => {
    switch (instance.type) {
        case SettingType.Limit:
            return (
                <>
                    {isSubLimitSettingInstance(instance) && (
                        <>
                            <Key>Sub-Limit of</Key>
                            <Value>{instance.parentLimitTemplateCode}</Value>
                        </>
                    )}
                    <Key>Scope</Key>
                    <Value>{scopeDisplay(instance)}</Value>
                    <Key>Duration</Key>
                    <Value>
                        <SettingInstanceDuration settingInstance={instance} />
                    </Value>
                    <Key>Unit</Key>
                    <Value>{unitDisplay(instance)}</Value>
                </>
            );
        case SettingType.Deductible:
            return (
                <>
                    <Key>Scope</Key>
                    <Value>{scopeDisplay(instance)}</Value>
                    <Key>Duration</Key>
                    <Value>
                        <SettingInstanceDuration settingInstance={instance} />
                    </Value>
                    <Key>Currency Unit</Key>
                    <Value>{instance.currencyUnit}</Value>
                </>
            );
        case SettingType.WaitingPeriod:
            return (
                <>
                    <Key>Duration Unit</Key>
                    <Value>{unitDisplay(instance)}</Value>
                </>
            );
        case SettingType.Coinsurance:
            return (
                <>
                    <Key>Variations</Key>
                    <Value>{instance.variant}</Value>
                </>
            );
        default:
            return null;
    }
};

export const SettingsGeneralDetailsSection: React.FC<{ readonly instance: SettingInstance }> = ({ instance }) => {
    return (
        <SummarySection>
            <SummarySectionTitle>General Details</SummarySectionTitle>
            <SummarySectionContent gridColsWidth="250px 1fr">
                <Key>Values</Key>
                <Value>
                    <SettingInstanceValues settingInstance={instance} />
                </Value>
                <Key>Description</Key>
                <Value>{instance.description}</Value>
                <Key>Related Coverages</Key>
                <Value>{instance.relatedCoverages.map(coverage => coverage.name).join(', ')}</Value>
                {getCustomDetails(instance)}
            </SummarySectionContent>
        </SummarySection>
    );
};

export const CoveragesGeneralDetailsSection: React.FC<{ readonly instance: CoverageInstance | null }> = ({
    instance,
}) => {
    if (instance == null) return null;

    return (
        <SummarySection>
            <SummarySectionTitle>General Details</SummarySectionTitle>
            <SummarySectionContent gridColsWidth="250px 1fr">
                <Key>Description</Key>
                <Value>{instance.description}</Value>
                <Key>Related Settings</Key>
                <Value>{instance.relatedSettings.map(settings => settings.name).join(', ')}</Value>
            </SummarySectionContent>
        </SummarySection>
    );
};
