import { clsx } from 'clsx';
import type { FC, PropsWithChildren, ReactNode } from 'react';
import { Text } from '../../base/Text/Text';
import { spacing } from '../../theme/spacing.css';
import { Card } from '../Card/Card';
import * as styles from './DynamicList.css';

export const DynamicListItem: FC<
  PropsWithChildren<{
    readonly additionalComponent?: ReactNode;
    readonly variant: 'ordered' | 'unordered';
    readonly className?: string;
    readonly invalid?: boolean;
  }>
> = ({ children, variant, className, invalid, additionalComponent }) => {
  return (
    <Card
      borderRadius="md"
      className={clsx(className, styles.recordListItem({ variant }))}
      data-has-error={invalid}
      display="flex"
      gap={spacing.s08}
      justifyContent="space-between"
      padding={spacing.s12}
      shadow="ds2"
      variant="primary"
    >
      {children}
      {additionalComponent}
    </Card>
  );
};

export const DynamicListText: FC<PropsWithChildren<{ readonly text: string }>> = ({ text }) => {
  return (
    <Text alignSelf="center" as="li" wordBreak="break-word">
      {text}
    </Text>
  );
};
