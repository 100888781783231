import { ErrorSection, LoadingSection, PageWrapper } from '@lemonade-hq/bluis';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { UnderwritingFiltersRulesSection } from './UnderwritingFiltersRulesSection';
import { EditionHeader } from 'components/LoCo/common/editions/EditionHeader';
import { EditionActionsDialogs } from 'components/LoCo/common/editions/editionSharedActions';
import type { EditionActionData } from 'components/LoCo/common/editions/editionSharedActions';
import { EditionSummarySection } from 'components/LoCo/common/editions/EditionSummarySection';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { useSuspenseGetProduct } from 'queries/LoCo/Insurance/ProductQueries';
import { useGetUnderwritingFiltersEdition } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

export const UnderwritingFiltersEdition: React.FC = () => {
    const [dialogData, setDialogData] = useState<EditionActionData | null>(null);
    const { productCode = '', underwritingFiltersEditionCode = '' } = useParams<{
        productCode: string;
        underwritingFiltersEditionCode: string;
    }>();

    const {
        data: underwritingFiltersEditionData,
        isError: isUnderwritingFiltersEditionError,
        isLoading: isLoadingUnderwritingFiltersEdition,
    } = useGetUnderwritingFiltersEdition(underwritingFiltersEditionCode);

    const {
        data: productData,
        isError: isProductError,
        isLoading: isLoadingProductEdition,
    } = useSuspenseGetProduct(productCode);

    if (isLoadingUnderwritingFiltersEdition || isLoadingProductEdition) {
        return <LoadingSection />;
    }

    if (isUnderwritingFiltersEditionError || !underwritingFiltersEditionData || isProductError || !productData) {
        return <ErrorSection title="No underwriting filters edition :(" />;
    }

    return (
        <>
            <EditionHeader
                edition={underwritingFiltersEditionData}
                product={productData}
                setDialogData={setDialogData}
                type={EditionType.UnderwritingFilters}
            />
            <PageWrapper>
                <EditionSummarySection
                    edition={underwritingFiltersEditionData}
                    product={productData}
                    type={EditionType.UnderwritingFilters}
                />
                {dialogData != null && (
                    <EditionActionsDialogs
                        dialogData={dialogData}
                        onClose={() => setDialogData(null)}
                        productCode={productCode}
                    />
                )}

                <UnderwritingFiltersRulesSection underwritingFiltersEdition={underwritingFiltersEditionData} />
            </PageWrapper>
        </>
    );
};
