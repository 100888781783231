import { DatetimeFormat, formatDatetime } from '@lemonade-hq/lemonation';

export function getFormattedDate(date: Date): string {
    return formatDatetime(new Date(date), {
        datetimeFormat: DatetimeFormat.DateOrdinalShortMonth,
    });
}

export function getFormattedDateMaybe(date: Date | undefined): string {
    return date ? getFormattedDate(date) : '-';
}

export function parseLocalDateToUtcDate(localDate: Date | null): Date | null {
    return localDate ? new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate())) : null;
}
