import { Button, Flex, spacing } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';

export const ActionButtons: FC = () => {
    return (
        <Flex gap={spacing.s08}>
            <Button label="Label" startIcon="labels" variant="secondary" />
            <Button label="Archive" startIcon="archive" variant="secondary" />
        </Flex>
    );
};
