import type { EntityTypes } from '@lemonade-hq/bluiza';
import type { MutationState } from '@tanstack/react-query';
import { useMutationState } from '@tanstack/react-query';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { queryClient } from '../../queryClient';
import { GalleryAttachmentQueryKey } from './AttachmentsQueries';
import type { AttachmentDTO } from './types';

interface Props {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly attachments?: AttachmentDTO[];
}

interface Context {
    readonly entityType: EntityTypes;
    readonly entityPublicId: string | null;
    readonly attachments: AttachmentDTO[];
}

const AttachmentsContext = createContext<Context | null>(null);

export const AttachmentsProvider: FC<PropsWithChildren<Props>> = ({
    entityPublicId,
    entityType,
    attachments,
    children,
}) => {
    const mutationKey = useMemo(
        () => [GalleryAttachmentQueryKey.GetAttachments, entityPublicId, entityType],
        [entityPublicId, entityType]
    );
    const attachmentsQueryData = useMemo(() => {
        return attachments ?? queryClient.getQueryData<AttachmentDTO[]>(mutationKey) ?? [];
    }, [attachments, mutationKey]);

    const [attachmentsList, setAttachmentsList] = useState(attachmentsQueryData);

    const data = useMutationState<
        MutationState<
            unknown,
            Error,
            { attachmentPublicId: string; type: string; description: string },
            AttachmentDTO[]
        >
    >({
        filters: { mutationKey, status: 'pending' },
    });

    useEffect(() => {
        if (data.length) {
            const [mutationData] = data;
            const { variables, context } = mutationData;
            const attachmentIndex = context?.findIndex(it => it.publicId === variables?.attachmentPublicId);

            if (attachmentIndex != null && context != null) {
                const updatedAttachment = [
                    ...context.slice(0, attachmentIndex),
                    { ...context[attachmentIndex], ...variables },
                    ...context.slice(attachmentIndex + 1),
                ];
                setAttachmentsList(updatedAttachment);
            }
        }
    }, [attachmentsList, data]);

    const value = useMemo(
        () => ({
            entityType,
            entityPublicId,
            attachments: attachmentsList,
        }),
        [entityType, entityPublicId, attachmentsList]
    );

    return <AttachmentsContext.Provider value={value}>{children}</AttachmentsContext.Provider>;
};

export const useAttachmentsData = (): Context => {
    const context = useContext(AttachmentsContext);

    if (!context) {
        throw new Error('useAttachmentsData must be used within a AttachmentsGalleryProvider');
    }

    return context;
};
