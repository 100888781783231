// eslint-disable-next-line @typescript-eslint/naming-convention
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { clsx } from 'clsx';
import { forwardRef } from 'react';
import { Flex } from '../../base/Flex/Flex';
import { Icon } from '../Icon/Icon';
import { icon, iconWrapper, root } from './Checkbox.css';

type Variants = Exclude<Exclude<Exclude<Parameters<typeof root>[0], undefined>, 'size'>['variant'], undefined>;

export interface CheckboxProps extends Omit<RadixCheckbox.CheckboxProps, 'onChange'> {
  readonly size?: 'md' | 'sm';
  readonly variant?: Variants;
}

export const Checkbox = forwardRef<HTMLButtonElement, CheckboxProps>(
  ({ checked, className, size = 'sm', variant = 'neutral', ...restProps }, ref) => {
    return (
      <Flex alignItems="center">
        <RadixCheckbox.Root
          checked={checked}
          className={clsx(root({ size, variant }), className)}
          {...restProps}
          ref={ref}
        >
          <RadixCheckbox.Indicator asChild className={iconWrapper}>
            <Flex alignItems="center" justifyContent="center">
              <Icon className={icon({ size })} name={checked === 'indeterminate' ? 'minus-solid' : 'check'} size="sm" />
            </Flex>
          </RadixCheckbox.Indicator>
        </RadixCheckbox.Root>
      </Flex>
    );
  },
);
