import type { CoverageRuleType, RuleEntityType } from './CoverageRule';
import type { UnderwritingDecisionLifecycleContext } from './UnderwritingFiltersEdition';

interface InstanceBinding {
    readonly settingTemplateCode: string;
    readonly coverageTemplateCode: string;
}

export enum ChangeType {
    CoverageAdded = 'coverageAdded',
    CoverageRemoved = 'coverageRemoved',
    CoverageAttributesUpdated = 'coverageAttributesUpdated',
    CoverageSetToRequired = 'coverageSetToRequired',
    CoverageSetToOptional = 'coverageSetToOptional',
    SettingAdded = 'settingAdded',
    SettingRemoved = 'settingRemoved',
    SettingAttributesUpdated = 'settingAttributesUpdated',
    SettingValuesUpdated = 'settingValuesUpdated',
    CoverageBindingAdded = 'coverageBindingAdded',
    CoverageBindingRemoved = 'coverageBindingRemoved',
    ProductSchemaUpdated = 'productSchemaUpdated',
    PlatformSchemaUpdated = 'platformSchemaUpdated',
    CoverageRulesUpdated = 'coverageRulesUpdated',
    DeclineRulesUpdated = 'declineRulesUpdated',
    FlagRulesUpdated = 'flagRulesUpdated',
    FlagChanges = 'flagChanges',
}

interface BaseChangesLogEntry<TChangeType extends ChangeType, TChangesLogMetadata extends object = object> {
    readonly changeType: TChangeType;
    readonly metadata: TChangesLogMetadata;
}

type ReferenceIdType =
    | ChangeType.CoverageAdded
    | ChangeType.CoverageRemoved
    | ChangeType.CoverageSetToOptional
    | ChangeType.CoverageSetToRequired
    | ChangeType.SettingAdded
    | ChangeType.SettingRemoved
    | ChangeType.SettingValuesUpdated;

export type ReferenceIdEntry = BaseChangesLogEntry<ReferenceIdType, ReferenceIdMetadata>;
export type AttributesUpdatedEntry = BaseChangesLogEntry<
    ChangeType.CoverageAttributesUpdated | ChangeType.SettingAttributesUpdated,
    AttributesUpdatedMetadata
>;
export type BindingEntry = BaseChangesLogEntry<
    ChangeType.CoverageBindingAdded | ChangeType.CoverageBindingRemoved,
    InstanceBinding
>;
export type SchemaUpdatedEntry = BaseChangesLogEntry<
    ChangeType.PlatformSchemaUpdated | ChangeType.ProductSchemaUpdated
>;
export type CoverageRulesUpdatedEntry = BaseChangesLogEntry<
    ChangeType.CoverageRulesUpdated,
    CoveragesRulesUpdatedMetadata
>;
export type DeclineRulesUpdatedEntry = BaseChangesLogEntry<ChangeType.DeclineRulesUpdated, DeclineRuleStats>;
export type FlagRulesUpdatedEntry = BaseChangesLogEntry<ChangeType.FlagRulesUpdated, FlagRuleStats>;
export type FlagChangesEntry = BaseChangesLogEntry<ChangeType.FlagChanges, FlagChanges>;

interface ReferenceIdMetadata {
    readonly referenceId: string;
}

type AttributesUpdatedMetadata = ReferenceIdMetadata & {
    readonly changedAttributes: string[];
};

export interface RuleStats {
    readonly added: number;
    readonly removed: number;
    readonly updated: number;
}

interface DeclineRuleStats extends RuleStats {
    readonly lifecycleContext: UnderwritingDecisionLifecycleContext;
    readonly reordered: boolean;
}

interface CoverageRuleStats {
    readonly [CoverageRuleType.Restriction]: RuleStats;
    readonly initialValue: RuleStats;
}

interface CoveragesRulesUpdatedMetadata {
    readonly entityCode: string;
    readonly entityType: RuleEntityType;
    readonly filed: CoverageRuleStats;
    readonly nonFiled: CoverageRuleStats;
    readonly nonFiledRulesReordered: boolean;
}

export interface FlagRuleStats extends RuleStats {
    readonly flagCode: string;
}

interface FlagChanges {
    readonly added: string[];
    readonly removed: string[];
}

export type ChangesLogEntry =
    | AttributesUpdatedEntry
    | BindingEntry
    | CoverageRulesUpdatedEntry
    | DeclineRulesUpdatedEntry
    | FlagChangesEntry
    | FlagRulesUpdatedEntry
    | ReferenceIdEntry
    | SchemaUpdatedEntry;

export interface ChangesLogResponse {
    readonly entries: ChangesLogEntry[];
}
