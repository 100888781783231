import type { Infer, RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import { produce } from 'immer';
import type { KeyboardEvent, PropsWithChildren, ReactElement } from 'react';
import { createContext, useContext, useRef, useState } from 'react';
import { useForm } from '../../FormContext';
import type { CommonFormChangePayload } from '../../types';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
function useDynamicList<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>, TItem>(
  schemaKey: TSchemaKey,
  items: TItem[],
) {
  const [editingIndex, setEditingIndex] = useState<number | null>(null);

  const { dispatch } = useForm<TSchema, CommonFormChangePayload>();
  const newItemIndexRef = useRef<number | null>(null);
  const deleteItem = (index: number): void => {
    const updatedDynamicList = produce(items, draft => {
      draft.splice(index, 1);
      return draft;
    });

    dispatch({
      type: 'setValue',
      key: schemaKey,
      value: updatedDynamicList as Infer<TSchema>[TSchemaKey],
      changePayload: {
        changeType: 'deleteItem',
      },
    });
  };

  const cancelEditing = (): void => {
    if (newItemIndexRef.current != null) {
      // if the selected item is a new item, we need to delete it (since it was never committed)
      deleteItem(newItemIndexRef.current);
      newItemIndexRef.current = null;
    }

    setEditingIndex(null);
  };

  const addItem = (index?: number): void => {
    cancelEditing();
    // need to remove empty items in the same dispatch otherwise they are left hanging
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
    const sanitizedDynamicList = items.filter(item => item !== undefined) as TItem[];
    const newItemIndex = index ?? sanitizedDynamicList.length;

    const newItem = undefined;
    const updatedDynamicList = [...sanitizedDynamicList];
    updatedDynamicList.splice(newItemIndex, 0, newItem as TItem);

    dispatch({
      type: 'setValue',
      key: schemaKey,
      value: updatedDynamicList as Infer<TSchema>[TSchemaKey],
      changePayload: {
        changeType: 'addItem',
      },
    });

    newItemIndexRef.current = newItemIndex;

    setEditingIndex(newItemIndex);
  };

  const reorderItems = (oldIndex: number, newIndex: number): void => {
    if (oldIndex === newIndex) return;

    const updatedDynamicList = produce(items, draft => {
      const [item] = draft.splice(oldIndex, 1);
      draft.splice(newIndex, 0, item);
    });

    dispatch({
      type: 'setValue',
      key: schemaKey,
      value: updatedDynamicList as Infer<TSchema>[TSchemaKey],
      changePayload: {
        changeType: 'reorderItems',
      },
    });
  };

  const startEditingItem = (index: number): void => {
    cancelEditing();
    setEditingIndex(index);
  };

  const finishEditingItem = (index: number, value: Infer<TSchema>[TSchemaKey][number]): void => {
    const updatedDynamicList = produce(items, draft => {
      draft[index] = value;
      return draft;
    });
    dispatch({
      type: 'setValue',
      key: schemaKey,
      value: updatedDynamicList as Infer<TSchema>[TSchemaKey],
      changePayload: {
        changeType: 'editItem',
      },
    });

    setEditingIndex(null);
    newItemIndexRef.current = null;
  };

  const handleESC = (e: KeyboardEvent<HTMLDivElement>): void => {
    if (e.key === 'Escape') {
      setEditingIndex(null);
    }
  };

  return {
    editingIndex,
    deleteItem,
    cancelEditing,
    addItem,
    reorderItems,
    startEditingItem,
    finishEditingItem,
    handleESC,
  };
}

const DynamicListContext = createContext<ReturnType<typeof useDynamicList> | null>(null);

export const DynamicListProvider = <TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>, TItem>({
  items,
  schemaKey,
  children,
}: PropsWithChildren<{
  readonly schemaKey: TSchemaKey;
  readonly items: TItem[];
}>): ReactElement => {
  const value = useDynamicList(schemaKey, items);
  return <DynamicListContext.Provider value={value}>{children}</DynamicListContext.Provider>;
};

export function useDynamicListContext(): ReturnType<typeof useDynamicList> {
  const context = useContext(DynamicListContext);
  if (context === null) {
    throw new Error('useDynamicListContext must be used within a DynamicListProvider');
  }

  return context;
}
