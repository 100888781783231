import { EntityTypes } from '@lemonade-hq/bluiza';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { AttachmentDTO } from '../types';
import { AttachmentGallery } from '.';

interface GalleryProps {
    readonly attachments?: AttachmentDTO[];
    readonly entityType: EntityTypes;
    readonly entityPublicId: string;
    readonly index?: number;
}

interface GalleryContextType {
    readonly openGallery: ({ index, attachments, entityType }: GalleryProps) => void;
}

const GalleryContext = createContext<GalleryContextType | null>(null);

export const GalleryProvider: FC<PropsWithChildren<unknown>> = ({ children }) => {
    const [showGallery, setShowGallery] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [attachmentList, setAttachmentList] = useState<AttachmentDTO[] | undefined>([]);
    const [entity, setEntity] = useState<EntityTypes>(EntityTypes.User);
    const [entityId, setEntityId] = useState('');

    const handleOpen = useCallback(({ index, attachments, entityType, entityPublicId }: GalleryProps) => {
        setCurrentIndex(index ?? 0);
        setAttachmentList(attachments ?? undefined);
        setEntity(entityType);
        setEntityId(entityPublicId);
        setShowGallery(true);
    }, []);

    const handleClose = useCallback(() => {
        setShowGallery(false);
    }, []);

    const value = useMemo(
        () => ({
            openGallery: handleOpen,
        }),
        [handleOpen]
    );

    return (
        <GalleryContext.Provider value={value}>
            {children}
            {showGallery && (
                <AttachmentGallery
                    attachments={attachmentList}
                    entityPublicId={entityId}
                    entityType={entity}
                    index={currentIndex}
                    onClose={handleClose}
                />
            )}
        </GalleryContext.Provider>
    );
};

export const useGallery = (): GalleryContextType => {
    const context = useContext(GalleryContext);

    if (!context) {
        throw new Error('useGallery must be used within a GalleryProvider');
    }

    return context;
};
