import type { UseOptimisticMutation } from '@lemonade-hq/blender-ui';
import { useOptimisticMutation } from '@lemonade-hq/blender-ui';
import type { UseMutationResult, UseQueryResult, UseSuspenseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import produce from 'immer';
import { insuranceBlender } from '../../../apiClients';
import { useSuspenseQueryWithErrorHandling } from 'components/LoCo/common/hooks/useSuspenseQueryWithErrorHandling';
import type { CreateEditionParams } from 'models/LoCo/Insurance/BaseEdition';
import { isCoverageRuleGroupPlain } from 'models/LoCo/Insurance/DigitalAgentEdition';
import type {
    DigitalAgentEdition,
    DigitalAgentEditionExtendedPreview,
    GetDigitalAgentEditionViewPreferenceResponse,
    UpdateDigitalAgentEditionViewPreferenceParams,
} from 'models/LoCo/Insurance/DigitalAgentEdition';

import type { DigitalAgentEditionExtendedPreview as DigitalAgentEditionExtendedPreviewV2 } from 'models/LoCo/Insurance/DigitalAgentEditionV2';
import { usePessimisticMutation } from 'queries/MutationHooks';

const BASE_PATH = '/api/v1/digital-agent-editions';

export enum DigitalAgentEditionQueryKey {
    GetDigitalAgentEdition = 'GET_DIGITAL_AGENT_EDITION',
    CreateDigitalAgentEdition = 'CREATE_DIGITAL_AGENT_EDITION',
    ApproveDigitalAgentEdition = 'APPROVE_DIGITAL_AGENT_EDITION',
    ArchiveDigitalAgentEdition = 'ARCHIVE_DIGITAL_AGENT_EDITION',
    CreateDigitalAgentEditionRule = 'CREATE_DIGITAL_AGENT_EDITION_RULE',
    EditDigitalAgentEditionRule = 'EDIT_DIGITAL_AGENT_EDITION_RULE',
    DeleteDigitalAgentEditionRule = 'DELETE_DIGITAL_AGENT_EDITION_RULE',
    ReorderDigitalAgentEditionRule = 'REORDER_DIGITAL_AGENT_EDITION_RULE',
    GetDigitalAgentEditionViewPreference = 'GET_DIGITAL_AGENT_EDITION_VIEW_PREFERENCE',
    UpdateDigitalAgentEditionViewPreference = 'UPDATE_DIGITAL_AGENT_EDITION_VIEW_PREFERENCE',
    GetExtendedPreview = 'GET_EXTENDED_PREVIEW',
}

// === Editions === //

export async function getDigitalAgentEdition(editionCode: string): Promise<DigitalAgentEdition> {
    const response = await insuranceBlender.get<{ data: DigitalAgentEdition }>(`${BASE_PATH}/${editionCode}`);

    return response.data.data;
}

export function useSuspenseGetDigitalAgentEdition(editionCode: string): UseSuspenseQueryResult<DigitalAgentEdition> {
    return useSuspenseQueryWithErrorHandling({
        queryKey: [DigitalAgentEditionQueryKey.GetDigitalAgentEdition, editionCode],
        queryFn: async () => await getDigitalAgentEdition(editionCode),
    });
}

export async function getDigitalAgentEditionExtendedPreview(
    editionCode?: string
): Promise<DigitalAgentEditionExtendedPreview> {
    const response = await insuranceBlender.get<{ data: DigitalAgentEditionExtendedPreview }>(
        `${BASE_PATH}/${editionCode}/extended-preview`
    );

    return response.data.data;
}

export function useGetDigitalAgentEditionExtendedPreview(
    editionCode?: string
): UseQueryResult<DigitalAgentEditionExtendedPreview> {
    return useQuery({
        queryKey: [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode],
        queryFn: async () => await getDigitalAgentEditionExtendedPreview(editionCode),
        enabled: editionCode != null,
    });
}

export async function getDigitalAgentEditionExtendedPreviewV2(
    editionCode?: string
): Promise<DigitalAgentEditionExtendedPreviewV2> {
    const response = await insuranceBlender.get<{ data: DigitalAgentEditionExtendedPreviewV2 }>(
        `${BASE_PATH}/${editionCode}/extended-preview`
    );

    return response.data.data;
}

export function useGetDigitalAgentEditionExtendedPreviewV2(
    editionCode?: string
): UseQueryResult<DigitalAgentEditionExtendedPreviewV2> {
    return useQuery({
        queryKey: [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode],
        queryFn: async () => await getDigitalAgentEditionExtendedPreviewV2(editionCode),
        enabled: editionCode != null,
    });
}

async function createDigitalAgentEdition({
    productCode,
    description,
    baseEditionCode,
}: CreateEditionParams): Promise<DigitalAgentEdition> {
    const response = await insuranceBlender.post<{ data: DigitalAgentEdition }>(BASE_PATH, {
        productCode,
        description,
        baseEditionCode,
    });

    return response.data.data;
}

export function useCreateDigitalAgentEdition(): UseMutationResult<
    DigitalAgentEdition,
    unknown,
    CreateEditionParams,
    null
> {
    return usePessimisticMutation({
        mutationFn: createDigitalAgentEdition,
        invalidateKeys: [],
        mutationKey: [DigitalAgentEditionQueryKey.CreateDigitalAgentEdition],
    });
}

interface DeleteDigitalAgentRulesParams {
    readonly editionCode: string;
    readonly ruleIds: string[];
}

async function deleteDigitalAgentEditionRules({ editionCode, ruleIds }: DeleteDigitalAgentRulesParams): Promise<void> {
    await insuranceBlender.delete(`${BASE_PATH}/${editionCode}/rules?rulePublicIds=${ruleIds.join(',')}`);
}

export function useDeleteDigitalAgentEditionRules(editionCode: string): UseMutationResult<
    void,
    unknown,
    {
        readonly ruleIds: string[];
    },
    null
> {
    return usePessimisticMutation({
        mutationFn: async ({ ruleIds }: { ruleIds: string[] }) =>
            await deleteDigitalAgentEditionRules({ editionCode, ruleIds }),
        invalidateKeys: [
            [DigitalAgentEditionQueryKey.GetDigitalAgentEdition, editionCode],
            [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode],
        ],
        mutationKey: [DigitalAgentEditionQueryKey.DeleteDigitalAgentEditionRule],
    });
}

async function reorderDigitalAgentEditionRules(
    editionCode: string,
    publicId: string,
    destination: number,
    variantName?: string
): Promise<null> {
    return await insuranceBlender.put(`${BASE_PATH}/${editionCode}/rules/order`, {
        publicId,
        destination,
        variantName,
    });
}

export function useOptimisticReorderDigitalAgentEditionRules(
    editionCode: string
): UseMutationResult<
    null,
    unknown,
    { readonly publicId: string; readonly destination: number; readonly variantName?: string },
    DigitalAgentEditionExtendedPreview
> {
    const queryKey = [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode];
    function mutateFn({
        data,
        variables,
    }: {
        data: DigitalAgentEditionExtendedPreview;
        variables: { publicId: string; destination: number; variantName?: string };
    }): DigitalAgentEditionExtendedPreview {
        return produce(data, draft => {
            const foundInCoverage = draft.coverages.some(coverage => {
                return Object.values(coverage.rulesByType).some(rulesGroup => {
                    if (isCoverageRuleGroupPlain(rulesGroup)) {
                        return rulesGroup.rules.some((rule, index) => {
                            if (rule.publicId === variables.publicId) {
                                const temp = rulesGroup.rules[variables.destination];
                                rulesGroup.rules[variables.destination] = rule;
                                rulesGroup.rules[index] = temp;
                                return true;
                            }

                            return false;
                        });
                    } else {
                        const variantIndex = rulesGroup.variants.findIndex(
                            variant => variant.variantName === variables.variantName
                        );
                        return rulesGroup.variants[variantIndex].rules.some((rule, index) => {
                            if (rule.publicId === variables.publicId) {
                                const temp = rulesGroup.variants[variantIndex].rules[variables.destination];
                                rulesGroup.variants[variantIndex].rules[variables.destination] = rule;
                                rulesGroup.variants[variantIndex].rules[index] = temp;
                                return true;
                            }

                            return false;
                        });
                    }
                });
            });

            if (!foundInCoverage) {
                draft.settings.some(setting => {
                    return Object.values(setting.rulesByType).some(rulesGroup => {
                        if (isCoverageRuleGroupPlain(rulesGroup)) {
                            return rulesGroup.rules.some((rule, index) => {
                                if (rule.publicId === variables.publicId) {
                                    const temp = rulesGroup.rules[variables.destination];
                                    rulesGroup.rules[variables.destination] = rule;
                                    rulesGroup.rules[index] = temp;
                                    return true;
                                }

                                return false;
                            });
                        } else {
                            const variantIndex = rulesGroup.variants.findIndex(
                                variant => variant.variantName === variables.variantName
                            );
                            return rulesGroup.variants[variantIndex].rules.some((rule, index) => {
                                if (rule.publicId === variables.publicId) {
                                    const temp = rulesGroup.variants[variantIndex].rules[variables.destination];
                                    rulesGroup.variants[variantIndex].rules[variables.destination] = rule;
                                    rulesGroup.variants[variantIndex].rules[index] = temp;
                                    return true;
                                }

                                return false;
                            });
                        }
                    });
                });
            }
        });
    }

    const args = {
        mutationFn: async ({
            publicId,
            destination,
            variantName,
        }: {
            publicId: string;
            destination: number;
            variantName?: string;
        }) => await reorderDigitalAgentEditionRules(editionCode, publicId, destination, variantName),
        mutate: mutateFn,
        mutateKey: queryKey,
        invalidateKeys: [queryKey, [DigitalAgentEditionQueryKey.GetDigitalAgentEdition, editionCode]],
    } satisfies UseOptimisticMutation<
        { publicId: string; destination: number; variantName?: string },
        DigitalAgentEditionExtendedPreview
    >;

    return useOptimisticMutation(args);
}

async function reorderDigitalAgentEditionRulesV2(
    editionCode: string,
    publicId: string,
    destination: number
): Promise<null> {
    return await insuranceBlender.put(`${BASE_PATH}/${editionCode}/rules/order`, { publicId, destination });
}

export function useOptimisticReorderDigitalAgentEditionRulesV2(
    editionCode: string
): UseMutationResult<
    null,
    unknown,
    { readonly publicId: string; readonly destination: number },
    DigitalAgentEditionExtendedPreviewV2
> {
    const queryKey = [DigitalAgentEditionQueryKey.GetExtendedPreview, editionCode];
    function mutateFn({
        data,
        variables,
    }: {
        data: DigitalAgentEditionExtendedPreviewV2;
        variables: { publicId: string; destination: number };
    }): DigitalAgentEditionExtendedPreviewV2 {
        return produce(data, draft => {
            const foundInCoverage = draft.coverages.some(coverage => {
                return Object.values(coverage.rulesByType).some(rulesGroup => {
                    return rulesGroup.rules.some((rule, index) => {
                        if (rule.publicId === variables.publicId) {
                            const temp = rulesGroup.rules[variables.destination];
                            rulesGroup.rules[variables.destination] = rule;
                            rulesGroup.rules[index] = temp;
                            return true;
                        }

                        return false;
                    });
                });
            });

            if (!foundInCoverage) {
                draft.settings.some(setting => {
                    return Object.values(setting.rulesByType).some(rulesGroup => {
                        return rulesGroup.rules.some((rule, index) => {
                            if (rule.publicId === variables.publicId) {
                                const temp = rulesGroup.rules[variables.destination];
                                rulesGroup.rules[variables.destination] = rule;
                                rulesGroup.rules[index] = temp;
                                return true;
                            }

                            return false;
                        });
                    });
                });
            }
        });
    }

    const args = {
        mutationFn: async ({ publicId, destination }: { publicId: string; destination: number }) =>
            await reorderDigitalAgentEditionRulesV2(editionCode, publicId, destination),
        mutate: mutateFn,
        mutateKey: queryKey,
        invalidateKeys: [queryKey, [DigitalAgentEditionQueryKey.GetDigitalAgentEdition, editionCode]],
    } satisfies UseOptimisticMutation<{ publicId: string; destination: number }, DigitalAgentEditionExtendedPreviewV2>;

    return useOptimisticMutation(args);
}

async function updateDigitalAgentViewPreference({
    coveragesEditionCode,
    digitalAgentEditionCode,
}: UpdateDigitalAgentEditionViewPreferenceParams): Promise<void> {
    await insuranceBlender.put(`${BASE_PATH}/${digitalAgentEditionCode}/view-preference`, {
        coveragesEditionCode,
    });
}

export function useUpdateDigitalAgentViewPreference(
    digitalAgentEditionCode: string
): UseMutationResult<void, unknown, UpdateDigitalAgentEditionViewPreferenceParams, null> {
    return usePessimisticMutation({
        mutationFn: updateDigitalAgentViewPreference,
        invalidateKeys: [
            [DigitalAgentEditionQueryKey.GetDigitalAgentEditionViewPreference, digitalAgentEditionCode],
            [DigitalAgentEditionQueryKey.GetExtendedPreview, digitalAgentEditionCode],
        ],
        mutationKey: [DigitalAgentEditionQueryKey.UpdateDigitalAgentEditionViewPreference],
    });
}

async function getDigitalAgentViewPreference(
    digitalAgentEditionCode: string
): Promise<GetDigitalAgentEditionViewPreferenceResponse> {
    const response = await insuranceBlender.get<{ data: GetDigitalAgentEditionViewPreferenceResponse }>(
        `${BASE_PATH}/${digitalAgentEditionCode}/view-preference`
    );

    return response.data.data;
}

export function useGetDigitalAgentViewPreference(
    digitalAgentEditionCode: string
): UseQueryResult<GetDigitalAgentEditionViewPreferenceResponse> {
    return useQuery({
        queryKey: [DigitalAgentEditionQueryKey.GetDigitalAgentEditionViewPreference, digitalAgentEditionCode],
        queryFn: async () => await getDigitalAgentViewPreference(digitalAgentEditionCode),
    });
}
