/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { Image, ImageProps, MainButton } from '@lemonade-hq/bluis';
import { FederatedModule as FederatedModuleNoFallback } from '@lemonade-hq/cdk';
import { LinkComp, getUrlResolver, ServiceNames } from '@lemonade-hq/bluiza';
import { SideMenu } from 'components/SideMenu';
import { PrincipalMenuToggle } from 'components/Header';
import { ImpersonationToggle } from 'components/Impersonation';
import { useSelector } from 'store/typedStore';
import { useClientSettings } from 'queries/ClientSettings';
import SearchInput from './SearchBar/SearchInput';
import { HeaderInfoBarConsumer } from '../../context/HeaderInfoBar/HeaderInfoBarProvider';
import ToggleViewMode from './ToggleViewMode';
import GlobalFetchingIndicator from '../Bluis/GlobalFetchingIndicator';
import { BlenderHeader } from './style';

const BlenderLogo = styled(Image)<ImageProps>`
    margin-left: 10px;
`;

const StyledGlobalFetchingIndicator = styled(GlobalFetchingIndicator)`
    margin-left: 16px;
`;

const canImpersonate = import.meta.env.VITE_ENV_NAME === 'development' || import.meta.env.VITE_ENV_NAME === 'staging';

const monolithUrlResolver = getUrlResolver({ service: ServiceNames.Monolith, fallbackMap: new Set() });

interface HeaderProps {
    isPrinting: boolean;
    showHeaderInfo: (info: { mainText: string; subText?: string; withSpinner?: boolean }) => void;
    hideHeaderInfo: () => void;
}

const FederatedModuleProps = {
    errorComponent: null,
    fallbackComponent: null,
};

const Header: React.FC<React.PropsWithChildren<HeaderProps>> = ({ isPrinting, showHeaderInfo, hideHeaderInfo }) => {
    const [isSideMenuOpen, setIsSideMenuOpen] = useState(false);
    const isFirstRender = useRef(true);
    const { data: clientSettings } = useClientSettings();
    const canCreateQuote = clientSettings?.canCreateQuote ?? false;

    const controlHeader = useCallback(() => {
        if (isPrinting) {
            showHeaderInfo({
                withSpinner: true,
                mainText: 'GENERATING PDF',
                subText: 'This might take a few moments',
            });
        } else {
            hideHeaderInfo();
        }
    }, [hideHeaderInfo, showHeaderInfo, isPrinting]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
        } else {
            controlHeader();
        }
    }, [controlHeader]);

    // TODO: Add active item title to header once the header is migrated to blender-general

    const closeSideMenu = () => {
        setIsSideMenuOpen(false);
    };

    const openSideMenu = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        e.preventDefault();
        e.stopPropagation();

        setIsSideMenuOpen(true);
    };

    function startCreateQuoteFlow() {
        const url = monolithUrlResolver('/start/1?f=1');

        window.open(url, '_blank');
    }

    return (
        <BlenderHeader className="blender-header">
            <FederatedModuleNoFallback {...FederatedModuleProps}>
                <SideMenu open={isSideMenuOpen} closeSideMenu={closeSideMenu} />
            </FederatedModuleNoFallback>
            <div className="blender-header-left">
                <div className="blender-header-left-main">
                    <a href="" onClick={e => openSideMenu(e)}>
                        <Image
                            img={{
                                width: 15,
                                height: 12,
                                lightSrc: '/blender_assets/backoffice/header/menu-toggle.png',
                                darkSrc: '/blender_assets/backoffice/header/menu-toggle_dark.png',
                            }}
                        />
                    </a>

                    <div className="blender-header-logo">
                        <Image
                            img={{ lightSrc: '/blender_assets/backoffice/lemonade_logo.svg', width: 27, height: 27 }}
                        />
                        <LinkComp url="/backoffice" className="blender-header-logo-link">
                            <BlenderLogo
                                img={{
                                    width: 85,
                                    height: 26,
                                    lightSrc: '/blender_assets/backoffice/header/blender_logo.png',
                                    darkSrc: '/blender_assets/backoffice/header/blender_logo_dark.png',
                                }}
                            />
                        </LinkComp>
                    </div>
                </div>
                <StyledGlobalFetchingIndicator />
            </div>

            <div className="blender-header-right">
                <SearchInput />
                {canCreateQuote && (
                    <div className="blender-header-right-main">
                        <MainButton size="small" onClick={startCreateQuoteFlow}>
                            Create a Quote
                        </MainButton>
                    </div>
                )}
                <div className="blender-header-right-main">
                    <ToggleViewMode />

                    {canImpersonate && (
                        <FederatedModuleNoFallback {...FederatedModuleProps}>
                            <ImpersonationToggle />
                        </FederatedModuleNoFallback>
                    )}

                    <FederatedModuleNoFallback {...FederatedModuleProps}>
                        <PrincipalMenuToggle />
                    </FederatedModuleNoFallback>
                </div>
            </div>
        </BlenderHeader>
    );
};

interface HeaderWithHeaderInfoBarProps extends HeaderProps {
    showHeaderInfo: (info: { mainText: string; subText?: string; withSpinner?: boolean }) => void;
    hideHeaderInfo: () => void;
}

const HeaderWithHeaderInfoBar: React.FC<React.PropsWithChildren<unknown>> = () => {
    const { isPrinting } = useSelector(state => state.view);

    return (
        <HeaderInfoBarConsumer>
            {({ showHeaderInfo, hideHeaderInfo }: HeaderWithHeaderInfoBarProps) => (
                <Header {...{ showHeaderInfo, hideHeaderInfo }} isPrinting={isPrinting} />
            )}
        </HeaderInfoBarConsumer>
    );
};

export default HeaderWithHeaderInfoBar;
