import type { ComboBoxItem } from '@lemonade-hq/blender-ui';
import {
    ActionsMenu,
    Button,
    Card,
    ComboBox,
    Flex,
    LabelValueTable,
    spacing,
    Text,
    TextArea,
    Tooltip,
} from '@lemonade-hq/blender-ui';
import { useAnalytics, useCurrentPrincipal } from '@lemonade-hq/boutique';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCarousel } from '../../Carousel/CarouselProvider';
import { filenameEllipsis } from './details.css';
import { useGetAttachmentTypes, useSubmitAttachmentDetails } from 'components/Attachments/AttachmentsQueries';
import { useAttachmentsData } from 'components/Attachments/context';
import { getAttachmentAnalyticsParam } from 'components/Attachments/utils';

interface GalleryAttachmentDetailsProps {
    readonly onSubmit?: () => void;
}

const EditMode: FC<{
    readonly onSubmit: GalleryAttachmentDetailsProps['onSubmit'];
    readonly setEditMode: (value: boolean) => void;
}> = ({ onSubmit, setEditMode }) => {
    const { attachments, entityType, entityPublicId } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const { fileName, type, description = '', publicId } = attachment;
    const { data: attachmentTypes, isError, isLoading } = useGetAttachmentTypes({ entityType });
    const { mutateAsync: submitAttachmentDetails } = useSubmitAttachmentDetails({
        entityPublicId: entityPublicId ?? '',
        entityType,
    });

    const { trackEvent } = useAnalytics();
    const { operator } = useCurrentPrincipal();
    const params = getAttachmentAnalyticsParam({
        attachment,
        entityType,
        entityId: entityPublicId ?? '',
        operatorId: operator?.id ?? '',
    });

    const isPolicyPdf = type === 'policy_pdf';

    const { values, setValue, enabled } = useForm({
        fields: {
            type: {
                startValue: type ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
            description: {
                startValue: description ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
        },
    });

    const types = useMemo(
        () => attachmentTypes?.map(it => ({ value: it, label: snakeCaseToReadable(it) })) ?? [],
        [attachmentTypes]
    );

    const handleSubmit = useCallback(async () => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        trackEvent('docs.gallery.updated', { ...params, doc_type: values.type, doc_description: values.description });
        setEditMode(false);
        onSubmit?.();
        await submitAttachmentDetails({
            type: values.type,
            description: values.description,
            attachmentPublicId: publicId,
        });
    }, [onSubmit, params, publicId, setEditMode, submitAttachmentDetails, trackEvent, values.description, values.type]);

    const handleSelectionChange = useCallback(
        (item: ComboBoxItem | null) => setValue('type', item?.value ?? ''),
        [setValue]
    );

    return (
        <Card p={spacing.s16}>
            <Flex flexDirection="column" gap={spacing.s20}>
                <Flex flexDirection="column" gap={spacing.s12}>
                    <Text fontWeight="semi-bold" type="text-lg">
                        File information
                    </Text>
                    <LabelValueTable
                        columnCount={1}
                        data={[
                            {
                                label: 'File name',
                                value: (
                                    <Tooltip content={fileName}>
                                        <Text className={filenameEllipsis}>{fileName}</Text>
                                    </Tooltip>
                                ),
                            },
                        ]}
                        labelWidth="100px"
                    />
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <Text color="primary" type="label-sm">
                            Type
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                        </Text>
                        <Flex flexDirection="column" gap={spacing.s06}>
                            {isPolicyPdf ? (
                                <Text type="label-sm">Policy PDF</Text>
                            ) : (
                                <ComboBox
                                    defaultValue={values.type}
                                    disabled={isLoading}
                                    items={types}
                                    onSelectionChange={handleSelectionChange}
                                    placeholder="Select attachment type"
                                />
                            )}
                            {isError && (
                                <Text color="error" type="label-sm">
                                    Error loading attachment types
                                </Text>
                            )}
                        </Flex>
                    </Flex>
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <Text color="primary" type="label-sm">
                            Description
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                        </Text>
                        <TextArea
                            autoExpanding
                            maxLength={500}
                            onChange={e => setValue('description', e.target.value)}
                            rows={5}
                            value={values.description}
                        />
                    </Flex>
                </Flex>

                <Button disabled={!enabled} label="Save" onClick={handleSubmit} size="md" variant="primary" />
            </Flex>
        </Card>
    );
};

const LabelValueMode: FC<{
    readonly fileName: string;
    readonly type: string;
    readonly description: string;
    readonly setEditMode: (value: boolean) => void;
}> = ({ fileName = '', type = '', description = '', setEditMode }) => {
    const data = [
        {
            label: 'File name',
            value: (
                <Tooltip content={fileName}>
                    <Text className={filenameEllipsis}>{fileName}</Text>
                </Tooltip>
            ),
        },
        { label: 'Type', value: snakeCaseToReadable(type) },
        { label: 'Description', value: description },
    ];

    return (
        <Card p={spacing.s16}>
            <Flex alignItems="center" justifyContent="space-between" mb={spacing.s12}>
                <Text fontWeight="semi-bold" type="text-lg">
                    File information
                </Text>
                <ActionsMenu items={[{ label: 'Edit', id: 'edit', onClick: () => setEditMode(true) }]} />
            </Flex>
            <LabelValueTable columnCount={1} data={data} labelWidth="100px" />
        </Card>
    );
};

export const GalleryAttachmentDetails: FC<GalleryAttachmentDetailsProps> = ({ onSubmit }) => {
    const { attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const { fileName, type, description = '' } = attachment;
    const [isEditMode, setEditMode] = useState(description === '' || description == null);

    useEffect(() => {
        setEditMode(description == null || description === '');
    }, [description, currentIndex]);

    if (isEditMode) {
        return <EditMode onSubmit={onSubmit} setEditMode={setEditMode} />;
    } else {
        return (
            <LabelValueMode
                description={description ?? ''}
                fileName={fileName ?? ''}
                setEditMode={setEditMode}
                type={type ?? ''}
            />
        );
    }
};
