import { Button, Flex, Layout, spacing, Text } from '@lemonade-hq/blender-ui';
import { AttachmentType, getTypeFromContentType } from '@lemonade-hq/bluis';
import { useAnalytics, useCurrentPrincipal } from '@lemonade-hq/boutique';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import type { AttachmentDTO } from '../../types';
import { getAttachmentAnalyticsParam, getInlineUrl } from '../../utils';
import { useCarousel } from '../Carousel/CarouselProvider';
import { GalleryImage } from './GalleryImage';
import { GalleryPDF } from './GalleryPDF';
import { GalleryVideo } from './GalleryVideo';
import { archivedBg, mediaWrapper } from './Media.css';
import { useUnArchiveAttachment } from 'components/Attachments/AttachmentsQueries';
import { useAttachmentsData } from 'components/Attachments/context';

export const MediaComp: FC<{
    readonly attachment: AttachmentDTO;
}> = ({ attachment }) => {
    const { fileName, contentType, url } = attachment;
    const type = getTypeFromContentType(contentType ?? url);
    const inlineUrl = useMemo(() => getInlineUrl(url), [url]);

    switch (type) {
        case AttachmentType.Pdf:
            return <GalleryPDF fileName={fileName ?? ''} url={inlineUrl} />;
        case AttachmentType.Image:
            return <GalleryImage attachment={attachment} />;
        case AttachmentType.Video:
            return <GalleryVideo fileName={fileName ?? ''} url={inlineUrl} />;
        default:
            return (
                <Flex
                    alignItems="center"
                    flexDirection="column"
                    gap={spacing.s24}
                    height="100%"
                    justifyContent="center"
                    width="100%"
                >
                    <Text>This file cannot be displayed</Text>
                    <Button
                        label="Download"
                        onClick={() => window.open(url.replace('?inline=true', ''), '_blank')}
                        startIcon="download"
                        variant="secondary"
                    />
                </Flex>
            );
    }
};

const CompareMode: FC = () => {
    const { attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const { detections: attachmentDetections } = attachment;
    const { annotatedSource, annotatedReconstructed } = attachmentDetections?.[0].detectionResult ?? {
        annotatedSource: '',
        annotatedReconstructed: '',
    };

    return (
        <Flex height="100%">
            <Layout width="50%">
                <MediaComp attachment={{ ...attachment, url: annotatedSource ?? attachment.url }} />
            </Layout>
            <Layout width="50%">
                <MediaComp attachment={{ ...attachment, url: annotatedReconstructed ?? attachment.url }} />
            </Layout>
        </Flex>
    );
};

const ArchivedBg: FC<{ readonly attachment: AttachmentDTO }> = ({ attachment }) => {
    const { entityPublicId, entityType } = useAttachmentsData();
    const { mutateAsync: unarchive } = useUnArchiveAttachment({ entityPublicId: entityPublicId ?? '', entityType });
    const { archivingReason, publicId } = attachment;
    const { trackEvent } = useAnalytics();
    const { operator } = useCurrentPrincipal();
    const params = getAttachmentAnalyticsParam({
        attachment,
        entityType,
        entityId: entityPublicId ?? '',
        operatorId: operator?.id ?? '',
    });

    const handleUnarchive = useCallback(async () => {
        trackEvent('docs.gallery.unarchive', params);
        await unarchive([{ attachmentPublicId: publicId }]);
    }, [params, publicId, trackEvent, unarchive]);

    return (
        <Flex alignItems="center" className={archivedBg} flexDirection="column" justifyContent="center">
            <Text color="light" fontWeight="bold" type="h4">
                This file is archived
            </Text>
            {Boolean(archivingReason) && (
                <Text as="p" color="light" pb={spacing.s16}>
                    Reason: {snakeCaseToReadable(archivingReason ?? '')}
                </Text>
            )}
            <Button label="Unarchive" onClick={handleUnarchive} startIcon="unarchive" variant="secondary" />
        </Flex>
    );
};

export const GalleryMedia: FC = () => {
    const { attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const { status } = attachment;
    const archived = status === 'archived';
    const isCompareMode = attachment.detections?.some(
        detection =>
            Boolean(detection.detectionResult.annotatedSource) &&
            Boolean(detection.detectionResult.annotatedReconstructed)
    );

    return (
        <div className={mediaWrapper}>
            {archived && <ArchivedBg attachment={attachment} />}
            {isCompareMode ? <CompareMode /> : <MediaComp attachment={attachment} />}
        </div>
    );
};
