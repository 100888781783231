/* eslint-disable @typescript-eslint/naming-convention */
import { Button, Card, Flex, Input, Select, spacing, Text } from '@lemonade-hq/blender-ui';
import { useAnalytics, useCurrentPrincipal } from '@lemonade-hq/boutique';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useCarousel } from '../Carousel/CarouselProvider';
import { archiveButton } from './ArchiveForm.css';
import {
    useArchiveAttachment,
    useAttachmentArchiveReasons,
    useDismissArchiveSuggestion,
} from 'components/Attachments/AttachmentsQueries';
import { useAttachmentsData } from 'components/Attachments/context';
import { getAttachmentAnalyticsParam } from 'components/Attachments/utils';

export const ArchiveForm: FC<{ readonly onClose: () => void }> = ({ onClose }) => {
    const { attachments, entityPublicId, entityType } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const { suggestedArchive, archivingReason, publicId } = attachment;
    const { data, isLoading, isError } = useAttachmentArchiveReasons();
    const { mutateAsync: archive } = useArchiveAttachment({ entityPublicId: entityPublicId ?? '', entityType });
    const { mutateAsync: dismissArchive } = useDismissArchiveSuggestion({
        entityPublicId: entityPublicId ?? '',
        entityType,
    });
    const [reason, setReason] = useState<string>(archivingReason ?? '');
    const [otherReason, setOtherReason] = useState<string>('');

    const { trackEvent } = useAnalytics();
    const { operator } = useCurrentPrincipal();
    const params = getAttachmentAnalyticsParam({
        attachment,
        entityType,
        entityId: entityPublicId ?? '',
        operatorId: operator?.id ?? '',
    });

    const handleSubmit = useCallback(async () => {
        trackEvent('docs.gallery.archive', {
            ...params,
            doc_archiving_reason: reason,
            ...(suggestedArchive === true && { suggested_reason_changed: (reason !== archivingReason).toString() }),
        });
        await archive([{ reason: reason === 'other' ? otherReason : reason, attachmentPublicId: attachment.publicId }]);

        onClose();
    }, [
        archive,
        archivingReason,
        attachment.publicId,
        onClose,
        otherReason,
        params,
        reason,
        suggestedArchive,
        trackEvent,
    ]);

    const reasons = useMemo(
        () =>
            data?.map(it => ({
                value: it,
                label: snakeCaseToReadable(it),
            })),
        [data]
    );

    const handleInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setOtherReason(e.target.value);
    }, []);

    const handleCancelArchive = useCallback(async () => {
        trackEvent('docs.gallery.dismiss_suggested_archive', params);
        await dismissArchive({ attachmentPublicId: publicId });
        onClose();
    }, [onClose, params, publicId, trackEvent, dismissArchive]);

    return (
        <Card p={spacing.s16}>
            <Text fontWeight="semi-bold" type="text-lg">
                ּ{suggestedArchive === true ? 'Suggested archive' : 'Archive'}
            </Text>
            <Flex flexDirection="column" gap={spacing.s24} mt={spacing.s12}>
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        {suggestedArchive === true ? 'We think you should archive this:' : 'Archive reason'}
                        {suggestedArchive == null ||
                            (!suggestedArchive && (
                                <Text as="span" color="error">
                                    &nbsp;*
                                </Text>
                            ))}
                    </Text>
                    <Select<never, { value: string; label: string }>
                        disabled={isLoading}
                        onChange={value => setReason(value ?? '')}
                        options={reasons ?? []}
                        placeholder="Select archive reason"
                        selectedKey={reason}
                    />
                    {isError && (
                        <Text color="error" type="label-sm">
                            Error loading archive reasons
                        </Text>
                    )}
                </Flex>
                {reason === 'other' && (
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <Text color="primary" type="label-sm">
                            Other reason
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                        </Text>
                        <Input onChange={handleInputChange} placeholder="Add reason" value={otherReason} />
                    </Flex>
                )}
                <Flex gap={spacing.s08}>
                    <Button
                        className={archiveButton}
                        label="Cancel"
                        onClick={handleCancelArchive}
                        size="md"
                        variant="secondary"
                    />
                    <Button
                        className={archiveButton}
                        disabled={reason === '' || (reason === 'other' && otherReason === '')}
                        label="Archive"
                        onClick={handleSubmit}
                        size="md"
                        variant="primary"
                    />
                </Flex>
            </Flex>
        </Card>
    );
};
