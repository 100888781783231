import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import type {
    Attachment,
    AttachmentDetection,
    HomeAttachment,
    HomeClaimAttachment,
    LmndDsDetectionResult,
    NotificationsAttachment,
    PetAttachmentAutoItemization,
    ResistantAiDetectionResult,
} from '../models/Attachment';
import {
    AttachmentDetectionType,
    AttachmentSource,
    AttachmentType,
    PetAttachmentType,
    ProductAttachmentType,
} from '../models/Attachment';

type Filename = {
    readonly filename: string;
    readonly extension: string;
};

export function getFilename(attachment: Attachment): Filename {
    if (!attachment.original_filename) {
        return {
            filename: '',
            extension: '',
        };
    }

    const [filename = '', extension = ''] = attachment.original_filename.split('.');
    const parsedExtension = extension.substring(0, extension.indexOf('?'));

    return {
        filename,
        extension: parsedExtension,
    };
}

export const assignmentTypes = [
    { id: 'Damage photo', label: 'Damage photo', value: 'damaged_item' },
    { id: 'Estimation / Proposal', label: 'Estimation / Proposal', value: 'estimation_proposal' },
    { id: 'Police report', label: 'Police report', value: 'police_report' },
    { id: 'Receipt / Invoice', label: 'Receipt / Invoice', value: 'item_receipt' },
];

export function getDisplayType(attachment_type: AttachmentType) {
    const option = assignmentTypes.find(option => option.value === attachment_type);

    return option ? option.label : snakeCaseToReadable(mapAttachmentType(attachment_type));
}

export function mapAttachmentType(type: AttachmentType): string {
    switch (type) {
        case AttachmentType.UserSignature:
            return 'User signature';
        case AttachmentType.PolicyPDF:
            return 'Policy PDF';
        case AttachmentType.ClaimVideo:
            return 'Claim video';
        case PetAttachmentType.ClaimOther:
            return 'Email attachment';
        case AttachmentType.Email:
            return 'Email attachment';
        case AttachmentType.InjuryDocuments:
            return 'Injury docs';
        case AttachmentType.DamageDocuments:
            return 'Damage docs';
        case AttachmentType.ThirdPartyInsuranceID:
            return 'Third party insurance card';
        case AttachmentType.RepairAppraisalAssessment:
            return 'Repair assessment';
        case AttachmentType.PreapprovalEstimate:
            return 'Pre-approval estimate';
        case AttachmentType.PreapprovalOther:
            return 'Other pre-approval attachment';
        default:
            return type.replace('claim_', '');
    }
}

function isHomeClaimAttachment(attachment: HomeAttachment | HomeClaimAttachment): attachment is HomeClaimAttachment {
    return 'claimItems' in attachment;
}

export function homeAttachmentToAttachment(
    attachment: HomeAttachment | HomeClaimAttachment
): Attachment & Partial<HomeAttachment> {
    return {
        ...attachment,
        download_url: attachment.url,
        type: attachment.type as AttachmentType,
        original_filename: isHomeClaimAttachment(attachment) ? attachment.filename ?? '' : attachment.name,
        taken_at: isHomeClaimAttachment(attachment) ? attachment.takenAt ?? '' : attachment.taken_at,
        description: attachment.description ?? '',
        content_type: attachment.url,
        file_public_id: String(attachment.id),
        fraud_detection_identifier: (isHomeClaimAttachment(attachment) && attachment.filePublicId) || null,
        originEntityPublicId: (isHomeClaimAttachment(attachment) && attachment.originEntityPublicId) || null,
        thumbnail: isHomeClaimAttachment(attachment) ? attachment.thumbnailUrl ?? undefined : attachment.thumbnail,
        ...(isHomeClaimAttachment(attachment) && {
            content_type: attachment.contentType ?? '',
            created_at: attachment.createdAt ?? '',
            location: attachment.address ?? '',
        }),
    };
}

function convertDetections(attachmentDetections?: AttachmentDetection[]) {
    if (!attachmentDetections || attachmentDetections.length === 0) return undefined;

    const detection = attachmentDetections.find(it => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return it.fraud_detection_type === AttachmentDetectionType.ModifiedDocuments;
    });

    if (detection == null) return undefined;

    return {
        fraudDoc: {
            prediction: true,
            annotatedSource: detection.details.left_comparison_file_url ?? null,
            annotatedReconstructed: detection.details.right_comparison_file_url ?? null,
            textChanges: detection.details.text_changes,
        },
    };
}

export function carAttachmentToAttachment(attachment: Attachment): Attachment {
    return {
        ...attachment,
        ...convertDetections(attachment.attachment_detections as unknown as AttachmentDetection[] | undefined),
    };
}

export function notificationsEmailAttachmentToAttachment(
    attachment: NotificationsAttachment
): Attachment & NotificationsAttachment {
    return {
        ...attachment,
        file_public_id: attachment.filePublicId,
        original_filename: attachment.name,
        url: attachment.url,
        download_url: attachment.url,
        type: attachment.type as AttachmentType,
        description: attachment.name,
        content_type: attachment.type,
        fraud_detection_identifier: attachment.filePublicId,
    };
}

export function anyAttachmentToAttachment(attachment: Record<string, string>): Attachment {
    return {
        ...attachment,
        type: (attachment.type || ProductAttachmentType.Attachment) as AttachmentType,
        download_url: attachment.downloadUrl || attachment.download_url || attachment.url,
        content_type: attachment.contentType || attachment.content_type || attachment.url,
        description: attachment.description || attachment.name,
        file_public_id: attachment.filePublicId || attachment.file_public_id || String(attachment.id),
        original_filename: attachment.name || attachment.originalFilename,
        taken_at: attachment.takenAt || attachment.taken_at,
        fraud_detection_identifier: attachment.filePublicId || null,
        ...convertDetections(attachment.attachment_detections as unknown as AttachmentDetection[] | undefined),
    };
}

export function photoToAttachment(attachment: Record<string, string>): Attachment {
    return {
        ...attachment,
        type: (attachment.source ? attachment.source : ProductAttachmentType.Attachment) as AttachmentType,
        download_url: attachment.url,
        content_type: attachment.contentType ? attachment.contentType : attachment.url,
        description: attachment.description ? attachment.description : attachment.fileName,
        file_public_id: attachment.filePublicId ? attachment.filePublicId : attachment.fileName,
        original_filename: attachment.fileName,
        fraud_detection_identifier: attachment.filePublicId,
    };
}

const serializeAttachment = (attachment: PetAttachmentAutoItemization): Attachment => {
    return {
        type: attachment.type,
        download_url: attachment.url,
        created_at: attachment.createdAt,
        content_type: attachment.contentType ?? '',
        description: attachment.description ?? '',
        taken_at: attachment.takenAt?.toISOString(),
        file_public_id: attachment.filePublicId,
        original_filename: attachment.description ?? '',
        public_id: attachment.filePublicId,
        transcription: attachment.transcription,
        transcription_confidence: attachment.transcriptionConfidence ?? 0,
        source: AttachmentSource.Manual,
        user_saved: attachment.userSaved,
        entity_public_id: attachment.entityId,
        fraud_detection_identifier: attachment.filePublicId,
    };
};

export const serializeAttachments = (attachments: PetAttachmentAutoItemization[]): Attachment[] => {
    return attachments.map(serializeAttachment);
};

export function filterPresentableAttachments(attachment: Attachment): boolean {
    return attachment.type !== AttachmentType.ClaimVideoPreview;
}

export function getAttachmentId(attachment: Attachment): string {
    return (
        attachment.public_id ?? (attachment.id != null ? String(attachment.id) : undefined) ?? attachment.file_public_id
    );
}

// Fraud helpers - there are 3 types of detection models, each have different detection result (see models/Attachment.ts) //
const hasPrediction = (attachment: Attachment): boolean =>
    Boolean(
        attachment.fraudDetections?.some(
            detection =>
                detection.detectionResult != null &&
                'prediction' in detection.detectionResult &&
                Boolean(detection.detectionResult.prediction)
        )
    );

const isLegacyFraud = (attachment: Attachment): boolean => Boolean(attachment.fraudDoc?.prediction);

export const isFraudByRAIModel = (attachment: Attachment): boolean => {
    return Boolean(
        attachment.fraudDetections?.some(
            detection =>
                detection.detectionResult != null &&
                'score' in detection.detectionResult &&
                detection.detectionResult.score === 'HIGH_RISK'
        )
    );
};

export const getRaiDetectionResult = (attachment: Attachment) =>
    attachment.fraudDetections?.find(detection => detection.detectionModel === 'resistant_ai')?.detectionResult as
        | ResistantAiDetectionResult
        | null
        | undefined;
export const getLmndDetectionResult = (attachment: Attachment) =>
    attachment.fraudDetections?.find(detection => detection.detectionModel === 'lmnd_ds')?.detectionResult as
        | LmndDsDetectionResult
        | null
        | undefined;

export const isFraud = (attachment: Attachment): boolean =>
    isLegacyFraud(attachment) || hasPrediction(attachment) || isFraudByRAIModel(attachment);
