import { Card, Flex, Layout, spacing, Text } from '@lemonade-hq/blender-ui';
import { getLinkByEntity } from '@lemonade-hq/bluiza';
import type { CoreEntityType, EntityTypes, ProductTypes } from '@lemonade-hq/bluiza';
import { format } from 'date-fns';
import { useMemo } from 'react';
import type { FC } from 'react';
import { Link } from 'react-router-dom';
import type { AttachmentSource } from '../../../types';
import { useCarousel } from '../../Carousel/CarouselProvider';
import { dotWrapper, sourceDot, sourceLine } from './AttachmentSources.css';
import { useAttachmentsData } from 'components/Attachments/context';

function getLink({
    linkType,
    entityType,
    id,
}: {
    readonly linkType: 'interaction';
    readonly entityType: EntityTypes;
    readonly id: string;
}): string {
    const [product, entity] = entityType.split('_') as [ProductTypes, CoreEntityType];

    switch (linkType) {
        case 'interaction':
            return `${getLinkByEntity({ product, entity })}/${id}/comms-timeline/`;
        default:
            return '';
    }
}
const Timeline: FC<{ readonly source: AttachmentSource; readonly isLast: boolean }> = ({ source, isLast }) => {
    const { entityPublicId, entityType } = useAttachmentsData();
    const { description, timestamp, link } = source;

    return (
        <Flex gap={spacing.s32}>
            <Flex className={dotWrapper} flexDirection="column">
                <div className={sourceDot} />
                {!isLast && <div className={sourceLine} />}
            </Flex>
            <Flex flexDirection="column" pb={isLast ? undefined : spacing.s12}>
                <Text as="p" fontWeight="bold" mb="0">
                    {description}{' '}
                    <Link
                        rel="noreferrer"
                        target="_blank"
                        to={`${getLink({ linkType: link.type, entityType, id: entityPublicId ?? '' })}?id=${link.param}`}
                    >
                        {link.text}
                    </Link>
                </Text>
                <Text as="p" color="tertiary" mb="0">
                    {format(new Date(timestamp), 'MMM dd, yyyy hh:mm')}
                </Text>
            </Flex>
        </Flex>
    );
};

export const AttachmentSources: FC = () => {
    const { attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);
    const { sources } = attachment;

    if (sources == null || sources.length === 0) return null;

    return (
        <Card p={spacing.s16}>
            <Layout mb={spacing.s12}>
                <Text fontWeight="semi-bold" type="text-lg">
                    Upload method{sources.length > 1 ? 's' : ''}
                </Text>
            </Layout>
            <Flex flexDirection="column">
                {sources.map((source, index) => (
                    <Timeline
                        isLast={index === sources.length - 1}
                        // eslint-disable-next-line react/no-array-index-key
                        key={`${format(new Date(source.timestamp), 'yyyy-MM-dd')}_${index}`}
                        source={source}
                    />
                ))}
            </Flex>
        </Card>
    );
};
