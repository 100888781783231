import type { TaggedUnion } from 'type-fest';

export enum ActionType {
  Dialog = 'dialog',
  Edit = 'edit',
  Navigate = 'navigate',
  Submit = 'submit',
  Button = 'button',
  ButtonLink = 'button_link',
}

export interface ActionBase {
  readonly type: ActionType;
  readonly disabled?: boolean;
  readonly tracking?: { readonly params: Record<string, string> };
}

export type Action = TaggedUnion<
  'type',
  {
    readonly [ActionType.Navigate]: {
      readonly url: string;
    };
    readonly [ActionType.Button]: {
      readonly onClick: () => void;
      readonly disabled?: boolean;
    };
    // in order to support catching the link press and doing things like react-router navigate
    readonly [ActionType.ButtonLink]: {
      readonly onClick: () => void;
      readonly url: string;
    };
  }
>;
