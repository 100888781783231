import type { RowItem, TableHeaderCellProps } from '@lemonade-hq/bluis';
import {
    copyToClipboard,
    ErrorSection,
    LoadingSection,
    MainButton,
    Table,
    TableHeader,
    TableRow,
    TableTitle,
} from '@lemonade-hq/bluis';
import { useCallback, useState } from 'react';
import { StyledSectionHeader } from '../../../LoCoPagesSharedStyles';
import { RegistryTemplateActions, TemplateAction } from '../RegistryActions';
import { ManageTemplateCoverageDialog } from './ManageTemplateCoverageDialog';
import { Paginator } from 'components/LoCo/common/components/Pagination/Paginator';
import type { CoverageTemplate } from 'models/LoCo/Insurance/Registry';
import { useGetRegistryCoverages } from 'queries/LoCo/Insurance/RegistryQueries';

const headers: TableHeaderCellProps[] = [
    { key: 'name', value: 'name', width: 200 },
    { key: 'description', value: 'description', width: 500 },
    { key: 'product lines', value: 'product lines', width: 300 },
    { key: 'actions', value: 'actions', width: 100 },
];

const mapCoverageToRow = (coverage: CoverageTemplate, onAction: (action: TemplateAction) => void): RowItem[] => {
    return [
        { key: 'name', value: coverage.name },
        { key: 'description', value: coverage.description },
        { key: 'product lines', value: coverage.productLines.map(productLine => productLine.name).join(', ') },
        {
            key: 'actions',
            value: (
                <RegistryTemplateActions
                    copyText={coverage.code}
                    entityName="Coverage"
                    onACtion={action => onAction(action)}
                />
            ),
        },
    ];
};

export const CoveragesRegistry: React.FC = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const { data, isLoading, isError } = useGetRegistryCoverages({ page: pageNumber, size: 20 });

    const [showDialog, setShowDialog] = useState(false);
    const [selectedCoverage, setSelectedCoverage] = useState<CoverageTemplate>();

    const onAction = useCallback((action: TemplateAction, coverage: CoverageTemplate) => {
        if (action === TemplateAction.Edit) {
            setShowDialog(true);
            setSelectedCoverage(coverage);
        } else {
            copyToClipboard(coverage.code);
        }
    }, []);

    const addCoverage = useCallback(() => {
        setSelectedCoverage(undefined);
        setShowDialog(true);
    }, []);

    const closeDialog = useCallback(() => {
        setShowDialog(false);
    }, []);

    const onPageClick = (nextPage: number) => () => {
        setPageNumber(nextPage);
    };

    if (isLoading) {
        return <LoadingSection />;
    }

    if (!data || isError) {
        return <ErrorSection title="No Coverages" />;
    }

    const { data: coverages, stats } = data;

    return (
        <>
            <StyledSectionHeader>
                <TableTitle title="Global Coverages" />
                <MainButton onClick={addCoverage}>Add</MainButton>
            </StyledSectionHeader>
            <Table>
                <TableHeader headers={headers} />
                {coverages.map(coverage => (
                    <TableRow
                        key={coverage.code}
                        row={mapCoverageToRow(coverage, action => onAction(action, coverage))}
                    />
                ))}
            </Table>
            <Paginator currentPage={pageNumber} onPageClick={onPageClick} totalPages={stats.totalPages} />
            {showDialog && <ManageTemplateCoverageDialog coverage={selectedCoverage} onClose={closeDialog} />}
        </>
    );
};
