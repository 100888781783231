/* eslint-disable jsx-a11y/no-autofocus */
import type { OnChangeSubscription } from '@lemonade-hq/blender-ui';
import {
    FormInputColumns,
    FormInputGroup,
    FormLayout,
    generateTypedFormComponents,
    useForm,
} from '@lemonade-hq/blender-ui';
import { pick } from '@lemonade-hq/maschema-schema';
import { clientToolSchema, ToolSubclassifierActionType } from '@lemonade-hq/persisted-tools';
import { useCallback, useEffect } from 'react';
import type { FC } from 'react';

const subclassifierShape = pick(clientToolSchema, 'subClassifiers').schema.subClassifiers.shape;
const { ErrorMessage, Select, Input, ComboBox, InputGroup } = generateTypedFormComponents<typeof subclassifierShape>();

export const ToolEditorSubclassifier: FC = () => {
    const {
        values: {
            thenAction: { type },
        },
        dispatch,
    } = useForm<typeof subclassifierShape>();

    // in order to improve UX - if someone by mistake types a tool name in reason, it reflects in the tool name field after changing
    const onChange: OnChangeSubscription<typeof subclassifierShape> = useCallback(
        ({ key, value }): void => {
            if (key === 'thenAction.reason') {
                dispatch({
                    type: 'setValue',
                    key: 'thenAction.toolName',
                    value: value as string,
                });
            }
        },
        [dispatch]
    );

    useEffect(() => {
        dispatch({ type: 'subscribe', key: 'subclassifier', subscriptions: { onChange } });

        return () => {
            dispatch({ type: 'unsubscribe', key: 'tool-subclassifier', subscriptions: { onChange } });
        };
    }, [onChange, dispatch]);

    return (
        <FormLayout>
            <InputGroup autoExpanding autoFocus inputComponent="TextArea" label="If" schemaKey="ifContent" />
            <FormInputColumns gridTemplateColumns="1fr 3fr">
                <FormInputGroup>
                    <Select
                        aria-label="then-action"
                        labels={{ reroute: 'Reroute', escalate: 'Escalate' }}
                        placeholder="Action"
                        schemaKey="thenAction.type"
                    />
                </FormInputGroup>
                <FormInputGroup>
                    {type === ToolSubclassifierActionType.Reroute ? (
                        <ComboBox
                            aria-label="then-action-tool-name"
                            placeholder="to..."
                            schemaKey="thenAction.toolName"
                        />
                    ) : (
                        <>
                            <Input aria-label="then-action-reason" placeholder="Reason" schemaKey="thenAction.reason" />
                            <ErrorMessage schemaKey="thenAction.reason" />
                        </>
                    )}
                </FormInputGroup>
            </FormInputColumns>
        </FormLayout>
    );
};
