import { Input } from '@lemonade-hq/bluis';
import type { FieldsValues, FormProps } from '@lemonade-hq/cdk';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useContext, useEffect } from 'react';
import type { RangeValuesState } from '../../SettingContext';
import { SettingActionType, SettingContext } from '../../SettingContext';
import { formatNumericField, parseNumber, sanitizeValue } from 'components/LoCo/common/helpers/inputHelpers';
import { useDebounce } from 'components/LoCo/common/hooks/useDebounce';
import { StyledFormInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import { ValueType } from 'models/LoCo/Insurance/CoveragesEdition';

enum FieldType {
    RangeInterval = 'RangeInterval',
    RangeMin = 'RangeMin',
    RangeMax = 'RangeMax',
}

type Field = { readonly label: string; readonly fieldType: FieldType };

const fields: Field[] = [
    { label: 'Interval Size', fieldType: FieldType.RangeInterval },
    { label: 'Minimum', fieldType: FieldType.RangeMin },
    { label: 'Maximum', fieldType: FieldType.RangeMax },
];

interface ValuesContainerProps {
    readonly minLimit?: number;
    readonly maxLimit?: number;
}

export const RangeValues: React.FC<ValuesContainerProps> = ({ minLimit, maxLimit }) => {
    const { isReady: notDuringTyping, onEvent } = useDebounce(1000);

    const { dispatch, state } = useContext(SettingContext);

    const valueIsBetweenRangeLimitsValidation = {
        errorMessage: `Value must be between ${minLimit} and ${maxLimit}`,
        test: (value: string) => {
            return (
                (!isDefined(minLimit) || parseNumber(value) >= minLimit) &&
                (!isDefined(maxLimit) || parseNumber(value) <= maxLimit)
            );
        },
        skipCondition: (values: FieldsValues<FormProps>) =>
            [values[FieldType.RangeInterval], values[FieldType.RangeMin], values[FieldType.RangeMax]].some(isEmpty),
    };

    const form = {
        fields: {
            [FieldType.RangeInterval]: {
                startValue: (state?.values as RangeValuesState).interval,
                validations: {
                    required: basicRequiredValidation,
                    isPositive: {
                        errorMessage: 'Interval size must be positive',
                        test: (value: string) => {
                            return !value.length || parseNumber(value) > 0;
                        },
                    },
                    intervalWithinRange: {
                        errorMessage: 'Interval size must be within range',
                        test: (value: string, values: FieldsValues<FormProps>) => {
                            return (
                                parseNumber(value) <=
                                parseNumber(values[FieldType.RangeMax]?.toString() ?? '0') -
                                    parseNumber(values[FieldType.RangeMin]?.toString() ?? '0')
                            );
                        },
                        skipCondition: values =>
                            [
                                values[FieldType.RangeInterval],
                                values[FieldType.RangeMin],
                                values[FieldType.RangeMax],
                            ].some(isEmpty),
                    },
                    intervalSizeFitsRange: {
                        errorMessage: 'Interval size must be evenly divisible by range (max - min)',
                        test: (value: string, values: FieldsValues<FormProps>) => {
                            const interval = parseNumber(value);
                            const max = parseNumber(values[FieldType.RangeMax]?.toString() ?? '0');
                            const min = parseNumber(values[FieldType.RangeMin]?.toString() ?? '0');

                            return (max - min) % interval === 0;
                        },
                        skipCondition: values =>
                            [
                                values[FieldType.RangeInterval],
                                values[FieldType.RangeMin],
                                values[FieldType.RangeMax],
                            ].some(isEmpty),
                    },
                    valueIsBetweenRangeLimitsValidation,
                },
            },
            [FieldType.RangeMin]: {
                startValue: (state?.values as RangeValuesState).minValue,
                validations: {
                    required: basicRequiredValidation,
                    isPositive: {
                        errorMessage: 'Minimum must be positive',
                        test: (value: string) => {
                            return !value.length || parseNumber(value) >= 0;
                        },
                    },
                    valueIsBetweenRangeLimitsValidation,
                },
            },
            [FieldType.RangeMax]: {
                startValue: (state?.values as RangeValuesState).maxValue,
                validations: {
                    required: basicRequiredValidation,
                    isPositive: {
                        errorMessage: 'Maximum size must be positive',
                        test: (value: string) => {
                            return !value.length || parseNumber(value) > 0;
                        },
                    },
                    range: {
                        errorMessage: 'Maximum should be greater than minimum',
                        skipCondition: values =>
                            isEmpty(values[FieldType.RangeMin]) || isEmpty(values[FieldType.RangeMax]),
                        test: (value: string, values: FieldsValues<FormProps>) => {
                            return parseNumber(value) > parseNumber(values[FieldType.RangeMin]?.toString() ?? '0');
                        },
                    },
                    valueIsBetweenRangeLimitsValidation,
                },
            },
        },
    } satisfies FormProps;

    const { values, setValue, errors, valid } = useForm(form);

    // update context only after form was updated (and not on field change events) to retrieve the full state (values and validity state)
    useEffect(() => {
        const newValues: RangeValuesState = {
            type: ValueType.Range,
            interval: values[FieldType.RangeInterval],
            minValue: values[FieldType.RangeMin],
            maxValue: values[FieldType.RangeMax],
            isValid: valid,
        };

        dispatch({ type: SettingActionType.SetRangeValues, payload: newValues });
    }, [values, valid, dispatch]);

    const onTextChange = useCallback(
        (fieldType: FieldType) => (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(fieldType, sanitizeValue(e.target.value));
            onEvent();
        },
        [onEvent, setValue]
    );

    return (
        <>
            {fields.map(({ label, fieldType }) => (
                <StyledFormInputWrapper
                    errorMessages={Object.values(errors[fieldType] ?? {})}
                    key={fieldType}
                    label={label}
                    showErrors={notDuringTyping && !isEmpty(errors[fieldType])}
                >
                    <Input
                        onChange={onTextChange(fieldType)}
                        type="text"
                        value={formatNumericField(values[fieldType])}
                    />
                </StyledFormInputWrapper>
            ))}
        </>
    );
};
