import { Flex } from '@lemonade-hq/blender-ui';
import { ContentSection, EmptySection, MainButton } from '@lemonade-hq/bluis';
import { Box } from '@lemonade-hq/cdk';
import { useCallback } from 'react';
import type { RuleActionData } from '../dialogs/UnderwritingRulesDialogs';
import { UnderwritingDialogType } from '../UnderwritingFiltersShared';
import { RulesTable } from './RulesTable';
import { useOrderableTable } from 'components/LoCo/common/components/Table/useOrderableTable';
import { getOutcomeText, getRulesTitle } from 'components/LoCo/common/display-texts/underwriting-rules';
import { StyledSectionHeader } from 'components/LoCo/LoCoPagesSharedStyles';
import type {
    UnderwritingDecisionLifecycleContext,
    UnderwritingDeclineRule,
} from 'models/LoCo/Insurance/UnderwritingFiltersEdition';
import { useReorderUnderwritingFiltersEditionRule } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

interface DeclineGroupedRulesProps {
    readonly rules: UnderwritingDeclineRule[];
    readonly lifecycleContext: UnderwritingDecisionLifecycleContext;
    readonly hideActions: boolean;
    readonly onAction: (actionData: RuleActionData) => void;
    readonly editionCode: string;
}

export const DeclineGroupedRules: React.FC<DeclineGroupedRulesProps> = ({
    lifecycleContext,
    rules,
    hideActions,
    onAction,
    editionCode,
}) => {
    const { mutateAsync, isPending } = useReorderUnderwritingFiltersEditionRule(editionCode, 'decline');
    const { getClassNameForItem, handleReorder, isReorderMode, reorderButton } = useOrderableTable(
        rules.map(r => r.publicId),
        async (rulePublicId, order) => await mutateAsync({ rulePublicId, destination: order })
    );
    const onActionRequested = useCallback((): void => {
        onAction({
            type: UnderwritingDialogType.AddDeclineRule,
            lifecycleContext,
        });
    }, [lifecycleContext, onAction]);

    return (
        <ContentSection>
            <StyledSectionHeader>
                {getRulesTitle(lifecycleContext)}
                {!hideActions && (
                    <Box ml="auto">
                        <MainButton
                            onClick={onActionRequested}
                        >{`Add ${getOutcomeText(lifecycleContext)} Rule`}</MainButton>
                    </Box>
                )}
            </StyledSectionHeader>
            <Flex flexDirection="row-reverse" margin=" 0 0 15px 0" width="100%">
                {!hideActions && reorderButton}
            </Flex>
            {rules.length === 0 ? (
                <EmptySection>No rules configured</EmptySection>
            ) : (
                <RulesTable
                    editionCode={editionCode}
                    getReorderedRowClassName={getClassNameForItem}
                    handleReorder={handleReorder}
                    hideActions={hideActions}
                    isLoading={isPending}
                    isReorderMode={isReorderMode}
                    onAction={onAction}
                    rules={rules}
                    type="decline"
                />
            )}
        </ContentSection>
    );
};
