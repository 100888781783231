import type { FormProps } from '@lemonade-hq/blender-ui';
import { Flex, FormLayout, FormProvider, generateTypedFormComponents, spacing, useForm } from '@lemonade-hq/blender-ui';
import type { DialogAction } from '@lemonade-hq/bluis';
import { Dialog } from '@lemonade-hq/bluis';
import { pick } from '@lemonade-hq/maschema-schema';
import { va } from '@lemonade-hq/maschema-validations-ui';
import type { Tool, ToolsRevision } from '@lemonade-hq/persisted-tools';
import { clientToolSchema } from '@lemonade-hq/persisted-tools';
import type { FC, PropsWithChildren } from 'react';
import { useMemo } from 'react';
import { useCreateTool } from '../../persisted_tools.queries';
import { displayError } from '../../shared/tool.helpers';

interface CreateToolDialogProps {
    readonly onClose: () => void;
    readonly onToolCreated: (newToolPublicId: string) => void;
    readonly toolsRevision?: ToolsRevision<Tool>;
}

const createToolSchema = pick(clientToolSchema, 'name');

const formConfig = (existingToolsNames: string[]): FormProps<typeof createToolSchema>['initialConfig'] => ({
    additionalValidations: {
        name: [va.noneOf(existingToolsNames, { error: 'Tool name already exist' })],
    },
});

const { InputGroup } = generateTypedFormComponents<typeof createToolSchema>();

const DialogContent: FC<PropsWithChildren<CreateToolDialogProps>> = ({
    onClose,
    onToolCreated: onSubmit,
    toolsRevision,
}) => {
    const { mutateAsync: createTool, isPending } = useCreateTool({
        toolsRevisionPublicId: toolsRevision?.publicId ?? '',
    });
    const {
        values: { name },
        validationResults: { valid },
    } = useForm<typeof createToolSchema>();

    const handleSubmit = async (): Promise<void> => {
        try {
            const newTool = await createTool({ name });
            onSubmit(newTool.name);
        } catch (e) {
            displayError('Error creating tool')(e);
        }
    };

    const actions: DialogAction[] = [
        {
            type: 'close',
            text: 'cancel',
        },
        {
            type: 'submit',
            disabled: !valid,
            text: 'Create Tool',
            onClick: handleSubmit,
        },
    ];

    const dialogProps = {
        actions,
        closeOnOutsideClick: true,
        loading: isPending,
        onClose,
        title: 'New tool',
    };

    return (
        <Dialog {...dialogProps}>
            <Flex flexDirection="column" gap={spacing.s20} height="7.2rem">
                <FormLayout>
                    <InputGroup
                        inputComponent="Input"
                        label="Give your tool a clear, distinct name"
                        placeholder="Tool name"
                        schemaKey="name"
                    />
                </FormLayout>
            </Flex>
        </Dialog>
    );
};

export const CreateToolDialog: FC<CreateToolDialogProps> = ({ onClose, onToolCreated, toolsRevision }) => {
    const existingToolsNames = useMemo(() => toolsRevision?.tools.map(t => t.name) ?? [], [toolsRevision]);

    return (
        <FormProvider initialConfig={formConfig(existingToolsNames)} schema={createToolSchema}>
            <DialogContent onClose={onClose} onToolCreated={onToolCreated} toolsRevision={toolsRevision} />
        </FormProvider>
    );
};
