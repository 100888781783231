/* eslint-disable @typescript-eslint/naming-convention */
import type { EntityTypes } from '@lemonade-hq/bluiza';
import type { AttachmentDTO, DetectionResult, Indicator, IndicatorType } from './types';

export const MISSING_VALUE = '-';

export const getRaiDetectionResult = (attachment: AttachmentDTO): DetectionResult | undefined =>
    attachment.detections?.find(detection => detection.detectionModel === 'resistant_ai')?.detectionResult;

export const getLmndDetectionResult = (attachment: AttachmentDTO): DetectionResult | undefined =>
    attachment.detections?.find(detection => detection.detectionModel === 'lmnd_ds')?.detectionResult;

export const getIndicators = (attachment: AttachmentDTO): Partial<Record<IndicatorType, Indicator[]>> | undefined =>
    attachment.detections?.find(detection => detection.detectionResult.indicators != null)?.detectionResult
        .indicators ?? undefined;

export const getTextChanges = (attachment: AttachmentDTO): { readonly [index: string]: string[] } | undefined =>
    attachment.detections?.find(detection => detection.detectionResult.textChanges != null)?.detectionResult
        .textChanges ?? undefined;

export const getContextByURL = (): {
    readonly context: string;
    readonly entityId: string;
    readonly product: string;
} => {
    const pathArray = window.location.pathname.split('/');

    return {
        context: pathArray[5],
        entityId: pathArray[4],
        product: pathArray[2],
    };
};

// export const groupIndicatorsByType = (indicators?: Indicator[]): Record<string, Indicator[]> => {
//     if (indicators == null) return {};

//     return indicators.reduce<Record<string, Indicator[]>>((acc, indicator) => {
//         const { type } = indicator;

//         if (!(type in acc)) {
//             acc[type] = [];
//         }

//         acc[type].push(indicator);
//         return acc;
//     }, {});
// };

export function getProductAndEntity(entityType: EntityTypes): {
    readonly product: string;
    readonly entity: string;
} {
    const [product, entity] = entityType.split('_');

    return { product, entity };
}

export function isFraud(attachment: AttachmentDTO): boolean {
    return Boolean(
        attachment.detections?.some(
            detection =>
                detection.detectionResult.score === 'HIGH_RISK' || detection.detectionResult.score === 'WARNING'
        )
    );
}

export const getInlineUrl = (url?: string | null): string => {
    if (
        !url?.endsWith('?inline=true') &&
        (url?.startsWith('https://files-') ?? url?.startsWith('https://icebox.lemonade'))
    ) {
        if (url.endsWith('?inline=false')) {
            return url.replace('inline=false', 'inline=true');
        }

        return `${url}?inline=true`;
    }

    return url ?? '';
};

export const getAttachmentAnalyticsParam = ({
    attachment,
    entityType,
    entityId,
    operatorId,
}: {
    readonly attachment: AttachmentDTO;
    readonly entityType: EntityTypes;
    readonly entityId: string;
    readonly operatorId: string;
}): Record<string, string> => {
    return {
        doc_id: attachment.publicId,
        entity_id: entityId,
        entity_type: entityType,
        modification_score: getLmndDetectionResult(attachment)?.score ?? '',
        content_type: attachment.contentType ?? '',
        doc_name: attachment.fileName ?? '',
        doc_type: attachment.type ?? '',
        doc_description: attachment.description ?? '',
        suggested_archive: attachment.suggestedArchive?.toString() ?? '',
        suggested_archive_reason: attachment.archivingReason ?? '',
        sources: attachment.sources?.map(source => source.description).join(',') ?? '',
        operator_id: operatorId,
        doc_status: attachment.status,
        doc_archiving_reason: attachment.archivingReason ?? '',
    };
};
