import type { RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import type { ReactNode } from 'react';
import { Icon } from '../../../Icon/Icon';
import { Tooltip } from '../../../Tooltip/Tooltip';
import { useForm } from '../../FormContext';
import { isSchemaKeyRequired } from '../common';
import { labelWrapper } from './Label.css';
import { Text } from 'libs/blender-ui/src/base/Text/Text';

export type LabelProps<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>> = {
  readonly schemaKey: TSchemaKey;
  readonly children: string;
  readonly tooltip?: ReactNode;
};

export const Label = <TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>(props): ReactNode => {
  const { schemaKey, children, tooltip } = props as LabelProps<TSchema, TSchemaKey>;
  const { schema, values } = useForm();
  const isRequired = isSchemaKeyRequired(schemaKey, schema, values);

  return (
    <Text className={labelWrapper} htmlFor={schemaKey} type="label-md">
      <Text>{children} </Text>
      {isRequired ? <Text color="error">*</Text> : <Text color="tertiary">(optional)</Text>}
      {tooltip != null ? (
        <Tooltip content={tooltip}>
          <Icon name="alert-circle-solid" />
        </Tooltip>
      ) : null}
    </Text>
  );
};
