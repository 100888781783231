import { FormInputWrapper, Select, SelectProps } from '@lemonade-hq/bluis';
import type { FormProps } from '@lemonade-hq/cdk';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import React, { useContext, useEffect } from 'react';
import { SettingActionType, SettingContext } from '../../SettingContext';
import { ListValues } from './ListValues';
import { RangeValues } from './RangeValues';
import { SettingUnit, ValueType } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

interface ValuesContainerProps {
    readonly valueType: ValueType | null;
    readonly minLimit?: number;
    readonly maxLimit?: number;
}

export const ValuesContainer: React.FC<ValuesContainerProps> = ({ valueType, minLimit, maxLimit }) => {
    const form = {
        fields: {
            valueType: {
                startValue: valueType ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
        },
    } satisfies FormProps;

    const { values, setValue } = useForm(form);
    const { state, dispatch } = useContext(SettingContext);

    // reset when the user changes the setting type
    useEffect(() => {
        if (!state?.values && values.valueType !== '') {
            setValue('valueType', '');
        }
    }, [setValue, state?.values, values.valueType]);

    const onValueTypeChange = ({ value }: { value: ValueType }) => {
        setValue('valueType', value);
        dispatch({ type: SettingActionType.SetValueType, payload: { valueType: value } });
        switch (value) {
            case ValueType.Range: {
                dispatch({
                    type: SettingActionType.SetRangeValues,
                    payload: {
                        interval: '',
                        isValid: false,
                        maxValue: '',
                        minValue: '',
                        type: ValueType.Range,
                    },
                });
                break;
            }

            case ValueType.List: {
                dispatch({ type: SettingActionType.SetListValues, payload: { newValues: { values: [] } } });
                break;
            }

            default:
                break;
        }
    };

    const renderValues = () => {
        if (!state?.values) return <></>;
        if (values.valueType === ValueType.Range) {
            return <RangeValues maxLimit={maxLimit} minLimit={minLimit} />;
        } else if (values.valueType === ValueType.List) {
            return <ListValues maxLimit={maxLimit} minLimit={minLimit} />;
        }

        return <></>;
    };

    const getValueTypes = () => {
        const options = [{ id: ValueType.List, value: ValueType.List, label: 'Fixed Options' }];
        const isClaimLossPercentage =
            state?.attributes?.type === SettingType.Limit && state.attributes.unit === SettingUnit.ClaimLossPercentage;
        if (!isClaimLossPercentage) {
            options.push({ id: ValueType.Range, value: ValueType.Range, label: 'Intervals in Range' });
        }

        return options;
    };

    return (
        <>
            <FormInputWrapper label="Value Types">
                <Select
                    onOptionSelected={onValueTypeChange}
                    options={getValueTypes()}
                    placeholder="Select"
                    value={values.valueType}
                />
            </FormInputWrapper>
            {renderValues()}
        </>
    );
};
