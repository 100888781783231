import type { UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import type { MentionUser } from '@lemonade-hq/cdk';
import type {
    UsersSearchParamsResponse,
    UsersSearchParams,
    UsersSearchResponse} from 'apis/UsersAPITyped';
import {
    getUserTimeline,
    updateUserData,
    blockClaim,
    unBlockClaim,
    simulatePhoneVerificationEmail,
    simulateSecondPhoneVerificationEmail,
    blockUser,
    unblockUser,
    getUserAlerts,
    fetchUserData,
    getUserOpportunityEligibility,
    fetchUserHomeData,
    getLegacyUserData,
    fetchUserCarData,
    fetchUserPetData,
    fetchUserLifeData,
    fetchSearchParams,
    searchUsers,
    unRevokeAccess,
    getLegacyNotifications,
} from 'apis/UsersAPITyped';
import {
    AgencyPolicies,
    AgencyPolicy,
    LegacyAndUserActionsPermissionsData,
    LegacyUserData,
    ReferralProgramInterface,
    UserActionsPermissions,
    UserData,
    UserNotification,
} from 'models/User';
import { TimelineEvent } from 'models/UserTimeline';
import { updateCause } from 'apis/CauseAPI';
import { blenderGeneral } from '../apiClients';
import { usePessimisticMutationLegacy } from './MutationHooks';

export enum UserQueryKeys {
    GetUserDetails = 'GET_USER_DETAILS',
    GetUserPaymentDetails = 'GET_USER_PAYMENT_DETAILS',
    GetUserReferrals = 'GET_USER_REFERRALS',
    GetUserReferralProgram = 'GET_USER_REFERRAL_PROGRAM',
    GetUserTimeline = 'GET_USER_TIMELINE',
    GetUserCarData = 'GET_USER_CAR_DATA',
    GetUserHomeData = 'GET_USER_HOME_DATA',
    GetUserHomeClaimsHistory = 'GET_USER_HOME_DATA',
    GetUserPetData = 'GET_USER_PET_DATA',
    GetUserLifeData = 'GET_USER_LIFE_DATA',
    GetUsersListSearchFilters = 'GET_USER_LIST_SEARCH_FILTERES',
    GetUserList = 'GET_USER_LIST',
    GetMentionedUsers = 'GET_MENTIONED_USERS',
    GetCauses = 'GET_CAUSES',
    GetNotifications = 'GET_NOTIFICATIONS',
    GetUserActionsPermissions = 'GET_USER_ACTIONS_PERMISSIONS',
    GetUserDetailsAndActionsPermissions = 'GET_USER_DETAILS_AND_ACTIONS_PERMISSIONS',
    GetUserConsents = 'GET_USER_CONSENTS',
    GetUserOpportunityEligibility = 'GET_USER_OPPORTUNITY_ELIGIBILITY',
    GetUserAgencyPolicies = 'GET_AGENCY_POLICIES',
}

export async function getUserPaymentDetails(userId: string) {
    return await fetchUserData(userId, ['paymentDetails']);
}

export async function getUserCauses(userId: string) {
    return await fetchUserData(userId, ['userCausesOptions']);
}

export async function getUserReferrals(userId: string) {
    return await fetchUserData(userId, ['referrals']);
}

export async function getUserReferralProgram(userId: string) {
    return await fetchUserData(userId, ['referralProgram']);
}

export async function getUserAgencyPolicies(userId: string) {
    return await fetchUserData(userId, ['agencyPolicies']);
}

export async function getUserConsents(userId: string) {
    return await fetchUserData(userId, ['userConsents']);
}

export function useUserHomeData(
    userId: string
): UseQueryResult<Pick<UserData, 'userHomeClaims' | 'userHomePolicies' | 'userHomeQuotes'>> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserHomeData, userId],
        queryFn: async () => await getUserHomeData(userId),
    });
}

export function useUserHomePolicies(
    userId: string,
    queryOptions: { readonly enabled?: boolean; readonly onSuccess?: (data: Pick<UserData, 'userHomePolicies'>) => void }
): UseQueryResult<Pick<UserData, 'userHomePolicies'>> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserHomeData, userId],
        queryFn: async () => await fetchUserHomeData(userId, ['userHomePolicies']),
        ...queryOptions,
    });
}

export async function getUserHomeData(userId: string) {
    return await fetchUserHomeData(userId, ['userHomePolicies', 'userHomeQuotes', 'userHomeClaims']);
}

export function useUserHomeClaimsHistory(userId: string): UseQueryResult<Pick<UserData, 'userHomeClaims'>> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserHomeClaimsHistory, userId],
        queryFn: async () => await getUserHomeClaimsHistory(userId),
    });
}

export async function getUserHomeClaimsHistory(userId: string) {
    return await fetchUserHomeData(userId, ['userHomeClaims']);
}

export function useUserCarData(
    userId?: string
): UseQueryResult<Pick<UserData, 'userCarClaims' | 'userCarPolicies' | 'userCarQuotes'>> {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return useQuery({
        queryKey: [UserQueryKeys.GetUserCarData, userId],
        queryFn: async () => await getUserCarData(userId!),
        enabled: userId != null,
    });
}

export async function getUserCarData(userId: string) {
    return await fetchUserCarData(userId, ['userCarQuotes', 'userCarPolicies', 'userCarClaims']);
}

export function useUserPetData(
    userId: string
): UseQueryResult<
    Pick<UserData, 'user_pet_claims' | 'user_pet_policies' | 'user_pet_preapprovals' | 'user_pet_quotes'>
> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserPetData, userId],
        queryFn: async () => await getUserPetData(userId),
    });
}

export async function getUserPetData(userId: string) {
    return await fetchUserPetData(userId, [
        'user_pet_claims',
        'user_pet_policies',
        'user_pet_quotes',
        'user_pet_preapprovals',
    ]);
}

export function useUserLifeData(
    userId: string
): UseQueryResult<Pick<UserData, 'user_life_policies' | 'user_life_quotes'>> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserLifeData, userId],
        queryFn: async () => await getUserLifeData(userId),
    });
}

export async function getUserLifeData(userId: string) {
    return await fetchUserLifeData(userId, ['user_life_quotes', 'user_life_policies']);
}

export function useUserLegacyData(userId: string): UseQueryResult<LegacyUserData> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserDetails, userId],
        queryFn: async () => await getLegacyUserData(userId),
    });
}

export function useUserNotifications(userPublicId: string): UseQueryResult<UserNotification> {
    return useQuery({
        queryKey: [UserQueryKeys.GetNotifications, userPublicId],
        queryFn: async () => await getAggregatedAlerts(userPublicId),
    });
}

export async function getLegacyDataAndActionsPermissions(userId: string) {
    const legacyUserData = await getLegacyUserData(userId);
    const userActionsPermissionsData = await getUserActionsPermissions(
        legacyUserData.user.external_id,
        legacyUserData.user.email
    );

    return {
        legacyUserData,
        userActionsPermissionsData,
    };
}

export function useLegacyDataAndActionsPermissions(
    userId: string
): UseQueryResult<LegacyAndUserActionsPermissionsData> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserDetailsAndActionsPermissions, userId],

        queryFn: async () => await getLegacyDataAndActionsPermissions(userId),
    });
}

export async function getUserActionsPermissions(userId: string, userEmail?: string) {
    const [homePermissions, generalPermissions] = await Promise.all([
        fetchUserHomeData(userId, ['userHomeActionsPermissions']),
        fetchUserData(userId, ['userGeneralActionsPermissions'], userEmail),
    ]);

    return {
        userActionsPermissions: {
            ...homePermissions.userHomeActionsPermissions,
            ...generalPermissions.userGeneralActionsPermissions,
        },
    };
}

async function getAggregatedAlerts(userPublicId: string) {
    const [notifications, alerts] = await Promise.all([
        getLegacyNotifications(userPublicId),
        getUserAlerts(userPublicId),
    ]);

    alerts.forEach(alert => {
        notifications[alert.type] = alert;
    });

    return Object.values(notifications);
}

export function useUserActionsPermissions(userId: string, userEmail?: string): UseQueryResult<UserActionsPermissions> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserActionsPermissions, userId],
        queryFn: async () => (await getUserActionsPermissions(userId, userEmail)).userActionsPermissions,
    });
}

export function useUserPaymentDetails(userId: string): UseQueryResult<UserData> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserPaymentDetails, userId],
        queryFn: async () => await getUserPaymentDetails(userId),
    });
}

export function useUserCauses(userId: string): UseQueryResult<UserData> {
    return useQuery({
        queryKey: [UserQueryKeys.GetCauses, userId],
        queryFn: async () => await getUserCauses(userId),
        enabled: Boolean(userId),
    });
}

export function useUserReferrals(userId: string): UseQueryResult<UserData> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserReferrals, userId],
        queryFn: async () => await getUserReferrals(userId),
    });
}

export function useUserConsents(userId: string): UseQueryResult<UserData> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserConsents, userId],
        queryFn: async () => await getUserConsents(userId),
    });
}

export function useUserReferralProgram(userId: string): UseQueryResult<ReferralProgramInterface> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserReferralProgram, userId],
        queryFn: async () => await getUserReferralProgram(userId),
    });
}

export function useUserAgencyPolicies(userId: string): UseQueryResult<AgencyPolicies> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserAgencyPolicies, userId],
        queryFn: async () => getUserAgencyPolicies(userId),
    });
}

export function useUserTimeline(user?: { readonly id: number | string }): UseQueryResult<TimelineEvent[]> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserTimeline, user?.id],
        queryFn: async () => await getUserTimeline(user?.id ?? ''),
        enabled: Boolean(user),
    });
}

export function useCauseUpdateMutation(userId: string, key: string) {
    return usePessimisticMutationLegacy(updateCause, [[key, userId]]);
}

export function useUpdateUserData(userId: number) {
    return usePessimisticMutationLegacy(updateUserData, [[UserQueryKeys.GetUserDetails, String(userId)]]);
}

export function useBlockClaim(userId: number) {
    return usePessimisticMutationLegacy(blockClaim, [[UserQueryKeys.GetUserDetails, String(userId)]]);
}

export function useUnBlockClaim(userId: number) {
    return usePessimisticMutationLegacy(unBlockClaim, [[UserQueryKeys.GetUserDetails, String(userId)]]);
}

export function useSimulatePhoneVerificationEmail(userId: number) {
    return usePessimisticMutationLegacy(simulatePhoneVerificationEmail, [
        [UserQueryKeys.GetUserDetails, String(userId)],
    ]);
}

export function useSimulateSecondPhoneVerificationEmail(userId: number) {
    return usePessimisticMutationLegacy(simulateSecondPhoneVerificationEmail, [
        [UserQueryKeys.GetUserDetails, String(userId)],
    ]);
}

export function useBlockUser(userId: number) {
    return usePessimisticMutationLegacy(blockUser, [[UserQueryKeys.GetUserDetails, String(userId)]]);
}

export function useUnBlockUser(userId: number) {
    return usePessimisticMutationLegacy(unblockUser, [[UserQueryKeys.GetUserDetails, String(userId)]]);
}

export function useUnRevokeAccess(userId: number) {
    return usePessimisticMutationLegacy(unRevokeAccess, [[UserQueryKeys.GetUserDetails, String(userId)]]);
}

export function useUsersSearchFilters(): UseQueryResult<UsersSearchParamsResponse> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUsersListSearchFilters],
        queryFn: async () => await fetchSearchParams(),
    });
}

export function useUsersSearch(searchParams: UsersSearchParams): UseQueryResult<UsersSearchResponse> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserList, searchParams],
        queryFn: async () => await searchUsers(searchParams),
    });
}

export function useGetMentionedUsers(mentions: string[]): UseQueryResult<MentionUser[]> {
    return useQuery({
        queryKey: [UserQueryKeys.GetMentionedUsers, mentions],

        queryFn: async () =>
            await blenderGeneral.get(`/api/v1/employees?publicId=${mentions.join(',')}`).then(res => res.data.data),
    });
}

export function useUserOpportunityEligibility(
    userId: number | string,
    { enabled = true } = {}
): UseQueryResult<boolean> {
    return useQuery({
        queryKey: [UserQueryKeys.GetUserOpportunityEligibility, userId],
        queryFn: async () => await getUserOpportunityEligibility(userId),
        enabled,
    });
}
