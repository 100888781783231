import { Card, Flex, Input, pageWrapper, Select, spacing, Text } from '@lemonade-hq/blender-ui';
import { Attachment } from '@lemonade-hq/bluis';
import { EntityTypes } from '@lemonade-hq/bluiza';
import { EntityDataProvider } from '@lemonade-hq/boutique';
import { useState } from 'react';
import type { FC } from 'react';
import { useGallery } from './AttachmentGallery/GalleryProvider';
import { isFraud } from './utils';
import { AttachmentHub } from './AttachmentHub';
import { useGetAttachments } from './AttachmentsQueries';
import { mockAttachments } from './mock';

const getProductByEntity = (entityType: EntityTypes): 'car' | 'home' | 'pet' => {
    if (entityType.includes('pet')) {
        return 'pet';
    } else if (entityType.includes('car')) {
        return 'car';
    } else {
        return 'home';
    }
};

const ExampleContent: FC<{
    readonly entityType: EntityTypes;
    readonly entityId: string;
}> = ({ entityType, entityId }) => {
    const { openGallery } = useGallery();
    const { data } = useGetAttachments({ entityType: entityType, entityPublicId: entityId });

    const handleClick = (index: number): void => {
        openGallery({ index, entityType, entityPublicId: entityId });
    };

    const handleMockClick = (index: number): void => {
        openGallery({ attachments: mockAttachments, index, entityType, entityPublicId: entityId });
    };

    return (
        <Flex className={pageWrapper} flexDirection="column" gap={spacing.s48}>
            <Text textAlign="center" type="h3">
                Attachments
            </Text>
            <Flex
                alignItems="center"
                flexWrap="wrap"
                gap={spacing.s08}
                justifyContent="center"
                mb={spacing.s120}
                width="100%"
            >
                {data?.map((attachment, index) => (
                    <Attachment
                        attachment={attachment}
                        isFraud={isFraud(attachment)}
                        key={attachment.publicId}
                        onClick={() => handleClick(index)}
                        type={attachment.contentType ?? ''}
                        url={attachment.url}
                    />
                ))}
            </Flex>
            <Text textAlign="center" type="h3">
                Attachments Mock (Design review)
            </Text>
            <Flex
                alignItems="center"
                flexWrap="wrap"
                gap={spacing.s08}
                justifyContent="center"
                mb={spacing.s120}
                width="100%"
            >
                {mockAttachments.map((attachment, index) => (
                    <Attachment
                        attachment={attachment}
                        isFraud={isFraud(attachment)}
                        key={attachment.publicId}
                        onClick={() => handleMockClick(index)}
                        type={attachment.contentType ?? ''}
                        url={attachment.url}
                    />
                ))}
            </Flex>
            <Text textAlign="center" type="h3">
                Attachments Hub
            </Text>
            <AttachmentHub entityId={entityId} entityType={entityType} />
        </Flex>
    );
};

export const ExamplePage: FC = () => {
    const [entityId, setEntityId] = useState('LC74DF0A4');
    const [entityType, setEntityType] = useState(EntityTypes.HomeClaim);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setEntityId(e.target.value);
    };

    const handleEntityTypeChange = (value: string): void => setEntityType(value as EntityTypes);

    return (
        <Flex flexDirection="column" gap={spacing.s48} height="85vh">
            <Card margin="auto" p={spacing.s16} width="320px">
                <Flex flexDirection="column" gap={spacing.s24}>
                    <Flex alignItems="center" justifyContent="space-between">
                        <Text fontWeight="semi-bold" type="text-lg">
                            Attachment Data
                        </Text>
                    </Flex>
                    <Flex flexDirection="column" gap={spacing.s12}>
                        <Flex flexDirection="column" gap={spacing.s06}>
                            <Text color="primary" type="label-sm">
                                Entity id
                                <Text as="span" color="error">
                                    &nbsp;*
                                </Text>
                            </Text>
                            <Input onChange={handleInputChange} value={entityId} />
                        </Flex>
                        <Flex flexDirection="column" gap={spacing.s06}>
                            <Text color="primary" type="label-sm">
                                Type
                                <Text as="span" color="error">
                                    &nbsp;*
                                </Text>
                            </Text>
                            <Flex flexDirection="column" gap={spacing.s06}>
                                <Select<never, { value: string; label: EntityTypes }>
                                    onChange={value => handleEntityTypeChange(value ?? '')}
                                    options={Object.values(EntityTypes).map(type => ({
                                        value: type,
                                        label: type,
                                    }))}
                                    placeholder="Select entity type"
                                    selectedKey={entityType}
                                />
                            </Flex>
                        </Flex>
                    </Flex>
                </Flex>
            </Card>

            <EntityDataProvider
                entityId={entityId}
                entityType={entityType}
                productType={getProductByEntity(entityType)}
            >
                <ExampleContent entityId={entityId} entityType={entityType} />
            </EntityDataProvider>
        </Flex>
    );
};
