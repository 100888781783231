import { ContentSection, EmptySection, MainButton } from '@lemonade-hq/bluis';
import { Box, Flex } from '@lemonade-hq/cdk';
import { useState } from 'react';
import styled from 'styled-components';
import type { RuleActionData } from './dialogs/UnderwritingRulesDialogs';
import { UnderwritingRulesDialogs } from './dialogs/UnderwritingRulesDialogs';
import { DeclineGroupedRules } from './rules-group/DeclineGroupedRules';
import { FlagGroupedRules } from './rules-group/FlagGroupedRules';
import { UnderwritingDialogType } from './UnderwritingFiltersShared';
import { isEditionApproved } from 'components/LoCo/common/editions/editionHelpers';
import { StyledSectionHeader } from 'components/LoCo/LoCoPagesSharedStyles';
import type {
    UnderwritingDecisionLifecycleContext,
    UnderwritingFiltersEdition,
} from 'models/LoCo/Insurance/UnderwritingFiltersEdition';

export interface UnderwritingFiltersRulesSectionProps {
    readonly underwritingFiltersEdition: UnderwritingFiltersEdition;
}

const StyledFlagRulesContainer = styled(Flex)`
    flex-direction: column;
    gap: 50px;
`;

export type DialogData = RuleActionData & { readonly editionCode: string };

export const UnderwritingFiltersRulesSection: React.FC<UnderwritingFiltersRulesSectionProps> = ({
    underwritingFiltersEdition,
}) => {
    const hideActions = isEditionApproved(underwritingFiltersEdition);
    const [dialog, setDialog] = useState<DialogData | null>(null);
    const { flagRulesGroup, declineRules } = underwritingFiltersEdition;
    return (
        <>
            {Object.entries(declineRules).map(([lifecycleContext, rules]) => (
                <DeclineGroupedRules
                    editionCode={underwritingFiltersEdition.code}
                    hideActions={hideActions}
                    key={lifecycleContext}
                    lifecycleContext={lifecycleContext as UnderwritingDecisionLifecycleContext}
                    onAction={actionData => {
                        setDialog({ ...actionData, editionCode: underwritingFiltersEdition.code });
                    }}
                    rules={rules}
                />
            ))}
            <ContentSection>
                <StyledSectionHeader>
                    {'Outcome Flags'}
                    {!hideActions && (
                        <Box ml="auto">
                            <MainButton
                                onClick={() =>
                                    setDialog({
                                        type: UnderwritingDialogType.AddFlagRule,
                                        editionCode: underwritingFiltersEdition.code,
                                    })
                                }
                            >
                                Add Flag Rule
                            </MainButton>
                        </Box>
                    )}
                </StyledSectionHeader>
                {flagRulesGroup.length === 0 ? (
                    <EmptySection>No rules configured</EmptySection>
                ) : (
                    <StyledFlagRulesContainer>
                        {flagRulesGroup.map(rulesGroup => (
                            <FlagGroupedRules
                                editionCode={underwritingFiltersEdition.code}
                                hideActions={hideActions}
                                key={rulesGroup.flagCode}
                                onAction={actionData => {
                                    setDialog({ ...actionData, editionCode: underwritingFiltersEdition.code });
                                }}
                                rulesGroup={rulesGroup}
                            />
                        ))}
                    </StyledFlagRulesContainer>
                )}
            </ContentSection>
            {dialog && <UnderwritingRulesDialogs dialogData={dialog} onClose={() => setDialog(null)} />}
        </>
    );
};
