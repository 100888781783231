import { ErrorSection, LoadingSection } from '@lemonade-hq/bluis';
import { ErrorBoundary } from '@sentry/react';
import { Suspense, useMemo, useRef, useState } from 'react';
import type { DialogData } from '../UnderwritingFiltersRulesSection';
import { ManageRuleStep, UnderwritingDialogType } from '../UnderwritingFiltersShared';
import { FlagSelection } from './FlagSelection';
import type { DeepNullable } from 'apps/blender/src/shared/utils/types';
import type { RuleBuilderRefMethods } from 'components/LoCo/common/components/CoverageRules/ManageRuleDialogContext';
import { RegistryProvider } from 'components/LoCo/common/components/CoverageRules/ManageRuleDialogContext';
import { SegmentFilterSelection } from 'components/LoCo/common/components/CoverageRules/Steps/SegmentSelection';
import { StyledDialog } from 'components/LoCo/common/components/Dialog/Dialog';
import { useMiniFlowFormDialog } from 'components/LoCo/common/components/Dialog/useMiniFlowFormDialog';
import { StyledMiniFlow } from 'components/LoCo/common/components/MiniFlow';
import { RuleDialogDimensions } from 'components/LoCo/LoCoPagesSharedStyles';
import type { CreateFlagRuleParams } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';
import { useCreateUnderwritingFlagsFiltersRules } from 'queries/LoCo/Insurance/UnderwritingFiltersEditionQueries';

const stepIds = [ManageRuleStep.Segment, ManageRuleStep.Outcome];

type FlagRuleDialogData = Extract<
    DialogData,
    { readonly type: UnderwritingDialogType.AddFlagRule | UnderwritingDialogType.EditFlagRule }
>;

export const ManageFlagRuleDialog: React.FC<{
    readonly dialogData: FlagRuleDialogData;
    readonly onClose: () => void;
}> = ({ dialogData, onClose }) => {
    const [isExpressionInvalid, setIsExpressionInvalid] = useState(
        UnderwritingDialogType.AddFlagRule === dialogData.type
    );

    const renderExpressionRef = useRef<RuleBuilderRefMethods | null>(null);
    const { mutateAsync, isPending: isLoading } = useCreateUnderwritingFlagsFiltersRules(dialogData.editionCode);
    const isEditMode = dialogData.type === UnderwritingDialogType.EditFlagRule;

    const { actions, currentStep, state, dispatch } = useMiniFlowFormDialog<
        ManageRuleStep,
        DeepNullable<CreateFlagRuleParams>
    >({
        steps: stepIds,
        onClose,
        onSubmit: async data => {
            if (dialogData.type === UnderwritingDialogType.EditFlagRule) return;
            if (data.expression === null || data.flagCode === null || data.editionCode === null)
                throw new Error('Expression is not set');

            await mutateAsync({
                expression: data.expression,
                flagCode: data.flagCode,
                editionCode: data.editionCode,
            });

            onClose();
        },
        isNextDisabled: (_, step) => {
            if (step === ManageRuleStep.Segment) {
                return isExpressionInvalid;
            }

            return false;
        },
        clear: () => undefined,
        onNext: (step, injectedDispatch) => {
            if (step === ManageRuleStep.Segment) {
                if (renderExpressionRef.current == null) {
                    throw new Error('Expression renderer is not set');
                }

                injectedDispatch({ type: 'expression', value: renderExpressionRef.current.get() });
            }
        },
        initialData: isEditMode
            ? { ...dialogData.data, editionCode: dialogData.editionCode }
            : {
                  expression: null,
                  editionCode: dialogData.editionCode,
                  flagCode: null,
              },
    });

    const mode = isEditMode ? 'Edit' : 'Add';
    const title = `${mode}  Flag Rule`;

    const steps = useMemo(
        () => [
            {
                title: 'Segment',
                body: (
                    <SegmentFilterSelection
                        allowEmpty={false}
                        expression={state.expression}
                        renderExpressionRef={renderExpressionRef}
                        setExpression={(expression: string) => dispatch({ type: 'expression', value: expression })}
                        setSegmentFilterIsInvalid={setIsExpressionInvalid}
                    />
                ),
                id: ManageRuleStep.Segment,
            },
            {
                title: 'Outcome',
                body: (
                    <FlagSelection
                        flagCode={state.flagCode}
                        onChange={flag => {
                            dispatch({ type: 'flagCode', value: flag as string });
                        }}
                    />
                ),
                id: ManageRuleStep.Outcome,
            },
        ],
        [dispatch, state.expression, state.flagCode]
    );

    return (
        <StyledDialog
            actions={actions}
            closeOnOutsideClick={false}
            loading={isLoading}
            minHeight={RuleDialogDimensions.minHeight}
            minWidth={RuleDialogDimensions.minWidth}
            onClose={onClose}
            size="x-large"
            title={title}
        >
            <ErrorBoundary fallback={<ErrorSection noBorders />}>
                <Suspense fallback={<LoadingSection noBorders />}>
                    <RegistryProvider>
                        <StyledMiniFlow
                            activeStepIndex={steps.findIndex(step => step.id === currentStep)}
                            steps={steps}
                        />
                    </RegistryProvider>
                </Suspense>
            </ErrorBoundary>
        </StyledDialog>
    );
};
