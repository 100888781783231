import { Button, Card, Flex, pageWrapper, spacing, Table, Text } from '@lemonade-hq/blender-ui';
import { formatOccurredAt } from '@lemonade-hq/bluiza';
import type { ToolsRevision } from '@lemonade-hq/persisted-tools';
import { ActionType } from '@lemonade-hq/typo';
import { clsx } from 'clsx';
import orderBy from 'lodash/orderBy';
import { useState } from 'react';
import type { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    useCreateToolsRevision,
    useGetPublishedToolsRevision,
    useListPendingToolsRevisions,
} from './persisted_tools.queries';
import * as styles from './PlaygroundIndexPage.css';
import { PLAYGROUND_COMPARE_BASE_PATH } from './shared/routing.consts';
import * as shimmeringStyles from './shared/shimmering.css';
import { displayError, formatChangedTools, sortToolsBySections } from './shared/tool.helpers';
import { ArchiveToolsRevisionDialog } from './toolEditor/dialogs/ArchiveToolsRevisionDialog';
import { useForceBlenderUI } from 'hooks/useForceBlenderUI';

const FailingIndexPage: FC = () => (
    <Flex alignItems="center" flexDirection="row" height="100%" justifyContent="center">
        <Text color="error" type="h4">
            Failed loading tools revisions
        </Text>
    </Flex>
);

const PlaceholderData: FC = () => <div className={shimmeringStyles.shimmeringMockBlock} />;

function getToolsRevisionUrl(toolsRevision: ToolsRevision): string {
    const sortedTools = sortToolsBySections(toolsRevision);
    return `./${toolsRevision.publicId}/${sortedTools[0].options[0].id}`;
}

export const PlaygroundIndexPage: FC = () => {
    const [archiveDialogOpenedForToolsRevision, setArchiveDialogOpenedForToolsRevision] = useState<string | null>(null);
    useForceBlenderUI();

    const navigate = useNavigate();
    const {
        data: toolsRevisions,
        error: isListError,
        isPlaceholderData: isPlaceholderToolsRevisionList,
    } = useListPendingToolsRevisions();
    const {
        data: publishedToolsRevision,
        error: isPublishedError,
        isPlaceholderData: isPlaceholderPublishedToolsRevision,
    } = useGetPublishedToolsRevision();
    const { mutateAsync: createToolsRevision, isPending: isCreatingToolsRevision } = useCreateToolsRevision();

    const handleCreateToolsRevision = async (): Promise<void> => {
        try {
            const createdToolsRevision = await createToolsRevision();
            navigate(getToolsRevisionUrl(createdToolsRevision));
        } catch (e: unknown) {
            displayError('Error creating tools revision')(e);
        }
    };

    // for type-safety reason, won't happen due to placeholder data
    if (publishedToolsRevision == null || toolsRevisions == null) return null;
    if (isListError != null || isPublishedError != null) return <FailingIndexPage />;

    return (
        <>
            <Flex className={pageWrapper} gap={spacing.s24} height="100%" padding={spacing.s24}>
                <Card display="flex" flexDirection="column" gap={spacing.s32}>
                    <Flex justifyContent="space-between">
                        <Text type="h3">CXLLM Playground</Text>
                        <Flex gap={spacing.s08} justifyContent="space-between">
                            <Button
                                label="New Tools Revision"
                                loading={isCreatingToolsRevision}
                                onClick={handleCreateToolsRevision}
                                startIcon="plus-solid"
                                variant="primary"
                            />
                            <Link to={`.${PLAYGROUND_COMPARE_BASE_PATH}`}>
                                <Button label="History" startIcon="calendar" variant="secondary" />
                            </Link>
                        </Flex>
                    </Flex>
                    <Flex
                        className={clsx({ [shimmeringStyles.shimmering]: isPlaceholderPublishedToolsRevision })}
                        flexDirection="column"
                        gap={spacing.s04}
                    >
                        <Text type="h4">Latest revision in production</Text>
                        {isPlaceholderPublishedToolsRevision ? (
                            <PlaceholderData />
                        ) : (
                            <Table
                                columns={[
                                    { key: 'id', name: 'ID' },
                                    { key: 'createdAt', name: 'Created At' },
                                    { key: 'publishedAt', name: 'Published At' },
                                    { key: 'publishedBy', name: 'Published By' },
                                    { key: 'changedTools', name: 'Changed Tools' },
                                ]}
                                data={[
                                    {
                                        id: {
                                            value: publishedToolsRevision.publicId,
                                            action: {
                                                type: ActionType.ButtonLink,
                                                url: getToolsRevisionUrl(publishedToolsRevision),
                                                onClick: () => navigate(getToolsRevisionUrl(publishedToolsRevision)),
                                            },
                                        },
                                        createdAt: { value: formatOccurredAt(publishedToolsRevision.addedAt) },
                                        publishedAt: { value: formatOccurredAt(publishedToolsRevision.publishedAt) },
                                        publishedBy: {
                                            value: publishedToolsRevision.publishedBy ?? 'N/A',
                                        },
                                        changedTools: {
                                            value: formatChangedTools(publishedToolsRevision),
                                        },
                                    },
                                ]}
                                // HACK: silly hack otherwise changing the data in the table makes react-aria-table crash
                                key={publishedToolsRevision.publicId}
                            />
                        )}
                    </Flex>
                    {toolsRevisions.length > 0 && (
                        <Flex
                            className={clsx({ [shimmeringStyles.shimmering]: isPlaceholderToolsRevisionList })}
                            flexDirection="column"
                            gap={spacing.s04}
                        >
                            <Text type="h4">Your WIP revisions</Text>
                            {isPlaceholderToolsRevisionList ? (
                                <PlaceholderData />
                            ) : (
                                <Table
                                    className={styles.pendingToolsRevisionsTable}
                                    columns={[
                                        { key: 'id', name: 'ID' },
                                        { key: 'createdAt', name: 'Created At' },
                                        { key: 'lastEditedAt', name: 'Last Edited At' },
                                        { key: 'changedTools', name: 'Changed Tools' },
                                        { key: 'actions', name: 'Actions' },
                                    ]}
                                    data={orderBy(toolsRevisions, 'updatedAt', 'desc').map(toolsRevision => ({
                                        id: {
                                            value: toolsRevision.publicId,
                                            action: {
                                                type: ActionType.ButtonLink,
                                                url: getToolsRevisionUrl(toolsRevision),
                                                onClick: () => navigate(getToolsRevisionUrl(toolsRevision)),
                                            },
                                        },
                                        createdAt: { value: formatOccurredAt(toolsRevision.addedAt) },
                                        lastEditedAt: { value: formatOccurredAt(toolsRevision.updatedAt) },
                                        changedTools: { value: formatChangedTools(toolsRevision) },
                                        actions: {
                                            value: 'Archive',
                                            action: {
                                                type: ActionType.Button,
                                                onClick: () => {
                                                    setArchiveDialogOpenedForToolsRevision(toolsRevision.publicId);
                                                },
                                            },
                                        },
                                    }))}
                                    // HACK: silly hack otherwise changing the data in the table makes react-aria-table crash
                                    key={toolsRevisions.length}
                                />
                            )}
                        </Flex>
                    )}
                </Card>
            </Flex>
            {archiveDialogOpenedForToolsRevision != null && (
                <ArchiveToolsRevisionDialog
                    onClose={() => setArchiveDialogOpenedForToolsRevision(null)}
                    toolsRevisionPublicId={archiveDialogOpenedForToolsRevision}
                />
            )}
        </>
    );
};
