import { Flex, Grid, spacing } from '@lemonade-hq/blender-ui';
import {
    Alert,
    AlertMode,
    ContentSection,
    EmptySection,
    LoadingSection,
    NoticeFilledIcon,
    TableTitle,
} from '@lemonade-hq/bluis';
import { Tooltip } from '@lemonade-hq/cdk';
import { Suspense, useState } from 'react';
import { StyledClickable, StyledSectionHeader } from '../../LoCoPagesSharedStyles';
import { ReleaseDialogType, useReleaseContext } from '../ReleasesShared';
import { editionSetExplanation } from './dialogs/ReleaseDetails.css';
import { RemoveEnvDialog } from './dialogs/RemoveEnvDialog';
import { compatibilityErrorWrapper, compatibilityWrapper, effectiveDateWithTooltip } from './ReleaseDetails.css';
import { ExpendButton, ExpendedDetailsTable } from 'components/LoCo/common/components/GridTable/ExpandedDetailsTable';
import type { Columns, ColumnsToRow } from 'components/LoCo/common/components/GridTable/GridTable';
import { GridTable } from 'components/LoCo/common/components/GridTable/GridTable';
import { IconWithLabel } from 'components/LoCo/common/components/IconWithLabel';
import { linksWrapper } from 'components/LoCo/common/components/LinksWrapper.css';
import { getFormattedDate } from 'components/LoCo/common/helpers/dateHelpers';
import {
    EditionsListLinks,
    friendlyNameRenderer,
} from 'components/LoCo/products/ProductMissionControl/EditionsListLinks';
import { SchemasLinks, simpleNameRenderer } from 'components/LoCo/products/ProductMissionControl/SchemasLinks';
import type { Edition } from 'models/LoCo/Insurance/BaseEdition';
import { EditionType, VersionType } from 'models/LoCo/Insurance/BaseEdition';
import type { EditionSet } from 'models/LoCo/Insurance/EditionSets';
import { ReleaseStatus } from 'models/LoCo/Insurance/Release';
import type { Release } from 'models/LoCo/Insurance/Release';
import type { SchemaType } from 'models/LoCo/Insurance/Schema';
import { useSuspenseGetEditionSetViolations } from 'queries/LoCo/Insurance/EditionSetQueries';
import { useSuspenseGetReleaseEditionSets } from 'queries/LoCo/Insurance/ReleasesQueries';

const editionLabelRendererGenerator = (release: Release) => (editionType: EditionType, edition: Edition) => {
    // eslint-disable-next-line react/destructuring-assignment
    const isEditionBeingPublished = Object.values(release.editions).some(({ code }) => code === edition.code);
    const version = friendlyNameRenderer(editionType, edition);
    if (isEditionBeingPublished) {
        return <b>{version}</b>;
    }

    return version;
};

const ViolationsExpendedDetails: React.FC<{
    readonly editionSetCode: string;
}> = ({ editionSetCode }) => {
    const { data: violationData } = useSuspenseGetEditionSetViolations(editionSetCode);

    return (
        <ExpendedDetailsTable
            rows={violationData.violationMessages.map(violation => [
                { key: 'error', value: 'ERROR' },
                { key: 'value', value: violation },
            ])}
        />
    );
};

const schemaLabelRendererGenerator = (release: Release) => (schemaType: SchemaType, schemaRevision: number) => {
    const version = simpleNameRenderer(schemaType, schemaRevision);
    if (
        (schemaType === 'product' && schemaRevision === release.productSchemaRevision) ||
        (schemaType === 'platform' && schemaRevision === release.platformSchemaRevision)
    ) {
        return <b>{version}</b>;
    }

    return version;
};

const editionSetHeaderFields = [
    { key: 'editionSet', title: 'Edition Set', width: '120px' },
    { key: 'effectiveNewBusiness', title: 'New Business', width: '140px' },
    { key: 'effectiveRenewals', title: 'Renewals', width: '140px' },
    { key: 'editions', title: 'Editions', width: '400px' },
    { key: 'environments', title: 'Environments', width: '200px' },
    { key: 'compatibility', title: 'Compatibility' },
] satisfies Columns;

const EffectiveDateWithTooltipMaybe: React.FC<{
    readonly checkDate: boolean;
    readonly effectiveDate: Date;
}> = ({ checkDate, effectiveDate }) => (
    <Flex className={effectiveDateWithTooltip}>
        {checkDate && effectiveDate < new Date() && (
            <Tooltip
                alignment="bottom"
                content={'Release cannot be published with an effective date which has already passed'}
            >
                <Flex>
                    <NoticeFilledIcon />
                </Flex>
            </Tooltip>
        )}
        {getFormattedDate(effectiveDate)}
    </Flex>
);

function getEditionSetRow(
    release: Release,
    editionSet: EditionSet,
    productCode: string,
    onRemoveEnvRequested: (editionSetCode: string, envId: string) => void
): ColumnsToRow<typeof editionSetHeaderFields> {
    const editions: Record<EditionType, Edition> = {
        [EditionType.DigitalAgent]: editionSet.digitalAgentEdition,
        [EditionType.Coverages]: editionSet.coveragesEdition,
        [EditionType.Rating]: editionSet.ratingEdition,
        [EditionType.UnderwritingFilters]: editionSet.underwritingFiltersEdition,
    };

    const checkDate = release.rolloutStrategy !== VersionType.BugFix;

    return {
        editionSet: { value: editionSet.version },
        effectiveNewBusiness: {
            value: (
                <EffectiveDateWithTooltipMaybe
                    checkDate={checkDate}
                    effectiveDate={editionSet.newBusinessEffectiveAt}
                />
            ),
        },

        effectiveRenewals: {
            value: (
                <EffectiveDateWithTooltipMaybe checkDate={checkDate} effectiveDate={editionSet.renewalEffectiveAt} />
            ),
        },

        editions: {
            value: (
                <Flex className={linksWrapper}>
                    <EditionsListLinks
                        editionLabelRenderer={editionLabelRendererGenerator(release)}
                        editions={editions}
                        openLinkInNewTab
                        productCode={productCode}
                    />
                    <SchemasLinks
                        openLinkInNewTab
                        productCode={productCode}
                        schemaLabelRenderer={schemaLabelRendererGenerator(release)}
                        schemaRevisions={{
                            platform: editionSet.platformSchemaRevision,
                            product: editionSet.productSchemaRevision,
                        }}
                    />
                </Flex>
            ),
        },
        environments: {
            value: (
                <Flex flexDirection="column">
                    {editionSet.availableEnvs.map(env => (
                        <Grid gap={spacing.s16} gridTemplateColumns="auto 70px" key={env}>
                            <div>{env}</div>
                            <StyledClickable fontSize="14px" onClick={() => onRemoveEnvRequested(editionSet.code, env)}>
                                Remove
                            </StyledClickable>
                        </Grid>
                    ))}
                </Flex>
            ),
        },
        compatibility: {
            value: (
                <Flex className={compatibilityWrapper}>
                    {editionSet.compatible ? (
                        <IconWithLabel
                            iconColor={'positive1'}
                            iconName="check-circle-solid"
                            label="No compatibility issues"
                        />
                    ) : (
                        <Flex className={compatibilityErrorWrapper}>
                            <IconWithLabel
                                iconColor={'negative1'}
                                iconName="alert-circle-solid"
                                label="Compatibility issues found"
                            />
                            <ExpendButton />
                        </Flex>
                    )}
                </Flex>
            ),
        },
    };
}
const ReleaseViolations: React.FC<{
    readonly releaseRolloutStrategy: VersionType;
    readonly editionSets: EditionSet[];
}> = ({ editionSets, releaseRolloutStrategy }) => {
    const hasIncompatibleEditionSets = editionSets.some(editionSet => !editionSet.compatible);
    const now = new Date();
    const hasPassedEffectiveDates =
        releaseRolloutStrategy !== VersionType.BugFix &&
        editionSets.some(editionSet => editionSet.newBusinessEffectiveAt < now || editionSet.renewalEffectiveAt < now);

    const { onActionRequested } = useReleaseContext();

    return (
        <Flex flexDirection="column" gap={spacing.s04}>
            {hasIncompatibleEditionSets && (
                <Alert
                    mode={AlertMode.Error}
                    title={
                        <Flex whiteSpace="break-spaces">
                            <b>Edition Set Compatibility Validation Failed</b> - This release cannot be published due to
                            compatibility issues detected between editions
                        </Flex>
                    }
                />
            )}
            {hasPassedEffectiveDates && (
                <Alert
                    actions={[
                        {
                            type: 'link',
                            label: 'Update Effective Dates',
                            onClick: () => onActionRequested(ReleaseDialogType.UpdateDates),
                        },
                    ]}
                    mode={AlertMode.Error}
                    title={
                        <Flex justifyContent="flex-start" whiteSpace="break-spaces">
                            <b>Effective Dates Have Passed</b> - The effective date of the Release has already passed
                            and must be updated
                        </Flex>
                    }
                />
            )}
            {!hasPassedEffectiveDates && !hasIncompatibleEditionSets && (
                <Alert
                    mode={AlertMode.Success}
                    title={
                        <Flex whiteSpace="break-spaces">
                            <b>Edition Set Compatibility Validations Completed</b> - No compatibility issues were
                            detected for the edition sets checked
                        </Flex>
                    }
                />
            )}
        </Flex>
    );
};

interface ReleaseEditionSetsProps {
    readonly productCode: string;
    readonly release: Release;
}

export const ReleaseEditionSets: React.FC<React.PropsWithChildren<ReleaseEditionSetsProps>> = ({
    productCode,
    release,
}) => {
    const { data: editionSets } = useSuspenseGetReleaseEditionSets(release.publicId);
    const [removeEnvDialogData, setRemoveEnvDialogData] = useState<{ editionSetCode: string; envId: string } | null>(
        null
    );

    const releaseCancelled = release.status === ReleaseStatus.Cancelled;

    return (
        <ContentSection>
            <StyledSectionHeader>
                <TableTitle title="Edition Sets" />
            </StyledSectionHeader>
            {!releaseCancelled && (
                <Flex className={editionSetExplanation}>
                    The following edition sets will be published to carry out this release
                </Flex>
            )}
            {editionSets.length === 0 ? (
                <EmptySection>
                    {releaseCancelled
                        ? 'Release was cancelled, all release edition sets were archived'
                        : 'Evaluating release edition sets...'}
                </EmptySection>
            ) : (
                <Flex flexDirection="column" gap={spacing.s16}>
                    <GridTable
                        columns={editionSetHeaderFields}
                        rows={editionSets.map(editionSet => ({
                            values: getEditionSetRow(
                                release,
                                editionSet,
                                productCode,
                                (editionSetCode: string, envId: string) =>
                                    setRemoveEnvDialogData({ editionSetCode, envId })
                            ),
                            expended: (
                                <Suspense fallback={<LoadingSection noBorders />}>
                                    <ViolationsExpendedDetails editionSetCode={editionSet.code} />
                                </Suspense>
                            ),
                        }))}
                    />
                    <ReleaseViolations editionSets={editionSets} releaseRolloutStrategy={release.rolloutStrategy} />
                </Flex>
            )}
            {removeEnvDialogData && (
                <RemoveEnvDialog
                    editionSetCode={removeEnvDialogData.editionSetCode}
                    envId={removeEnvDialogData.envId}
                    onClose={() => setRemoveEnvDialogData(null)}
                    productCode={productCode}
                />
            )}
        </ContentSection>
    );
};
