import { useSortable } from '@dnd-kit/sortable';
import type { Transform } from '@dnd-kit/utilities';
import { CSS } from '@dnd-kit/utilities';
import type { FC } from 'react';
import * as styles from './DraggableItem.css';

interface DraggableItemProps {
  readonly id: string;
  readonly children: React.ReactNode;
  readonly disabled?: boolean;
}

function getStyle(transform: Transform | null): { readonly transform?: string } {
  if (transform == null) return {};

  return {
    transform: CSS.Transform.toString({
      ...transform,
      // the library default scale looks bad on text
      scaleX: 1,
      scaleY: 1,
    }),
  };
}

export const DraggableItem: FC<DraggableItemProps> = ({ id, children, disabled = false }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging, data } = useSortable({ id, disabled });
  const isSingleItem = data.sortable.items.length < 2;

  if (isSingleItem || disabled) return children;

  return (
    <div
      className={styles.draggleItem({ isDragging })}
      data-dragging={isDragging}
      ref={setNodeRef}
      style={getStyle(transform)}
      {...attributes}
      {...listeners}
    >
      {children}
    </div>
  );
};
