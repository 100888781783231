import type { LabelValueItem } from '@lemonade-hq/typo';
import type { FC } from 'react';
import { ChangeAccordion } from '../ChangeAccordion';
import { getRelatedSettingsAndRulesFromCoverage } from '../getCoveragesAndRules';
import { getIsBenefit } from './coverage-attributes';
import { getSettingTypeDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import type { ReferenceIdEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';
import type { DigitalAgentEdition } from 'models/LoCo/Insurance/DigitalAgentEdition';

interface CoverageAddedProps {
    readonly change: ReferenceIdEntry;
    readonly coveragesEdition: CoveragesEdition;
    readonly digitalAgentEdition: DigitalAgentEdition | null;
}

export const CoverageAdded: FC<CoverageAddedProps> = ({ change, coveragesEdition, digitalAgentEdition }) => {
    const updated = getRelatedSettingsAndRulesFromCoverage({
        code: change.metadata.referenceId,
        coveragesEdition,
        digitalAgentEdition,
    });

    const rows: LabelValueItem[] = [];
    if (updated.coverage.isBenefit) rows.push(getIsBenefit(updated.coverage));

    rows.push({
        label: 'Related settings',
        value:
            updated.relatedSettings.length > 0
                ? updated.relatedSettings
                      .map(setting => `${setting.name} (${getSettingTypeDisplayName(setting.type)})`)
                      .join(', ')
                : 'None',
    });

    return (
        <ChangeAccordion
            generalTabData={rows}
            originalRules={null}
            ruleType="coverage"
            title={`${updated.coverage.required ? 'Required' : 'Optional'} coverage added - ${updated.coverage.name}`}
            updatedRules={updated.rules}
        />
    );
};
