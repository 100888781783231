import { InfoOutlinedIcon, NoticeFilledIcon } from '@lemonade-hq/bluis';
import { themedColor, Tooltip } from '@lemonade-hq/boutique';
import { Flex } from '@lemonade-hq/cdk';
import styled from 'styled-components';
import { StyledFormInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';

export const SplitStyledFormInputWrapper = styled(StyledFormInputWrapper)`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    > div {
        width: 100%;
    }

    .inner {
        width: 300px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 12px;

        > div {
            width: 100%;

            > button {
                width: 100% !important;
            }
        }
    }
`;

const StyledInfoIcon = styled(InfoOutlinedIcon)`
    color: ${themedColor('inputBorder')};
`;

const StyledErrorIcon = styled(NoticeFilledIcon)`
    color: ${themedColor('inputBorder')};
`;

export const LabelWithTooltip: React.FC<{
    readonly label: string;
    readonly tooltipContent: JSX.Element | string;
    readonly type: 'error' | 'info';
}> = ({ label, tooltipContent, type }) => (
    <Flex alignItems="center" gap="12px">
        {label}
        <Tooltip alignment="top" content={tooltipContent}>
            <Flex>{type === 'error' ? <StyledErrorIcon /> : <StyledInfoIcon />}</Flex>
        </Tooltip>
    </Flex>
);

export const ScopeLabel: React.FC = () => (
    <LabelWithTooltip
        label="Scope"
        tooltipContent={
            <Flex alignItems="flex-start" flexDirection="column">
                <span>
                    Should this Setting be applied as a total for the entire policy, or separately to different entities
                    covered by the policy?
                </span>
                <span>(for example: a Per-Driver or Per-Vehicle limit)</span>
            </Flex>
        }
        type="info"
    />
);

export const ValueSelectionLabel: React.FC = () => (
    <LabelWithTooltip
        label="Value Selection"
        tooltipContent={
            <Flex alignItems="flex-start" flexDirection="column">
                <span>Can customers select different values for each insured entity on their policy,</span>
                or should the same value be used for each entity in a policy?
                <span />
            </Flex>
        }
        type="info"
    />
);

export const InsurableEntityLabel: React.FC = () => (
    <LabelWithTooltip
        label="Insurable Entity"
        tooltipContent={
            <Flex alignItems="flex-start" flexDirection="column">
                <span>Which entity should this Setting apply to?</span>
            </Flex>
        }
        type="info"
    />
);

export const DurationLabel: React.FC = () => (
    <LabelWithTooltip
        label="Duration"
        tooltipContent={
            <Flex alignItems="flex-start" flexDirection="column">
                <span>Should this Setting be applied across the entire policy term, applied separately per event,</span>
                <span>or reset on a fixed timeframe? (for example: a daily or weekly limit)</span>
            </Flex>
        }
        type="info"
    />
);

export const ExternalEntityLabel: React.FC = () => (
    <LabelWithTooltip
        label="Entity Name"
        tooltipContent={
            <Flex alignItems="flex-start" flexDirection="column">
                <span>
                    {`Name the entity which is considered the scope of this limit (for example: "Tree" for a Per-Tree
                    limit)`}
                </span>
            </Flex>
        }
        type="info"
    />
);

export const ParentLimitLabel: React.FC = () => (
    <LabelWithTooltip
        label="Sub limit of"
        tooltipContent={
            <Flex alignItems="flex-start" flexDirection="column">
                <span>Is this a sub limit of another limit?</span>
            </Flex>
        }
        type="info"
    />
);
