import type { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageRuleType, RuleEntityType } from 'models/LoCo/Insurance/CoverageRule';

export enum DialogType {
    AddRule = 'addRule',
}

export type ActionData = {
    readonly type: DialogType;
    readonly entity?: { readonly type: RuleEntityType; readonly code?: string };
    readonly lifecycleContexts?: RuleLifecycleContext[];
    readonly ruleType?: CoverageRuleType;
};

export type Actions = DialogType.AddRule;
