import { Flex, spacing } from '@lemonade-hq/blender-ui';
import type { ActionOption } from '@lemonade-hq/bluis';
import { ActionsMenu, Image, TagMode } from '@lemonade-hq/bluis';
import {
    EntitySummaryHeader,
    HeaderGeneralDetails,
    HeaderLocation,
    HeaderRightSideBlock,
    LinkComp,
} from '@lemonade-hq/bluiza';
import { Box } from '@lemonade-hq/cdk';
import type { SetStateAction } from 'react';
import React from 'react';
import { getProductUrl } from '../urlBuilders';
import { HeaderPortal } from 'components/Header';
import { getEditionName, getEditionStatus } from 'components/LoCo/common/editions/editionHelpers';
import type { EditionActionData } from 'components/LoCo/common/editions/editionSharedActions';
import { getEditionActions } from 'components/LoCo/common/editions/editionSharedActions';
import { HeaderImageSizes, ImageContainer } from 'components/LoCo/LoCoPagesSharedStyles';
import type { Edition, EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type { Product } from 'models/LoCo/Insurance/Product';
import { useGetEditionApproveEligibility } from 'queries/LoCo/Insurance/BaseEditionQueries';

interface EditionActionsMenuProps {
    readonly type: EditionType;
    readonly edition: Edition;
    readonly product: Product;
    readonly setDialogData: React.Dispatch<SetStateAction<EditionActionData | null>>;
}

const EditionActionsMenu: React.FC<EditionActionsMenuProps> = ({ edition, product, setDialogData, type }) => {
    const { data: approvalEligibility } = useGetEditionApproveEligibility(edition.code, type);

    const actions: ActionOption[] = getEditionActions(edition, type, product.name, approvalEligibility, setDialogData);
    return <ActionsMenu actions={actions} buttonText="Actions" />;
};

interface EditionHeaderProps {
    readonly type: EditionType;
    readonly edition: Edition;
    readonly product: Product;
    readonly setDialogData: React.Dispatch<SetStateAction<EditionActionData | null>>;
}

type EditionSubtitleProps = Pick<EditionHeaderProps, 'edition' | 'product'>;

function getMode(edition: Edition): TagMode {
    if (edition.approvedAt) {
        return TagMode.Success;
    }

    if (edition.archivedAt) {
        return TagMode.Pending;
    }

    return TagMode.Info;
}
const Subtitle: React.FC<EditionSubtitleProps> = ({ edition, product }) => {
    return (
        <Flex>
            <Flex pr={spacing.s10}>{edition.friendlyName}</Flex>
            <HeaderRightSideBlock style={{ paddingLeft: '10px' }}>
                <LinkComp url={getProductUrl(product.code)}>{product.name}</LinkComp>
            </HeaderRightSideBlock>
        </Flex>
    );
};

export const EditionHeader: React.FC<EditionHeaderProps> = ({ type, edition, product, setDialogData }) => {
    const status = getEditionStatus(edition);
    const mode = getMode(edition);

    return (
        <HeaderPortal>
            <EntitySummaryHeader>
                <ImageContainer>
                    <Image
                        img={{
                            lightSrc: 'appraisal.svg',
                            darkSrc: 'appraisal-dark.svg',
                            ...HeaderImageSizes,
                        }}
                    />
                </ImageContainer>
                <HeaderGeneralDetails
                    status={{ label: status, mode }}
                    subtitle={<Subtitle edition={edition} product={product} />}
                    title={`${getEditionName(type)} Edition`}
                />
                <HeaderLocation country={product.country} state={product.state ?? product.country} />
                <Box ml="auto">
                    <EditionActionsMenu edition={edition} product={product} setDialogData={setDialogData} type={type} />
                </Box>
            </EntitySummaryHeader>
        </HeaderPortal>
    );
};
