import React, { useContext } from 'react';
import { CoinsuranceAttributes } from './CoinsuranceAttributes';
import { DeductibleAttributes } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/DeductibleAttributes';
import { LimitAttributes } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/LimitAttributes';
import { WaitingPeriodAttributes } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/WaitingPeriodAttributes';
import { SettingContext } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/SettingContext';
import type { SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

interface CommonAttributesProps {
    readonly editionSettings: SettingInstance[];
}

export const SettingsAttributes: React.FC<CommonAttributesProps> = ({ editionSettings }) => {
    const { state, dispatch } = useContext(SettingContext);

    switch (state?.settingType) {
        case SettingType.Deductible:
            return <DeductibleAttributes dispatch={dispatch} state={state} />;
        case SettingType.Limit:
            return <LimitAttributes dispatch={dispatch} editionSettings={editionSettings} state={state} />;
        case SettingType.WaitingPeriod:
            return <WaitingPeriodAttributes dispatch={dispatch} state={state} />;
        case SettingType.Coinsurance:
            return <CoinsuranceAttributes dispatch={dispatch} state={state} />;
        default:
            return null;
    }
};
