import type { AttachmentDTO } from './types';

export const mockAttachments: AttachmentDTO[] = [
    {
        publicId: '11',
        type: 'attachment-type',
        description: 'attachment-description',
        url: 'https://files-master.lmndstaging.com/files/lfl-smfshqukdh29be23x2vs?inline=true',
        fileName: 'all_team.jpg',
        contentType: 'image/jpeg',
        status: 'active',
        suggestedArchive: false,
        archivingReason: null,
        sources: [
            {
                description: 'Uploaded via claim submission',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
            {
                description: 'Received via email',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
    },
    {
        publicId: '12',
        type: 'attachment-type',
        description: 'attachment-description',
        url: 'https://files-master.lmndstaging.com/files/lfl-pb-rg-x4narl7sbb51nx?inline=true',
        fileName: 'archived_attachment.jpg',
        contentType: 'image/png',
        status: 'archived',
        suggestedArchive: false,
        archivingReason: 'accidentally_uploaded',
        sources: [
            {
                description: 'Uploaded via claim submission',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
    },
    {
        publicId: '13',
        url: 'https://files-master.lmndstaging.com/files/lfl-u0zg47zpjxquncin76y4?inline=true',
        fileName: 'unknown_content_type_attachment',
        contentType: '',
        status: 'active',
        suggestedArchive: false,
        archivingReason: null,
        sources: [
            {
                description: 'Uploaded via claim submission',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
    },
    {
        publicId: '14',
        url: 'https://files-master.lmndstaging.com/files/lfl-smfshqukdh29be23x2vs?inline=true',
        fileName: 'suggested_archive_attachment.jpg',
        contentType: 'image/jpeg',
        status: 'active',
        suggestedArchive: true,
        archivingReason: 'duplicate',
        sources: [
            {
                description: 'Uploaded via claim submission',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
    },
    {
        publicId: '1',
        url: 'https://files-master.public.lmndstaging.com/tokens/lftkn-xtgtgjxl4p0pfjmae00h?inline=true',
        fileName: 'policy_pdf',
        status: 'active',
        suggestedArchive: false,
        archivingReason: null,
        type: 'policy_pdf',
        description: '',
        contentType: 'application/pdf',
        sources: [
            {
                description: 'Related to claim',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
            {
                description: 'Related to policy',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
    },
    {
        publicId: '2',
        url: 'https://files-master.lmndstaging.com/files/lfl-7qcau44ax34cnxej2ihz?inline=true',
        fileName: 'suggested_archive_attachment.jpg',
        status: 'active',
        suggestedArchive: true,
        type: 'invoice',
        description: 'Some description',
        archivingReason: 'accidentally_uploaded',
        contentType: 'image/jpeg',
        sources: [
            {
                description: 'Related to user',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
            {
                description: 'Related to contract',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
    },
    {
        publicId: '3',
        url: 'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/ElephantsDream.mp4',
        fileName: 'video.mp4',
        status: 'active',
        suggestedArchive: false,
        archivingReason: null,
        type: 'report',
        description: 'Some description',
        contentType: 'video/mp4',
        sources: [
            {
                description: 'Related to policy',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
    },
    {
        publicId: '4',
        url: 'https://files-master.lmndstaging.com/files/lfl-3acr7zwyx7idgry4qp8f?inline=true?inline=true',
        fileName: 'pdf_attachment.pdf',
        status: 'active',
        suggestedArchive: false,
        type: 'claim_receipt',
        description: '',
        archivingReason: null,
        contentType: 'application/pdf',
        sources: [
            {
                description: 'Related to claim',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
    },
    {
        publicId: '5',
        url: 'https://files-master.lmndstaging.com/files/lfl-zxysw2110aynrvrhte2c?inline=true',
        fileName: 'image_attachment.jpg',
        status: 'active',
        suggestedArchive: false,
        type: 'invoice',
        description: '',
        archivingReason: null,
        contentType: 'image/jpeg',
        sources: [
            {
                description: 'Related to contract',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
            {
                description: 'Related to policy',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
    },
    {
        publicId: '6',
        url: 'https://files-master.lmndstaging.com/files/lfl-avp089m9pgfgn-lrftrj?inline=true',
        fileName: 'image_attachment_with_warning.jpg',
        status: 'active',
        suggestedArchive: false,
        type: 'report',
        description: '',
        archivingReason: null,
        contentType: 'image/jpeg',
        sources: [
            {
                description: 'Related to user',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
            {
                description: 'Related to claim',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
        detections: [
            {
                detectionModel: 'resistant_ai',
                detectionStatus: 'completed',
                publicId: '6',
                detectionResult: {
                    score: 'WARNING',
                    indicators: {
                        RISK: [
                            {
                                indicator_id: '1',
                                type: 'RISK',
                                category: 'category',
                                title: 'title',
                                description: 'description',
                                origin: 'origin',
                                metadata: null,
                            },
                        ],
                        INFO: [
                            {
                                indicator_id: '2',
                                type: 'INFO',
                                category: 'category',
                                title: 'title',
                                description: 'description',
                                origin: 'origin',
                                metadata: null,
                            },
                        ],
                    },
                },
            },
        ],
    },
    {
        publicId: '7',
        url: 'https://files-master.lmndstaging.com/files/lfl-pdgvaaj8ll797ezdlg7h',
        fileName: 'fraud_pdf_attachment.pdf',
        status: 'active',
        suggestedArchive: false,
        type: 'claim_receipt',
        description: 'Some description',
        archivingReason: null,
        contentType: 'application/pdf',
        sources: [
            {
                description: 'Related to claim',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
            {
                description: 'Related to user',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
        detections: [
            {
                detectionModel: 'resistant_ai',
                detectionStatus: 'completed',
                publicId: '7',
                detectionResult: {
                    score: 'HIGH_RISK',
                    annotatedSource: 'https://files-master.lmndstaging.com/files/lfl-vs51wr31sp74wa3p9ste',
                    annotatedReconstructed: 'https://files-master.lmndstaging.com/files/lfl-gsjn23d7hsteacl5nku1',
                    textChanges: {
                        1: ['$', '11', '24.98 USD'],
                    },
                },
            },
        ],
    },
    {
        publicId: '8',
        url: 'https://s3.amazonaws.com/staging-lmnd-attachments/LU27DCDD06_1639302784_70942723.png?AWSAccessKeyId=AKIAIM57LAXLK6WAORZA&Expires=1727309977&Signature=rOGyXVaA9%2FTSUziIDw6HfbGwATQ%3D',
        fileName: 'old_attachment.jpg',
        status: 'active',
        suggestedArchive: false,
        type: 'invoice',
        description: '',
        archivingReason: null,
        contentType: 'image/jpeg',
        sources: [
            {
                description: 'Related to contract',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
            {
                description: 'Related to policy',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
    },
    {
        publicId: '9',
        url: 'https://files-master.lmndstaging.com/files/lfl-52r0fwu-ersukm95a83r?inline=true',
        fileName: 'fraud_with_text_changes.pdf',
        status: 'active',
        suggestedArchive: false,
        archivingReason: null,
        type: 'report',
        description: '',
        contentType: 'application/pdf',
        sources: [
            {
                description: 'Related to policy',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
            {
                description: 'Related to user',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
        detections: [
            {
                detectionModel: 'car_attachment_detection',
                detectionStatus: 'completed',
                publicId: '9',
                detectionResult: {
                    annotatedSource: '',
                    annotatedReconstructed: '',

                    score: 'HIGH_RISK',
                    textChanges: {
                        1: ['$', '11', '24.98 USD'],
                    },
                },
            },
        ],
    },
    {
        publicId: '10',
        url: 'https://files-master.lmndstaging.com/files/lfl-r3ouudx8z4wv-j1lzrrz',
        fileName: 'archived_fraud_with_fraud.pdf',
        status: 'archived',
        suggestedArchive: false,
        type: 'report',
        description: '',
        archivingReason: 'other',
        contentType: 'application/pdf',
        sources: [
            {
                description: 'Related to policy',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
            {
                description: 'Related to user',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
        detections: [
            {
                detectionModel: 'car_attachment_detection',
                detectionStatus: 'completed',
                publicId: '9',
                detectionResult: {
                    annotatedSource: '',
                    annotatedReconstructed: '',

                    score: 'HIGH_RISK',
                    indicators: {
                        RISK: [
                            {
                                indicator_id: 'has_text_hidden_by_text_annotation',
                                type: 'RISK',
                                category: 'content_hiding',
                                title: 'Text changed via annotations',
                                description:
                                    'Some words in this document were covered up and replaced with text annotations. This is a strong indication of fraud.',
                                origin: 'fraud',
                                metadata: null,
                            },
                            {
                                indicator_id: 'has_text_hidden_by_annotation',
                                type: 'RISK',
                                category: 'content_hiding',
                                title: 'Text hidden by annotation',
                                description: 'This file has text hidden by annotation.',
                                origin: 'fraud',
                                metadata: null,
                            },
                            {
                                indicator_id: 'is_generated_with_preview',
                                type: 'RISK',
                                category: 'modifications',
                                title: 'PDF produced using Apple Preview',
                                description: 'This document was created in Apple Preview.',
                                origin: 'fraud',
                                metadata: null,
                            },
                            {
                                indicator_id: 'has_text_annotations',
                                type: 'RISK',
                                category: 'annotations',
                                title: 'Contains text-annotations',
                                description:
                                    'Text-annotation may be used to prepend, append or change any text in this document. For example, in an invoice the text annotation can be used to alter the account number of the beneficiary.',
                                origin: 'fraud',
                                metadata: null,
                            },
                        ],
                        INFO: [
                            {
                                indicator_id: 'has_square_annotations',
                                type: 'INFO',
                                category: 'annotations',
                                title: 'Contains square-annotations',
                                description:
                                    'Square-annotations usually display a pop-up window containing the text of the associated note. However, these annotations can be misused to hide content.',

                                origin: 'fraud',
                                metadata: null,
                            },
                            {
                                indicator_id: 'has_macos_metadata',
                                type: 'INFO',
                                category: 'known_creator',
                                title: 'Saved in MacOS',
                                description: 'The document was processed and saved in MacOS.',

                                origin: 'fraud',
                                metadata: null,
                            },
                            {
                                indicator_id: 'has_quartz_metadata',
                                type: 'INFO',
                                category: 'known_creator',
                                title: 'Saved in Quartz',
                                description:
                                    'The document was processed and saved in Quartz - software for drawing 2D graphics on MacOS with an option to export to PDF.',

                                origin: 'fraud',
                                metadata: null,
                            },
                            {
                                indicator_id: 'has_other_annotations',
                                type: 'INFO',
                                category: 'annotations',
                                title: 'Contains document annotations',
                                description:
                                    'Some annotations may tamper with the content of the document changing the semantics of individual words or the whole content. Examples of annotations are comments, notes, reductions or printer marks.',

                                origin: 'fraud',
                                metadata: null,
                            },
                        ],
                        TRUST: [
                            {
                                indicator_id: 'is_pdf_unmodified_per_metadata',
                                type: 'TRUST',
                                category: 'authenticity',
                                title: 'No modification in document metadata',
                                description:
                                    "Timestamps in this document's metadata do not indicate that the file was incrementally updated or modified.",

                                origin: 'fraud',
                                metadata: null,
                            },
                            {
                                indicator_id: 'is_pdf_unmodified_per_trailer_id',
                                type: 'TRUST',
                                category: 'authenticity',
                                title: 'No modification in document trailer',
                                description:
                                    'File trailer, which describes the internal structure of this document, does not indicate that the file was incrementally updated or modified.',

                                origin: 'fraud',
                                metadata: null,
                            },
                        ],
                    },
                },
            },
        ],
    },
    {
        publicId: '16',
        url: 'https://files-master.lmndstaging.com/files/lfl-r3ouudx8z4wv-j1lzrrz',
        fileName: 'normal_attachment_with_external_view_url.pdf',
        status: 'active',
        suggestedArchive: false,
        type: 'report',
        description: '',
        archivingReason: null,
        contentType: 'application/pdf',
        sources: [
            {
                description: 'Related to policy',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
            {
                description: 'Related to user',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
        detections: [
            {
                detectionModel: 'resistant_ai',
                detectionStatus: 'completed',
                publicId: '9',
                detectionResult: {
                    annotatedSource: '',
                    annotatedReconstructed: '',

                    score: 'NORMAL',
                    externalViewUrl:
                        'https://ins-lemonade.documents.testing.resistant.ai/analysis/41550bdb-430b-4bf7-ae86-5c1d53e11f28',
                },
            },
        ],
    },
    {
        publicId: '17',
        url: 'https://files-master.lmndstaging.com/files/lfl-eyob-gnq6asl1wrzaxt4?inline=true',
        fileName: 'trusted_attachment_with_external_view_url.jpeg',
        status: 'active',
        suggestedArchive: false,
        type: 'report',
        description: '',
        archivingReason: null,
        contentType: 'image/jpeg',
        sources: [
            {
                description: 'Related to policy',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
            {
                description: 'Related to user',
                link: {
                    type: 'interaction',
                    param: 'LINTERACTION8158-0659-9310',
                    text: 'See on comms-timeline',
                },
                timestamp: new Date(),
            },
        ],
        detections: [
            {
                detectionModel: 'resistant_ai',
                detectionStatus: 'completed',
                publicId: '9',
                detectionResult: {
                    annotatedSource: '',
                    annotatedReconstructed: '',

                    score: 'TRUSTED',
                    externalViewUrl:
                        'https://ins-lemonade.documents.testing.resistant.ai/analysis/41550bdb-430b-4bf7-ae86-5c1d53e11f28',
                },
            },
        ],
    },
];
