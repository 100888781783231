import type { ComboBoxItem } from '@lemonade-hq/blender-ui';
import { ComboBox, Flex, spacing, Text, TextArea } from '@lemonade-hq/blender-ui';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import { useCallback, useMemo, useState } from 'react';
import type { FC } from 'react';
import { AttachmentDialogBase } from './AttachmentDialogBase';
import { useGetAttachmentTypes, useSubmitAttachmentDetails } from 'components/Attachments/AttachmentsQueries';
import { useAttachmentsData } from 'components/Attachments/context';
import type { AttachmentDTO } from 'components/Attachments/types';

export const UpdateAttachmentDialog: FC<{ readonly attachments: AttachmentDTO[]; readonly onClose: () => void }> = ({
    attachments,
    onClose,
}) => {
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');

    const { entityPublicId, entityType } = useAttachmentsData();
    const { data: attachmentTypes, isError, isLoading } = useGetAttachmentTypes({ entityType });
    const { mutateAsync: updateAttachmentDetails, isPending } = useSubmitAttachmentDetails({
        entityPublicId: entityPublicId ?? '',
        entityType,
    });

    const handleSelectionChange = useCallback((item: ComboBoxItem | null) => {
        setType(item?.value ?? '');
    }, []);

    const handleSubmit = useCallback(async () => {
        await updateAttachmentDetails({ type, description, attachmentPublicId: attachments[0].publicId });

        onClose();
    }, [attachments, description, onClose, type, updateAttachmentDetails]);

    const types = useMemo(
        () =>
            attachmentTypes?.map(attachmentType => ({
                value: attachmentType,
                label: snakeCaseToReadable(attachmentType),
            })) ?? [],
        [attachmentTypes]
    );

    return (
        <AttachmentDialogBase
            attachments={attachments}
            isLoading={isPending}
            isSubmitDisabled={(type === '' && description === '') || isPending}
            onClose={onClose}
            onSubmit={handleSubmit}
            submitLabel="Done"
            title="Re-label attachments"
        >
            <Flex flexDirection="column" gap={spacing.s12}>
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        Type
                    </Text>
                    <ComboBox
                        defaultValue={type}
                        disabled={isLoading}
                        items={types}
                        onSelectionChange={handleSelectionChange}
                        placeholder="Select attachment type"
                    />
                    {isError && (
                        <Text color="error" type="label-sm">
                            Error loading attachment types
                        </Text>
                    )}
                </Flex>
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        Description
                    </Text>
                    <TextArea
                        autoExpanding
                        maxLength={500}
                        onChange={e => setDescription(e.target.value)}
                        rows={5}
                        value={description}
                    />
                </Flex>
            </Flex>
        </AttachmentDialogBase>
    );
};
