import type { FormProps } from '@lemonade-hq/cdk';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import type { Currency } from '@lemonade-hq/lemonation';
import type { Nullish } from '@lemonade-hq/ts-helpers';
import { useEffect, useMemo } from 'react';
import type {
    DeductibleAttributesState,
    LimitAttributesState,
    SettingAction,
    SettingStateBase,
    SettingStates,
} from '../../SettingContext';
import { toReadable } from 'commons/StringUtils';
import { getDurationTypeDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import type { Duration, EntityScopeValueSelectionMethod } from 'models/LoCo/Insurance/CoveragesEdition';
import {
    DurationType,
    DurationUnit as DurationUnitEnum,
    InsuranceScopeType,
    SettingUnit,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

export enum FieldType {
    Scope = 'Scope',
    ValueSelectionMethod = 'ValueSelectionMethod',
    Duration = 'Duration',
    DurationUnit = 'DurationUnit',
    LimitUnit = 'LimitUnit',
    CurrencyUnit = 'CurrencyUnit',
    ParentLimitTemplateCode = 'ParentLimitTemplateCode',
    EntityName = 'EntityName',
    InsuredEntityCode = 'insuredEntityCode',
    DurationAmount = 'DurationAmount',
    Variant = 'Variant',
}

export interface SettingTypeAttributesProps {
    readonly state: SettingStates;
    readonly dispatch: React.Dispatch<SettingAction>;
}

const isLimitSettingState = (state: Nullish | SettingStates): state is SettingStateBase<LimitAttributesState> =>
    state?.settingType === SettingType.Limit;

export const buildBaseLimitDeductibleForm = <
    T extends SettingStateBase<DeductibleAttributesState | LimitAttributesState>,
>(
    state: Nullish | T
) => {
    return {
        [FieldType.Scope]: {
            startValue: state?.attributes?.scope.type ?? '',
            validations: {
                required: basicRequiredValidation,
            },
        },
        [FieldType.ValueSelectionMethod]: {
            startValue: state?.attributes?.valueSelectionMethod ?? '',
            validations: {
                required: basicRequiredValidation,
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            skipValidationsCondition: (values: any) => values[FieldType.Scope] !== InsuranceScopeType.InsuredEntity,
        },
        [FieldType.Duration]: {
            startValue: state?.attributes?.duration.type ?? '',
            validations: {
                required: basicRequiredValidation,
            },
            skipValidationsCondition: () => state?.settingType === SettingType.WaitingPeriod,
        },
        [FieldType.DurationAmount]: {
            startValue:
                state?.attributes?.type === SettingType.Limit || state?.attributes?.type === SettingType.Deductible
                    ? state.attributes.duration.amount
                    : undefined,
            validations: {
                required: basicRequiredValidation,
            },
            skipValidationsCondition: () => state?.attributes?.duration.type !== DurationType.Timespan,
        },
        [FieldType.DurationUnit]: {
            startValue:
                state?.attributes?.type === SettingType.Limit || state?.attributes?.type === SettingType.Deductible
                    ? state.attributes.duration.unit
                    : '',
            validations: {
                required: basicRequiredValidation,
            },
            skipValidationsCondition: () => state?.attributes?.duration.type !== DurationType.Timespan,
        },
        [FieldType.InsuredEntityCode]: {
            startValue: state?.attributes?.scope.insuredEntityCode ?? '',
            validations: {
                required: basicRequiredValidation,
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            skipValidationsCondition: (values: any) => values[FieldType.Scope] !== InsuranceScopeType.InsuredEntity,
        },
        [FieldType.EntityName]: {
            startValue: state?.attributes?.scope.name,
            validations: {
                required: basicRequiredValidation,
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            skipValidationsCondition: (values: any) => values[FieldType.Scope] !== InsuranceScopeType.ExternalEntity,
        },
        [FieldType.CurrencyUnit]: {
            startValue: state?.attributes?.currencyUnit ?? '',
            validations: {
                required: basicRequiredValidation,
            },
            skipValidationsCondition: () =>
                isLimitSettingState(state) && state.attributes?.unit !== SettingUnit.Currency,
        },
    } as const;
};

export const useCommonAttributes = <T extends FormProps>(form: T, state: Nullish | SettingStates) => {
    const { values, setValue, valid, errors } = useForm(form);

    const { insurableEntities } = useGetProductData();

    useEffect(() => {
        if (state?.attributes) return;
        if (values[FieldType.Scope] !== '') {
            setValue(FieldType.Scope, '');
        }

        if (values[FieldType.Duration] !== '') {
            setValue(FieldType.Duration, '');
        }

        if (values[FieldType.ValueSelectionMethod] !== '') {
            setValue(FieldType.ValueSelectionMethod, '');
        }

        if (values[FieldType.LimitUnit] !== '') {
            setValue(FieldType.LimitUnit, '');
        }

        if (values[FieldType.CurrencyUnit] !== '') {
            setValue(FieldType.CurrencyUnit, '');
        }
    }, [setValue, state?.attributes, values]);

    const showCurrencyUnit = useMemo(
        () => state?.settingType === SettingType.Deductible || values[FieldType.LimitUnit] === SettingUnit.Currency,
        [state?.settingType, values]
    );

    const ShowTimeSpan = useMemo(() => values[FieldType.Duration] === DurationType.Timespan, [values]);

    const onDurationChange = ({ value }: { value: Partial<Duration> }) => {
        if (value.type) {
            setValue(FieldType.Duration, value.type);
            setValue(FieldType.DurationAmount, '');
            setValue(FieldType.DurationUnit, '');
        }

        if (value.amount) {
            setValue(FieldType.DurationAmount, value.amount);
        }

        if (value.unit) {
            setValue(FieldType.DurationUnit, value.unit);
        }
    };

    const onValueSelectionChange = ({ value }: { value: EntityScopeValueSelectionMethod }) => {
        setValue(FieldType.ValueSelectionMethod, value);
    };

    const onScopeChange = ({ value }: { value: InsuranceScopeType }) => {
        setValue(FieldType.Scope, value);
        if (value !== InsuranceScopeType.ExternalEntity) {
            setValue(FieldType.EntityName, undefined);
        }
    };

    const onCurrencyUnitChange = ({ value }: { value: Currency }) => {
        setValue(FieldType.CurrencyUnit, value);
    };

    const durationOptions = useMemo(() => {
        return [DurationType.Event, DurationType.Policy, DurationType.Timespan].map(duration => ({
            id: duration,
            value: duration,
            label: getDurationTypeDisplayName(duration),
        }));
    }, []);

    const durationUnitOptions = useMemo(() => {
        return Object.values(DurationUnitEnum).map(duration => ({
            id: duration,
            value: duration,
            label: `${toReadable(duration)}s`,
        }));
    }, []);

    const onEntityNameChange = ({ value }: { value: string }) => {
        setValue(FieldType.EntityName, value);
    };

    const scopeOptions = useMemo(() => {
        const options: InsuranceScopeType[] = [InsuranceScopeType.Policy, InsuranceScopeType.ExternalEntity];

        if (insurableEntities.length > 0) {
            options.push(InsuranceScopeType.InsuredEntity);
        }

        return options.map(scope => ({
            id: scope,
            value: scope,
            label: toReadable(scope),
        }));
    }, [insurableEntities]);

    const insurableEntityOptions = insurableEntities.map(entity => ({
        id: entity.code,
        value: entity.code,
        label: entity.name,
    }));

    return {
        values,
        errors,
        setValue,
        valid,
        onDurationChange,
        onValueSelectionChange,
        onEntityNameChange,
        onScopeChange,
        onCurrencyUnitChange,
        showCurrencyUnit,
        durationOptions,
        durationUnitOptions,
        ShowTimeSpan,
        insurableEntityOptions,
        scopeOptions,
    };
};
