import type { RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import type { ReactElement } from 'react';
import { ActionsMenu } from '../../../ActionsMenu/ActionsMenu';
import { useDynamicListContext } from './DynamicListContext';

interface DynamicListItemActionMenuProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TItem,
> {
  readonly schemaKey: TSchemaKey;
  readonly items: TItem[];
  readonly index: number;
}

export const DynamicListItemActionMenu = <
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TItem,
>({
  index,
}: DynamicListItemActionMenuProps<TSchema, TSchemaKey, TItem>): ReactElement => {
  const { startEditingItem, addItem, deleteItem } = useDynamicListContext();

  return (
    <ActionsMenu
      items={[
        { label: 'Edit', id: 'edit', onSelect: () => startEditingItem(index) },
        { label: 'Add above', id: 'add-above', onSelect: () => addItem(index) },
        { label: 'Remove', id: 'remove', onSelect: () => deleteItem(index) },
      ]}
    />
  );
};
