import { isAuthorizationError } from '@lemonade-hq/bluis';
import { HttpCode } from '@lemonade-hq/ts-helpers';
import { QueryClient } from '@tanstack/react-query';

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            throwOnError: error => isAuthorizationError(error),
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore -- retry only when response status is 500 or above
            retry: (failureCount, error) => error.response?.status >= HttpCode.InternalServerError && failureCount < 2,
        },
        mutations: {
            throwOnError: error => isAuthorizationError(error),
        },
    },
});
