import { Select } from '@lemonade-hq/bluis';
import type { FormProps } from '@lemonade-hq/cdk';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import React, { useEffect } from 'react';
import type { SettingTypeAttributesProps } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/CommonAttributes';
import {
    FieldType,
    useCommonAttributes,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/CommonAttributes';
import type {
    SettingStateBase,
    WaitingPeriodAttributesState,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/SettingContext';
import { SettingActionType } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/SettingContext';
import { StyledFormInputWrapper, StyledFormWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import type { DurationUnit } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

export const WaitingPeriodAttributes: React.FC<SettingTypeAttributesProps> = ({ state, dispatch }) => {
    const waitingPeriodState = state as SettingStateBase<WaitingPeriodAttributesState> | undefined;
    const form = {
        fields: {
            [FieldType.DurationUnit]: {
                startValue: waitingPeriodState?.attributes?.durationUnit ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
            },
        },
    } satisfies FormProps;

    const { values, setValue, valid } = useForm(form);

    useEffect(() => {
        dispatch({
            type: SettingActionType.SetWaitingPeriodAttributes,
            payload: {
                newValues: {
                    type: SettingType.WaitingPeriod,
                    durationUnit: values[FieldType.DurationUnit] as DurationUnit,
                    isValid: valid,
                },
            },
        });
    }, [values, valid, dispatch, state.settingType]);

    const { durationUnitOptions } = useCommonAttributes(form, state);

    const onDurationUnitChange = ({ value }: { value: DurationUnit }) => {
        setValue(FieldType.DurationUnit, value);
    };

    return (
        <StyledFormWrapper>
            <StyledFormInputWrapper label="Duration Unit">
                <Select
                    onOptionSelected={option => onDurationUnitChange({ value: option.value })}
                    options={durationUnitOptions}
                    placeholder="Select Unit"
                    value={values[FieldType.DurationUnit] as DurationUnit}
                />
            </StyledFormInputWrapper>
        </StyledFormWrapper>
    );
};
