import { Input, Select } from '@lemonade-hq/bluis';
import type { Currency } from '@lemonade-hq/lemonation';
import React from 'react';
import { getCurrencyOptions } from '../../../../../../common/helpers/currencyHelpers';
import { toReadable } from 'commons/StringUtils';
import { SplitStyledFormInputWrapper } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/SharedLabels';
import { StyledFormInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import type { Duration } from 'models/LoCo/Insurance/CoveragesEdition';
import { DurationUnit } from 'models/LoCo/Insurance/CoveragesEdition';

interface CurrencyUnitInputFieldProps {
    readonly value: string;
    readonly onCurrencyUnitChange: (option: { readonly value: Currency }) => void;
}

interface TimeSpanInputFieldProps {
    readonly durationUnit?: string;
    readonly durationAmount?: number;
    readonly onDurationChange: ({ value }: { readonly value: Partial<Duration> }) => void;
}
export const CurrencyUnitInputField: React.FC<CurrencyUnitInputFieldProps> = ({ value, onCurrencyUnitChange }) => (
    <StyledFormInputWrapper label="Currency Unit">
        <Select
            onOptionSelected={onCurrencyUnitChange}
            options={getCurrencyOptions()}
            placeholder="Select"
            value={value}
        />
    </StyledFormInputWrapper>
);

export const TimeSpanInputField: React.FC<TimeSpanInputFieldProps> = ({
    durationUnit,
    durationAmount,
    onDurationChange,
}) => {
    const durationUnitOptions = Object.values(DurationUnit).map(duration => ({
        id: duration,
        value: duration,
        label: `${toReadable(duration)}s`,
    }));

    return (
        <SplitStyledFormInputWrapper label="Time Span">
            <div className="inner">
                <Select
                    onOptionSelected={option => onDurationChange({ value: { unit: option.value } })}
                    options={durationUnitOptions}
                    placeholder="Select Unit"
                    value={durationUnit as DurationUnit}
                />
                <Input
                    onChange={e => onDurationChange({ value: { amount: parseInt(e.target.value) } })}
                    placeholder="Amount"
                    value={durationAmount}
                />
            </div>
        </SplitStyledFormInputWrapper>
    );
};
