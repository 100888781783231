import * as sentry from '@sentry/react';
import { Route, Routes } from 'react-router-dom';
import { SuspendableContent } from './common/components/SuspendableContent';
import { CoveragesEdition } from './editions/coverage-editions/CoveragesEdition';
import { DigitalAgentEditionV2 } from './editions/digital-agent-edition-v2/DigitalAgentEditionV2';
import { RatingEdition } from './editions/rating-edition/RatingEdition';
import { UnderwritingFiltersEdition } from './editions/underwriting-filters-edition/UnderwritingFiltersEdition';
import { CoveragesAndSettingsRegistry } from './global-registry/coverages-registry/CoveragesAndSettingsRegistry';
import { UnderwritingRegistry } from './global-registry/underwriting-registry/UnderwritingRegistry';
import { ProductLayout } from './products/ProductMissionControl/ProductLayout';
import { Release } from './releases/Release';
import { LoCoProducts } from 'components/LoCo/products/LoCoProducts';
import { LoCoProductsMissionControl } from 'components/LoCo/products/ProductMissionControl/ProductMissionControl';

const SentryRoutes = sentry.withSentryReactRouterV6Routing(Routes);

export const LoCoRoutes: React.FC<React.PropsWithChildren<unknown>> = () => {
    return (
        <SentryRoutes>
            <Route element={<LoCoProducts />} path="/products" />
            <Route element={<ProductLayout />} path="/products/:productCode/">
                {['/products/:productCode/', '/products/:productCode/*'].map(path => (
                    <Route element={<LoCoProductsMissionControl />} key={path} path={path} />
                ))}

                <Route
                    element={
                        <SuspendableContent>
                            <RatingEdition />
                        </SuspendableContent>
                    }
                    path="/products/:productCode/rating/:ratingEditionCode/*"
                />
                <Route element={<CoveragesEdition />} path="/products/:productCode/coverages/:coveragesEditionCode" />
                <Route
                    element={
                        <SuspendableContent>
                            <DigitalAgentEditionV2 />
                        </SuspendableContent>
                    }
                    path="/products/:productCode/digital-agent/:digitalAgentEditionCode"
                />
                <Route
                    element={
                        <SuspendableContent>
                            <UnderwritingFiltersEdition />
                        </SuspendableContent>
                    }
                    path="/products/:productCode/underwriting-filters/:underwritingFiltersEditionCode"
                />
                <Route
                    element={
                        <SuspendableContent>
                            <Release />
                        </SuspendableContent>
                    }
                    path="/products/:productCode/releases/:releaseCode/*"
                />
            </Route>
            <Route
                element={
                    <SuspendableContent>
                        <CoveragesAndSettingsRegistry />
                    </SuspendableContent>
                }
                path="/coverages-registry/*"
            />
            <Route
                element={
                    <SuspendableContent>
                        <UnderwritingRegistry />
                    </SuspendableContent>
                }
                path="/underwriting-registry/*"
            />
        </SentryRoutes>
    );
};
