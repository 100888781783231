/* eslint-disable react/no-array-index-key */
import { ActionType } from '@lemonade-hq/typo';
import { clsx } from 'clsx';
import type { MouseEvent } from 'react';
import { forwardRef, useMemo } from 'react';
import type {
  Checkable,
  SortableSet,
  TableColumn,
  TableItem,
  Table as TableType,
} from '../../../../typo/src/components/ui/ui.types';
import { Layout } from '../../base/Layout/Layout';
import { errorText } from '../../theme/utils.css';
import { Button } from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import { Icon } from '../Icon/Icon';
import { cellStyles, headerWrapper, sortIcon, sortIconWrapper, tableStyles, tableWrapper } from './table.css';

// TODO: add table footer and pagination once design is ready

function isSimpleValue(value: TableItem['value']): value is number | string {
  return typeof value === 'string' || typeof value === 'number';
}

function getRowId(row: TableProps['data'][number], rowIdentifier: Checkable['rowIdentifier']): string {
  if (typeof row[rowIdentifier].value !== 'string') {
    throw new Error(`rowIdentifier's value must be a string`);
  }

  return row[rowIdentifier].value as string;
}

function getAllRowIds(checked: boolean, rowIdentifier: Checkable['rowIdentifier'], data: TableProps['data']): string[] {
  const rowIds = data.map(row => getRowId(row, rowIdentifier));

  return checked ? rowIds : [];
}

type TableProps = TableType['props'] & { readonly className?: string };

const Value: React.FC<TableItem> = props => {
  const { value, warning, action } = props;

  if (value == null) {
    return <>-</>;
  } else if (action?.type === ActionType.ButtonLink) {
    // having both url and onClick in order to allow CMD+clicking and opening the link in a different tab
    return (
      <a
        href={action.url}
        onClick={(e: MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          action.onClick();
        }}
        rel="noreferrer"
      >
        {value}
      </a>
    );
  } else if (action?.type === ActionType.Button) {
    return (
      <Button
        disabled={action.disabled}
        label={isSimpleValue(value) ? value.toString() : ''}
        onClick={action.onClick}
        variant="secondary"
      />
    );
  }

  if (warning) {
    return <span className={errorText}>{value}</span>;
  }

  return value;
};

const TableHead: React.FC<{
  readonly data: TableProps['data'];
  readonly cols: TableColumn[];
  readonly sortableSet?: SortableSet;
  readonly checkable?: Checkable;
}> = ({ cols, sortableSet, checkable, data }) => {
  return (
    <thead>
      <tr>
        {checkable != null && (
          <Layout as="th" className={cellStyles({ type: 'isHeader' })}>
            <Checkbox
              checked={checkable.checkedIds.length === data.length}
              onCheckedChange={(checked: boolean) =>
                checkable.onAllChecked(getAllRowIds(checked, checkable.rowIdentifier, data))
              }
              variant="brand"
            />
          </Layout>
        )}
        {cols.map(({ name, sortable, key, hidden, ...restProps }) => {
          const sortedBy = Boolean(sortable) && sortableSet?.sortingBy === key;
          const isSortable = sortableSet && sortable;

          if (hidden === true) {
            return null;
          }

          return (
            <Layout
              as="th"
              className={cellStyles({ type: 'isHeader', isSortable })}
              key={key}
              {...restProps}
              aria-sort={sortedBy ? (sortableSet.sortDirection === 'asc' ? 'ascending' : 'descending') : undefined}
              onClick={isSortable ? () => sortableSet.sort(key) : undefined}
            >
              {name}
              {isSortable === true && (
                <Layout className={clsx(sortIconWrapper, sortedBy ? sortableSet.sortDirection : undefined)}>
                  <Icon
                    className={sortIcon({
                      selected: sortedBy,
                      direction: sortedBy ? sortableSet.sortDirection : undefined,
                    })}
                    name="chevron-down"
                  />
                </Layout>
              )}
            </Layout>
          );
        })}
      </tr>
    </thead>
  );
};

const TableBody: React.FC<{ readonly data: TableProps['data']; readonly checkable?: Checkable }> = ({
  data,
  checkable,
}) => {
  const rows = useMemo(() => {
    return data.map((row, index) => (
      <tr id={`row_${index}`} key={`row_${index}`}>
        {checkable != null && (
          <Layout as="td" className={cellStyles()}>
            <Checkbox
              checked={checkable.checkedIds.includes(getRowId(row, checkable.rowIdentifier))}
              onCheckedChange={() => checkable.onRowChecked(getRowId(row, checkable.rowIdentifier))}
              variant="brand"
            />
          </Layout>
        )}
        {Object.values(row).map((cell, idx) => {
          if (cell.hidden === true) {
            return null;
          }

          return (
            <Layout
              as="td"
              className={cellStyles({ type: index === data.length - 1 ? 'isLastRowCell' : undefined })}
              key={`row_${index}_cell_${idx}`}
            >
              <Value {...cell} />
            </Layout>
          );
        })}
      </tr>
    ));
  }, [checkable, data]);

  return <tbody>{rows}</tbody>;
};

export const Table = forwardRef<HTMLTableElement, TableProps>(
  ({ data, columns, className: externalClassName, header, sortableSet, checkable }, ref) => {
    const withHeaderComponent = Boolean(header);

    return (
      <div className={tableWrapper}>
        {withHeaderComponent && <div className={headerWrapper}>{header}</div>}
        <Layout as="table" className={clsx(externalClassName, tableStyles)} ref={ref}>
          <TableHead checkable={checkable} cols={columns} data={data} sortableSet={sortableSet} />
          <TableBody checkable={checkable} data={data} />
        </Layout>
      </div>
    );
  },
);
