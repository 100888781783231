import { createContext } from 'react';
import type { CoinsuranceVariant, DurationUnit } from 'models/LoCo/Insurance/CoveragesEdition';
import { ValueType } from 'models/LoCo/Insurance/CoveragesEdition';
import type { SettingType } from 'models/LoCo/Insurance/SettingType';

// Values
export type SingleValueState = {
    readonly value: string;
    readonly isValid: boolean;
    readonly guid: string;
};

export type ListValuesState = {
    readonly type: ValueType.List;
    readonly values: SingleValueState[];
    readonly includeUnlimited: boolean;
};

export type RangeValuesState = {
    readonly type: ValueType.Range;
    readonly minValue: string;
    readonly maxValue: string;
    readonly interval: string;
    readonly isValid: boolean;
};

export type ValuesStates = ListValuesState | RangeValuesState;

// Attributes

export type AttributesStateBase<T extends SettingType> = {
    readonly type: T;
    readonly isValid: boolean;
};

export type DeductibleLimitAttributesState<T extends SettingType> = AttributesStateBase<T> & {
    readonly scope: {
        readonly type: string;
        readonly name?: string; // external entity name
        readonly insuredEntityCode?: string;
    };
    readonly valueSelectionMethod: string;
    readonly currencyUnit: string;
    readonly duration: {
        readonly type: string;
        readonly amount?: number;
        readonly unit?: DurationUnit;
    };
};

export type DeductibleAttributesState = DeductibleLimitAttributesState<SettingType.Deductible>;

export type LimitAttributesState = DeductibleLimitAttributesState<SettingType.Limit> & {
    readonly unit: string;
    readonly parentLimitTemplateCode?: string;
    readonly timeSpan?: {
        readonly amount: number;
        readonly unit: DurationUnit;
    };
};

export type WaitingPeriodAttributesState = AttributesStateBase<SettingType.WaitingPeriod> & {
    readonly durationUnit: DurationUnit;
};

export type CoinsuranceAttributesState = AttributesStateBase<SettingType.Coinsurance> & {
    readonly variant: CoinsuranceVariant;
};

export type AttributesStates =
    | CoinsuranceAttributesState
    | DeductibleAttributesState
    | LimitAttributesState
    | WaitingPeriodAttributesState;

// Setting
export type SettingStateBase<TAttributes extends AttributesStates> = {
    readonly settingType: SettingType | null;
    readonly values: ValuesStates | null;
    readonly attributes: TAttributes | null;
};

export type SettingStates = SettingStateBase<AttributesStates>;

// Context
export const SettingContext = createContext<{
    readonly state: SettingStates | null;
    readonly dispatch: React.Dispatch<SettingAction>;
}>({ state: null, dispatch: () => {} });

export enum SettingActionType {
    SetValueType,
    SetRangeValues,
    SetListValues,
    SetDeductibleAttributes,
    SetLimitAttributes,
    SetWaitingPeriodAttributes,
    SetCoinsuranceAttributes,
    InitSetting,
}

type SetValueTypeAction = {
    readonly type: SettingActionType.SetValueType;
    readonly payload: { readonly valueType: ValueType };
};

type SetRangeValueAction = {
    readonly type: SettingActionType.SetRangeValues;
    readonly payload: RangeValuesState;
};

type SetListValueAction = {
    readonly type: SettingActionType.SetListValues;
    readonly payload: { readonly newValues: Partial<ListValuesState> };
};

type SetDeductibleAttributesAction = {
    readonly type: SettingActionType.SetDeductibleAttributes;
    readonly payload: { readonly newValues: Partial<DeductibleAttributesState> };
};

type SetLimitAttributesAction = {
    readonly type: SettingActionType.SetLimitAttributes;
    readonly payload: { readonly newValues: Partial<LimitAttributesState> };
};

type SetWaitingPeriodAttributesAction = {
    readonly type: SettingActionType.SetWaitingPeriodAttributes;
    readonly payload: { readonly newValues: Partial<WaitingPeriodAttributesState> };
};

type SetCoinsuranceAttributesAction = {
    readonly type: SettingActionType.SetCoinsuranceAttributes;
    readonly payload: { readonly newValues: Partial<CoinsuranceAttributesState> };
};

type InitSettingAction = {
    readonly type: SettingActionType.InitSetting;
    readonly payload: { readonly settingType: SettingType };
};

export type SettingAction =
    | InitSettingAction
    | SetCoinsuranceAttributesAction
    | SetDeductibleAttributesAction
    | SetLimitAttributesAction
    | SetListValueAction
    | SetRangeValueAction
    | SetValueTypeAction
    | SetWaitingPeriodAttributesAction;

export function settingReducer(data: SettingStates, action: SettingAction): SettingStates {
    switch (action.type) {
        case SettingActionType.SetValueType: {
            // reset the data
            if (action.payload.valueType === ValueType.List) {
                return { ...data, values: { type: ValueType.List, values: [], includeUnlimited: false } };
            }

            return {
                ...data,
                values: {
                    isValid: false,
                    type: ValueType.Range,
                    minValue: '',
                    maxValue: '',
                    interval: '',
                },
            };
        }

        case SettingActionType.SetRangeValues: {
            return { ...data, values: action.payload };
        }

        case SettingActionType.SetListValues: {
            return {
                ...data,
                values: {
                    type: ValueType.List,
                    values: (data.values as ListValuesState | undefined)?.values ?? [],
                    includeUnlimited: (data.values as ListValuesState | undefined)?.includeUnlimited ?? false,
                    ...action.payload.newValues,
                },
            };
        }

        case SettingActionType.InitSetting: {
            return { values: null, attributes: null, settingType: action.payload.settingType };
        }

        case SettingActionType.SetDeductibleAttributes: {
            return {
                ...data,
                attributes: { ...(data.attributes as DeductibleAttributesState), ...action.payload.newValues },
            };
        }

        case SettingActionType.SetLimitAttributes: {
            return {
                ...data,
                attributes: { ...(data.attributes as LimitAttributesState), ...action.payload.newValues },
            };
        }

        case SettingActionType.SetWaitingPeriodAttributes: {
            return {
                ...data,
                attributes: { ...(data.attributes as WaitingPeriodAttributesState), ...action.payload.newValues },
            };
        }

        case SettingActionType.SetCoinsuranceAttributes: {
            return {
                ...data,
                attributes: { ...(data.attributes as CoinsuranceAttributesState), ...action.payload.newValues },
            };
        }

        default:
            return data;
    }
}
