/* eslint-disable react/no-array-index-key, @typescript-eslint/no-non-null-assertion */
import {
    Button,
    Card,
    Flex,
    Hr,
    IconButton,
    ListItemMenu,
    pageWrapper,
    spacing,
    Text,
    Tooltip,
} from '@lemonade-hq/blender-ui';
import { clsx } from 'clsx';
import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import type { FC } from 'react';
import { useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { NEWER_TOOL, OLDER_TOOL } from './compare/compare.helpers';
import { PlaygroundComparedToolContainer } from './compare/PlaygroundComparedToolContainer';
import { useComparedTools } from './compare/useComparedTools';
import { useComparePageNavigation } from './compare/useComparePageNavigation';
import { useToolsDiff } from './compare/useToolsDiff';
import { useToolsRevisionsHistory } from './compare/useToolsRevisionsHistory';
import {
    useGetPublishedToolsRevision,
    useGetToolsRevision,
    useListSunsettedToolsRevisions,
} from './persisted_tools.queries';
import { MISSING_TOOLS_REVISION_ID_MARKER, PLAYGROUND_FULL_PATH_PREFIX } from './shared/routing.consts';
import * as shimmeringStyles from './shared/shimmering.css';
import { getToolSideLabel } from './shared/tool.helpers';
import { useForceBlenderUI } from 'hooks/useForceBlenderUI';
import { useFullscreen } from 'hooks/useFullScreen';

export const PlaygroundComparePage: FC = () => {
    useFullscreen();
    useForceBlenderUI();
    const navigate = useNavigate();
    const {
        navigate: navigateToCompare,
        olderToolsRevisionPublicId,
        newerToolsRevisionPublicId,
    } = useComparePageNavigation();

    const requiresOlderToolsRevisionSelection = olderToolsRevisionPublicId === MISSING_TOOLS_REVISION_ID_MARKER;
    const requiresNewerToolsRevisionSelection = newerToolsRevisionPublicId === MISSING_TOOLS_REVISION_ID_MARKER;
    const requiresAnotherToolsRevisionSelection =
        requiresOlderToolsRevisionSelection || requiresNewerToolsRevisionSelection;
    const hasValidComparisonParams = !isEmpty(olderToolsRevisionPublicId) && !isEmpty(newerToolsRevisionPublicId);
    const specificToolsRevisionsQueriesEnabled = hasValidComparisonParams && !requiresAnotherToolsRevisionSelection;

    const {
        data: publishedToolsRevision,
        isPlaceholderData: isLoadingPublished,
        isRefetching: isRefetchingPublishedToolsRevision,
        refetch: refetchPublishedToolsRevision,
    } = useGetPublishedToolsRevision();
    const {
        data: sunsettedToolsRevisionsPages,
        isPlaceholderData: isLoadingSunsetted,
        isFetchingNextPage: isFetchingNextSunsettedToolsRevisions,
        fetchNextPage: fetchNextSunsettedToolsRevisions,
    } = useListSunsettedToolsRevisions();
    const sunsettedToolsRevisions = useMemo(
        () => sunsettedToolsRevisionsPages?.pages.flat() ?? [],
        [sunsettedToolsRevisionsPages]
    );
    const {
        data: preOlderToolsRevision,
        isPending: isLoadingOlder,
        isError: isOlderToolsRevisionError,
    } = useGetToolsRevision(olderToolsRevisionPublicId, {
        disablePlaceholder: true,
        enabled: specificToolsRevisionsQueriesEnabled,
        staleTime: Number.POSITIVE_INFINITY,
    });
    const {
        data: preNewerToolsRevision,
        isPending: isLoadingNewer,
        isError: isNewerToolsRevisionError,
    } = useGetToolsRevision(newerToolsRevisionPublicId, {
        disablePlaceholder: true,
        enabled: specificToolsRevisionsQueriesEnabled,
        staleTime: Number.POSITIVE_INFINITY,
    });

    const [olderToolsRevision, newerToolsRevision] = useMemo(() => {
        const beforeComparisonOlderToolsRevision = requiresOlderToolsRevisionSelection
            ? undefined
            : preOlderToolsRevision;
        const beforeComparisonNewerToolsRevision = requiresNewerToolsRevisionSelection
            ? undefined
            : preNewerToolsRevision;

        if (beforeComparisonOlderToolsRevision == null) return [undefined, beforeComparisonNewerToolsRevision];

        return orderBy([beforeComparisonOlderToolsRevision, beforeComparisonNewerToolsRevision], 'publishedAt', 'asc');
    }, [
        preNewerToolsRevision,
        preOlderToolsRevision,
        requiresNewerToolsRevisionSelection,
        requiresOlderToolsRevisionSelection,
    ]);

    const { olderTool, newerTool, calculatedToolsStatuses, selectedToolName } = useComparedTools({
        olderToolsRevision,
        newerToolsRevision,
    });

    useEffect(() => {
        // if has no comparison params, or loading one of the compared tools revision fails, default to the published one and its parent
        const hasLoadingError = isOlderToolsRevisionError || isNewerToolsRevisionError;
        if (!isLoadingPublished && !isLoadingSunsetted && (hasLoadingError || !hasValidComparisonParams)) {
            navigateToCompare(sunsettedToolsRevisions[0].publicId, publishedToolsRevision!.publicId);
        }
    }, [
        hasValidComparisonParams,
        navigateToCompare,
        isLoadingPublished,
        isLoadingSunsetted,
        isNewerToolsRevisionError,
        isOlderToolsRevisionError,
        publishedToolsRevision,
        sunsettedToolsRevisions,
    ]);

    const isLoadingList = isLoadingPublished || isLoadingSunsetted;
    const isLoadingComparedTools = (isLoadingOlder || isLoadingNewer) && !requiresAnotherToolsRevisionSelection;

    const { toolsRevisionHistoryForListMenu, setToolsRevisionRange } = useToolsRevisionsHistory({
        olderToolsRevisionPublicId,
        olderToolsRevision,
        newerToolsRevisionPublicId,
        newerToolsRevision,
        publishedToolsRevision,
        sunsettedToolsRevisions,
    });

    const isDiffDisabled =
        isLoadingList || isLoadingComparedTools || olderToolsRevision == null || newerToolsRevision == null;
    const { diff } = useToolsDiff(olderTool, newerTool, { disabled: isDiffDisabled });

    const handleLoadMore = useCallback(() => {
        void fetchNextSunsettedToolsRevisions();
        void refetchPublishedToolsRevision();
    }, [fetchNextSunsettedToolsRevisions, refetchPublishedToolsRevision]);

    return (
        <Flex className={pageWrapper} height="100%" padding={spacing.s24}>
            <Card
                display="flex"
                flexDirection="column"
                gap={spacing.s16}
                justifyContent="space-between"
                overflow="hidden"
                padding={spacing.s16}
            >
                <Flex alignItems="center" gap={spacing.s10} role="status">
                    <Tooltip content="Back to playground index" side="bottom">
                        <IconButton
                            aria-label="back"
                            icon="chevron-down"
                            onClick={() => navigate(PLAYGROUND_FULL_PATH_PREFIX)}
                            rotation="cw90deg"
                            size="md"
                            variant="inline"
                        />
                    </Tooltip>
                    <Flex flexDirection="column" gap={spacing.s02}>
                        <Text type="h5">Revisions history</Text>
                    </Flex>
                </Flex>
                <Card
                    display="flex"
                    flexGrow={1}
                    gap={spacing.s12}
                    overflow="hidden"
                    padding={spacing.s12}
                    variant="tertiary"
                >
                    <Flex flexDirection="column" flexShrink={0} width="25rem">
                        <Flex
                            className={clsx({
                                [shimmeringStyles.shimmering]: isLoadingList,
                            })}
                            flexDirection="column"
                            flexShrink={0}
                            gap={spacing.s06}
                            maxHeight="60%"
                        >
                            <ListItemMenu
                                asTimeline
                                mode="multiple"
                                onSelect={setToolsRevisionRange}
                                sections={toolsRevisionHistoryForListMenu}
                                selection={
                                    [olderToolsRevisionPublicId, newerToolsRevisionPublicId].filter(Boolean) as string[]
                                }
                            />
                            <Button
                                label="Load earlier revisions"
                                loading={isFetchingNextSunsettedToolsRevisions || isRefetchingPublishedToolsRevision}
                                onClick={handleLoadMore}
                                startIcon="refresh"
                                variant="secondary"
                            />
                        </Flex>
                        <Hr />
                        <Flex
                            className={clsx({
                                [shimmeringStyles.shimmering]: isLoadingNewer || isLoadingOlder,
                            })}
                            flexDirection="column"
                            gap={spacing.s06}
                            maxHeight="40%"
                        >
                            <ListItemMenu
                                onSelect={val =>
                                    navigateToCompare(olderToolsRevisionPublicId, newerToolsRevisionPublicId, val)
                                }
                                preventDeselection
                                sections={[
                                    {
                                        label: 'Changed tools',
                                        options: calculatedToolsStatuses.map(t => ({
                                            id: t.name,
                                            label: t.name,
                                            sideLabel: getToolSideLabel(t.changeStatus),
                                        })),
                                    },
                                ]}
                                selection={selectedToolName}
                            />
                        </Flex>
                    </Flex>
                    <PlaygroundComparedToolContainer
                        comparisonType={OLDER_TOOL}
                        counterpartToolsRevision={newerToolsRevision}
                        diff={diff}
                        isLoading={isLoadingOlder && !requiresOlderToolsRevisionSelection}
                        isLoadingCounterpart={isLoadingNewer && !requiresNewerToolsRevisionSelection}
                        tool={olderTool}
                        toolsRevision={olderToolsRevision}
                    />
                    <PlaygroundComparedToolContainer
                        comparisonType={NEWER_TOOL}
                        counterpartToolsRevision={olderToolsRevision}
                        diff={diff}
                        isLoading={isLoadingNewer && !requiresNewerToolsRevisionSelection}
                        isLoadingCounterpart={isLoadingOlder && !requiresOlderToolsRevisionSelection}
                        tool={newerTool}
                        toolsRevision={newerToolsRevision}
                    />
                </Card>
            </Card>
        </Flex>
    );
};
