import { formatCurrency } from '@lemonade-hq/lemonation';
import type { Currency } from '@lemonade-hq/lemonation';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { formatNumericField } from '../../helpers/inputHelpers';
import { StackedValues } from 'components/LoCo/LoCoPagesSharedStyles';
import type { RangeValue, SettingInstance, Values } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingUnit, ValueType } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

function formatValue(value: number, valueSuffix: string | undefined, currency: string | undefined): string {
    if (isDefined(valueSuffix)) return `${value}${valueSuffix}`;
    if (isDefined(currency)) return formatCurrency(value, { currency: currency.toUpperCase() as Currency });

    return formatNumericField(value.toString());
}

export function formatSettingValuesList(
    valueList: number[],
    includeUnlimited: boolean,
    valueSuffix: string | undefined,
    currency: string | undefined
): string {
    return (
        [...valueList]
            .sort((a, b) => a - b)
            .map(value => formatValue(value, valueSuffix, currency))
            .join(', ') + (includeUnlimited ? ', Unlimited' : '')
    );
}

export function formatSettingValuesRange(
    rangeValues: RangeValue,
    valueSuffix: string | undefined,
    currency: string | undefined
): string[] {
    const { min, max, step } = rangeValues;

    return [
        `Interval: ${formatValue(step, valueSuffix, currency)}`,
        `Minimum: ${formatValue(min, valueSuffix, currency)}`,
        `Maximum: ${formatValue(max, valueSuffix, currency)}`,
    ];
}

export const SettingValues: React.FC<{
    readonly type: SettingType;
    readonly values: Values;
    readonly currencyUnit?: Currency | undefined;
    readonly unit?: SettingUnit;
}> = ({ type, values, currencyUnit, unit }) => {
    let currency: Currency | undefined;
    let valueSuffix: string | undefined;

    switch (type) {
        case SettingType.Limit: {
            if (unit === SettingUnit.ClaimLossPercentage) {
                valueSuffix = '%';
            }

            break;
        }

        case SettingType.Deductible:
            currency = currencyUnit;
            break;

        case SettingType.Coinsurance:
            valueSuffix = '%';
            break;
        case SettingType.WaitingPeriod:
        default:
            break;
    }

    if (values.type === ValueType.List) {
        return <span>{formatSettingValuesList(values.values, values.includeUnlimited, valueSuffix, currency)}</span>;
    }

    return <StackedValues values={formatSettingValuesRange(values, valueSuffix, currency)} />;
};

export const SettingInstanceValues: React.FC<{ readonly settingInstance: SettingInstance }> = ({ settingInstance }) => {
    return (
        <SettingValues
            currencyUnit={
                settingInstance.type === SettingType.Limit || settingInstance.type === SettingType.Deductible
                    ? settingInstance.currencyUnit
                    : undefined
            }
            type={settingInstance.type}
            unit={settingInstance.type === SettingType.Limit ? settingInstance.unit : undefined}
            values={settingInstance.values}
        />
    );
};
