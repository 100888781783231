import {
    Button,
    Card,
    Flex,
    Input,
    pageWrapper,
    spacing,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from '@lemonade-hq/blender-ui';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { clsx } from 'clsx';
import { useState } from 'react';
import type { FC } from 'react';
import { useGallery } from '../AttachmentGallery/GalleryProvider';
import { AttachmentsProvider } from '../context';
import { useGetAttachments } from '../AttachmentsQueries';
import type { AttachmentDTO } from '../types';
import { ActionButtons } from './ActionButtons';
import { AttachmentActions } from './AttachmentActions';
import { Filters } from './filters';
import { ActiveAttachmentsTable } from './Tables/ActiveAttachmentsTable';
import { ArchivedAttachmentsTable } from './Tables/ArchivedAttachmentsTable';
import { UnreviewedAttachmentsTable } from './Tables/UnreviewedAttachmentsTable';

// TODO: Archived should show only if there are archived attachments
// TODO: Unreviewed should show only if there are unreviewed attachments
// TODO: Add query params to GET attachments

interface AttachmentHubProps {
    readonly entityType: EntityTypes;
    readonly entityId: string;
    readonly className?: string;
}

export const AttachmentHub: FC<AttachmentHubProps> = ({ entityId, entityType, className }) => {
    const { openGallery } = useGallery();
    const { data } = useGetAttachments({ entityType: entityType, entityPublicId: entityId });
    const [showFilters, setShowFilters] = useState(false);
    const [actionProps, setActionProps] = useState<{ action: string; attachments: AttachmentDTO[] } | undefined>();

    if (!data) return null;

    const handleClick = (index: number): void => {
        openGallery({ index, entityType, entityPublicId: entityId });
    };

    const handleFilterClick = (): void => {
        setShowFilters(!showFilters);
    };

    const openAddDialog = (): void => {
        // open add dialog
        console.log('open add dialog');
    };

    return (
        <AttachmentsProvider attachments={data} entityPublicId={entityId} entityType={entityType}>
            <Card className={clsx(pageWrapper, className)}>
                <Card padding={0}>
                    <Tabs size="md" variant="fill">
                        <Flex alignItems="center" justifyContent="space-between" p={spacing.s12}>
                            <TabList>
                                <Tab count={data.length}>Unreviewed</Tab>
                                <Tab count={data.length}>Active</Tab>
                                <Tab count={data.length}>Archived</Tab>
                            </TabList>
                            <Flex gap={spacing.s08}>
                                <Input icon="search-solid" placeholder="Search..." />
                                <Button
                                    label="Filters"
                                    onClick={handleFilterClick}
                                    startIcon="filter-lines"
                                    variant="secondary"
                                />
                                <Button label="Add" onClick={openAddDialog} startIcon="plus-solid" variant="primary" />
                            </Flex>
                        </Flex>
                        {showFilters && <Filters />}
                        <TabPanels>
                            <TabPanel>
                                <UnreviewedAttachmentsTable handleClick={handleClick} setActionProps={setActionProps} />
                            </TabPanel>
                            <TabPanel>
                                <ActiveAttachmentsTable handleClick={handleClick} setActionProps={setActionProps} />
                            </TabPanel>
                            <TabPanel>
                                <ArchivedAttachmentsTable handleClick={handleClick} setActionProps={setActionProps} />
                            </TabPanel>
                        </TabPanels>
                    </Tabs>
                    <Flex alignItems="center" justifyContent="space-between" p={spacing.s12}>
                        <div>Pagination</div>
                        <ActionButtons />
                    </Flex>
                </Card>
            </Card>
            {actionProps != null && <AttachmentActions {...actionProps} onClose={() => setActionProps(undefined)} />}
        </AttachmentsProvider>
    );
};
