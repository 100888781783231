import { Icon, Tooltip } from '@lemonade-hq/blender-ui';
import { ActionsMenu } from '@lemonade-hq/bluis';
import { Flex, themedColor } from '@lemonade-hq/cdk';
import type { Dispatch, SetStateAction } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';

import { toReadable } from 'commons/StringUtils';
import { RuleRow } from 'components/LoCo/common/components/CoverageRules/CoverageRulesShared';
import { ExpendButton, ExpendedDetailsTable } from 'components/LoCo/common/components/GridTable/ExpandedDetailsTable';
import {
    getDurationTypeDisplayName,
    getInsuranceScopeTypeDisplayName,
} from 'components/LoCo/common/display-texts/setting-instance';
import type { SettingActionData } from 'components/LoCo/products/SharedTableConfig';
import { DialogType, menuItems, StyledActionsMenuWrapper } from 'components/LoCo/products/SharedTableConfig';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import type {
    CoveragesEdition,
    DeductibleSettingInstance,
    LimitSettingInstance,
    SettingInstance,
    SettingInstanceBase,
    Values,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { DurationType, InsuranceScopeType, ValueType } from 'models/LoCo/Insurance/CoveragesEdition';

export interface SettingInstancesTableProps<T extends SettingInstance> {
    readonly settingInstances: T[];
    readonly coveragesEdition: CoveragesEdition;
    readonly setDialog: Dispatch<SetStateAction<SettingActionData | null>>;
    readonly hideActions: boolean;
}

type Actions = DialogType.AddRule | DialogType.Edit | DialogType.Remove;

interface SettingsTableExpendedDetailsProps {
    readonly settingInstance: SettingInstanceBase;
    readonly editionCode: string;
    readonly hideActions: boolean;
}

export const SettingsTableExpendedDetails: React.FC<SettingsTableExpendedDetailsProps> = ({
    settingInstance,
    editionCode,
    hideActions,
}) => {
    const relatedCoverages =
        settingInstance.relatedCoverages.length > 0
            ? settingInstance.relatedCoverages.map(r => r.name).join(', ')
            : '-';

    return (
        <ExpendedDetailsTable
            rows={[
                [
                    { key: 'title', value: 'DESCRIPTION' },
                    { key: 'value', value: settingInstance.description },
                ],
                [
                    { key: 'title', value: 'RELATED COVERAGES' },
                    { key: 'value', value: relatedCoverages },
                ],
                ...settingInstance.relatedRules.map(rule => [
                    { key: 'title', value: 'RULE' },
                    {
                        key: 'value',
                        value: (
                            <RuleRow
                                editionCode={editionCode}
                                editionType={EditionType.Coverages}
                                hideActions={hideActions}
                                rule={rule}
                            />
                        ),
                    },
                ]),
            ]}
        />
    );
};

export const SubHeader = styled.span`
    color: ${themedColor('secondaryText')};
    font-size: 12px;
`;

export const SubHeaderWrapper = styled(Flex)`
    flex-direction: column;
    gap: 2px;
`;

const hasMultipleValues = (values: Values): boolean =>
    (values.type === ValueType.List && values.values.length > 1) || values.type === ValueType.Range;

export const SettingInstanceActions: React.FC<{
    readonly hideActions: boolean;
    readonly onActionRequested: (dialogType: DialogType.AddRule | DialogType.Edit | DialogType.Remove) => void;
    readonly instance: SettingInstance;
}> = ({ hideActions, onActionRequested, instance }) => {
    const actions = useMemo(() => {
        const items = [...menuItems];

        if (hasMultipleValues(instance.values)) {
            items.push({
                label: 'Add Filed Rule',
                value: DialogType.AddRule,
            });
        }

        return items;
    }, [instance.values]);

    return hideActions ? (
        <ExpendButton />
    ) : (
        <StyledActionsMenuWrapper>
            <ExpendButton />
            <ActionsMenu
                actions={actions}
                disabled={hideActions}
                onChange={value => onActionRequested(value as Actions)}
                type="dots"
            />
        </StyledActionsMenuWrapper>
    );
};

export const SettingInstanceDuration: React.FC<{
    readonly settingInstance: DeductibleSettingInstance | LimitSettingInstance;
}> = ({ settingInstance }) => {
    const hasSubHeader = settingInstance.duration.type === DurationType.Timespan;
    const { amount, unit } = settingInstance.duration;

    return (
        <SubHeaderWrapper>
            <>
                <span>{getDurationTypeDisplayName(settingInstance.duration.type)}</span>
                {hasSubHeader && (
                    <SubHeader style={{ color: 'gray' }}>
                        {`${amount} ${unit}${(amount ?? 0) > 1 ? 's' : ''}`}
                    </SubHeader>
                )}
            </>
        </SubHeaderWrapper>
    );
};

export function scopeDisplay(settingInstance: DeductibleSettingInstance | LimitSettingInstance): string {
    return settingInstance.scope.type === InsuranceScopeType.ExternalEntity
        ? `Per ${toReadable(settingInstance.scope.name)}`
        : getInsuranceScopeTypeDisplayName(settingInstance.scope.type);
}

export const SettingName: React.FC<{
    readonly settingInstance: SettingInstanceBase;
}> = ({ settingInstance }: { readonly settingInstance: SettingInstanceBase }) => {
    return (
        <Flex style={{ alignItems: 'center', gap: '4px' }}>
            <span>{settingInstance.name}</span>
            {settingInstance.relatedCoverages.length === 0 && (
                <Tooltip content={undefined} title={`Setting must be connected to at least one Coverage`}>
                    <Icon color="negative1" name="info-circle-solid" />
                </Tooltip>
            )}
        </Flex>
    );
};
