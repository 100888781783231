/* eslint-disable @lemonade-hq/base/favor-singular-enums */
import { useOptimisticMutation } from '@lemonade-hq/blender-ui';
import type { SelectOptionBase } from '@lemonade-hq/cdk';
import type { UseMutationResult, UseQueryResult } from '@tanstack/react-query';
import { useQuery } from '@tanstack/react-query';
import produce from 'immer';
import { getLenders } from '../APIs/LendersAPI';
import { HomeClaimQueryKeys } from './HomeClaimQueries';
import { usePessimisticMutationLegacy } from './MutationHooks';
import type { PremiumDiffData, SimulatePriceParams } from 'apis/Home/PolicyEndorsementsAPI';
import { cancel, create, simulatePrice, submitChanges } from 'apis/Home/PolicyEndorsementsAPI';
import { activateRenewalPolicy } from 'apis/Home/PolicyRenewal';
import type { AddedItem, approveValuableItem, deleteValuableItem } from 'apis/Home/PolicyValuableItemsAPI';
import {
    addValuableItems,
    calcValuableItemsPremium,
    cancelValuableItem,
    editValuableItem,
} from 'apis/Home/PolicyValuableItemsAPI';
import type { UpdateReviewAttributes } from 'apis/Home/UnderwritingReviewsAPI';
import { updateReview } from 'apis/Home/UnderwritingReviewsAPI';
import type { HomeClaimDataResponse } from 'apis/HomeClaimsAPI';
import type {
    BackdateCancellationData,
    CancelPolicyData,
    EditMortgageeAttributes,
    HomePoliciesSearchParams,
    HomePoliciesSearchParamsResponse,
    HomePoliciesSearchResponse,
    PaymentPlansData,
    ScheduleNonrenewalData,
    SchedulePolicyCancellation,
    UwCancellationPayload,
} from 'apis/HomePoliciesAPI';
import {
    addInterestedParty,
    addMortgage,
    addProtectiveDevice,
    backdateCancellation,
    cancelPolicy,
    createRenewal,
    editInterestedParty,
    editMortgagee,
    editVerifiedMortgagee,
    fetchHomePolicyData,
    fetchSearchParams,
    getAggregatedNotifications,
    getHeaderSettings,
    getPaymentPlans,
    getReplacementCostsCalc,
    policyNonrenewalRevert,
    processRenewalForNotice,
    removeInterestedParty,
    removeMortgagee,
    removeProtectiveDevice,
    removeSecondaryInsured,
    removeUserAdditionalContact,
    revertSchedulePolicyCancellation,
    scheduleNonrenewal,
    schedulePolicyCancellation,
    searchHomePolicies,
    setTestPolicy,
    updatePolicy,
    updatePropertyDetails,
    updateSecondaryInsured,
    uwCancellation,
    uwCancellationRevert,
} from 'apis/HomePoliciesAPI';
import { getValuableItemsRejectionReasons, rejectValuableItem } from 'apis/HomeValuableItemsAPI';
import type {
    DisputesDetails,
    HomePolicyData,
    HomePolicyMembers,
    LastActiveDunning,
    Lender,
    PolicyRatingAttribute,
    ReplacementCostData,
    SchoolAndPolicyHolderType,
} from 'models/HomePolicy';
import type { HeaderColumn } from 'models/HomeShared';
import type { Syndicate } from 'models/Syndicate';

export enum HomePolicyQueryKeys {
    GetPolicySummary = 'GET_POLICY_SUMMARY',
    GetPolicyDetails = 'GET_POLICY_DETAILS',
    GetPolicyChanges = 'GET_POLICY_CHANGES',
    GetPolicyCredits = 'GET_POLICY_CREDITS',
    GetPolicyMembers = 'GET_POLICY_MEMBERS',
    GetPolicyHouseholdMembers = 'GET_POLICY_HOUSEHOLD_MEMBERS',
    GetPolicyHeader = 'GET_POLICY_HEADER',
    GetPolicyHeaderActions = 'GET_POLICY_HEADER_ACTIONS',
    GetUser = 'GET_USER',
    GetUserSummary = 'GET_USER_SUMMARY',
    GetDeductibles = 'GET_DEDUCTIBLES',
    PaymentPlans = 'GET_PAYMENT_PLANS',
    GetLenders = 'GET_LENDERS',
    GetRatingLog = 'GET_RATING_LOG',
    GetNonrenewal = 'GET_NONRENEWAL',
    GetUwCancellation = 'GET_UW_CANCELLATION',
    GetCancellationReasons = 'GET_CANCELLATION_REASONS',
    GetMinMaxEffectiveDate = 'GET_MIN_MAX_EFFECTIVE_DATE',
    GetHomeOwnerReviewMetadata = 'GET_HOME_OWNERS_REVIEW_METADATA',
    GetReferrals = 'GET_REFERRALS',
    GetRatingData = 'GET_RATING_DATA',
    GetScheduledItems = 'GET_SCHEDULED_ITEMS',
    GetEndorsements = 'GET_ENDORSEMENTS',
    GetPolicyNotifications = 'GET_POLICY_NOTIFICATIONS',
    GetPolicyRenewal = 'GET_POLICY_RENEWAL',
    GetEndorsementPriceSimulation = 'GET_ENDORSEMENT_PRICE_SIMULATION',
    GetPoliciesListSearchFilters = 'GET_POLICIES_LIST_SEARCH_FILTERS',
    GetPolicyList = 'GET_POLICY_LIST',
    GetPolicySyndicate = 'GET_POLICY_SYNDICATE',
    GetDisputesDetails = 'GET_DISPUTES_DETAILS',
    GetLastActiveDunning = 'GET_LAST_ACTIVE_DUNNING',
    GetPolicyCancellationDetails = 'GET_POLICY_CANCELLATION_DETAILS',
    GetHeaderSettings = 'GET_POLICIES_HEADER_SETTINGS',
    GetPolicyPaymentDetails = 'GET_POLICY_PAYMENT_DETAILS',
    GetReplacementCostCalc = 'GET_REPLACEMENT_COSTS_CALC',
    GetCertificateDiyLink = 'GET_CERTIFICATE_DIY_LINK',
}

export type HomePolicyRes = Pick<
    HomePolicyData,
    | 'additional_policyholders'
    | 'cancellation_reasons'
    | 'coverages'
    | 'declaration_page_pdf'
    | 'deductible_options'
    | 'disputes_details'
    | 'endorsements'
    | 'homeowners_review_permissions'
    | 'last_active_dunning'
    | 'members'
    | 'min_max_effective_date'
    | 'nonrenewal'
    | 'page_layout'
    | 'payments_info'
    | 'permissions'
    | 'policy_actions'
    | 'policy_cancellation_details'
    | 'policy_changes'
    | 'policy_credits'
    | 'policy_en_pdf'
    | 'policy_payment_actions'
    | 'policy_payment_details'
    | 'policy_pdf'
    | 'policy_summary'
    | 'policy'
    | 'protective_devices'
    | 'rating_data'
    | 'rating_log'
    | 'referrals'
    | 'renewal_details'
    | 'scheduled_items'
    | 'user_summary'
    | 'user'
    | 'uw_cancellation'
>;

async function getHomePolicyUser(id: string) {
    return await fetchHomePolicyData(id, ['user']);
}

async function getHomePolicyUserSummary(id: string) {
    return await fetchHomePolicyData(id, ['user_summary']);
}

async function getPaymentPlansData(id: string) {
    return await getPaymentPlans(id);
}

export async function getHomePolicySummary(id: string) {
    return await fetchHomePolicyData(id, ['policy_summary']);
}

async function getRatingLog(id: string) {
    return await fetchHomePolicyData(id, ['rating_log']);
}

async function getHomeOwnerReviewMetadata(id: string) {
    return await fetchHomePolicyData(id, [
        'policy_summary',
        'user_summary',
        'uw_cancellation',
        'homeowners_review_permissions',
    ]);
}

async function getHomePolicyHeader(id: string) {
    return await fetchHomePolicyData(id, ['policy_summary', 'user_summary', 'page_layout']);
}

async function getHomePolicyHeaderActions(id: string) {
    return await fetchHomePolicyData(id, ['policy_actions']);
}

async function geHomePolicyRenewal(renewalId: string) {
    return await fetchHomePolicyData(renewalId, ['policy', 'payments_info', 'renewal_details']);
}

async function getDeductibleOptions(id: string) {
    return await fetchHomePolicyData(id, ['deductible_options']);
}

async function getNonrenewal(id: string) {
    return await fetchHomePolicyData(id, ['nonrenewal']);
}

async function getUwCancellation(id: string) {
    return await fetchHomePolicyData(id, ['uw_cancellation']);
}

async function getCancellationReasons(id: string) {
    return await fetchHomePolicyData(id, ['cancellation_reasons']);
}

async function getHomePolicyLenders(status: string) {
    return await getLenders(status);
}

async function getMinMaxEffectiveDate(id: string) {
    return await fetchHomePolicyData(id, ['min_max_effective_date']);
}

async function getScheduledItems(id: string) {
    return await fetchHomePolicyData(id, ['scheduled_items']);
}

async function getEndorsements(id: string) {
    return await fetchHomePolicyData(id, ['endorsements']);
}

async function getAdditionalPolicyholders(id: string) {
    return await fetchHomePolicyData(id, ['additional_policyholders']);
}

async function getReferrals(id: string) {
    return await fetchHomePolicyData(id, ['referrals']);
}

async function getRatingData(id: string) {
    return await fetchHomePolicyData(id, ['rating_data']);
}

async function getSyndicate(id: string) {
    return await fetchHomePolicyData(id, ['syndicate']);
}

async function getDisputesDetails(id = '') {
    return await fetchHomePolicyData(id, ['disputes_details']);
}

async function getLastActiveDunning(id = '') {
    return await fetchHomePolicyData(id, ['last_active_dunning']);
}

async function getPolicyCancellationDetails(id: string) {
    return await fetchHomePolicyData(id, ['policy_cancellation_details']);
}

async function getPolicyPaymentsDetails(policyId: string) {
    return await fetchHomePolicyData(policyId, ['policy_payment_details', 'policy_summary', 'policy_payment_actions']);
}

export function useHomePolicySummary(id: string): UseQueryResult<Pick<HomePolicyRes, 'policy_summary'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicySummary, id],
        queryFn: async () => await getHomePolicySummary(id),
        enabled: Boolean(id),
    });
}

export function useHomePolicyUser(id: string): UseQueryResult<Pick<HomePolicyRes, 'user'>> {
    return useQuery({ queryKey: [HomePolicyQueryKeys.GetUser, id], queryFn: async () => await getHomePolicyUser(id) });
}

export function useHomePolicyUserSummary(id: string): UseQueryResult<Pick<HomePolicyRes, 'user_summary'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetUserSummary, id],
        queryFn: async () => await getHomePolicyUserSummary(id),
        enabled: Boolean(id),
    });
}

export function useMinMaxEffectiveDate(id: string): UseQueryResult<Pick<HomePolicyRes, 'min_max_effective_date'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetMinMaxEffectiveDate, id],
        queryFn: async () => await getMinMaxEffectiveDate(id),
        enabled: Boolean(id),
    });
}

async function getHomePolicyDetails(id: string) {
    return await fetchHomePolicyData(id, ['policy', 'coverages', 'permissions']);
}

export function useHomePolicyDetailsTab(
    id: string
): UseQueryResult<Pick<HomePolicyRes, 'coverages' | 'permissions' | 'policy'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyDetails, id],
        queryFn: async () => await getHomePolicyDetails(id),
        enabled: Boolean(id),
    });
}

async function getHomePolicyChanges(id: string) {
    return await fetchHomePolicyData(id, ['policy_changes']);
}

export function useHomePolicyChanges(id: string): UseQueryResult<Pick<HomePolicyRes, 'policy_changes'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyChanges, id],
        queryFn: async () => await getHomePolicyChanges(id),
        enabled: Boolean(id),
    });
}

async function getHomePolicyCredits(id: string) {
    return await fetchHomePolicyData(id, ['policy_credits', 'protective_devices']);
}

export function useHomePolicyCredits(
    id: string
): UseQueryResult<Pick<HomePolicyRes, 'policy_credits' | 'protective_devices'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyCredits, id],
        queryFn: async () => await getHomePolicyCredits(id),
        enabled: Boolean(id),
    });
}

async function getHomePolicyMembers(id: string) {
    return await fetchHomePolicyData(id, ['members']);
}

export function useHomePolicyMembers(id: string): UseQueryResult<Pick<HomePolicyRes, 'members'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyMembers, id],
        queryFn: async () => await getHomePolicyMembers(id),
        enabled: Boolean(id),
    });
}

export function useAdditionalPolicyholders(
    id: string
): UseQueryResult<Pick<HomePolicyRes, 'additional_policyholders'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyHouseholdMembers, id],
        queryFn: async () => await getAdditionalPolicyholders(id),
        enabled: Boolean(id),
    });
}

export function useCertificateDiyLink(policyId: string): UseQueryResult<string> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetCertificateDiyLink, policyId],
        queryFn: async () => (await fetchHomePolicyData(policyId, ['certificate_diy_link'])).certificate_diy_link.url,
    });
}

export function useValuableItems(id: string): UseQueryResult<Pick<HomePolicyRes, 'scheduled_items'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetScheduledItems, id],
        queryFn: async () => await getScheduledItems(id),
    });
}

export function useEndorsements(id: string): UseQueryResult<Pick<HomePolicyRes, 'endorsements'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetEndorsements, id],
        queryFn: async () => await getEndorsements(id),
    });
}

export function useReferrals(id: string): UseQueryResult<Pick<HomePolicyRes, 'referrals'>> {
    return useQuery({ queryKey: [HomePolicyQueryKeys.GetReferrals, id], queryFn: async () => await getReferrals(id) });
}

export function useDisputesDetails(id?: string): UseQueryResult<DisputesDetails> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetDisputesDetails, id],
        queryFn: async () => (await getDisputesDetails(id)).disputes_details,
        enabled: Boolean(id),
    });
}

export function useRatingData(id: string, enabled = true): UseQueryResult<Pick<HomePolicyRes, 'rating_data'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetRatingData, id],
        queryFn: async () => await getRatingData(id),
        enabled: enabled && Boolean(id),
    });
}

export function useLastActiveDunning(id?: string): UseQueryResult<LastActiveDunning> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetLastActiveDunning, id],
        queryFn: async () => (await getLastActiveDunning(id)).last_active_dunning,
        enabled: Boolean(id),
    });
}

export function useHomePolicyHeader(
    id: string,
    { enabled = true } = {}
): UseQueryResult<Pick<HomePolicyRes, 'page_layout' | 'policy_summary' | 'user_summary'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyHeader, id],
        queryFn: async () => await getHomePolicyHeader(id),
        enabled,
    });
}

export function useHomePolicyHeaderActions(
    id: string,
    enabled = false
): UseQueryResult<Pick<HomePolicyRes, 'policy_actions'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyHeaderActions, id],
        queryFn: async () => await getHomePolicyHeaderActions(id),
        enabled: enabled && Boolean(id),
    });
}

export function useHomePolicyRenewal(
    id: string
): UseQueryResult<Pick<HomePolicyRes, 'payments_info' | 'policy_cancellation_details' | 'policy' | 'renewal_details'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyRenewal, id],
        queryFn: async () => await geHomePolicyRenewal(id),
        enabled: Boolean(id),
    });
}

export function useHomeOwnerReviewPolicyMetadata(
    id: string
): UseQueryResult<
    Pick<HomePolicyRes, 'homeowners_review_permissions' | 'policy_summary' | 'user_summary' | 'uw_cancellation'>
> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetHomeOwnerReviewMetadata, id],
        queryFn: async () => await getHomeOwnerReviewMetadata(id),
        enabled: Boolean(id),
    });
}

export function useDeductibleOptions(id: string): UseQueryResult<Pick<HomePolicyRes, 'deductible_options'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetDeductibles, id],
        queryFn: async () => await getDeductibleOptions(id),
    });
}

export function useLenders(status: string): UseQueryResult<Lender[]> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetLenders, status],
        queryFn: async () => await getHomePolicyLenders(status),
    });
}

export function useHomePaymentPlans(policyId: string): UseQueryResult<PaymentPlansData> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.PaymentPlans, policyId],
        queryFn: async () => await getPaymentPlansData(policyId),
        enabled: Boolean(policyId),
    });
}

export function useRatingLog(id: string): UseQueryResult<Pick<HomePolicyRes, 'rating_log'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetRatingLog, id],
        queryFn: async () => await getRatingLog(id),
        enabled: Boolean(id),
    });
}

export function useNonrenewal(policyId: string): UseQueryResult<Pick<HomePolicyRes, 'nonrenewal'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetNonrenewal, policyId],
        queryFn: async () => await getNonrenewal(policyId),
        enabled: Boolean(policyId),
    });
}

export function useHomeUwCancellationModal(policyId: string): UseQueryResult<Pick<HomePolicyRes, 'uw_cancellation'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetUwCancellation, policyId],
        queryFn: async () => await getUwCancellation(policyId),
        enabled: Boolean(policyId),
    });
}

export function useCancellationReasons(policyId: string): UseQueryResult<Pick<HomePolicyRes, 'cancellation_reasons'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetCancellationReasons, policyId],
        queryFn: async () => await getCancellationReasons(policyId),
        enabled: Boolean(policyId),
    });
}

function getHomePolicyStatusBasedKeys(policyId: string): [HomePolicyQueryKeys, string][] {
    return [
        [HomePolicyQueryKeys.GetPolicyHeader, policyId],
        [HomePolicyQueryKeys.GetPolicyHeaderActions, policyId],
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
    ];
}

function getHomePolicyPremiumBasedKeys(policyId: string): [HomePolicyQueryKeys, string][] {
    return [
        [HomePolicyQueryKeys.GetPolicyHeader, policyId],
        [HomePolicyQueryKeys.GetPolicyMembers, policyId],
        [HomePolicyQueryKeys.GetPolicyHeaderActions, policyId],
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
    ];
}

function getHomePolicyProtectiveDevicesKeys(policyId: string): [HomePolicyQueryKeys, string][] {
    return [
        [HomePolicyQueryKeys.GetPolicyHeader, policyId],
        [HomePolicyQueryKeys.GetPolicyHeaderActions, policyId],
        [HomePolicyQueryKeys.GetPolicyCredits, policyId],
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
    ];
}

export function getHomePolicyAddressBasedKeys(policyId: string): [HomePolicyQueryKeys, string][] {
    return [
        [HomePolicyQueryKeys.GetPolicyHeader, policyId],
        [HomePolicyQueryKeys.GetPolicyHeaderActions, policyId],
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
    ];
}

function getHomePolicyEndorsementKeys(policyId: string): [HomePolicyQueryKeys, string][] {
    return [...getHomePolicyAddressBasedKeys(policyId), [HomePolicyQueryKeys.GetEndorsements, policyId]];
}

export function useTestPolicyMutation(
    policyId: string
): UseMutationResult<null, unknown, { readonly testPolicy: boolean; readonly policyId: string }, HomePolicyData> {
    const queryKey = [HomePolicyQueryKeys.GetPolicyDetails, policyId];

    function mutateTestPolicy({ data, variables }: { data: HomePolicyData; variables: { testPolicy: boolean } }) {
        const { testPolicy } = variables;

        return produce(data, draftData => {
            draftData.policy.testPolicy = testPolicy;
        });
    }

    return useOptimisticMutation<{ testPolicy: boolean; policyId: string }, HomePolicyData>({
        mutationFn: setTestPolicy,
        invalidateKeys: [queryKey],
        mutate: mutateTestPolicy,
        mutateKey: queryKey,
    });
}

export function useUpdatePolicyMutation(
    policyId: string
): UseMutationResult<
    null,
    unknown,
    { readonly value: number | string; readonly attr: string; readonly id: number | string },
    null
> {
    return usePessimisticMutationLegacy(updatePolicy, getHomePolicyPremiumBasedKeys(policyId));
}

export function useUpdatePropertyDetailsMutation({
    policyId,
    onSuccess,
}: {
    readonly policyId: string;
    readonly onSuccess: () => void;
}): UseMutationResult<
    null,
    unknown,
    {
        readonly policyId: string;
        readonly updatedAttributes: Partial<Record<PolicyRatingAttribute, string[] | boolean | number | string>>;
    },
    null
> {
    return usePessimisticMutationLegacy(
        updatePropertyDetails,
        [[HomePolicyQueryKeys.GetRatingData, policyId]],
        onSuccess
    );
}

export function useReplacementCostsCalc(
    policyId: string
): UseMutationResult<
    ReplacementCostData,
    unknown,
    Partial<Record<PolicyRatingAttribute, string[] | boolean | number | string>>,
    null
> {
    return usePessimisticMutationLegacy(
        async args => await getReplacementCostsCalc(policyId, args),
        [[HomePolicyQueryKeys.GetReplacementCostCalc, policyId]]
    );
}

export function useUpdateReviewStatusMutation(
    policyId: string
): UseMutationResult<null, unknown, UpdateReviewAttributes, null> {
    return usePessimisticMutationLegacy(updateReview, getHomePolicyStatusBasedKeys(policyId));
}

export function useAddProtectiveDeviceMutation(
    policyId: string
): UseMutationResult<
    null,
    unknown,
    { readonly policyId: string; readonly category: string; readonly type: string },
    null
> {
    return usePessimisticMutationLegacy(addProtectiveDevice, getHomePolicyProtectiveDevicesKeys(policyId));
}

export function useRemoveProtectiveDeviceMutation(
    policyId: string
): UseMutationResult<
    null,
    unknown,
    { readonly policyId: string; readonly category: string; readonly type: string },
    null
> {
    return usePessimisticMutationLegacy(removeProtectiveDevice, getHomePolicyProtectiveDevicesKeys(policyId));
}

export function useScheduleNonrenewal(
    policyId: string
): UseMutationResult<null, unknown, ScheduleNonrenewalData, null> {
    return usePessimisticMutationLegacy(scheduleNonrenewal, getHomePolicyStatusBasedKeys(policyId));
}

export function usePolicyNonrenewalRevert(
    policyId: string
): UseMutationResult<null, unknown, { readonly policyId: string }, null> {
    return usePessimisticMutationLegacy(policyNonrenewalRevert, getHomePolicyStatusBasedKeys(policyId));
}

export function useCreateRenewal(
    policyId: string
): UseMutationResult<null, unknown, { readonly policyId: string }, null> {
    return usePessimisticMutationLegacy(createRenewal, getHomePolicyStatusBasedKeys(policyId));
}

export function useRevertSchedulePolicyCancellation(
    policyId: string
): UseMutationResult<null, unknown, { readonly policyId: string }, null> {
    return usePessimisticMutationLegacy(revertSchedulePolicyCancellation, getHomePolicyStatusBasedKeys(policyId));
}

export function useCancelPolicy(
    policyId: string
): UseMutationResult<null, unknown, { readonly policyId: string; readonly data: CancelPolicyData }, null> {
    return usePessimisticMutationLegacy(cancelPolicy, getHomePolicyStatusBasedKeys(policyId));
}

export function useUwCancellation(policyId: string) {
    return usePessimisticMutationLegacy(
        async (payload: UwCancellationPayload) => await uwCancellation({ payload, policyId }),
        getHomePolicyStatusBasedKeys(policyId)
    );
}

export function useUwCancellationRevert(
    policyId: string
): UseMutationResult<null, unknown, { readonly policyId: string }, null> {
    return usePessimisticMutationLegacy(uwCancellationRevert, getHomePolicyStatusBasedKeys(policyId));
}

export function useSchedulePolicyCancellation(
    policyId: string
): UseMutationResult<null, unknown, { readonly policyId: string; readonly data: SchedulePolicyCancellation }, null> {
    return usePessimisticMutationLegacy(schedulePolicyCancellation, getHomePolicyStatusBasedKeys(policyId));
}

export function useBackdateCancellation(
    policyId: string
): UseMutationResult<
    null,
    unknown,
    { readonly policyId: string; readonly cancellationDetails: BackdateCancellationData },
    null
> {
    return usePessimisticMutationLegacy(backdateCancellation, getHomePolicyStatusBasedKeys(policyId));
}

export function useHomePolicyNotifications(policyId: string): UseQueryResult<Record<string, unknown>[]> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyNotifications, policyId],
        queryFn: async () => await getAggregatedNotifications(policyId),
        enabled: Boolean(policyId),
        refetchOnWindowFocus: false,
    });
}

export function useAddInterestedPartyMutation(
    policyId: string
): UseMutationResult<
    null,
    unknown,
    { readonly policyId: string; readonly name: string; readonly email: string; readonly address: string },
    null
> {
    return usePessimisticMutationLegacy(addInterestedParty, [
        [HomePolicyQueryKeys.GetEndorsements, policyId],
        [HomePolicyQueryKeys.GetPolicyMembers, policyId],
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
    ]);
}

export function useEditInterestedPartyMutation(policyId: string): UseMutationResult<
    null,
    unknown,
    {
        readonly policyId: string;
        readonly memberId: number;
        readonly name: string;
        readonly email: string;
        readonly address: string;
    },
    null
> {
    return usePessimisticMutationLegacy(editInterestedParty, [
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
        [HomePolicyQueryKeys.GetPolicyMembers, policyId],
    ]);
}

export function useRemoveInterestedPartyMutation(
    policyId: string
): UseMutationResult<
    null,
    unknown,
    { readonly id: number; readonly policyId: string; readonly requestedBy: string; readonly removalReason: string },
    null
> {
    return usePessimisticMutationLegacy(removeInterestedParty, [
        [HomePolicyQueryKeys.GetEndorsements, policyId],
        [HomePolicyQueryKeys.GetPolicyMembers, policyId][(HomePolicyQueryKeys.GetPolicyDetails, policyId)],
    ]);
}

export function useUpdateSecondaryInsuredMutation(
    policyId: string,
    claimId?: string
): UseMutationResult<
    null,
    unknown,
    { readonly policyId: string; readonly firstName: string; readonly lastName: string; readonly email: string },
    HomePolicyData
> {
    const queryKey = [
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
        [HomePolicyQueryKeys.GetPolicyMembers, policyId],
        ...[claimId ? [HomeClaimQueryKeys.GetClaimIncidentDetailsUserDetails, claimId] : []],
    ];

    function mutateUpdateSecondaryInsured({
        data,
        variables,
    }: {
        data: HomePolicyData;
        variables: { firstName: string; lastName: string; email: string };
    }) {
        const { firstName, lastName, email } = variables;

        return produce(data, draftData => {
            draftData.members.secondaryInsured = { firstName, lastName, email };
        });
    }

    return useOptimisticMutation<
        { policyId: string; firstName: string; lastName: string; email: string },
        HomePolicyData
    >({
        mutationFn: updateSecondaryInsured,
        invalidateKeys: [queryKey],
        mutate: mutateUpdateSecondaryInsured,
        mutateKey: queryKey,
    });
}

export function useRemoveSecondaryInsuredMutation(
    policyId: string,
    claimId?: string
): UseMutationResult<null, unknown, { readonly policyId: string }, HomePolicyMembers | Partial<HomeClaimDataResponse>> {
    const queryKey = [
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
        [HomePolicyQueryKeys.GetPolicyMembers, policyId],
        ...[claimId ? [HomeClaimQueryKeys.GetClaimIncidentDetailsUserDetails, claimId] : []],
    ];

    function mutateRemoveSecondaryInsured({ data }: { data: HomePolicyMembers }) {
        return produce(data, draftData => {
            draftData.secondaryInsured = null;
        });
    }

    return useOptimisticMutation<{ policyId: string }, HomePolicyMembers>({
        mutationFn: removeSecondaryInsured,
        mutateKey: queryKey,
        mutate: mutateRemoveSecondaryInsured,
        invalidateKeys: [queryKey],
    });
}

export function useRemoveAdditionalContactMutation(policyId: string): UseMutationResult<
    null,
    unknown,
    {
        readonly userPublicId: string;
        readonly contactPublicId: string;
    },
    HomePolicyMembers
> {
    const queryKey = [[HomePolicyQueryKeys.GetPolicyMembers, policyId]];

    function mutateRemoveAdditionalContact({ data }: { data: HomePolicyMembers }) {
        return produce(data, draftData => {
            draftData.userAdditionalContacts = null;
        });
    }

    return useOptimisticMutation<
        {
            readonly userPublicId: string;
            readonly contactPublicId: string;
        },
        HomePolicyMembers
    >({
        mutationFn: removeUserAdditionalContact,
        mutateKey: queryKey,
        mutate: mutateRemoveAdditionalContact,
        invalidateKeys: [queryKey],
    });
}

export function useAddMortgageMutation(
    policyId: string
): UseMutationResult<
    null,
    unknown,
    { readonly policyId: string; readonly attributes: EditMortgageeAttributes; readonly notifyLender: string },
    null
> {
    return usePessimisticMutationLegacy(addMortgage, [
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
        [HomePolicyQueryKeys.GetPolicyMembers, policyId],
    ]);
}

export function useEditMortgageeMutation(policyId: string): UseMutationResult<
    null,
    unknown,
    {
        readonly mortgageId: number;
        readonly policyId: string;
        readonly attributes: EditMortgageeAttributes;
        readonly notifyLender: string;
    },
    null
> {
    return usePessimisticMutationLegacy(editMortgagee, [
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
        [HomePolicyQueryKeys.GetPolicyMembers, policyId],
    ]);
}

export function useEditVerifiedMortgageeMutation(policyId: string): UseMutationResult<
    null,
    unknown,
    {
        readonly mortgageeId: number;
        readonly lenderId: number;
        readonly loanNumber: string;
        readonly notifyLender: string;
    },
    null
> {
    return usePessimisticMutationLegacy(editVerifiedMortgagee, [
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
        [HomePolicyQueryKeys.GetPolicyMembers, policyId],
    ]);
}

export function useRemoveMortgageeMutation(
    policyId: string
): UseMutationResult<null, unknown, { readonly mortgageeId: number; readonly policyId: string }, HomePolicyData> {
    const queryKey = [
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
        [HomePolicyQueryKeys.GetPolicyMembers, policyId],
    ];

    function mutateRemoveMortgagee({ data, variables }: { data: HomePolicyData; variables: { mortgageeId: number } }) {
        const { mortgageeId } = variables;

        return produce(data, draftData => {
            draftData.members.mortgagees = data.members.mortgagees.filter(mortgagee => mortgagee.id === mortgageeId);
        });
    }

    return useOptimisticMutation<{ mortgageeId: number; policyId: string }, HomePolicyData>({
        mutationFn: removeMortgagee,
        invalidateKeys: [queryKey],
        mutate: mutateRemoveMortgagee,
        mutateKey: queryKey,
    });
}

export function useCancelEndorsementMutation(
    policyId: string
): UseMutationResult<null, unknown, { readonly endorsementId: string }, null> {
    return usePessimisticMutationLegacy(cancel, getHomePolicyEndorsementKeys(policyId));
}

export function useCreateEndorsementMutation(
    policyId: string
): UseMutationResult<
    null,
    unknown,
    { readonly endorsement: { readonly [key: string]: string[] | boolean | number | string } },
    null
> {
    return usePessimisticMutationLegacy(create, getHomePolicyEndorsementKeys(policyId));
}

export function useSubmitChangesEndorsementMutation<ChangesType>(policyId: string): UseMutationResult<
    null,
    unknown,
    {
        readonly policyId: string;
        readonly changes: ChangesType;
        readonly type?: SchoolAndPolicyHolderType;
    },
    null
> {
    return usePessimisticMutationLegacy(submitChanges, getHomePolicyEndorsementKeys(policyId));
}

export function useSimulatePriceEndorsementMutation(
    policyId: string
): UseMutationResult<PremiumDiffData, unknown, SimulatePriceParams, null> {
    return usePessimisticMutationLegacy(simulatePrice, [[HomePolicyQueryKeys.GetEndorsementPriceSimulation, policyId]]);
}

export function useAddValuableItemMutation(
    policyId: string
): UseMutationResult<null, unknown, { readonly items: AddedItem[]; readonly policyId: string }, null> {
    return usePessimisticMutationLegacy(addValuableItems, [
        ...getHomePolicyAddressBasedKeys(policyId),
        [HomePolicyQueryKeys.GetScheduledItems, policyId],
    ]);
}

export function useEditValuableItemMutation(policyId: string): UseMutationResult<
    null,
    unknown,
    {
        readonly itemId: string;
        readonly data: {
            readonly title: string;
            readonly description: string;
            readonly value: string;
            readonly attachments: {
                readonly type: string;
                readonly url: string;
            }[];
        };
    },
    null
> {
    return usePessimisticMutationLegacy(editValuableItem, [
        ...getHomePolicyAddressBasedKeys(policyId),
        [HomePolicyQueryKeys.GetScheduledItems, policyId],
    ]);
}

export function useApproveDeleteValuableItemMutation(
    policyId: string,
    func: typeof approveValuableItem | typeof deleteValuableItem
): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutationLegacy(func, [
        ...getHomePolicyAddressBasedKeys(policyId),
        [HomePolicyQueryKeys.GetScheduledItems, policyId],
    ]);
}

export function useCancelValuableItemMutation(
    policyId: string
): UseMutationResult<void, unknown, { readonly itemId: string }, null> {
    return usePessimisticMutationLegacy(cancelValuableItem, [
        ...getHomePolicyAddressBasedKeys(policyId),
        [HomePolicyQueryKeys.GetScheduledItems, policyId],
    ]);
}

export function useActivateRenewalPolicyMutation(
    policyId: string,
    renewalPolicyId: string
): UseMutationResult<null, unknown, { readonly policyId: string }, null> {
    return usePessimisticMutationLegacy(activateRenewalPolicy, [
        [HomePolicyQueryKeys.GetPolicyHeader, policyId],
        [HomePolicyQueryKeys.GetPolicyHeaderActions, policyId],
        [HomePolicyQueryKeys.GetPolicyDetails, policyId],
        [HomePolicyQueryKeys.GetPolicyRenewal, renewalPolicyId],
    ]);
}

export function useProcessRenewalForNoticeMutation(
    renewalPolicyId: string
): UseMutationResult<void, unknown, string, null> {
    return usePessimisticMutationLegacy(processRenewalForNotice, [
        [HomePolicyQueryKeys.GetPolicyRenewal, renewalPolicyId],
    ]);
}

export function usePoliciesSearchFilters(): UseQueryResult<HomePoliciesSearchParamsResponse> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPoliciesListSearchFilters],
        queryFn: async () => await fetchSearchParams(),
    });
}

export function usePoliciesSearch(searchParams: HomePoliciesSearchParams): UseQueryResult<HomePoliciesSearchResponse> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyList, searchParams],
        queryFn: async () => await searchHomePolicies(searchParams),
    });
}

export function useSyndicate(policyId: string): UseQueryResult<Syndicate> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicySyndicate, policyId],
        queryFn: async () => (await getSyndicate(policyId)).syndicate,
    });
}

export function useRejectScheduledItemMutation(
    policyId: string
): UseMutationResult<
    void,
    unknown,
    { readonly itemId: string; readonly reason: string; readonly selectedOption: string },
    null
> {
    return usePessimisticMutationLegacy(
        async ({ itemId, reason, selectedOption }) => await rejectValuableItem(itemId, reason, selectedOption),
        [[HomePolicyQueryKeys.GetScheduledItems, policyId]]
    );
}

export function useRejectionReason(): UseQueryResult<{ readonly data: SelectOptionBase[] }> {
    return useQuery({ queryKey: ['valuableItemsRejectionReasons'], queryFn: getValuableItemsRejectionReasons });
}

export function useCalculateScheduledItems(
    policyId: string
): UseMutationResult<
    [{ readonly id: string; readonly premiumDisplay: string }],
    unknown,
    { readonly items: AddedItem[]; readonly policyId: string },
    null
> {
    return usePessimisticMutationLegacy(calcValuableItemsPremium, [[HomePolicyQueryKeys.GetScheduledItems, policyId]]);
}

export function usePolicyCancellationDetails(
    policyId: string
): UseQueryResult<Pick<HomePolicyRes, 'policy_cancellation_details'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyCancellationDetails, policyId],
        queryFn: async () => await getPolicyCancellationDetails(policyId),
    });
}

export function useHeaderSettings(): UseQueryResult<HeaderColumn[]> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetHeaderSettings],
        queryFn: async () => await getHeaderSettings(),
    });
}

export function useHomePolicyPaymentsDetails(
    policyId: string
): UseQueryResult<Pick<HomePolicyRes, 'policy_payment_actions' | 'policy_payment_details' | 'policy_summary'>> {
    return useQuery({
        queryKey: [HomePolicyQueryKeys.GetPolicyPaymentDetails, policyId],
        queryFn: async () => await getPolicyPaymentsDetails(policyId),
    });
}
