/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
import type { RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import type { SelectionMode } from '../../theme/selection';
import type { AssertedAccordionProps } from './adapters/Accordion/Accordion';
import { Accordion } from './adapters/Accordion/Accordion';
import type { AssertedCheckboxProps } from './adapters/Checkbox/Checkbox';
import { Checkbox } from './adapters/Checkbox/Checkbox';
import type { AssertedComboBoxProps } from './adapters/ComboBox/ComboBox';
import { ComboBox } from './adapters/ComboBox/ComboBox';
import type { AssertedDynamicListProps } from './adapters/DynamicList/DynamicList';
import { DynamicList } from './adapters/DynamicList/DynamicList';
import type { ErrorMessageProps } from './adapters/ErrorMessage/ErrorMessage';
import { ErrorMessage } from './adapters/ErrorMessage/ErrorMessage';
import type { AssertedInputProps } from './adapters/Input/Input';
import { Input } from './adapters/Input/Input';
import type { InputComponents, LabelInputErrorProps, LabelInputErrorPropsBase } from './adapters/InputGroup/InputGroup';
import { InputGroup } from './adapters/InputGroup/InputGroup';
import type { LabelProps } from './adapters/Label/Label';
import { Label } from './adapters/Label/Label';
import type { AssertedRadioProps } from './adapters/Radio/Radio';
import { Radio } from './adapters/Radio/Radio';
import type { AssertedSelectProps } from './adapters/Select/Select';
import { Select } from './adapters/Select/Select';
import type { AssertedTextAreaProps } from './adapters/TextArea/TextArea';
import { TextArea } from './adapters/TextArea/TextArea';

export function generateTypedFormComponents<TSchema extends RecordLikeShape>() {
  return {
    Select: Select as <TSchemaKey extends RecordPath<TSchema>, TMode extends SelectionMode = 'single'>(
      props: AssertedSelectProps<TSchema, TSchemaKey, TMode>,
    ) => JSX.Element,
    Checkbox: Checkbox as unknown as <TSchemaKey extends RecordPath<TSchema>>(
      props: AssertedCheckboxProps<TSchema, TSchemaKey>,
    ) => JSX.Element,
    Input: Input as <TSchemaKey extends RecordPath<TSchema>>(
      props: AssertedInputProps<TSchema, TSchemaKey>,
    ) => JSX.Element,
    TextArea: TextArea as <TSchemaKey extends RecordPath<TSchema>>(
      props: AssertedTextAreaProps<TSchema, TSchemaKey>,
    ) => JSX.Element,
    Radio: Radio as <TSchemaKey extends RecordPath<TSchema>>(
      props: AssertedRadioProps<TSchema, TSchemaKey>,
    ) => JSX.Element,
    ComboBox: ComboBox as <TSchemaKey extends RecordPath<TSchema>, TMode extends SelectionMode = 'single'>(
      props: AssertedComboBoxProps<TSchema, TSchemaKey, TMode>,
    ) => JSX.Element,
    Accordion: Accordion as <TSchemaKey extends RecordPath<TSchema>>(
      props: AssertedAccordionProps<TSchema, TSchemaKey>,
    ) => JSX.Element,
    DynamicList: DynamicList as <TSchemaKey extends RecordPath<TSchema>>(
      props: AssertedDynamicListProps<TSchema, TSchemaKey>,
    ) => JSX.Element,
    Label: Label as <TSchemaKey extends RecordPath<TSchema>>(props: LabelProps<TSchema, TSchemaKey>) => JSX.Element,
    ErrorMessage: ErrorMessage as <TSchemaKey extends RecordPath<TSchema>>(
      props: ErrorMessageProps<TSchema, TSchemaKey>,
    ) => JSX.Element,
    InputGroup: InputGroup as <
      TSchemaKey extends RecordPath<TSchema>,
      TComponentType extends keyof InputComponents<TSchema, TSchemaKey>,
      TMode extends SelectionMode = 'single',
    >(
      props: TMode extends 'single'
        ? LabelInputErrorProps<TSchema, TSchemaKey, TComponentType, TMode>
        : AssertedSelectProps<TSchema, TSchemaKey, TMode> &
            LabelInputErrorPropsBase<TSchema, TSchemaKey, TComponentType>,
    ) => JSX.Element,
  };
}
