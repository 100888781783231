import type { RowItem } from '@lemonade-hq/bluis';
import {
    EmptySection,
    ErrorSection,
    LoadingSection,
    MainButton,
    Table,
    TableHeader,
    TableRow,
    TableTitle,
} from '@lemonade-hq/bluis';
import { useCallback, useState } from 'react';
import { StyledSectionHeader } from '../../../LoCoPagesSharedStyles';
import { RegistryTemplateActions, TemplateAction } from '../RegistryActions';
import { ManageTemplateSettingDialog } from './ManageTemplateSettingDialog';
import { toReadable } from 'commons/StringUtils';
import { Paginator } from 'components/LoCo/common/components/Pagination/Paginator';
import type { SettingTemplate } from 'models/LoCo/Insurance/Registry';
import { useGetRegistrySettings } from 'queries/LoCo/Insurance/RegistryQueries';

const headers = [
    { key: 'settingName', value: 'setting name', width: 200 },
    { key: 'type', value: 'type', width: 100 },
    { key: 'description', value: 'description', width: 300 },
    { key: 'product lines', value: 'product lines', width: 200 },
    { key: 'actions', value: 'actions', width: 100 },
];

const mapSettingToRow = (setting: SettingTemplate, onAction: (action: TemplateAction) => void): RowItem[] => {
    return [
        { key: 'settingName', value: setting.name },
        { key: 'type', value: toReadable(setting.type) },
        { key: 'description', value: setting.description },
        { key: 'product lines', value: setting.productLines.map(productLine => productLine.name).join(', ') },
        {
            key: 'actions',
            value: (
                <RegistryTemplateActions
                    copyText={setting.code}
                    entityName="Setting"
                    onACtion={action => onAction(action)}
                />
            ),
        },
    ];
};

export const SettingsRegistry: React.FC = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const { data, isPending: isLoading, isError } = useGetRegistrySettings({ page: pageNumber, size: 20 });

    const [addSettingDialogOpen, setAddSettingQuoteDialogOpen] = useState(false);
    const [selectedSetting, setSelectedSetting] = useState<SettingTemplate>();

    const onAction = useCallback((action: TemplateAction, setting: SettingTemplate) => {
        if (action === TemplateAction.Edit) {
            setAddSettingQuoteDialogOpen(true);
            setSelectedSetting(setting);
        }
    }, []);
    const openAddSettingDialog = useCallback(() => setAddSettingQuoteDialogOpen(true), [setAddSettingQuoteDialogOpen]);

    const closeAddSettingDialog = useCallback(() => {
        setAddSettingQuoteDialogOpen(false);
    }, [setAddSettingQuoteDialogOpen]);

    const onPageClick = (nextPage: number) => () => {
        setPageNumber(nextPage);
    };

    if (isLoading) {
        return <LoadingSection />;
    }

    if (!data || isError) {
        return <ErrorSection />;
    }

    const { data: settings, stats } = data;

    return (
        <>
            <StyledSectionHeader>
                <TableTitle title="Global Settings" />
                <MainButton onClick={openAddSettingDialog}>Add</MainButton>
            </StyledSectionHeader>
            {settings.length === 0 ? (
                <EmptySection>no settings defined</EmptySection>
            ) : (
                <Table>
                    <TableHeader headers={headers} />
                    {settings.map(setting => (
                        <TableRow
                            key={setting.code}
                            row={mapSettingToRow(setting, action => onAction(action, setting))}
                        />
                    ))}
                </Table>
            )}
            <Paginator currentPage={pageNumber} onPageClick={onPageClick} totalPages={stats.totalPages} />
            {addSettingDialogOpen && (
                <ManageTemplateSettingDialog onClose={closeAddSettingDialog} setting={selectedSetting} />
            )}
        </>
    );
};
