import { Input } from '@lemonade-hq/bluis';
import type { FormProps } from '@lemonade-hq/cdk';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import { isDefined } from '@lemonade-hq/ts-helpers';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import type { SingleValueState } from '../../SettingContext';
import { RemoveButton } from 'components/LoCo/common/components/RemoveButton';
import { formatNumericField, parseNumber, sanitizeValue } from 'components/LoCo/common/helpers/inputHelpers';
import { StyledFormInputWrapper } from 'components/LoCo/LoCoPagesSharedStyles';

const StyledWrapper = styled(StyledFormInputWrapper)`
    position: relative;
`;

interface SingleListValueProps {
    readonly guid: string;
    readonly value: string;
    readonly allValues: SingleValueState[];
    readonly showRemoveButton: boolean;
    readonly showErrors: boolean;
    readonly minLimit?: number;
    readonly maxLimit?: number;
    readonly onTextChange: () => void; // used for debouncing
    readonly onChange: (value: string, isValid: boolean, guid: string) => void; // used for real context update
    readonly onRemove: () => void;
}

export const SingleListValue: React.FC<SingleListValueProps> = ({
    value,
    guid,
    allValues,
    showRemoveButton,
    showErrors,
    minLimit,
    maxLimit,
    onTextChange,
    onChange,
    onRemove,
}) => {
    const otherValues = allValues.filter(val => val.guid !== guid).map(val => val.value);

    const form = {
        fields: {
            value: {
                startValue: value,
                validations: {
                    required: basicRequiredValidation,
                    isDuplicate: {
                        errorMessage: 'Value must be unique',
                        test: (value: string) => {
                            return !value.length || !otherValues.includes(value);
                        },
                    },
                    valueIsBetweenRangeLimitsValidation: {
                        errorMessage: `Value must be between ${minLimit} and ${maxLimit}`,
                        test: (value: string) => {
                            return (
                                (!isDefined(minLimit) || parseNumber(value) >= minLimit) &&
                                (!isDefined(maxLimit) || parseNumber(value) <= maxLimit)
                            );
                        },
                        skipCondition: values => {
                            return isEmpty(values.value);
                        },
                    },
                },
            },
        },
    } satisfies FormProps;

    const { values, setValue, errors, valid } = useForm(form);

    // update context only after form was updated (and not on field change events) to retrieve the full state (values and validity state)
    useEffect(() => {
        onChange(values.value, valid, guid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [values, valid]);

    const onInputChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue('value', sanitizeValue(e.target.value));
            onTextChange();
        },
        [onTextChange, setValue]
    );

    return (
        <StyledWrapper
            errorMessages={Object.values(errors.value ?? {})}
            key={`listValue_${guid}`}
            label=""
            showErrors={showErrors && !isEmpty(errors.value)}
        >
            <>
                <Input onChange={onInputChange} value={formatNumericField(values.value)} />
                {showRemoveButton && <RemoveButton onRemoveClick={onRemove} right="-10px" top="6px" />}
            </>
        </StyledWrapper>
    );
};
