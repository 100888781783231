export enum EditionType {
    Rating = 'rating',
    Coverages = 'coverages',
    DigitalAgent = 'digitalAgent',
    UnderwritingFilters = 'underwritingFilters',
}

export enum EditionStatus {
    Draft = 'Draft',
    Approved = 'Approved',
    Archived = 'Archived',
}

export interface Edition {
    readonly code: string;
    readonly addedBy: string;
    readonly addedAt: Date;
    readonly productCode: string;
    readonly description: string;
    readonly approvedBy?: string;
    readonly approvedAt?: Date;
    readonly archivedBy?: string;
    readonly archivedAt?: Date;
    readonly versionMajor?: number;
    readonly versionMinor?: number;
    readonly versionType?: VersionType;
    readonly baseEditionCode?: string;
    readonly version?: string;
    readonly addedByName: string;
    readonly friendlyName: string;
    readonly archivedByName?: string;
    readonly approvedByName?: string;
}

export interface ApprovedEdition extends Edition {
    readonly approvedBy: string;
    readonly approvedAt: Date;
    readonly approvedByName: string;

    readonly version: string;
    readonly versionMajor: number;
    readonly versionMinor: number;
    readonly versionType: VersionType;
}

export interface BaseEditionFilter {
    readonly approved?: boolean;
    readonly archived?: boolean;
    readonly maxDaysSinceArchived?: number;
}

export interface CreateEditionParams {
    readonly productCode: string;
    readonly description: string;
    readonly baseEditionCode?: string;
}

export type EditionWithType = Edition & { readonly type: EditionType };

export interface ArchiveEditionParams {
    readonly editionCode: string;
}

export enum VersionType {
    Major = 'major',
    Minor = 'minor',
    BugFix = 'bugfix',
}

export enum RuleLifecycleContext {
    NewBusiness = 'new-business',
    Renewal = 'renewal',
    PolicyEdits = 'policy-edits',
    Moving = 'moving',
}

export enum VersionTypeBlockReason {
    EditionApproved = 'EditionApproved',
    BaseEditionNotApproved = 'BaseEditionNotApproved',
    BaseNotLatestVersion = 'BaseNotLatestVersion',
    BaseNotLatestVersionForMajor = 'BaseNotLatestVersionForMajor',
    MissingBaseEdition = 'MissingBaseEdition',
    MissingPriorMajor = 'MissingPriorMajor',
}

export interface VersionTypeInfo {
    readonly type: VersionType;
    readonly allowed: boolean;
    readonly blockReasons: VersionTypeBlockReason[];
}

export interface ApprovalEligibility {
    readonly versionTypes: VersionTypeInfo[];
    readonly hasAvailableVersionType: boolean;
    readonly editionAlerts: EditionAlert[];
}

export enum EditionAlertLevel {
    Warning = 'warning',
    Info = 'info',
}

export enum EditionAlertType {
    EligibleToAllVersionTypes = 'eligible_to_all_version_types',
    AllVersionTypesBlocked = 'all_version_types_blocked',
    MajorVersionBlocked = 'major_version_blocked',
}

export interface EditionAlert {
    readonly type: EditionAlertType;
    readonly level: EditionAlertLevel;
}
