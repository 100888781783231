import { Checkbox, Label } from '@lemonade-hq/bluis';
import type { FormProps } from '@lemonade-hq/cdk';
import { basicRequiredValidation, Flex, themedColor, useForm } from '@lemonade-hq/cdk';
import { useCallback, useContext } from 'react';
import styled from 'styled-components';
import type { ListValuesState, SingleValueState } from '../../SettingContext';
import { SettingActionType, SettingContext } from '../../SettingContext';
import { AddItemButton } from './AddItemButton';
import { NoMaxLimitContainer } from './shared';
import { SingleListValue } from './SingleListValue';
import { useDebounce } from 'components/LoCo/common/hooks/useDebounce';
import { generateGUID } from 'components/LoCo/products/SharedTableConfig';
import { ValueType } from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

const StyledButtonWrapper = styled(Flex)`
    justify-content: flex-end;
    padding-right: 25px;
`;

const StyledButton = styled(Flex)`
    border: 1px dashed ${themedColor('separator')};
    border-radius: 4px;
    padding: 10px;
    cursor: pointer;
    margin-bottom: 20px;
    width: 300px;
`;

enum FieldType {
    IncludeUnlimited = 'includeUnlimited',
}

interface ListValuesProps {
    readonly minLimit?: number;
    readonly maxLimit?: number;
}

export const ListValues: React.FC<ListValuesProps> = ({ minLimit, maxLimit }) => {
    const { dispatch, state } = useContext(SettingContext);

    const { isReady: notDuringTyping, onEvent } = useDebounce(1000);

    const showNoMaxLimit = state?.settingType === SettingType.Limit;

    const form = {
        fields: {
            [FieldType.IncludeUnlimited]: {
                startValue: (state?.values as ListValuesState | undefined)?.[FieldType.IncludeUnlimited],
                validations: { required: basicRequiredValidation },
                skipValidationsCondition: () => !showNoMaxLimit,
            },
        },
    } satisfies FormProps;

    const { values, setValue } = useForm(form);

    const onUnlimitedChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setValue(FieldType.IncludeUnlimited, e.target.checked ?? false);

            dispatch({
                type: SettingActionType.SetListValues,
                payload: { newValues: { includeUnlimited: e.target.checked ?? false } },
            });
        },
        [dispatch, setValue]
    );

    if (state?.values?.type !== ValueType.List) return null;

    const { values: listValues } = state.values;

    if (listValues.length === 0) {
        dispatch({
            type: SettingActionType.SetListValues,
            payload: { newValues: { values: [{ isValid: false, value: '', guid: generateGUID() }] } },
        });
        return <></>;
    }

    return (
        <>
            {listValues.map((valueProp, index) => (
                <SingleListValue
                    allValues={listValues}
                    guid={valueProp.guid}
                    key={valueProp.guid}
                    maxLimit={maxLimit}
                    minLimit={minLimit}
                    onChange={(newValue, isValid, guid) => {
                        const newValues = listValues.map<SingleValueState>(valueProp =>
                            guid === valueProp.guid ? { isValid, value: newValue, guid: valueProp.guid } : valueProp
                        );

                        dispatch({
                            type: SettingActionType.SetListValues,
                            payload: { newValues: { values: newValues } },
                        });
                    }}
                    onRemove={() => {
                        const newValues = listValues.filter((_, i) => index !== i);

                        dispatch({
                            type: SettingActionType.SetListValues,
                            payload: { newValues: { values: newValues } },
                        });
                    }}
                    onTextChange={onEvent}
                    showErrors={notDuringTyping}
                    showRemoveButton={listValues.length > 1}
                    value={valueProp.value}
                />
            ))}
            <StyledButtonWrapper>
                <StyledButton
                    onClick={() => {
                        const newValues = [...listValues, { value: '', isValid: false, guid: generateGUID() }];

                        dispatch({
                            type: SettingActionType.SetListValues,
                            payload: { newValues: { values: newValues } },
                        });
                    }}
                >
                    <AddItemButton text="Add Value" />
                </StyledButton>
            </StyledButtonWrapper>

            {showNoMaxLimit && (
                <NoMaxLimitContainer>
                    <Checkbox checked={values[FieldType.IncludeUnlimited]} onChange={e => onUnlimitedChange(e)} />
                    <Label>Unlimited</Label>
                </NoMaxLimitContainer>
            )}
        </>
    );
};
