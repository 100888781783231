import type { Infer, RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import get from 'lodash/get';
import type { ReactNode } from 'react';
import { useMemo } from 'react';
import type { SelectionMode } from '../../../../theme/selection';
import type { SelectProps as BUISelectProps } from '../../../Select/Select';
import { Select as BUISelect } from '../../../Select/Select';
import { useForm } from '../../FormContext';
import { getOptionsFromSchema } from '../../utils';
import type { CommonAdapterProps } from '../common';
import { isSchemaKeyRequired, useConnectToForms } from '../common';
import type { AssertSchemaKeyIsArrayOfTypeAtomic, AssertSchemaKeyIsOfTypeAtomic } from '../shape-assertion.types';

type SelectProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TMode extends SelectionMode = 'single',
> = CommonAdapterProps<TSchema, TSchemaKey> &
  Omit<BUISelectProps<TMode>, 'onChange' | 'options' | 'value'> & {
    readonly labels?: Record<Infer<TSchema>[NoInfer<TSchemaKey>], string>;
  };

/**
 * The props for the FormSelect component.
 *
 * This type definition ensures that the given schema key is valid and points to a shape of type "oneOf".
 *
 * - First, it extracts the Props type to a generic type variable `TExtractedProps` so we can return it from
 *   from the AssertSchemaKeyIsOfTypeOneOfOrIs assertion type
 * - The second `infer` is used to extract `TSchemaKeyToCheck` from the schemaKey param which ensures TypeScript
 *   doesn't infer a union type for `TSchemaKey`.
 * - `AssertSchemaKeyIsOfTypeOneOfOrIs` verifies that the extracted schema key (`TSchemaKeyToCheck`) corresponds to
 *   a "oneOf" type in the schema.
 * - If the schemaKey indeed points to a "oneOf" type, the props are returned with the correct type, otherwise we
 *   return `never`
 */
export type AssertedSelectProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TMode extends SelectionMode = 'single',
> = Omit<BUISelectProps<TMode>, 'onChange' | 'options' | 'value'> &
  (SelectProps<TSchema, TSchemaKey, TMode> extends infer TExtractedProps extends {
    readonly schemaKey: infer TSchemaKeyToCheck extends NoInfer<TSchemaKey>;
  }
    ? NoInfer<TMode> extends 'single'
      ? AssertSchemaKeyIsOfTypeAtomic<TSchema, TSchemaKeyToCheck, TExtractedProps>
      : AssertSchemaKeyIsArrayOfTypeAtomic<TSchema, TSchemaKeyToCheck, TExtractedProps>
    : never);

export const Select = <
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TMode extends SelectionMode = 'single',
>(
  props: SelectProps<TSchema, TSchemaKey, TMode>,
): ReactNode => {
  const { schemaKey, labels, rules, onBlur, mode = 'single', ...restProps } = props;
  const { values: data, dispatch, schema } = useForm<TSchema>();
  const { disabled, visible } = useConnectToForms({ schemaKey, rules });

  const optionsFromSchema = useMemo(() => {
    return getOptionsFromSchema<string>(schema, schemaKey, data, mode);
  }, [schema, schemaKey, mode, data]);

  const isRequired = useMemo(() => {
    return isSchemaKeyRequired(schemaKey, schema, data);
  }, [schema, schemaKey, data]);

  const unTypedLabels = labels as Record<string, string> | undefined;

  return visible ? (
    <BUISelect
      cancelable={!isRequired && mode === 'single'}
      disabled={disabled}
      id={schemaKey}
      mode={mode}
      onBlur={e => {
        dispatch({ type: 'blur', key: schemaKey });
        onBlur?.(e);
      }}
      onChange={value => {
        dispatch({
          type: 'setValue',
          key: schemaKey as RecordPath<TSchema>,
          value: value as Infer<TSchema>[RecordPath<TSchema>],
        });
      }}
      options={optionsFromSchema.map(value => ({
        label: unTypedLabels ? unTypedLabels[value] : value,
        value,
      }))}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment -- this is string | undefined, TS is having trouble with that
      selectedKey={get(data, schemaKey)}
      {...restProps}
    />
  ) : null;
};
