import { Card, Flex, Icon, IconButton, spacing } from '@lemonade-hq/blender-ui';
import type { FC } from 'react';
import { useCallback, useMemo } from 'react';
import { GalleryMedia } from '../MediaComponents/GalleryMedia';
import { getInlineUrl } from '../../utils';
import { arrow, carouselWrapper, documentWrapper } from './Carousel.css';
import { useCarousel } from './CarouselProvider';
import { Detection } from './Detection';
import { Thumbnails } from './Thumbnails';
import { useAttachmentsData } from 'components/Attachments/context';

const Carousel: FC = () => {
    const { previousSlide, nextSlide } = useCarousel();

    return (
        <Flex flexDirection="column" height="100%">
            <div className={documentWrapper}>
                <button className={arrow({ direction: 'prev' })} onClick={previousSlide} type="button">
                    <Icon name="chevron-down" />
                </button>
                <Flex alignItems="center" height="100%" justifyContent="center">
                    <GalleryMedia />
                </Flex>
                <button className={arrow({ direction: 'next' })} onClick={nextSlide} type="button">
                    <Icon name="chevron-down" />
                </button>
            </div>
            <Thumbnails />
        </Flex>
    );
};

export const AttachmentCarousel: FC = () => {
    const { attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);

    const openInNewTab = useCallback(() => {
        const inlineUrl = getInlineUrl(attachment.url);

        window.open(inlineUrl, '_blank');
    }, [attachment]);

    return (
        <Card className={carouselWrapper}>
            <Flex
                alignItems="flex-start"
                justifyContent="space-between"
                position="relative"
                py={`0 ${spacing.s08}`}
                zIndex={1}
            >
                <Detection />
                <IconButton
                    color="neutral7"
                    icon="open-in-new"
                    iconSize="xl"
                    onClick={openInNewTab}
                    size="lg"
                    variant="inline"
                />
            </Flex>
            <Carousel />
        </Card>
    );
};
