import { font, themedColor } from '@lemonade-hq/boutique';
import styled from 'styled-components';
import { ThemedColors } from './Colors';

/**
 * @deprecated
 * use Input from '@lemonade-hq/bluis' instead
 */
const Input = styled.input.attrs<{ readonly withError?: boolean }>(({ withError, type }) => ({
    'data-valid': !withError,
    type: type ?? 'text',
}))<{ readonly withError?: boolean; readonly disabled?: boolean }>`
    width: 255px;
    height: 38px;
    border-radius: 5px;
    border: 1px solid !important;
    border-color: ${({ withError }) => (withError ? themedColor('errorColor') : themedColor('separator'))} !important;
    background-color: ${themedColor('componentBackground')};
    color: ${themedColor('primaryText')};
    outline: none;
    padding-left: 12px;
    opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
    ${font('main', { fontSize: '14px', fontWeight: 400 })}

    &::placeholder {
        color: ${themedColor('secondaryText')};
    }

    &:focus,
    &:active {
        border-color: ${themedColor('primaryText')} !important;
    }
`;

export default Input;
