import { handleThemeBodyClass } from '@lemonade-hq/blender-ui';
import { RootErrorBoundary } from '@lemonade-hq/bluiza';
import type { EntityTypes } from '@lemonade-hq/bluiza';
import { useAnalytics, useCurrentPrincipal, VIEW_MODE_KEY, ViewMode } from '@lemonade-hq/boutique';
import type { FC } from 'react';
import { useEffect, useLayoutEffect } from 'react';
import { AttachmentsProvider, useAttachmentsData } from '../context';
import type { AttachmentDTO } from '../types';
import { getAttachmentAnalyticsParam } from '../utils';
import { AttachmentCarousel } from './Carousel/AttachmentCarousel';
import { CarouselProvider } from './Carousel/CarouselProvider';
import { GalleryDialog } from './Gallery.css';
import { InfoSidebar } from './InfoSidebar';

interface AttachmentGalleryProps {
    readonly onClose: () => void;
    readonly index?: number;
}

const AttachmentGalleryContent: FC<AttachmentGalleryProps> = ({ onClose, index }) => {
    const { attachments, entityPublicId, entityType } = useAttachmentsData();
    const { trackEvent } = useAnalytics();
    const { operator } = useCurrentPrincipal();
    const params = getAttachmentAnalyticsParam({
        attachment: attachments[index ?? 0],
        entityType,
        entityId: entityPublicId ?? '',
        operatorId: operator?.id ?? '',
    });

    useEffect(() => {
        trackEvent('docs.gallery.opened', params);
    }, [params, trackEvent]);

    return (
        <CarouselProvider index={index} onClose={onClose} totalSlides={attachments.length}>
            <div className={GalleryDialog}>
                <InfoSidebar onClose={onClose} />
                <AttachmentCarousel />
            </div>
        </CarouselProvider>
    );
};

export const AttachmentGallery: FC<
    AttachmentGalleryProps & {
        readonly index?: number;
        readonly attachments?: AttachmentDTO[];
        readonly entityType: EntityTypes;
        readonly entityPublicId: string;
    }
> = ({ onClose, index, attachments, entityType, entityPublicId }) => {
    // dark mode - set view mode to dark
    useLayoutEffect(() => {
        const vm = localStorage.getItem(VIEW_MODE_KEY)?.replaceAll('"', '');
        const currentViewMode = vm === ViewMode.Dark ? ViewMode.Dark : ViewMode.Light;

        if (currentViewMode === ViewMode.Light) {
            handleThemeBodyClass(ViewMode.Dark);
        }

        return () => {
            if (currentViewMode === ViewMode.Light) {
                handleThemeBodyClass(ViewMode.Light);
            }
        };
    }, []);

    return (
        <RootErrorBoundary>
            <AttachmentsProvider attachments={attachments} entityPublicId={entityPublicId} entityType={entityType}>
                <AttachmentGalleryContent index={index} onClose={onClose} />
            </AttachmentsProvider>
        </RootErrorBoundary>
    );
};
