import { Input, Select } from '@lemonade-hq/bluis';
import type { FormProps } from '@lemonade-hq/cdk';
import { basicRequiredValidation } from '@lemonade-hq/cdk';
import React, { useEffect, useMemo } from 'react';
import { getValueSelectionMethodDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import type { SettingTypeAttributesProps } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/CommonAttributes';
import {
    buildBaseLimitDeductibleForm,
    FieldType,
    useCommonAttributes,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/CommonAttributes';
import {
    CurrencyUnitInputField,
    TimeSpanInputField,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/SharedInputFields';
import {
    DurationLabel,
    ExternalEntityLabel,
    InsurableEntityLabel,
    ParentLimitLabel,
    ScopeLabel,
    ValueSelectionLabel,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/SharedLabels';
import type {
    LimitAttributesState,
    SettingStateBase,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/SettingContext';
import { SettingActionType } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/SettingContext';
import { StyledFormInputWrapper, StyledFormWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import type { DurationUnit, SettingInstance } from 'models/LoCo/Insurance/CoveragesEdition';
import {
    DurationType,
    EntityScopeValueSelectionMethod,
    InsuranceScopeType,
    SettingUnit,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

interface LimitSettingTypeAttributesProps extends SettingTypeAttributesProps {
    readonly editionSettings: SettingInstance[];
}

export const LimitAttributes: React.FC<LimitSettingTypeAttributesProps> = ({ editionSettings, state, dispatch }) => {
    const limitState = state as SettingStateBase<LimitAttributesState> | undefined;

    const form = {
        fields: {
            [FieldType.LimitUnit]: {
                startValue: limitState?.attributes?.unit ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
                skipValidationsCondition: () => state.attributes?.type !== SettingType.Limit,
            },
            [FieldType.ParentLimitTemplateCode]: {
                startValue: limitState?.attributes?.parentLimitTemplateCode ?? '',
                validations: {
                    required: basicRequiredValidation,
                },
                skipValidationsCondition: values => values[FieldType.ParentLimitTemplateCode] === '',
            },
            ...buildBaseLimitDeductibleForm(limitState),
        },
    } satisfies FormProps;

    const {
        values,
        setValue,
        valid,
        onDurationChange,
        onValueSelectionChange,
        onEntityNameChange,
        onScopeChange,
        onCurrencyUnitChange,
        insurableEntityOptions,
        scopeOptions,
        showCurrencyUnit,
        durationOptions,
        ShowTimeSpan,
    } = useCommonAttributes(form, state);

    const parentLimitOptions = useMemo(() => {
        const options = editionSettings
            .filter(setting => setting.type === SettingType.Limit)
            .map(setting => ({
                id: setting.templateCode,
                value: setting.templateCode,
                label: setting.name,
            }));

        return [{ id: '', value: '', label: 'Not a sub limit' }, ...options];
    }, [editionSettings]);

    useEffect(() => {
        const duration =
            values[FieldType.Duration] === DurationType.Timespan
                ? {
                      type: values[FieldType.Duration],
                      amount: values[FieldType.DurationAmount],
                      unit: values[FieldType.DurationUnit] as DurationUnit,
                  }
                : {
                      type: values[FieldType.Duration],
                  };

        dispatch({
            type: SettingActionType.SetLimitAttributes,
            payload: {
                newValues: {
                    type: SettingType.Limit,
                    scope: {
                        type: values[FieldType.Scope],
                        name: values[FieldType.EntityName],
                    },
                    duration,
                    valueSelectionMethod: values[FieldType.ValueSelectionMethod],
                    unit: values[FieldType.LimitUnit],
                    currencyUnit: showCurrencyUnit ? values[FieldType.CurrencyUnit] : '',
                    parentLimitTemplateCode: values[FieldType.ParentLimitTemplateCode],
                    isValid: valid,
                },
            },
        });
    }, [values, valid, dispatch, showCurrencyUnit, state.settingType]);

    const onUnitChange = ({ value }: { value: SettingUnit }) => {
        setValue(FieldType.LimitUnit, value);
    };

    const onParentLimitChange = ({ value }: { value: string }) => {
        setValue(FieldType.ParentLimitTemplateCode, value);
    };

    return (
        <StyledFormWrapper>
            <StyledFormInputWrapper label={<ScopeLabel />}>
                <Select
                    onOptionSelected={onScopeChange}
                    options={scopeOptions}
                    placeholder="Select"
                    value={values[FieldType.Scope]}
                />
            </StyledFormInputWrapper>
            {values[FieldType.Scope] === InsuranceScopeType.InsuredEntity && (
                <>
                    <StyledFormInputWrapper label={<InsurableEntityLabel />}>
                        <Select
                            onOptionSelected={({ value }) => setValue(FieldType.InsuredEntityCode, value)}
                            options={insurableEntityOptions}
                            placeholder="Select"
                            value={values[FieldType.InsuredEntityCode]}
                        />
                    </StyledFormInputWrapper>
                    <StyledFormInputWrapper label={<ValueSelectionLabel />}>
                        <Select
                            onOptionSelected={onValueSelectionChange}
                            options={[
                                EntityScopeValueSelectionMethod.PerEntity,
                                EntityScopeValueSelectionMethod.Stacked,
                            ].map(method => ({
                                id: method,
                                value: method,
                                label: getValueSelectionMethodDisplayName(method),
                            }))}
                            placeholder="Select"
                            value={values[FieldType.ValueSelectionMethod]}
                        />
                    </StyledFormInputWrapper>
                </>
            )}
            {values[FieldType.Scope] === InsuranceScopeType.ExternalEntity && (
                <StyledFormInputWrapper label={<ExternalEntityLabel />}>
                    <Input
                        onChange={e => onEntityNameChange({ value: e.target.value })}
                        value={values[FieldType.EntityName]}
                    />
                </StyledFormInputWrapper>
            )}
            <StyledFormInputWrapper label={<DurationLabel />}>
                <Select
                    onOptionSelected={option => onDurationChange({ value: { type: option.value } })}
                    options={durationOptions}
                    placeholder="Select"
                    value={values[FieldType.Duration]}
                />
            </StyledFormInputWrapper>
            {ShowTimeSpan && (
                <TimeSpanInputField
                    durationAmount={values[FieldType.DurationAmount]}
                    durationUnit={values[FieldType.DurationUnit]}
                    onDurationChange={onDurationChange}
                />
            )}
            <StyledFormInputWrapper label="Unit">
                <Select
                    onOptionSelected={onUnitChange}
                    options={[
                        { id: SettingUnit.Currency, value: SettingUnit.Currency, label: 'Currency' },
                        { id: SettingUnit.Event, value: SettingUnit.Event, label: 'Events' },
                        { id: SettingUnit.Item, value: SettingUnit.Item, label: 'Items' },
                        {
                            id: SettingUnit.ClaimLossPercentage,
                            value: SettingUnit.ClaimLossPercentage,
                            label: '% of Claim Loss Amount',
                        },
                    ]}
                    placeholder="Select"
                    value={values[FieldType.LimitUnit]}
                />
            </StyledFormInputWrapper>
            {showCurrencyUnit && (
                <CurrencyUnitInputField
                    onCurrencyUnitChange={onCurrencyUnitChange}
                    value={values[FieldType.CurrencyUnit]}
                />
            )}
            <StyledFormInputWrapper label={<ParentLimitLabel />}>
                <Select
                    onOptionSelected={onParentLimitChange}
                    options={parentLimitOptions}
                    placeholder="Not a sub limit"
                    value={values[FieldType.ParentLimitTemplateCode]}
                />
            </StyledFormInputWrapper>
        </StyledFormWrapper>
    );
};
