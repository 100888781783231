import { FormInputWrapper, Multiselect } from '@lemonade-hq/bluis';
import React, { useMemo } from 'react';
import type { CoverageInstance } from 'models/LoCo/Insurance/CoveragesEdition';

interface RelatedCoveragesProps {
    readonly editionCoverages: { readonly coverage: CoverageInstance; readonly isSelected: boolean }[];
    readonly onChange: (selectedCoverageCodes: string[]) => void;
}

export const RelatedCoverages: React.FC<RelatedCoveragesProps> = ({ editionCoverages, onChange }) => {
    const options = useMemo(() => {
        return editionCoverages.map(editionCoverage => ({
            id: editionCoverage.coverage.templateCode,
            label: editionCoverage.coverage.name,
        }));
    }, [editionCoverages]);

    const selectedOptions = useMemo(() => {
        return editionCoverages
            .filter(editionCoverage => editionCoverage.isSelected)
            .map(selectedCoverage => selectedCoverage.coverage.templateCode);
    }, [editionCoverages]);

    const isOptionsEmpty = options.length === 0;

    return (
        <FormInputWrapper label="Coverages">
            <Multiselect
                disabled={isOptionsEmpty}
                options={options}
                placeholder={isOptionsEmpty ? 'No coverages' : 'Select'}
                selectedIds={selectedOptions}
                setSelectedOptions={selectedOptions => onChange(selectedOptions.map(option => option.id))}
                showAllWhenAllOptionsSelected={false}
            />
        </FormInputWrapper>
    );
};
