import type { ListboxProps } from '@headlessui/react';
import { Label, Listbox, ListboxOption, ListboxOptions } from '@headlessui/react';
import type { RecipeVariants } from '@vanilla-extract/recipes';
import { clsx } from 'clsx';
import type { ElementType, ReactElement, ReactNode } from 'react';
import { useCallback } from 'react';
import type * as inputStyles from '../../theme/input.css';
import * as selectStyles from '../../theme/select.css';
import type { SelectionMode } from '../../theme/selection';
import { ListItem } from '../ListItem/ListItem';
import * as styles from './Select.css';
import { SelectOptionsTriggerBar } from './SelectOptionsTriggerBar';

export interface SelectOptionBase {
  readonly label: JSX.Element | string;
  readonly value: number | string;
}

export type SelectProps<
  TMode extends SelectionMode = 'single',
  TOption extends SelectOptionBase = SelectOptionBase,
> = Pick<
  ListboxProps<'div', NoInfer<TMode> extends 'single' ? TOption : TOption[]>,
  'aria-label' | 'className' | 'id' | 'onBlur' | 'style'
> & {
  readonly selectedKey?: (NoInfer<TMode> extends 'single' ? TOption['value'] : TOption['value'][]) | null;
  readonly mode?: TMode;
  readonly onChange: (value: (NoInfer<TMode> extends 'single' ? TOption['value'] : TOption['value'][]) | null) => void;
  readonly label?: string;
  readonly hasError?: boolean;
  readonly options: TOption[];
  readonly selectedValueTemplate?: (selectedValue: ReactNode) => JSX.Element;
  readonly size?: NoInfer<TMode> extends 'single'
    ? NonNullable<RecipeVariants<typeof inputStyles.input>>['size']
    : undefined;
  readonly placeholder: string;
  readonly disabled?: boolean;
  readonly cancelable?: boolean;
};

export const Select = <TMode extends SelectionMode = 'single', TOption extends SelectOptionBase = SelectOptionBase>({
  hasError,
  onChange,
  label,
  disabled,
  options,
  size,
  selectedKey,
  selectedValueTemplate,
  placeholder,
  cancelable,
  'aria-label': ariaLabel,
  className,
  // @ts-expect-error the TMode type should be inferred and not explicity passed via type args
  mode = 'single',
  style,
}: SelectProps<TMode, TOption>): ReactElement => {
  const onChangeOption = useCallback(
    (value: TMode extends 'single' ? TOption : TOption[]) => {
      onChange(
        (mode === 'single'
          ? // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition -- can be null in case of clear
            (value as TOption)?.value ?? null
          : ((value as TOption[] | null) ?? []).map(option => option.value)) as TMode extends 'single'
          ? TOption['value']
          : TOption['value'][],
      );
    },
    [onChange, mode],
  );

  const selected = (
    mode === 'single'
      ? options.find(option => option.value === selectedKey)
      : options.filter(option => (selectedKey as TOption['value'][] | undefined)?.includes(option.value))
  ) as TMode extends 'single' ? TOption : TOption[];

  return (
    <Listbox<ElementType, TOption>
      aria-label={ariaLabel}
      as="div"
      by="value"
      className={clsx(styles.selectWrapper, className)}
      disabled={disabled}
      multiple={mode === 'multiple'}
      // @ts-expect-error i'll deal with this later
      onChange={onChangeOption}
      style={style}
      value={selected as TOption | undefined}
    >
      {label != null && <Label>{label}</Label>}
      <SelectOptionsTriggerBar
        cancelable={cancelable}
        disabled={disabled}
        hasError={hasError}
        mode={mode}
        onChange={onChangeOption}
        placeholder={placeholder}
        selected={selected}
        selectedValueTemplate={selectedValueTemplate}
        size={size}
      />
      <ListboxOptions className={clsx(selectStyles.listBox, selectStyles.popover)}>
        {options.map(option => (
          <ListboxOption
            as={ListItem}
            checked={
              mode === 'multiple'
                ? (selected as TOption[]).some(selectedOption => selectedOption.value === option.value)
                : undefined
            }
            id={option.value}
            key={option.value}
            label={option.label}
            value={option}
          ></ListboxOption>
        ))}
      </ListboxOptions>
    </Listbox>
  );
};
