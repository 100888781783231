/* eslint-disable @typescript-eslint/naming-convention */
import type { RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import type { FC, ReactNode } from 'react';
import type { SelectionMode } from '../../../../theme/selection';
import { FormInputGroup } from '../../FormLayout';
import { generateTypedFormComponents } from '../../typeHelpers';
import type { AssertedCheckboxProps } from '../Checkbox/Checkbox';
import type { AssertedComboBoxProps } from '../ComboBox/ComboBox';
import type { ErrorMessageProps } from '../ErrorMessage/ErrorMessage';
import { ErrorMessage } from '../ErrorMessage/ErrorMessage';
import type { AssertedInputProps } from '../Input/Input';
import type { LabelProps } from '../Label/Label';
import { Label } from '../Label/Label';
import type { AssertedRadioProps } from '../Radio/Radio';
import type { AssertedSelectProps } from '../Select/Select';
import type { AssertedTextAreaProps } from '../TextArea/TextArea';

export interface InputComponents<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TMode extends SelectionMode = 'single',
> {
  readonly Select: AssertedSelectProps<TSchema, TSchemaKey, TMode>;
  readonly Checkbox: AssertedCheckboxProps<TSchema, TSchemaKey>;
  readonly Input: AssertedInputProps<TSchema, TSchemaKey>;
  readonly TextArea: AssertedTextAreaProps<TSchema, TSchemaKey>;
  readonly Radio: AssertedRadioProps<TSchema, TSchemaKey>;
  readonly ComboBox: AssertedComboBoxProps<TSchema, TSchemaKey>;
}

export type LabelInputErrorPropsBase<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TComponentType extends keyof InputComponents<TSchema, NoInfer<TSchemaKey>>,
> = Omit<ErrorMessageProps<TSchema, NoInfer<TSchemaKey>>, 'children' | 'schemaKey'> &
  Omit<LabelProps<TSchema, NoInfer<TSchemaKey>>, 'children' | 'schemaKey'> & {
    readonly schemaKey: TSchemaKey;
    readonly label?: string;
    readonly customErrorMessage?: string;
    readonly inputComponent: TComponentType;
  };

export type LabelInputErrorProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TComponentType extends keyof InputComponents<TSchema, NoInfer<TSchemaKey>>,
  TMode extends SelectionMode = 'single',
> = LabelInputErrorPropsBase<TSchema, TSchemaKey, TComponentType> &
  Omit<InputComponents<TSchema, NoInfer<TSchemaKey>, TMode>[NoInfer<TComponentType>], 'schemaKey'>;

export const InputGroup = <
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TComponentType extends keyof InputComponents<TSchema, TSchemaKey>,
>(
  props,
): ReactNode => {
  const { schemaKey, label, customErrorMessage, inputComponent, ...rest } = props as LabelInputErrorProps<
    TSchema,
    TSchemaKey,
    TComponentType
  >;

  const Component = generateTypedFormComponents()[inputComponent] as FC;
  return (
    <FormInputGroup>
      {label !== undefined && <Label schemaKey={schemaKey}>{label}</Label>}
      {/* eslint-disable-next-line @typescript-eslint/no-explicit-any */}
      <Component {...(rest as any)} schemaKey={schemaKey} />
      <ErrorMessage schemaKey={schemaKey}>{customErrorMessage}</ErrorMessage>
    </FormInputGroup>
  );
};
