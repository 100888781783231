/* eslint-disable @typescript-eslint/naming-convention */
import { Banner, Button, Flex, Layout, spacing, Text } from '@lemonade-hq/blender-ui';
import type { Status } from '@lemonade-hq/blender-ui';
import { AttachmentType, getTypeFromContentType, toast } from '@lemonade-hq/bluis';
import { EntityTypes } from '@lemonade-hq/bluiza';
import { trackEvent, useCurrentPrincipal } from '@lemonade-hq/boutique';
import { capitalize } from '@lemonade-hq/ts-helpers';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import type { AttachmentDTO, Indicator, IndicatorType } from '../../types';
import { getAttachmentAnalyticsParam, getIndicators, getRaiDetectionResult, getTextChanges } from '../../utils';
import { raiLink, statusBanner } from './Carousel.css';
import { useCarousel } from './CarouselProvider';
import type { ScanFraudError } from 'apis/FraudAPI';
import { useScanFraud } from 'components/Attachments/AttachmentsQueries';
import { useAttachmentsData } from 'components/Attachments/context';

const ALLOWED_ENTITIES = [EntityTypes.HomeClaim];

enum ErrorType {
    ResistantAILimitExceededException = 'ResistantAILimitExceededException',
    Default = 'Default',
}

const errorsMap = {
    [ErrorType.ResistantAILimitExceededException]: {
        title: 'Quota limit reached',
        message:
            'Document cannot be scanned right now due to quota limits. Please discuss next steps with your team lead',
    },

    [ErrorType.Default]: {
        title: 'Error',
        message: 'Oops, something went wrong',
    },
};

const handleError = (nextErrorType: ErrorType | undefined): void => {
    toast.error(errorsMap[nextErrorType ?? ErrorType.Default].message);
};

const getFraudBannerVariant = (attachment: AttachmentDTO, isScanning: boolean): Status => {
    if (isScanning) {
        return 'update';
    }

    const raiDetectionResult = getRaiDetectionResult(attachment);

    if (raiDetectionResult?.score != null) {
        switch (raiDetectionResult.score) {
            case 'NORMAL':
                return 'info';
            case 'WARNING':
                return 'attention';
            case 'TRUSTED':
                return 'success';
            case 'HIGH_RISK':
                return 'error';
            default:
                return 'info';
        }
    }

    return 'attention';
};

const getFraudBannerTitle = (attachment: AttachmentDTO, isScanning: boolean): JSX.Element | string => {
    const raiDetectionResult = getRaiDetectionResult(attachment);

    if (isScanning) {
        return (
            <Flex justifyContent="space-between" width="100%">
                <Text as="span" type="text-md">
                    <b>Scanning documents.</b>
                    <span>This can take up to 30 sec</span>
                </Text>
            </Flex>
        );
    }

    if (raiDetectionResult?.score != null) {
        let text = '';
        switch (raiDetectionResult.score) {
            case 'NORMAL':
                text = 'This looks normal to us';
                break;
            case 'TRUSTED':
                text = 'Trusted document';
                break;
            case 'HIGH_RISK':
                text = 'Something is fishy here';
                break;
            case 'WARNING':
                text = 'This document may have been modified';
                break;
            default:
                text = '';
        }

        return (
            <Flex alignItems="center" justifyContent="space-between" width="100%">
                <Text>{text}</Text>
                <a className={raiLink} href={raiDetectionResult.externalViewUrl} rel="noreferrer" target="_blank">
                    See more
                </a>
            </Flex>
        );
    }

    return 'This document may have been modified';
};

const FraudBannerContent: FC<{ readonly attachment: AttachmentDTO }> = ({ attachment }) => {
    const textChanges = getTextChanges(attachment) ?? {};
    const indicators = getIndicators(attachment);

    if (Object.keys(textChanges).length > 0) {
        return (
            <Layout pb={spacing.s08}>
                <Text as="p" m="0" type="text-md">
                    <ul>
                        {Object.keys(textChanges).map(key => (
                            <li key={key}>
                                {textChanges[key].map(str => (
                                    <div key={str}>
                                        Page {key}, Text: &quot;{str}&quot;
                                    </div>
                                ))}
                            </li>
                        ))}
                    </ul>
                </Text>
            </Layout>
        );
    }

    if (indicators != null) {
        return (
            <Layout pb={spacing.s08}>
                <Text as="p" m="0" type="text-md">
                    <Flex>
                        <Flex flexDirection="column" padding="0 2px">
                            {Object.keys(indicators).map(key => (
                                <>
                                    <Flex alignItems="center" gap="0 8px" mb={12}>
                                        <b>{capitalize(key.toLocaleLowerCase())} Indicators</b>
                                    </Flex>
                                    <ul>
                                        {indicators[key as IndicatorType]?.map((indicator: Indicator) => (
                                            <li key={indicator.indicator_id}>
                                                <b>{indicator.title}</b> {indicator.description}
                                            </li>
                                        ))}
                                    </ul>
                                </>
                            ))}
                        </Flex>
                    </Flex>
                </Text>
            </Layout>
        );
    }

    return undefined;
};

export const Detection: FC = () => {
    const { entityPublicId, entityType, attachments } = useAttachmentsData();
    const { currentIndex } = useCarousel();
    const attachment = useMemo(() => attachments[currentIndex], [attachments, currentIndex]);

    const { operator } = useCurrentPrincipal();
    const params = getAttachmentAnalyticsParam({
        attachment,
        entityType,
        entityId: entityPublicId ?? '',
        operatorId: operator?.id ?? '',
    });

    const [scanningIds, setScanningIds] = useState<string[]>(
        attachments
            .filter(att => att.detections?.some(detection => detection.detectionStatus === 'processing'))
            .map(({ publicId }) => publicId)
    );

    const contentType = useMemo(() => getTypeFromContentType(attachment.contentType ?? ''), [attachment.contentType]);
    const attachmentHasDetections = useMemo(
        () => attachment.detections != null && attachment.detections.length !== 0,
        [attachment]
    );
    const raiDetectionResult = useMemo(() => getRaiDetectionResult(attachment), [attachment]);
    const scoreIsHigh = useMemo(() => raiDetectionResult?.score === 'HIGH_RISK', [raiDetectionResult]);

    const { mutateAsync: scanForFraud } = useScanFraud(entityPublicId ?? '', entityType);

    const isScanAllowed = useMemo(
        () =>
            ALLOWED_ENTITIES.includes(entityType) &&
            attachment.type !== 'policy_pdf' &&
            contentType != null &&
            [AttachmentType.Doc, AttachmentType.Image, AttachmentType.Pdf].includes(contentType),
        [attachment.type, contentType, entityType]
    );

    const handleScanStart = useCallback((attachmentId: string) => setScanningIds(curr => [...curr, attachmentId]), []);

    const handleScanClick = useCallback(async () => {
        trackEvent('docs.gallery.scan_for_modifications', params);

        if (entityPublicId != null) {
            try {
                await scanForFraud({
                    attachmentPublicId: attachment.publicId,
                    productLine: entityType.includes('home')
                        ? `home_${entityType.includes('eu') ? 'eu' : 'us'}`
                        : undefined,
                });

                handleScanStart(String(attachment.publicId));
            } catch (error) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                handleError((error?.response?.data as ScanFraudError).data?.type as ErrorType);
            }
        }
    }, [attachment.publicId, entityPublicId, entityType, handleScanStart, params, scanForFraud]);

    const isScanning = scanningIds.includes(String(attachment.publicId));

    return (
        <Flex gap={spacing.s08}>
            {isScanAllowed && raiDetectionResult == null && !isScanning && (
                <div>
                    <Button label="Scan for modifications" onClick={handleScanClick} variant="secondary" />
                </div>
            )}
            {isScanning || attachmentHasDetections || raiDetectionResult?.score != null ? (
                <Banner
                    className={statusBanner}
                    content={isScanning ? undefined : <FraudBannerContent attachment={attachment} />}
                    contentClassName={statusBanner}
                    floatingContent
                    isOpenByDefault={scoreIsHigh}
                    title={getFraudBannerTitle(attachment, isScanning)}
                    variant={getFraudBannerVariant(attachment, isScanning)}
                />
            ) : null}
        </Flex>
    );
};
