import type { RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import type { BaseValidation, Validation, ValidationLimitOperator } from '@lemonade-hq/maschema-validations';
import type { ValidationUIExtension } from '@lemonade-hq/maschema-validations-ui';
import get from 'lodash/get';
import pluralize from 'pluralize';
import type { FC, ReactNode } from 'react';
import { useForm } from '../../FormContext';
import * as styles from './ErrorMessage.css';
import { Text } from 'libs/blender-ui/src/base/Text/Text';

export type ErrorMessageProps<TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>> = {
  readonly schemaKey: TSchemaKey;
  readonly children?: string;
};

const Messages: FC<{ readonly validations?: Validation[] }> = ({ validations }) => {
  if (validations === undefined || validations.length === 0) return 'Error';

  const messages = validations.flatMap(v => getErrorMessages(v));

  if (messages.length === 1) {
    return messages[0];
  }

  return (
    <ul className={styles.errorMessagesList}>
      {messages.map((message, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>{message}</li>
      ))}
    </ul>
  );
};

export const ErrorMessage = <TSchema extends RecordLikeShape, TSchemaKey extends RecordPath<TSchema>>(
  props,
): ReactNode => {
  const { schemaKey, children } = props as ErrorMessageProps<TSchema, TSchemaKey>;
  const {
    validationResults,
    config: { showErrorsOnBlur, globallyDisabled },
    status: { afterBlurValidationResults },
  } = useForm();

  if (globallyDisabled) return null;

  const failingValidations = showErrorsOnBlur
    ? afterBlurValidationResults[schemaKey]
    : (get(validationResults.failingValidations, schemaKey) as BaseValidation[] | undefined);

  const isValidationError = failingValidations ? failingValidations.length > 0 : false;

  return (
    isValidationError && (
      <Text
        aria-label="error messages"
        className={styles.errorContainer}
        color="error"
        htmlFor={schemaKey}
        textTransform="none"
        type="label-xs"
      >
        {children ?? <Messages validations={failingValidations as Validation[] | undefined} />}
      </Text>
    )
  );
};

const REQUIRED_PREFIX = 'This field must';
const CANNOT_PREFIX = 'This field cannot';
const FIELD_KEY_PREFIX = 'This field must';
const ELEMENT_PREFIX = "This field's elements must";
const SOME_PREFIX = 'This field must meet one of several possible requirements. One of them is: must';

export function getErrorMessages(failingValidation: Validation, prefix = REQUIRED_PREFIX): string[] | string {
  if (isExtendedValidation(failingValidation) && failingValidation.error !== undefined) {
    return failingValidation.error;
  }

  let message = 'Error';

  // eslint-disable-next-line default-case
  switch (failingValidation.type) {
    case 'chars': {
      message = `be ${getMessageByLimit(failingValidation.limit)} ${failingValidation.chars} characters long`;
      break;
    }

    case 'oneOf': {
      message = `be one of the following values: ${failingValidation.values.join(', ')}`;
      break;
    }

    case 'dateRange': {
      message = `be between ${failingValidation.min} and ${failingValidation.max}`;
      break;
    }

    case 'knownRegExp':
      switch (failingValidation.patternCode) {
        case 'alphanumericChars':
          message = 'contain only alphanumeric characters';
          break;
        case 'alphabetChars':
          message = 'contain only alphabet characters';
          break;
        case 'addressChars':
          message = 'contain only address characters';
          break;
        case 'alphabetCharsWithUmlauts':
          message = 'contain only alphabet characters with umlauts';
          break;
        case 'alphabetLatinChars':
          message = 'contain only latin alphabet characters';
          break;
        case 'alphanumericLatinChars':
          message = 'contain only latin alphanumeric characters';
          break;
        case 'digitChars':
          message = 'contain only digit characters';
          break;
        case 'emailChars':
          message = 'contain only email characters';
          break;
        case 'noSymbols':
          message = 'not contain any symbols';
          break;
        case 'personNameChars':
          message = 'contain only person name characters';
          break;
        case 'email':
          message = 'contain a valid email address';
          break;
        case 'isoDate':
          message = 'contain a valid date in ISO format';
          break;
        case 'isoDatetime':
          message = 'contain a valid date and time in ISO format';
          break;
        case 'personName':
          message = 'contain a valid person name';
          break;
        case 'phoneNumber':
          message = 'contain a valid phone number';
          break;
        default:
          message = 'Error';
          break;
      }

      break;

    case 'required':
      message = 'not be left empty';
      break;

    case 'dateWeekdays': {
      message = 'be a weekday';
      break;
    }

    case 'conditionalDataContextValues': {
      return failingValidation.validations.flatMap(v => getErrorMessages(v));
    }

    case 'conditionalIterationContextValue': {
      return failingValidation.validations.flatMap(v => getErrorMessages(v));
    }

    case 'is': {
      message = `be equal to ${JSON.stringify(failingValidation.value)}`;
      break;
    }

    case 'noneOf': {
      message = `not be one of the following values: ${failingValidation.values.join(', ')}`;
      break;
    }

    case 'none': {
      return failingValidation.validations.flatMap(v => getErrorMessages(v, CANNOT_PREFIX));
    }

    case 'some': {
      return failingValidation.validations.flatMap(v => getErrorMessages(v, SOME_PREFIX));
    }

    case 'elementsCount': {
      message = `have ${getMessageByLimit(failingValidation.limit)} ${failingValidation.count} elements`;
      break;
    }

    case 'elementsUniqueness': {
      message = 'contain unique elements';
      break;
    }

    case 'elements': {
      return failingValidation.validations.flatMap(v => getErrorMessages(v, ELEMENT_PREFIX));
    }

    case 'integer': {
      message = 'be an integer';
      break;
    }

    case 'nullish': {
      message = 'be null or undefined';
      break;
    }

    case 'objectEntries': {
      message = `have the following keys: ${Object.keys(failingValidation.entries).join(', ')}`;
      break;
    }

    case 'objectKeys': {
      return failingValidation.validations.flatMap(v => getErrorMessages(v, FIELD_KEY_PREFIX));
    }

    case 'range': {
      message = `be between ${failingValidation.min} and ${failingValidation.max}
${failingValidation.step !== undefined ? ` with steps of size ${failingValidation.step}` : ''}`;
      break;
    }

    case 'regExp': {
      message = `match the pattern ${failingValidation.pattern}`;
      break;
    }

    case 'sequenceOfDigits': {
      message = 'be a sequence of digits';
      break;
    }

    case 'url': {
      message = 'contain a valid URL';
      break;
    }

    case 'words': {
      message = `be ${getMessageByLimit(failingValidation.limit)} ${failingValidation.words} ${pluralize('word', failingValidation.words)} long`;
      break;
    }
  }

  return `${prefix} ${message}`;
}

function isExtendedValidation(validation: Validation): validation is Validation & ValidationUIExtension {
  return 'error' in validation;
}

function getMessageByLimit(limit: ValidationLimitOperator): string {
  // eslint-disable-next-line default-case
  switch (limit) {
    case 'equals':
      return 'exactly';
    case 'max':
      return 'at most';
    case 'min':
      return 'at least';
  }
}
