import { AddIcon } from '@lemonade-hq/bluis';
import { Flex, font } from '@lemonade-hq/cdk';
import React from 'react';
import styled from 'styled-components';
import { ThemedColors } from 'components/Bluis/Colors';

const Title = styled.p`
    ${font('main', { fontSize: '14px', fontWeight: 700, lineHeight: 'normal' })}
    color: ${ThemedColors.secondaryText};
    margin-bottom: 0;
    margin-left: 12px;
    text-transform: uppercase;
`;

const StyledWrapper = styled(Flex)`
    cursor: pointer;
`;

interface AddItemButtonProps {
    readonly text: string;
    readonly onClick?: () => void;
}

export const AddItemButton: React.FC<AddItemButtonProps> = ({ text, onClick }) => {
    return (
        <StyledWrapper onClick={onClick}>
            <AddIcon />
            <Title>{text} </Title>
        </StyledWrapper>
    );
};
