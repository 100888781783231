import type { FC } from 'react';
import { ChangeAccordion } from '../ChangeAccordion';
import { getRelatedCoveragesAndRulesFromSetting } from '../getCoveragesAndRules';
import { findSetting } from './common';
import { getAttributesForSetting, settingValuesDisplay } from './setting-attributes';
import { getSettingTypeDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import type { ReferenceIdEntry } from 'models/LoCo/Insurance/ChangesLog';
import type { CoveragesEdition } from 'models/LoCo/Insurance/CoveragesEdition';
import type { DigitalAgentEdition } from 'models/LoCo/Insurance/DigitalAgentEdition';

interface SettingAddedProps {
    readonly change: ReferenceIdEntry;
    readonly coveragesEdition: CoveragesEdition;
    readonly digitalAgentEdition: DigitalAgentEdition | null;
}

export const SettingAdded: FC<SettingAddedProps> = ({ change, coveragesEdition, digitalAgentEdition }) => {
    const product = useGetProductData();

    const updated = getRelatedCoveragesAndRulesFromSetting({
        code: change.metadata.referenceId,
        coveragesEdition,
        digitalAgentEdition,
    });

    const setting = findSetting(coveragesEdition, change.metadata.referenceId);
    const values = settingValuesDisplay(setting);

    return (
        <ChangeAccordion
            generalTabData={[
                {
                    label: 'Related coverages',
                    value:
                        updated.relatedCoverages.length > 0
                            ? updated.relatedCoverages.map(coverage => coverage.name).join(', ')
                            : 'None',
                },
                {
                    label: 'Values',
                    value: values,
                },
                ...getAttributesForSetting(setting, product, coveragesEdition.settings),
            ]}
            originalRules={null}
            ruleType="coverage"
            title={`${getSettingTypeDisplayName(updated.setting.type)} added - ${setting.name}`}
            updatedRules={updated.rules}
        />
    );
};
