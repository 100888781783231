/* eslint-disable react/destructuring-assignment */
import type { DialogAction, SelectOption } from '@lemonade-hq/bluis';
import { Alert, AlertMode, Dialog, Form, FormInputWrapper, Input, Select } from '@lemonade-hq/bluis';
import { basicRequiredValidation, useForm } from '@lemonade-hq/cdk';
import { Currency } from '@lemonade-hq/lemonation';
import capitalize from 'lodash/capitalize';
import differenceWith from 'lodash/differenceWith';
import isEmpty from 'lodash/isEmpty';
import { useCallback, useMemo, useReducer } from 'react';
import styled from 'styled-components';
import { getValueNumber } from '../../../../common/display-texts/setting-instance';
import { SettingsAttributes } from './FormItems/Attributes/SettingsAttributes';
import { RelatedCoverages } from './FormItems/Binding/RelatedCoverages';
import { ValuesContainer } from './FormItems/Values/ValuesContainer';
import type {
    AttributesStates,
    CoinsuranceAttributesState,
    DeductibleAttributesState,
    DeductibleLimitAttributesState,
    LimitAttributesState,
    ListValuesState,
    RangeValuesState,
    SettingStates,
    SingleValueState,
    WaitingPeriodAttributesState,
} from './SettingContext';
import { SettingActionType, SettingContext, settingReducer } from './SettingContext';
import { GENERAL_ERROR_MSG } from 'commons/Constants';
import { useGetProductData } from 'components/LoCo/common/hooks/useGetProduct';
import { StyledFormInputWrapper, StyledSectionWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import { DialogType, generateGUID } from 'components/LoCo/products/SharedTableConfig';
import type {
    CoverageInstance,
    Duration,
    EntityScopeValueSelectionMethod,
    InsuranceScope,
    ListOfValues,
    RangeValue,
    SettingInstance,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { DurationType, SettingUnit, ValueType } from 'models/LoCo/Insurance/CoveragesEdition';
import { RegistryType } from 'models/LoCo/Insurance/Registry';
import type { SettingInstancePayload } from 'models/LoCo/Insurance/SettingInstanceRequests';
import { SettingType } from 'models/LoCo/Insurance/SettingType';
import { useGetRegistryByProductLine } from 'queries/LoCo/Insurance/RegistryQueries';

const StyledForm = styled(Form)<{ readonly height?: string }>`
    height: ${({ height }) => height ?? 'auto'};
`;

const InputWrapper = styled.div`
    width: 300px;
`;

type SettingDialogPropsBase<T extends DialogType> = {
    readonly type: T;
    readonly editionCode: string;
    readonly onSubmit: (settingInstance: SettingInstancePayload) => Promise<void>;
    readonly isMutationError: boolean;
    readonly isLoadingMutation: boolean;
    readonly editionCoverages: CoverageInstance[];
    readonly editionSettingInstances: SettingInstance[];
    readonly onClose: () => void;
};

type AddSettingDialogProps = SettingDialogPropsBase<DialogType.Add>;

type EditSettingDialogProps = SettingDialogPropsBase<DialogType.Edit> & {
    readonly editedSettingInstance: SettingInstance;
};

type DialogProps = AddSettingDialogProps | EditSettingDialogProps;

export const ManageSettingDialog: React.FC<DialogProps> = props => {
    const { editionCoverages, editionSettingInstances, isLoadingMutation, isMutationError, onClose, onSubmit, type } =
        props;

    const product = useGetProductData();

    const formFields = {
        // the registry setting
        setting: {
            startValue: type === DialogType.Edit ? props.editedSettingInstance.templateCode : '',
            validations: {
                required: basicRequiredValidation,
            },
        },
        selectedCoverages: {
            startValue:
                type === DialogType.Edit
                    ? props.editedSettingInstance.relatedCoverages.map(relatedCoverage => relatedCoverage.templateCode)
                    : [],
            validations: {},
        },
    };

    const initialSettingType = useMemo<SettingType | null>(() => {
        if (type === DialogType.Add) return null;

        return props.editedSettingInstance.type;
        // initial values are passed via props and aren't changed as long as the dialog is open, thus immutable
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialValues = useMemo<ListValuesState | RangeValuesState | null>(() => {
        if (type === DialogType.Add) return null;
        if (props.editedSettingInstance.values.type === ValueType.List) {
            return {
                type: ValueType.List,
                values: props.editedSettingInstance.values.values.map<SingleValueState>(val => ({
                    value: val.toString(),
                    isValid: true,
                    guid: generateGUID(),
                })),
                includeUnlimited: props.editedSettingInstance.values.includeUnlimited,
            } as ListValuesState;
        }

        return {
            type: ValueType.Range,
            interval: props.editedSettingInstance.values.step.toString(),
            minValue: props.editedSettingInstance.values.min.toString(),
            maxValue: props.editedSettingInstance.values.max.toString(),
            isValid: true,
        } as RangeValuesState;
        // initial values are passed via props and aren't changed as long as the dialog is open, thus immutable
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const initialAttributes = useMemo<AttributesStates | null>(() => {
        if (type === DialogType.Add) return null;
        if (props.editedSettingInstance.type === SettingType.WaitingPeriod) {
            return {
                type: SettingType.WaitingPeriod,
                isValid: true,
                durationUnit: props.editedSettingInstance.durationUnit,
            };
        }

        if (props.editedSettingInstance.type === SettingType.Coinsurance) {
            return {
                type: SettingType.Coinsurance,
                isValid: true,
                variant: props.editedSettingInstance.variant,
            };
        }

        const duration = {
            type: props.editedSettingInstance.duration.type,
            amount: props.editedSettingInstance.duration.amount,
            unit: props.editedSettingInstance.duration.unit,
        } as Duration;

        if (props.editedSettingInstance.type === SettingType.Deductible) {
            return {
                type: SettingType.Deductible,
                currencyUnit: props.editedSettingInstance.currencyUnit,
                duration,
                valueSelectionMethod: props.editedSettingInstance.valueSelectionMethod,
                isValid: true,
                scope: props.editedSettingInstance.scope,
            } as DeductibleAttributesState;
        } else {
            return {
                type: SettingType.Limit,
                duration,
                valueSelectionMethod: props.editedSettingInstance.valueSelectionMethod,
                unit: props.editedSettingInstance.unit,
                currencyUnit: props.editedSettingInstance.currencyUnit,
                scope: props.editedSettingInstance.scope,
                isValid: true,
                tbd: '',
            } as LimitAttributesState;
        }
        // initial values are passed via props and aren't changed as long as the dialog is open, thus immutable
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [settingState, dispatch] = useReducer(settingReducer, {
        settingType: initialSettingType,
        values: initialValues,
        attributes: initialAttributes,
    });

    const { errors, values, setValue, valid } = useForm({ fields: formFields });

    const [{ data: settingsRegistry }] = useGetRegistryByProductLine(product.productLineCode, [RegistryType.Setting]);

    const settingOptions: SelectOption[] = useMemo(
        () =>
            type === DialogType.Add
                ? // filter out already existing coverages, in order to show only selectable coverages
                  differenceWith(
                      settingsRegistry,
                      editionSettingInstances,
                      (registrySetting, settingInstance) => registrySetting.code === settingInstance.templateCode
                  ).map(({ code, name }) => ({ value: code, id: code, label: name }))
                : [],
        [editionSettingInstances, settingsRegistry, type]
    );

    const submit = useCallback(async () => {
        if (!settingState.attributes || !settingState.values) return;

        let entityToSave: Partial<SettingInstancePayload> = {
            relatedCoverages: values.selectedCoverages,
            templateCode: values.setting,
            values:
                settingState.values.type === ValueType.List
                    ? ({
                          type: ValueType.List,
                          values: settingState.values.values.map(val => Number(val.value)),
                          includeUnlimited: settingState.values.includeUnlimited,
                      } satisfies ListOfValues)
                    : ({
                          type: ValueType.Range,
                          min: getValueNumber(settingState.values.minValue),
                          max: getValueNumber(settingState.values.maxValue),
                          step: getValueNumber(settingState.values.interval),
                      } satisfies RangeValue),
        };

        if (settingState.settingType === SettingType.WaitingPeriod) {
            const attrs = settingState.attributes as WaitingPeriodAttributesState;

            entityToSave = {
                ...entityToSave,
                type: settingState.attributes.type,
                durationUnit: attrs.durationUnit,
            };
        } else if (settingState.settingType === SettingType.Coinsurance) {
            const attrs = settingState.attributes as CoinsuranceAttributesState;

            entityToSave = {
                ...entityToSave,
                type: settingState.attributes.type,
                variant: attrs.variant,
            };
        } else {
            const { scope, duration, valueSelectionMethod, currencyUnit } =
                settingState.attributes as DeductibleLimitAttributesState<SettingType.Deductible>;

            const { unit, parentLimitTemplateCode } = settingState.attributes as LimitAttributesState;

            const isTimeSpan = (duration.type as DurationType) === DurationType.Timespan;

            entityToSave = {
                ...entityToSave,
                type: settingState.attributes.type,
                duration: {
                    type: duration.type as DurationType,
                    amount: isTimeSpan ? duration.amount : undefined,
                    unit: isTimeSpan ? duration.unit : undefined,
                } as Duration,
                scope: scope as InsuranceScope,
                valueSelectionMethod: valueSelectionMethod as EntityScopeValueSelectionMethod,
                currencyUnit: (Object.values(Currency) as string[]).includes(currencyUnit)
                    ? (currencyUnit as Currency)
                    : undefined,
                unit: (settingState.attributes.type === SettingType.Limit ? unit : undefined) as SettingUnit,
                parentLimitTemplateCode: parentLimitTemplateCode === '' ? undefined : parentLimitTemplateCode,
            };
        }

        await onSubmit(entityToSave as SettingInstancePayload);
        onClose();
    }, [
        onClose,
        onSubmit,
        settingState.attributes,
        settingState.settingType,
        settingState.values,
        values.selectedCoverages,
        values.setting,
    ]);

    const isValuesValid = useMemo(() => {
        if (settingState.values == null) return false;
        if (settingState.values.type === ValueType.Range) {
            return settingState.values.isValid;
        }

        return settingState.values.values.every(({ isValid }) => isValid);
    }, [settingState.values]);

    const isAttributesValid = useMemo(() => {
        if (settingState.attributes == null) return false;

        return settingState.attributes.isValid;
    }, [settingState.attributes]);

    const actions: DialogAction[] = useMemo(
        () => [
            {
                text: 'Cancel',
                type: 'close',
                onClick: onClose,
            },
            {
                text: 'Save',
                type: 'submit',
                onClick: submit,
                disabled: isLoadingMutation || !valid || !isValuesValid || !isAttributesValid,
            },
        ],
        [submit, isLoadingMutation, onClose, valid, isValuesValid, isAttributesValid]
    );

    const getSettingType = useCallback(
        (setting: string) => settingsRegistry.find(({ code }) => code === setting)?.type ?? '',
        [settingsRegistry]
    );

    function selectSetting(selectedOption: SelectOption): void {
        setValue('setting', selectedOption.value);

        const settingType = getSettingType(selectedOption.value);

        if (settingType !== '') {
            dispatch({
                type: SettingActionType.InitSetting,
                payload: { settingType },
            });
        }
    }

    const disableSettingSelect = isMutationError || isLoadingMutation || settingsRegistry.length === 0;

    const notices = useMemo(
        () =>
            type === DialogType.Edit
                ? [
                      <Alert
                          key="1"
                          mode={AlertMode.Info}
                          title={
                              <span>
                                  Please review that any rules related to this setting still use available values
                              </span>
                          }
                      />,
                  ]
                : undefined,
        [type]
    );

    const providerValue = useMemo(() => ({ state: settingState, dispatch }), [settingState]);

    const maxValue = (state: SettingStates): number | undefined => {
        if (state.settingType === SettingType.Coinsurance) {
            return 100;
        }

        if (
            state.attributes?.type === SettingType.Limit &&
            state.values?.type === ValueType.List &&
            state.attributes.unit === SettingUnit.ClaimLossPercentage
        ) {
            return 100;
        }
    };

    const minValue = (state: SettingStates): number | undefined => {
        if (state.settingType === SettingType.Coinsurance) {
            return 0;
        }

        if (
            state.attributes?.type === SettingType.Limit &&
            state.values?.type === ValueType.List &&
            state.attributes.unit === SettingUnit.ClaimLossPercentage
        ) {
            return 1;
        }
    };

    return (
        <SettingContext.Provider value={providerValue}>
            <Dialog
                actions={actions}
                closeOnOutsideClick
                error={isMutationError ? GENERAL_ERROR_MSG : undefined}
                loading={isLoadingMutation}
                notice={notices}
                onClose={onClose}
                size="large"
                title={type === DialogType.Add ? 'Add Setting' : 'Edit Setting'}
            >
                {
                    <StyledForm height={getSettingType(values.setting) !== '' ? '500px' : ''}>
                        <FormInputWrapper label="Setting" showErrors={!isEmpty(errors.setting)}>
                            {type === DialogType.Add ? (
                                <Select
                                    disabled={disableSettingSelect}
                                    onOptionSelected={option => selectSetting(option)}
                                    options={settingOptions}
                                    placeholder="Select"
                                    value={values.setting}
                                />
                            ) : (
                                <InputWrapper>
                                    <Input disabled value={props.editedSettingInstance.name} />
                                </InputWrapper>
                            )}
                        </FormInputWrapper>

                        {values.setting && (
                            <>
                                <StyledFormInputWrapper label="Type">
                                    <Input disabled value={capitalize(getSettingType(values.setting))} />
                                </StyledFormInputWrapper>
                                <RelatedCoverages
                                    editionCoverages={editionCoverages.map(x => ({
                                        coverage: x,
                                        isSelected: values.selectedCoverages.includes(x.templateCode),
                                    }))}
                                    onChange={selectedCoverages => setValue('selectedCoverages', selectedCoverages)}
                                />
                                <StyledSectionWrapper>
                                    <ValuesContainer
                                        maxLimit={maxValue(settingState)}
                                        minLimit={minValue(settingState)}
                                        valueType={type === DialogType.Edit ? settingState.values?.type ?? null : null}
                                    />
                                </StyledSectionWrapper>
                                <SettingsAttributes
                                    editionSettings={(props as AddSettingDialogProps).editionSettingInstances}
                                />
                            </>
                        )}
                    </StyledForm>
                }
            </Dialog>
        </SettingContext.Provider>
    );
};
