import { Input, Select } from '@lemonade-hq/bluis';
import type { FormProps } from '@lemonade-hq/cdk';
import React, { useEffect } from 'react';
import { getValueSelectionMethodDisplayName } from 'components/LoCo/common/display-texts/setting-instance';
import type { SettingTypeAttributesProps } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/CommonAttributes';
import {
    buildBaseLimitDeductibleForm,
    FieldType,
    useCommonAttributes,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/CommonAttributes';
import {
    CurrencyUnitInputField,
    TimeSpanInputField,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/SharedInputFields';
import {
    DurationLabel,
    ExternalEntityLabel,
    InsurableEntityLabel,
    ScopeLabel,
    ValueSelectionLabel,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/FormItems/Attributes/SharedLabels';
import type {
    DeductibleAttributesState,
    SettingStateBase,
} from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/SettingContext';
import { SettingActionType } from 'components/LoCo/editions/coverage-editions/Settings/Dialogs/SettingContext';
import { StyledFormInputWrapper, StyledFormWrapper } from 'components/LoCo/LoCoPagesSharedStyles';
import type { DurationUnit } from 'models/LoCo/Insurance/CoveragesEdition';
import {
    DurationType,
    EntityScopeValueSelectionMethod,
    InsuranceScopeType,
} from 'models/LoCo/Insurance/CoveragesEdition';
import { SettingType } from 'models/LoCo/Insurance/SettingType';

export const DeductibleAttributes: React.FC<SettingTypeAttributesProps> = ({ state, dispatch }) => {
    const form = {
        fields: {
            ...buildBaseLimitDeductibleForm(state as SettingStateBase<DeductibleAttributesState>),
        },
    } satisfies FormProps;

    const {
        values,
        valid,
        onDurationChange,
        onValueSelectionChange,
        onEntityNameChange,
        onScopeChange,
        onCurrencyUnitChange,
        setValue,
        insurableEntityOptions,
        scopeOptions,
        showCurrencyUnit,
        durationOptions,
        ShowTimeSpan,
    } = useCommonAttributes(form, state);

    useEffect(() => {
        const duration =
            values[FieldType.Duration] === DurationType.Timespan
                ? {
                      type: values[FieldType.Duration],
                      amount: values[FieldType.DurationAmount],
                      unit: values[FieldType.DurationUnit] as DurationUnit,
                  }
                : {
                      type: values[FieldType.Duration],
                  };

        dispatch({
            type: SettingActionType.SetDeductibleAttributes,
            payload: {
                newValues: {
                    type: SettingType.Deductible,
                    scope: {
                        type: values[FieldType.Scope],
                        name: values[FieldType.EntityName],
                    },
                    duration,
                    valueSelectionMethod: values[FieldType.ValueSelectionMethod],
                    currencyUnit: showCurrencyUnit ? values[FieldType.CurrencyUnit] : '',
                    isValid: valid,
                },
            },
        });
    }, [values, valid, dispatch, showCurrencyUnit, state.settingType]);

    return (
        <StyledFormWrapper>
            <StyledFormInputWrapper label={<ScopeLabel />}>
                <Select
                    onOptionSelected={onScopeChange}
                    options={scopeOptions}
                    placeholder="Select"
                    value={values[FieldType.Scope]}
                />
            </StyledFormInputWrapper>
            {values[FieldType.Scope] === InsuranceScopeType.InsuredEntity && (
                <>
                    <StyledFormInputWrapper label={<InsurableEntityLabel />}>
                        <Select
                            onOptionSelected={({ value }) => setValue(FieldType.InsuredEntityCode, value)}
                            options={insurableEntityOptions}
                            placeholder="Select"
                            value={values[FieldType.InsuredEntityCode]}
                        />
                    </StyledFormInputWrapper>
                    <StyledFormInputWrapper label={<ValueSelectionLabel />}>
                        <Select
                            onOptionSelected={onValueSelectionChange}
                            options={[
                                EntityScopeValueSelectionMethod.PerEntity,
                                EntityScopeValueSelectionMethod.Stacked,
                            ].map(method => ({
                                id: method,
                                value: method,
                                label: getValueSelectionMethodDisplayName(method),
                            }))}
                            placeholder="Select"
                            value={values[FieldType.ValueSelectionMethod]}
                        />
                    </StyledFormInputWrapper>
                </>
            )}
            {values[FieldType.Scope] === InsuranceScopeType.ExternalEntity && (
                <StyledFormInputWrapper label={<ExternalEntityLabel />}>
                    <Input
                        onChange={e => onEntityNameChange({ value: e.target.value })}
                        value={values[FieldType.EntityName]}
                    />
                </StyledFormInputWrapper>
            )}
            <StyledFormInputWrapper label={<DurationLabel />}>
                <Select
                    onOptionSelected={option => onDurationChange({ value: { type: option.value } })}
                    options={durationOptions}
                    placeholder="Select"
                    value={values[FieldType.Duration]}
                />
            </StyledFormInputWrapper>

            {ShowTimeSpan && (
                <TimeSpanInputField
                    durationAmount={values[FieldType.DurationAmount]}
                    durationUnit={values[FieldType.DurationUnit]}
                    onDurationChange={onDurationChange}
                />
            )}

            {showCurrencyUnit && (
                <CurrencyUnitInputField
                    onCurrencyUnitChange={onCurrencyUnitChange}
                    value={values[FieldType.CurrencyUnit]}
                />
            )}
        </StyledFormWrapper>
    );
};
