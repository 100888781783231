import { Banner, Button, Flex, Layout, spacing } from '@lemonade-hq/blender-ui';
import { getEnvId } from '@lemonade-hq/bluiza';
import { useState } from 'react';
import type { FC } from 'react';
import { code, warning, warningBanner } from './EnvIdWarning.css';

export const EnvIdWarning: FC = () => {
    const [showWarning, setShowWarning] = useState(true);
    const urlEnvId = getEnvId();
    const envId = window.ENV_ID;

    if (import.meta.env.VITE_ENV_NAME !== 'staging' || urlEnvId === envId) return null;

    const handleClose = () => setShowWarning(false);

    if (!showWarning) return null;

    return (
        <Layout className={warning}>
            <Banner
                className={warningBanner}
                content={
                    <Flex flexDirection="column">
                        <p>
                            <b>
                                If you don&apos;t have a branch in blender-frontend service,
                                <br /> this message does not concern you.{' '}
                            </b>
                        </p>
                        <p>Otherwise, do the following:</p>
                        <Layout as="ul" pl={spacing.s12}>
                            <li>
                                Install reload_blender_nginx command in your .zshrc file <br />
                                (if you don&apos;t have it already){' '}
                                <code className={code}>cd Lemonade/blender && make install-nginx-scripts</code>
                                <b>Close and reopen your terminal</b>
                            </li>
                            <li>
                                Run <code className={code}>reload_blender_nginx {urlEnvId}</code>
                            </li>
                        </Layout>
                        <Flex justifyContent="flex-end">
                            <Button label="Dismiss" onClick={handleClose} variant="inline" />
                        </Flex>
                    </Flex>
                }
                contentClassName={warningBanner}
                title="Env ID mismatch"
                variant="error"
            />
        </Layout>
    );
};
