import { GENERAL_ERROR_MSG } from '@lemonade-hq/bluis';
import { useCallback } from 'react';
import { RemoveRuleDialog } from '../RemoveRuleDialog';
import { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { isCoverageRuleGroupABTest } from 'models/LoCo/Insurance/DigitalAgentEdition';
import type { EntityRules } from 'models/LoCo/Insurance/DigitalAgentEdition';
import { useDeleteCoveragesEditionRule } from 'queries/LoCo/Insurance/CoveragesEditionQueries';
import { useDeleteDigitalAgentEditionRules } from 'queries/LoCo/Insurance/DigitalAgentEditionQueries';

interface RemoveSingleRuleDialogProps {
    readonly editionType: EditionType;
    readonly editionCode: string;
    readonly ruleId: string;
    readonly onClose: () => void;
}

export const RemoveSingleRuleDialog: React.FC<RemoveSingleRuleDialogProps> = ({
    onClose,
    editionType,
    editionCode,
    ruleId,
}) => {
    const {
        mutateAsync: deleteCoverageRule,
        isPending: isCoveragesLoading,
        isError: isCoveragesError,
    } = useDeleteCoveragesEditionRule(editionCode);

    const {
        mutateAsync: deleteDigitalAgentRule,
        isPending: isDigitalAgentLoading,
        isError: isDigitalAgentError,
    } = useDeleteDigitalAgentEditionRules(editionCode);

    const onSubmit = useCallback(async () => {
        if (editionType === EditionType.Coverages) {
            await deleteCoverageRule({ ruleId });
        } else {
            await deleteDigitalAgentRule({ ruleIds: [ruleId] });
        }

        onClose();
    }, [editionType, onClose, deleteCoverageRule, ruleId, deleteDigitalAgentRule]);

    return (
        <RemoveRuleDialog
            error={isDigitalAgentError || isCoveragesError ? GENERAL_ERROR_MSG : undefined}
            isLoading={isCoveragesLoading || isDigitalAgentLoading}
            onClose={onClose}
            onSubmit={onSubmit}
        />
    );
};

interface RemoveEntityRulesDialogProps {
    readonly editionCode: string;
    readonly entityRules: EntityRules;
    readonly onClose: () => void;
}

export const RemoveEntityRulesDialog: React.FC<RemoveEntityRulesDialogProps> = ({
    onClose,
    editionCode,
    entityRules,
}) => {
    const {
        mutateAsync: deleteEditionRule,
        isPending: isLoading,
        isError,
    } = useDeleteDigitalAgentEditionRules(editionCode);
    const onSubmit = useCallback(async () => {
        const ruleIds = Object.values(entityRules.rulesByType).flatMap(rules => {
            if (isCoverageRuleGroupABTest(rules)) {
                return []; // TODO: handle AB test rules
            } else return rules.rules.map(rule => rule.publicId);
        });
        await deleteEditionRule({ ruleIds });

        onClose();
    }, [entityRules, deleteEditionRule, onClose]);

    return (
        <RemoveRuleDialog
            body={`Are you sure you want to remove this ${entityRules.entityType}'s rules?`}
            error={isError ? GENERAL_ERROR_MSG : undefined}
            isLoading={isLoading}
            onClose={onClose}
            onSubmit={onSubmit}
            title={`Remove ${entityRules.entityType} rules`}
        />
    );
};
