import type { Infer, RecordLikeShape, RecordPath } from '@lemonade-hq/maschema-schema';
import type { ReactNode } from 'react';
import * as styles from '../../../DynamicList/DynamicList.css';
import { useConnectToForms } from '../common';
import { Input } from '../Input/Input';
import { DynamicListItemActionMenu } from './common';
import type { DynamicListProps, PrimitiveShape } from './DynamicList';
import { Flex } from 'libs/blender-ui/src/base/Flex/Flex';

type DynamicListPrimitiveItemsProps<
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TItem extends Infer<PrimitiveShape>,
> = DynamicListProps<TSchema, TSchemaKey, PrimitiveShape> & {
  readonly items: TItem[];
  readonly schemaKey: TSchemaKey;
};

export const DynamicListPrimitiveItems = <
  TSchema extends RecordLikeShape,
  TSchemaKey extends RecordPath<TSchema>,
  TItem extends Infer<PrimitiveShape>,
>({
  items,
  schemaKey,
}: DynamicListPrimitiveItemsProps<TSchema, TSchemaKey, TItem>): ReactNode => {
  const { disabled } = useConnectToForms({ schemaKey });

  return (
    <>
      {items.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Flex className={styles.primitiveListItemContainer} key={i}>
          <Input className={styles.primitiveListItem} schemaKey={`${schemaKey}[${i}]`} />
          {disabled ? null : <DynamicListItemActionMenu index={i} items={items} schemaKey={schemaKey} />}
        </Flex>
      ))}
    </>
  );
};
