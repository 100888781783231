import type { IconName } from '@lemonade-hq/blender-ui';
import { ComboBox, Flex, Select } from '@lemonade-hq/blender-ui';
import { clsx } from 'clsx';
import type { ComboBoxItem } from 'libs/blender-ui/src/components/ComboBox/ComboBox';
import { singular } from 'pluralize';
import { useCallback, useMemo } from 'react';
import type { Key } from 'react-aria-components';
import { getInputOperators, getTypeFromSchema } from './ExpressionSimpleEditor/ExpressionSimpleEditorShared';
import type { Argument } from './ExpressionSimpleEditor/expressionTypes';
import { ArgumentType } from './ExpressionSimpleEditor/expressionTypes';
import type { InputFunction } from './ExpressionSimpleEditor/operators';
import * as schemaAutocompleteStyles from './SchemaAutocomplete.css';
import type { FieldEntry } from 'models/LoCo/Insurance/Schema';

export interface ExtendedComboBoxOption extends ComboBoxItem {
    readonly func?: string;
    readonly actualValue: string;
}

export interface OptionBase {
    readonly type: 'function' | 'value';
    readonly value: string;
}

export interface FunctionOption extends OptionBase {
    readonly type: 'function';
    readonly argument: string;
}

export interface ValueOption extends OptionBase {
    readonly type: 'value';
}

export type SchemaAutocompleteOption = FunctionOption | ValueOption;

interface SchemaAutocompleteProps {
    /** determines the general type of the expression - used to filter out the options */
    readonly expressionType?: Argument;
    readonly productSchema: Record<string, FieldEntry>;
    readonly value: SchemaAutocompleteOption;
    readonly placeholder?: string;
    readonly onChange: (item: SchemaAutocompleteOption | null) => void;
    readonly error?: boolean;
    readonly onClear?: () => void;
    readonly width?: number;
    readonly additionalFunctions?: Record<string, InputFunction>;
    readonly className?: string;
}

const SchemaTypeIcon: Record<ArgumentType, IconName> = {
    [ArgumentType.Number]: 'hash',
    [ArgumentType.Enum]: 'list',
    [ArgumentType.String]: 'text',
    [ArgumentType.Boolean]: 'toggle-left',
    [ArgumentType.Unknown]: 'x-circle-solid',
};

export const SchemaAutocomplete: React.FC<SchemaAutocompleteProps> = ({
    productSchema,
    additionalFunctions = {},
    value,
    placeholder,
    onChange,
    error = false,
    expressionType,
    className,
}) => {
    const items: ExtendedComboBoxOption[] = useMemo(() => {
        return Object.values(productSchema)
            .filter(field => {
                const fieldType = getTypeFromSchema(productSchema, field.name);

                if (fieldType.type === ArgumentType.Unknown) return false;
                if (!expressionType) return true;
                if (expressionType.type === fieldType.type) {
                    return true;
                }

                return false;
            })
            .map(field => {
                const fieldName = field.name;

                const fieldType = getTypeFromSchema(productSchema, fieldName);

                const option: ExtendedComboBoxOption = {
                    value: `input-${fieldName}`,
                    actualValue: fieldName,
                    label: fieldName,
                    func: 'input',
                    icon: SchemaTypeIcon[fieldType.type],
                };

                return option;
            })
            .concat(getInputOperators(additionalFunctions));
    }, [productSchema, expressionType, additionalFunctions]);

    const handleSelectionChange = useCallback(
        (option: ExtendedComboBoxOption | null, newlyGenerated?: boolean) => {
            onChange(
                option === null
                    ? null
                    : newlyGenerated === true
                      ? { type: 'value', value: option.label }
                      : { type: 'function', value: option.func ?? '', argument: option.actualValue }
            );
        },
        [onChange]
    );

    const updateArgument = useCallback(
        (newValue: Key | null): void => {
            onChange({
                type: 'function',
                value: value.value,
                argument: newValue as string,
            });
        },
        [onChange, value.value]
    );

    const valueIsFunction = isFunction(value);
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const isCollapsed = valueIsFunction && additionalFunctions[value.value]?.collapse;

    const func = valueIsFunction ? additionalFunctions[value.value] : undefined;
    const argument = func?.argument !== undefined ? func.argument : undefined;

    const argumentOptions = argument
        ? argument.symbols.map(sym => ({
              value: sym,
              label: sym,
              id: sym,
          }))
        : [];

    const actualValue = value.value ? (isCollapsed ? `${value.value}-${value.argument}` : value.value) : undefined;

    return (
        <Flex className={clsx(schemaAutocompleteStyles.autocompleteContainer, className)}>
            <ComboBox<'single', ExtendedComboBoxOption>
                allowCustomValue={expressionType?.type !== undefined}
                defaultValue={actualValue}
                hasError={error}
                items={items}
                onSelectionChange={handleSelectionChange}
                placeholder={placeholder ?? 'select'}
            />
            {valueIsFunction && argument && (
                <Flex alignItems="center" gap="1rem" justifyContent="space-between" width="100%">
                    <Select
                        className={schemaAutocompleteStyles.argumentSelector}
                        onChange={updateArgument}
                        options={argumentOptions}
                        placeholder={`Select ${singular(argument.enumName)}`}
                        selectedKey={value.argument}
                    />
                </Flex>
            )}
            {error && <Flex className={schemaAutocompleteStyles.error}>This field is empty</Flex>}
        </Flex>
    );
};

function isFunction(value: SchemaAutocompleteOption): value is FunctionOption {
    return value.type === 'function';
}
