import { ErrorSection, LoadingSection } from '@lemonade-hq/bluis';
import { isDefined } from '@lemonade-hq/ts-helpers';
import { ErrorBoundary } from '@sentry/react';
import { Suspense, useCallback, useMemo, useRef, useState } from 'react';
import { formatRuleType } from '../../display-texts/common';
import { StyledDialog } from '../Dialog/Dialog';
import { useMiniFlowFormDialog } from '../Dialog/useMiniFlowFormDialog';
import { StyledMiniFlow } from '../MiniFlow';
import { ConnectedSegmentSelection } from './ConnectedSegmentSelection';

import { ManageRuleStep } from './ManageRuleCommon';
import type {
    ManageRuleFormState,
    ManageRuleStepItem,
    RuleBuilderRefMethods,
    RuleDialogDispatch,
} from './ManageRuleDialogContext';
import { getInitialStep, ManageRuleDialogContext, RegistryProvider, useGetOnSubmit } from './ManageRuleDialogContext';
import { ContextSelection } from './Steps/ContextSelection';
import { EntitySelection } from './Steps/EntitySelection';
import type { HighlightedEntities } from './Steps/EntitySelection';
import { OutcomeSelection } from './Steps/OutcomeSelection';
import { RuleTypeSelection } from './Steps/RuleTypeSelection';
import { RuleDialogDimensions } from 'components/LoCo/LoCoPagesSharedStyles';
import type { EditionType, RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageRule, CoverageRuleType, RuleEntityType } from 'models/LoCo/Insurance/CoverageRule';

interface ManageRuleDialogProps {
    readonly editionType: EditionType;
    readonly editionCode: string;
    readonly entity?: {
        readonly code?: string;
        readonly type: RuleEntityType;
    };
    readonly ruleType?: CoverageRuleType;
    readonly lifecycleContexts?: RuleLifecycleContext[];
    readonly editedCoverageRule?: CoverageRule;
    readonly onClose: () => void;
    readonly highlightedEntities?: HighlightedEntities;
}

const stepFormEntries: Record<ManageRuleStep, (keyof ManageRuleFormState)[]> = {
    [ManageRuleStep.Entity]: ['entityType', 'entityCode'],
    [ManageRuleStep.RuleType]: ['ruleType'],
    [ManageRuleStep.Context]: ['lifecycleContexts'],
    [ManageRuleStep.Segment]: ['expression'],
    [ManageRuleStep.Outcome]: ['outcome'],
};

function getSteps(
    entity: { readonly code?: string; readonly type: RuleEntityType } | undefined,
    ruleType: CoverageRuleType | undefined,
    editedCoverageRule: CoverageRule | undefined,
    highlightedEntities?: HighlightedEntities
): ManageRuleStepItem[] {
    const stepItems: ManageRuleStepItem[] = [];

    if (!isDefined(entity) || !isDefined(entity.code)) {
        stepItems.push({
            title: 'Entity Type',
            body: <EntitySelection highlightedEntities={highlightedEntities} />,
            id: ManageRuleStep.Entity,
        });
    }

    if (!isDefined(ruleType)) {
        stepItems.push({
            title: 'Rule Type',
            body: <RuleTypeSelection />,
            id: ManageRuleStep.RuleType,
        });
    }

    if (!isDefined(editedCoverageRule)) {
        stepItems.push({
            title: 'Context',
            body: <ContextSelection />,
            id: ManageRuleStep.Context,
        });
    }

    stepItems.push(
        {
            title: 'Segment',
            body: <ConnectedSegmentSelection />,
            id: ManageRuleStep.Segment,
        },
        {
            title: 'Outcome',
            body: <OutcomeSelection />,
            id: ManageRuleStep.Outcome,
        }
    );

    return stepItems;
}

export const ManageRuleDialog: React.FC<ManageRuleDialogProps> = ({
    ruleType,
    entity,
    editionCode,
    editionType,
    lifecycleContexts,
    editedCoverageRule,
    onClose,
    highlightedEntities,
}) => {
    const steps = useMemo(
        () => getSteps(entity, ruleType, editedCoverageRule, highlightedEntities),
        [editedCoverageRule, entity, ruleType, highlightedEntities]
    );
    const { isError, isLoading, onSubmit } = useGetOnSubmit(editionType, editionCode, onClose, editedCoverageRule);
    const [hasError, setHasError] = useState(false);

    const renderExpressionRef = useRef<RuleBuilderRefMethods | null>(null);

    const clear = useCallback((stepToClear: ManageRuleStep, dispatch: RuleDialogDispatch) => {
        stepFormEntries[stepToClear].forEach(formEntry => {
            dispatch({ type: formEntry, value: null });
            if (stepToClear === ManageRuleStep.Segment) {
                renderExpressionRef.current = null;
            }
        });
    }, []);

    const initialStep = useMemo(() => {
        return getInitialStep(entity?.code, lifecycleContexts, ruleType, editedCoverageRule);
    }, [editedCoverageRule, entity?.code, lifecycleContexts, ruleType]);

    const { currentStep, actions, dispatch, state } = useMiniFlowFormDialog({
        steps: steps.map(step => step.id),
        onClose,
        onSubmit,
        clear,
        isNextDisabled: (values, step) => {
            switch (step) {
                case ManageRuleStep.Entity:
                    return !(Boolean(values.entityCode) && Boolean(values.entityType));
                case ManageRuleStep.RuleType:
                    return !values.ruleType;
                case ManageRuleStep.Context:
                    return values.lifecycleContexts === null || values.lifecycleContexts.length === 0;
                case ManageRuleStep.Segment:
                    return hasError;
                case ManageRuleStep.Outcome:
                    return false;
                default:
                    return true;
            }
        },
        onNext: (step, injectedDispatch) => {
            if (step === ManageRuleStep.Segment) {
                if (renderExpressionRef.current == null) {
                    throw new Error('Expression renderer is not set');
                }

                injectedDispatch({ type: 'expression', value: renderExpressionRef.current.get() });
            }
        },
        initialData: {
            entityType: entity?.type ?? null,
            entityCode: entity?.code ?? null,
            ruleType: editedCoverageRule?.ruleType ?? ruleType ?? null,
            lifecycleContexts: editedCoverageRule?.lifecycleContexts ?? lifecycleContexts ?? null,
            expression: editedCoverageRule?.expression ?? null,
            outcome: editedCoverageRule?.outcome ?? null,
        },
        initialStep,
    });

    const mode = isDefined(editedCoverageRule) ? 'Edit' : 'Add';
    const title = isDefined(ruleType) ? `${mode} ${formatRuleType(ruleType)} Rule` : `${mode} Rule`;
    const contextValue = useMemo(() => {
        return { dispatch, renderExpressionRef, hasError, setHasError, values: state };
    }, [dispatch, hasError, state]);

    return (
        <StyledDialog
            actions={actions}
            className="add-rule-dialog"
            closeOnOutsideClick={false}
            error={isError ? `Failed to ${mode.toLowerCase()} rule` : undefined}
            loading={isLoading}
            minHeight={RuleDialogDimensions.minHeight}
            minWidth={RuleDialogDimensions.minWidth}
            onClose={onClose}
            size="x-large"
            title={title}
        >
            <ErrorBoundary fallback={<ErrorSection noBorders />}>
                <Suspense fallback={<LoadingSection noBorders />}>
                    <RegistryProvider>
                        <ManageRuleDialogContext.Provider value={contextValue}>
                            <StyledMiniFlow
                                activeStepIndex={steps.findIndex(step => step.id === currentStep)}
                                steps={steps}
                            />
                        </ManageRuleDialogContext.Provider>
                    </RegistryProvider>
                </Suspense>
            </ErrorBoundary>
        </StyledDialog>
    );
};
