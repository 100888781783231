import { ActionsMenu } from '@lemonade-hq/bluis';
import { Flex } from '@lemonade-hq/cdk';
import { capitalize } from '@lemonade-hq/ts-helpers';
import { useState } from 'react';
import styled from 'styled-components';
import { DialogType, StyledActionsMenuWrapper } from '../../../products/SharedTableConfig';
import { formatLifeCycleContext } from '../../display-texts/common';
import { RemoveSingleRuleDialog } from './RemoveRuleDialog';

import { ManageRuleDialog } from 'components/LoCo/common/components/CoverageRules/ManageRuleDialog';
import type { EditionType } from 'models/LoCo/Insurance/BaseEdition';

import type { CoverageRuleDisplay } from 'models/LoCo/Insurance/CoverageRule';

type ActionData =
    | {
          readonly type: DialogType.EditRule;
          readonly data: CoverageRuleDisplay;
      }
    | {
          readonly type: DialogType.RemoveRule;
          readonly data: CoverageRuleDisplay;
      };

const StyledRuleDisplayWrapper = styled(Flex)`
    padding-top: 12px;
    padding-bottom: 9px;
    position: relative;
    flex-direction: column;
    gap: 3px;
    align-items: flex-start;
`;

const StyledRuleRow = styled(Flex)`
    flex-direction: row;
    justify-content: space-between;
`;

// TODO use this and maybe use the new actions menu
export const RulesActionMenu: React.FC<{
    readonly rule: CoverageRuleDisplay;
    readonly editionCode: string;
    readonly editionType: EditionType;
}> = ({ rule, editionCode, editionType }) => {
    const [dialog, setDialog] = useState<ActionData | null>(null);

    const items = [
        {
            label: 'Edit Rule',
            value: 'editRule',
        },
        {
            label: 'Remove Rule',
            value: 'removeRule',
        },
    ];

    const onActionRequested = (type: DialogType): void => {
        switch (type) {
            case DialogType.RemoveRule:
                setDialog({
                    type: DialogType.RemoveRule,
                    data: rule,
                });
                break;
            case DialogType.EditRule:
                setDialog({
                    type: DialogType.EditRule,
                    data: rule,
                });
                break;
            default:
                break;
        }
    };

    return (
        <>
            <StyledActionsMenuWrapper>
                <ActionsMenu actions={items} onChange={value => onActionRequested(value as DialogType)} type="dots" />
            </StyledActionsMenuWrapper>
            {dialog?.type === DialogType.EditRule && (
                <ManageRuleDialog
                    editedCoverageRule={rule}
                    editionCode={editionCode}
                    editionType={editionType}
                    entity={{
                        code: rule.entityCode,
                        type: rule.entityType,
                    }}
                    onClose={() => setDialog(null)}
                    ruleType={dialog.data.ruleType}
                />
            )}
            {dialog?.type === DialogType.RemoveRule && (
                <RemoveSingleRuleDialog
                    editionCode={editionCode}
                    editionType={editionType}
                    onClose={() => setDialog(null)}
                    ruleId={rule.publicId}
                />
            )}
        </>
    );
};

const RuleItemPieceOfData: React.FC<{ readonly subject: string; readonly content: string }> = ({
    subject,
    content,
}) => {
    return (
        <span>
            <b>
                <i>{subject}</i>
            </b>
            {` ${content} `}
        </span>
    );
};

export const RuleItem: React.FC<{ readonly rule: CoverageRuleDisplay }> = ({ rule }) => {
    return (
        <StyledRuleDisplayWrapper>
            <RuleItemPieceOfData
                content={rule.lifecycleContexts.map(formatLifeCycleContext).join(', ').toLowerCase()}
                subject="FOR"
            />
            <RuleItemPieceOfData content={ruleExpressionDisplay(rule.expression)} subject="IF" />
            <RuleItemPieceOfData content={rule.outcomeDisplay} subject="THEN" />
        </StyledRuleDisplayWrapper>
    );
};

export const RuleRow: React.FC<{
    readonly rule: CoverageRuleDisplay;
    readonly editionCode: string;
    readonly editionType: EditionType;
    readonly hideActions: boolean;
}> = ({ rule, editionCode, editionType, hideActions }) => {
    return (
        <StyledRuleRow>
            <RuleItem rule={rule} />
            {!hideActions && <RulesActionMenu editionCode={editionCode} editionType={editionType} rule={rule} />}
        </StyledRuleRow>
    );
};

export function rulesCountDisplay(rules: CoverageRuleDisplay[]): string {
    const rulesCount = rules.length;
    return rulesCount === 0 ? '-' : `${rulesCount.toString()} filed rules`;
}

export function ruleExpressionDisplay(expression: string, capital = false): string {
    const text = expression === '' ? 'always (no conditions)' : expression;
    return capital ? capitalize(text) : text;
}
