import { Flex, Select, spacing, Text, TextArea } from '@lemonade-hq/blender-ui';
import { snakeCaseToReadable } from '@lemonade-hq/ts-helpers';
import type { FC } from 'react';
import { useCallback, useMemo, useState } from 'react';
import { useArchiveAttachment, useAttachmentArchiveReasons } from '../../AttachmentsQueries';
import { useAttachmentsData } from '../../context';
import type { AttachmentDTO } from '../../types';
import { AttachmentDialogBase } from './AttachmentDialogBase';

export const ArchiveDialog: FC<{ readonly attachments: AttachmentDTO[]; readonly onClose: () => void }> = ({
    attachments,
    onClose,
}) => {
    const { entityPublicId, entityType } = useAttachmentsData();
    const [reason, setReason] = useState<string>('');
    const [otherReason, setOtherReason] = useState<string>('');

    const { data, isLoading, isError } = useAttachmentArchiveReasons();
    const { mutateAsync: archive, isPending } = useArchiveAttachment({
        entityPublicId: entityPublicId ?? '',
        entityType,
    });

    const reasons = useMemo(
        () =>
            data?.map(value => ({
                value,
                label: snakeCaseToReadable(value),
            })),
        [data]
    );

    const handleSubmit = useCallback(async () => {
        await archive(
            attachments.map(attachment => ({
                reason: reason === 'other' ? otherReason : reason,
                attachmentPublicId: attachment.publicId,
            }))
        );

        onClose();
    }, [archive, attachments, onClose, otherReason, reason]);

    return (
        <AttachmentDialogBase
            attachments={attachments}
            isLoading={isLoading || isPending}
            isSubmitDisabled={reason === '' || (reason === 'other' && otherReason === '') || isPending}
            onClose={onClose}
            onSubmit={handleSubmit}
            submitLabel="Archive"
            title="Archive"
        >
            <Flex flexDirection="column" gap={spacing.s12}>
                <Flex flexDirection="column" gap={spacing.s06}>
                    <Text color="primary" type="label-sm">
                        Archive reason
                        <Text as="span" color="error">
                            &nbsp;*
                        </Text>
                    </Text>
                    <Select<never, { value: string; label: string }>
                        disabled={isLoading}
                        onChange={value => setReason(value ?? '')}
                        options={reasons ?? []}
                        placeholder="Select archive reason"
                        selectedKey={reason}
                    />
                    {isError && (
                        <Text color="error" type="label-sm">
                            Error loading archive reasons
                        </Text>
                    )}
                </Flex>
                {reason === 'other' && (
                    <Flex flexDirection="column" gap={spacing.s06}>
                        <Text color="primary" type="label-sm">
                            Other reason
                            <Text as="span" color="error">
                                &nbsp;*
                            </Text>
                        </Text>
                        <TextArea
                            autoExpanding
                            maxLength={500}
                            onChange={e => setOtherReason(e.target.value)}
                            rows={5}
                            value={otherReason}
                        />
                    </Flex>
                )}
            </Flex>
        </AttachmentDialogBase>
    );
};
