import styled from 'styled-components';

export const NoMaxLimitContainer = styled.div`
    width: 300px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: auto;
    margin-right: 25px;
    gap: 6px;
`;
