import type { ActionsMenuItem } from '@lemonade-hq/blender-ui';
import { ActionsMenu } from '@lemonade-hq/blender-ui';
import { capitalize } from '@lemonade-hq/ts-helpers';
import { useMemo } from 'react';
import type { FC } from 'react';
import type { AttachmentDTO } from 'components/Attachments/types';

export interface AttachmentActionsMenuProps {
    readonly attachment: AttachmentDTO;
    readonly setActionProps: (props: { readonly action: string; readonly attachments: AttachmentDTO[] }) => void;
}

export const AttachmentActionsMenu: FC<AttachmentActionsMenuProps> = ({ attachment, setActionProps }) => {
    const items = useMemo<ActionsMenuItem[]>(
        () =>
            attachment.actions?.map(action => ({
                label: capitalize(action),
                onClick: () => setActionProps({ action, attachments: [attachment] }),
            })) ?? [],
        [attachment, setActionProps]
    );

    if (items.length === 0) return null;

    return <ActionsMenu items={items} />;
};
