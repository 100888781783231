import { isDefined } from '@lemonade-hq/ts-helpers';
import type { Dispatch, MutableRefObject, ReactElement, ReducerAction } from 'react';
import { createContext, useCallback, useContext, useMemo } from 'react';
import { useGetProductData } from '../../hooks/useGetProduct';
import { ArgumentType } from '../ExpressionSimpleEditor/expressionTypes';
import type { InputFunction } from '../ExpressionSimpleEditor/operators';
import { ManageRuleStep } from './ManageRuleCommon';
import type { EditionType } from 'models/LoCo/Insurance/BaseEdition';
import { RuleLifecycleContext } from 'models/LoCo/Insurance/BaseEdition';
import type { CoverageRule } from 'models/LoCo/Insurance/CoverageRule';
import { CoverageRuleType } from 'models/LoCo/Insurance/CoverageRule';
import type { BaseRegistryTemplate } from 'models/LoCo/Insurance/Registry';
import { RegistryType } from 'models/LoCo/Insurance/Registry';
import type { CreateCoverageRuleParams } from 'queries/LoCo/Insurance/BaseEditionQueries';
import { useCreateEditionRule, useEditEditionRule } from 'queries/LoCo/Insurance/BaseEditionQueries';
import { useGetRegistryByProductLine } from 'queries/LoCo/Insurance/RegistryQueries';

export type ManageRuleFormState = { readonly [key in keyof Omit<CoverageRule, 'publicId'>]: CoverageRule[key] | null };

export type FormStateReducerAction<T extends keyof ManageRuleFormState = keyof ManageRuleFormState> = {
    readonly type: T;
    readonly value: ManageRuleFormState[T];
};

function reducer<T extends keyof ManageRuleFormState>(
    state: ManageRuleFormState,
    action: FormStateReducerAction<T>
): ManageRuleFormState {
    return { ...state, [action.type]: action.value };
}

export type RuleDialogDispatch = Dispatch<ReducerAction<typeof reducer>>;

export type RuleBuilderRefMethods = {
    readonly get: () => string;
};

type ManageRuleDialogContextProps = {
    readonly values: ManageRuleFormState;
    readonly dispatch: <T extends keyof ManageRuleFormState>(action: FormStateReducerAction<T>) => void;
    readonly renderExpressionRef: MutableRefObject<RuleBuilderRefMethods | null>;
    readonly hasError: boolean;
    readonly setHasError: (enabled: boolean) => void;
};

type RegistryContext = {
    readonly coverages: BaseRegistryTemplate[];
    readonly settings: BaseRegistryTemplate[];
};

export const ManageRuleDialogContext = createContext<ManageRuleDialogContextProps | null>(null);
const registryContext = createContext<RegistryContext | null>(null);

export const useManageRuleDialogContext = (): ManageRuleDialogContextProps => {
    const context = useContext(ManageRuleDialogContext);

    if (!context) {
        throw new Error('useManageRuleDialogContext must be used within ManageRuleDialogProvider');
    }

    return context;
};

export const useRegistryContext = (): RegistryContext => {
    const context = useContext(registryContext);
    if (!context) {
        throw new Error('useRegistryContext must be used within RegistryContext');
    }

    return context;
};

export const RegistryProvider: React.FC<{ readonly children: ReactElement | ReactElement[] }> = ({ children }) => {
    const product = useGetProductData();

    const [{ data: coverages }, { data: settings }] = useGetRegistryByProductLine(product.productLineCode, [
        RegistryType.Coverage,
        RegistryType.Setting,
    ]);

    const values = useMemo(
        () => ({
            coverages: coverages,
            settings: settings,
        }),
        [coverages, settings]
    );

    return <registryContext.Provider value={values}>{children}</registryContext.Provider>;
};

export function getInitialStep(
    templateCode?: string,
    lifecycleContexts?: RuleLifecycleContext[],
    ruleType?: CoverageRuleType,
    editedCoverageRule?: CoverageRule
): ManageRuleStep {
    if (isDefined(templateCode)) {
        // for initial value, we skip to the next step, but for restriction, we allow the user to change the lifecycle contexts
        if (
            isDefined(lifecycleContexts) &&
            lifecycleContexts.length > 0 &&
            isDefined(ruleType) &&
            ruleType === CoverageRuleType.InitialValue
        ) {
            return ManageRuleStep.Segment;
        }

        if (isDefined(editedCoverageRule)) return ManageRuleStep.Segment;

        return isDefined(ruleType) ? ManageRuleStep.Context : ManageRuleStep.RuleType;
    }

    return ManageRuleStep.Entity;
}

export function useGetOnSubmit(
    editionType: EditionType,
    editionCode: string,
    onClose: () => void,
    editedCoverageRule?: CoverageRule
): {
    readonly onSubmit: (values: ManageRuleFormState) => Promise<void>;
    readonly isError: boolean;
    readonly isLoading: boolean;
} {
    const {
        mutateAsync: createEditionRule,
        isError: isCreateError,
        isPending: isAddLoading,
    } = useCreateEditionRule(editionType, editionCode);

    const {
        mutateAsync: editEditionRule,
        isError: isEditError,
        isPending: isEditLoading,
    } = useEditEditionRule(editionType, editionCode);

    const onSubmit = useCallback(
        async (values: ManageRuleFormState) => {
            if (
                values.entityCode === null ||
                values.entityType === null ||
                values.expression === null ||
                values.lifecycleContexts === null ||
                values.ruleType === null ||
                values.outcome === null
            ) {
                throw new Error('Missing required fields');
            }

            const rule: CreateCoverageRuleParams['rule'] = {
                ruleType: values.ruleType,
                outcome: values.outcome,
                entityCode: values.entityCode,
                entityType: values.entityType,
                expression: values.expression,
                lifecycleContexts: values.lifecycleContexts,
            };

            if (isDefined(editedCoverageRule)) {
                await editEditionRule({
                    rule: {
                        ...rule,
                        publicId: editedCoverageRule.publicId,
                    },
                });
            } else {
                await createEditionRule({
                    rule,
                });
            }

            onClose();
        },
        [editedCoverageRule, onClose, editEditionRule, createEditionRule]
    );

    return { onSubmit, isError: isCreateError || isEditError, isLoading: isAddLoading || isEditLoading };
}

export type ManageRuleStepItem = {
    readonly title: string;
    readonly body: JSX.Element;
    readonly id: ManageRuleStep;
};

export function getAdditionalFunctions(
    coverages: BaseRegistryTemplate[],
    settings: BaseRegistryTemplate[],
    lifecycleContexts: RuleLifecycleContext[]
): Record<string, InputFunction> {
    const coveragesNames = coverages.map(coverage => coverage.name);
    const settingsNames = settings.map(setting => setting.name);

    const showPrevious = [RuleLifecycleContext.Renewal, RuleLifecycleContext.Moving].some(context =>
        lifecycleContexts.includes(context)
    );

    const settingInputBase = {
        argument: {
            type: ArgumentType.Enum,
            symbols: settingsNames,
            enumName: 'Settings',
        },
        returnType: {
            type: ArgumentType.Number,
        },
    } as InputFunction;

    const coverageInputBase = {
        argument: {
            type: ArgumentType.Enum,
            symbols: coveragesNames,
            enumName: 'Coverages',
        },
        returnType: {
            type: ArgumentType.Enum,
            symbols: ['On', 'Off'],
            enumName: 'CoverageStatus',
        },
    } as InputFunction;

    let functions: Record<string, InputFunction> = {
        selectedCoverage: {
            ...coverageInputBase,
            display: 'Coverage (this term)',
        },
        selectedSetting: {
            ...settingInputBase,
            display: 'Setting (this term)',
        },
    };

    if (showPrevious) {
        functions = {
            ...functions,
            previousCoverage: {
                ...coverageInputBase,
                display: 'Coverage (previous term)',
            },
            previousSetting: {
                ...settingInputBase,
                display: 'Setting (previous term)',
            },
        };
    }

    return functions;
}
