import { Layout, Table, useCheckableTable } from '@lemonade-hq/blender-ui';
import { Attachment } from '@lemonade-hq/bluis';
import { useCallback } from 'react';
import type { FC } from 'react';
import { useAttachmentsData } from '../../context';
import { MISSING_VALUE } from '../../utils';
import type { AttachmentActionsMenuProps } from './AttachmentActionMenu';
import { AttachmentActionsMenu } from './AttachmentActionMenu';

// TODO add possibility to render custom columns
// TODO add actions by those returned on the attachment
// TODO add loading state

const cols = [
    { key: 'id', name: '', hidden: true },
    { key: 'thumbnail', name: '' },
    { key: 'fileName', name: 'File Name' },
    { key: 'type', name: 'Type' },
    { key: 'description', name: 'Description' },
    { key: 'uploadDate', name: 'Upload date' },
    { key: 'actions', name: '' },
];

export const ActiveAttachmentsTable: FC<{
    readonly handleClick: (index: number) => void;
    readonly setActionProps: AttachmentActionsMenuProps['setActionProps'];
}> = ({ handleClick, setActionProps }) => {
    const { attachments } = useAttachmentsData();
    const { selectedRows, handleToggleRow, handleToggleAll } = useCheckableTable([]);

    const getRows = useCallback(() => {
        return attachments.map((attachment, index) => {
            return {
                id: { value: attachment.publicId, hidden: true },
                thumbnail: {
                    value: (
                        <Attachment
                            attachment={attachment}
                            onClick={() => handleClick(index)}
                            type={attachment.contentType ?? ''}
                            url={attachment.url}
                        />
                    ),
                },
                fileName: { value: attachment.fileName ?? MISSING_VALUE },
                type: { value: attachment.type ?? MISSING_VALUE },
                description: { value: attachment.description ?? MISSING_VALUE },
                uploadDate: { value: MISSING_VALUE },
                actions: { value: <AttachmentActionsMenu attachment={attachment} setActionProps={setActionProps} /> },
            };
        });
    }, [attachments, handleClick, setActionProps]);

    const onSelectRow = (rowId: string): void => {
        handleToggleRow(rowId);
    };

    const onSelectAll = (rowIds: string[]): void => {
        handleToggleAll(rowIds);
    };

    const checkableProps = {
        checkedIds: Array.from(selectedRows),
        onAllChecked: onSelectAll,
        onRowChecked: onSelectRow,
        rowIdentifier: 'id',
    };

    return (
        <Layout>
            <Table checkable={checkableProps} columns={cols} data={getRows()} />
        </Layout>
    );
};
